<template>
  <div class="container">
    <Title v-if="!isAdmin" :showMinister="true" :showService="true" />

    <Carrusel />
    <div>
        <Breadcrumb :links="breadCrumbData" />

      <Main :readOnly="readOnly" />
    </div>
  </div>
</template>

<script>
import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
// import Buttons from './Buttons.vue'
import Main from './Main.vue'
import Carrusel from '@/components/private/Carrusel.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Title,
    Breadcrumb,
    // Buttons,
    Main,
    Carrusel
  },
  data () {
    return {
      readOnly: true,
      breadCrumbData: null
    }
  },
  computed: {
    // get user
    ...mapState('sesion', ['usuario']),
    isAdmin: function () {
      return this.usuario.username === process.env.VUE_APP_USER_ADMIN
    }
  },
  methods: {
    // name of breadcrumb
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        {
          name: 'Portada BGI',
          routeName: 'bgi',
          routeParams: {
            idUsuario: this.usuario.id
          }
        },
        {
          name: 'Escritorio de Gestión Virtual',
          routeName: 'buttons',
          routeParams: {
            idUsuario: this.usuario.id,
            idServicio: this.$route.params.idServicio
          }
        },
        {
          name: 'Evaluaciones - Informes Programa',
          routeName: 'informeprograma',
          routeParams: {
            idServicio: this.$route.params.idServicio
          }
        }
      ]
    },
    // USER SERVICE RELATIONSHIP
    obtenerRelacionUsuarioServicio () {
      const data = JSON.parse(localStorage.getItem('user'))
      const relacionUs = data.servicios.find(
        (s) => s.id === parseInt(this.$route.params.idServicio)
      )
      if (!relacionUs) return
      if (
        relacionUs.permisos.find((p) => p.permisoId === 9) ||
        relacionUs.rolId === 3 ||
        relacionUs.rolId === 4 ||
        relacionUs.rolId === 5
      ) {
        this.readOnly = false
      }
    }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRelacionUsuarioServicio()
  }
}
</script>

.<style scoped>
.containers {
  margin-left: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
}
</style>
