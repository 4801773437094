<template>
  <div>
    <div>
      <Navbar />
      <Header
        :titulo="nombreServicio"
        :desc="ministerioServicio"
        :showYearSelect="false"
      />
    </div>

    <div>
      <AlertError text="En este momento no se han encontrado datos, por favor vuelva pronto."/>
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/layout/Navbar.vue'
import Header from '../../components/cabecera/Header.vue'
import AlertError from '../../components/error/AlertError.vue'
// import Contents from "./components/Contents.vue"
export default {
  components: {
    Navbar,
    Header,
    AlertError
  },
  data () {
    return {
      nombreServicio: 'Acerca de',
      ministerioServicio: 'Acerca de'
    }
  }
}
</script>
