<template>
  <div>
    <b-button variant="link" class="modal-button" id="show-btn" @click="$bvModal.show('bv-modal-example')">
      <Config /> {{ button }}
    </b-button>
    <b-modal  size="lg" id="bv-modal-example" hide-footer>
      <template #modal-title><h4 class="title-modal">{{ title }}</h4></template>

      <div class="container" style="margin-top:5px">
        Entregar un recuento de la gestión operativa y económica del ministerio, a través de una breve descripción, su misión, servicios relacionados o dependientes, recursos financieros, dotación, ciudadanos a los que atiende o afecta y principales logros o resultados relevantes de la gestión del año 2023 del ministerio en su conjunto. (Relato simple, claro comunicacionalmente preciso, y con información validada y confiable).
        <br><br>
        Adicionalmente agregar resultados o logros relevantes de Compromisos del período de Gobierno 2022-2026, que el ministro del ramo decida resaltar.
        <br><br>
        El relato debe permitir vincular algún resultado del ministerio con logro o logros relevantes de los Servicios.
        <br><br>
        Para lo anterior se deben presentar, para los Servicios que desee relevar, los avances del desempeño institucional que correspondan al año 2023, con efecto en los usuarios, beneficiarios o ciudadanos para el caso de instituciones que atienden directa o indirectamente usuarios, beneficiarios o ciudadanos.
        <br><br>
        Se entiende por avance a las acciones desarrolladas por la institución que tuvieron resultados satisfactorios (logros), los que pueden expresarse de manera cuantitativa a través de indicadores de desempeño y/o niveles de actividad, o de manera cualitativa.
        <br><br>
        Estas acciones pueden corresponder a actividades relacionadas con el funcionamiento normal del Servicio y/o a actividades extraordinarias. En especial aquellos resultados que el ministro del ramo desea destacar, ya sea como parte o respuesta respecto de prioridades del Programa de Gobierno 2022-2026, prioridades ministeriales del período 2022-2026, prioridades manifestadas por la ciudadanía, Presupuesto, y Productos Estratégicos, según corresponda. En todos los casos, centrado en aquello que corresponda dar cuenta del período de Gobierno 2022-2026.
        <br><br>
        El texto debe mostrar claramente los aspectos relevantes de la gestión de la Institución que el ministro quiere relevar, y que están relacionadas con el período de Gobierno. De esta manera, se refuerza la participación de la más alta autoridad en el BGI de la respectiva institución.
        <br><br>
        A modo de orientación, se podrían considerar algunas acciones, actividades o proyectos relacionados con:
        <ul>
          <li>Mejores resultados en la provisión de bienes y servicios (productos estratégicos), expresados, por ejemplo, en niveles de cobertura, efecto en los usuarios, beneficiarios, ciudadanos u otras variables relevantes de medición.</li>
          <li>Fortalecimiento de las capacidades con que cuentan las diversas áreas o centros de responsabilidad de la institución mediante la implementación de leyes, programas, iniciativas o planes desarrollados.</li>
          <li>Cumplimiento de compromisos existentes en la agenda de gobierno, ministerio o servicio.</li>
          <li>Avances en áreas de gestión transversal, que contribuyan a una mayor eficacia y/o eficiencia en la operación del Servicio.</li>
          <li>Otros logros que sean considerados relevantes por el Servicio.</li>
        </ul>
        <br><br>
        Para la presentación de cada avance se deberá acompañar una breve descripción cualitativa que permita contextualizar el avance que se describe y exponerlo de forma precisa y objetiva. También, se recomienda fuertemente que se sustente cuantitativamente, y podrá apoyarse con gráficos y tablas.
      </div>

    </b-modal>
  </div>
</template>

<script>
import Config from '@/components/image/icon/Config'
export default {
  props: ['html'],
  components: {
    Config
  },
  data () {
    return {
      button: 'Instrucciones: ¿Cómo completar esta sección?'
    }
  }
}
</script>

<style scoped>
.modal-button {
  display: flex;
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.modal-button:hover {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.modal-button:focus {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.title-modal {
  color: #052333;
  font-size: 20px;
  text-align: center;
}

</style>
