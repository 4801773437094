<template>
  <div class="containers">
    <b-row>
      <div>
        <Help />
      </div>

      <div>
        <Visualizar :idServicio="idServicio" seccion="anexo_2" v-if="1===2"/>
      </div>
      <div v-if="!readOnly" class="div-button">
        <!--
        <b-button
          variant="primary"
          @click="guardarBorrador"
          v-if="userPermissions"
          style="margin-right: 5px"
          class="btn-one"
          pill
        >
          Guardar Borrador
        </b-button>
        -->
        <b-button
          variant="primary"
          @click="guardarYFinalizar"
          v-if="userPermission"
          class="btn-two"
          pill
        >
          Guardar
        </b-button>
      </div>
    </b-row>

    <h3 class="title">Recursos Humanos</h3>

    <b-row v-if="userPermissions" class="Checkbox-form">
      <b-form-checkbox
        v-if="!readOnly"
        style="display:flex;   justify-content: flex-end; "
        v-model="checked"
        name="check-button"
        switch
      >
        <span class="span" v-if="checked"> No Aplica</span>
        <span class="span" v-else>No Aplica</span>
      </b-form-checkbox>
    </b-row>
    <b-row v-if="checked">
      <AlertError />
    </b-row>

    <b-row v-else>
      <div v-if="servicios_listado()">
        <b-row>
          <table style="border-color: red; border-width: 2px;" border="1">
            <tr>
              <td>
                Nota: Se hace presente que las cifras de este anexo responden al IV Informe Trimestral de los Recursos Humanos del Sector Público del año {{anioBgi}} de la Dirección de Presupuestos, situación que explica eventuales diferencias con la información de la respectiva Institución.
              </td>
            </tr>
          </table>
          <br>
        </b-row>
        <div class="container" v-if="estadisticas">
        <!-- Priemera Grafica-->
        <section class="seccion-background-color-table" >

          <div class="head-data">
            <h6 class="title">Dotación efectiva año {{anioBgi}}, por tipo de contrato (mujeres y hombres)</h6>
          </div>
          <grafico-general :datos_completos="estadisticas" filtro="dotacion" columna='calidad_juridica' />
        </section>

        <section class="seccion-background-color-table">
          <div class="head-data">
            <h6 class="title">Dotación efectiva año {{anioBgi}}, por Estamento (mujeres y hombres)</h6>
          </div>
          <grafico-general :datos_completos="estadisticas" filtro="dotacion" columna='estamento' />
        </section>

        <section class="seccion-background-color-table">
          <div class="head-data">
            <h6 class="title">Dotación efectiva año {{anioBgi}}, por Grupos de Edad (mujeres y hombres)</h6>
          </div>
          <grafico-general :datos_completos="estadisticas" filtro="dotacion" columna='edad' />
        </section>

        <section class="seccion-background-color-table">
          <div class="head-data">
            <h6 class="title">Personal fuera de dotación {{anioBgi}}, por tipo de contrato (mujeres y hombres)</h6>
          </div>
          <grafico-general :datos_completos="estadisticas" filtro="fuera_dotacion" columna='calidad_juridica' />
        </section>

        <!-- Quinta grafica -->
        <section class="seccion-background-color-table">
          <div class="head-data">
            <h6 class="title">Personal fuera de dotación año {{anioBgi}}, según función desempeñada (mujeres y hombres)</h6>
          </div>
          <grafico-general :datos_completos="estadisticas" filtro="fuera_dotacion" columna='estamento' />
        </section>

        <!-- Sexta grafica -->
        <section class="seccion-background-color-table">
          <div class="head-data">
            <h6 class="title">Personal a honorarios año {{anioBgi}}, según permanencia en el Servicio (mujeres y hombres)</h6>
          </div>
          <grafico-general :datos_completos="estadisticas" filtro="honorarios" columna='permanencia' />
          <!-- <MainTable /> -->
        </section>
        </div>
        <div v-if="indicadores" >
        <section class="seccion-background-color-table">
          <h3 class="title">1. Reclutamiento y Selección</h3>
          <h4 class="rrhh-indicador">1.1 Porcentaje de ingresos a la contrata cubiertos por procesos de reclutamiento y selección</h4>
          <table >
            <tr>
              <th style="width:600px">Variables</th>
              <th>{{YearTwo}}</th>
              <th>{{YearOne}}</th>
              <th>{{anioBgi}}</th>
            </tr>
            <tr>
              <td>(a) N° de ingresos a la contrata año t vía proceso de reclutamiento y selección.</td>
              <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.porcentaje_ingresos.numerador[YearTwo]"/></td>
              <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.porcentaje_ingresos.numerador[YearOne]"/></td>
              <td><input type="number" step="1" class="form-control" v-model="indicadores.porcentaje_ingresos.numerador[anioBgi]"/></td>
            </tr>
            <tr>
              <td>(b) Total de ingresos a la contrata año t</td>
              <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.porcentaje_ingresos.denominador[YearTwo]"/></td>
              <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.porcentaje_ingresos.denominador[YearOne]"/></td>
              <td><input type="number" step="1" class="form-control" v-model="indicadores.porcentaje_ingresos.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje ingresos a la contrata con proceso de reclutamiento y selección (a/b)</td>
                <td>{{relacion_porcentaje('porcentaje_ingresos',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('porcentaje_ingresos',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('porcentaje_ingresos',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">1.2 Efectividad de la selección</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de ingresos a la contrata vía proceso de reclutamiento y selección año t, con renovación de contrato para año t+1</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.efectividad_seleccion.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.efectividad_seleccion.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.efectividad_seleccion.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) N° de ingresos a la contrata año t vía proceso de reclutamiento y selección</td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.efectividad_seleccion.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.efectividad_seleccion.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.efectividad_seleccion.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje de ingresos con proceso de reclutamiento y selección, con renovación (a/b)</td>
                <td>{{relacion_porcentaje('efectividad_seleccion',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('efectividad_seleccion',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('efectividad_seleccion',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
        </section>
        <section class="seccion-background-color-table">
          <h3 class="title">2. Rotación de Personal</h3>
          <h4 class="rrhh-indicador">2.1 Porcentaje de egresos del Servicio respecto de la dotación efectiva</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de funcionarios que cesan o se retiran del Servicio por cualquier causal año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.egreso_servicio.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.egreso_servicio.numerador[YearOne]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.egreso_servicio.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total dotación efectiva año t</td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.egreso_servicio.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.egreso_servicio.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.egreso_servicio.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje de funcionarios que cesan o se retiran (a/b)</td>
                <td>{{relacion_porcentaje('egreso_servicio',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('egreso_servicio',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('egreso_servicio',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">2.2 Causales de cese o retiro</h4>
          <table >
            <tr>
                <th style="width:600px">Causales</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>Funcionarios jubilados año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.causales_retiros.jubilados[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.causales_retiros.jubilados[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.causales_retiros.jubilados[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Funcionarios fallecidos año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.causales_retiros.fallecidos[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.causales_retiros.fallecidos[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.causales_retiros.fallecidos[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Retiros voluntarios con incentivo al retiro año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.causales_retiros.incentivo[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.causales_retiros.incentivo[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.causales_retiros.incentivo[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Otros retiros voluntarios año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.causales_retiros.voluntario[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.causales_retiros.voluntario[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.causales_retiros.voluntario[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Funcionarios retirados por otras causales año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.causales_retiros.otros[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.causales_retiros.otros[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.causales_retiros.otros[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Total de ceses o retiros</td>
                <td>{{suma_causales(YearTwo) | formatInteger}} </td>
                <td>{{suma_causales(YearOne) | formatInteger}} </td>
                <td>{{suma_causales(anioBgi) | formatInteger}} </td>
            </tr>
          </table>
          <br>
          <h4 class="rrhh-indicador">2.3 Porcentaje de recuperación de funcionarios</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de funcionarios que ingresan a la dotación del Servicio año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.recuperacion_funcionarios.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.recuperacion_funcionarios.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.recuperacion_funcionarios.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) N° de funcionarios que cesan o se retiran del Servicio por cualquier causal año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.recuperacion_funcionarios.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.recuperacion_funcionarios.denominador[YearOne]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.recuperacion_funcionarios.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje de recuperación (a/b)</td>
                <td>{{relacion_porcentaje('recuperacion_funcionarios',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('recuperacion_funcionarios',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('recuperacion_funcionarios',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
        </section>
        <section class="seccion-background-color-table">
          <h3 class="title">3. Grado de Movilidad en el Servicio</h3>
          <h4 class="rrhh-indicador">3.1 Porcentaje de funcionarios de planta ascendidos o promovidos, respecto de la Planta Efectiva de Personal</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de funcionarios de Planta ascendidos o promovidos año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_ascendidos.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_ascendidos.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.funcionarios_ascendidos.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total Planta efectiva año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_ascendidos.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_ascendidos.denominador[YearOne]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_ascendidos.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje de funcionarios ascendidos o promovidos (a/b)</td>
                <td>{{relacion_porcentaje('funcionarios_ascendidos',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('funcionarios_ascendidos',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('funcionarios_ascendidos',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">3.2 Porcentaje de funcionarios recontratados en grado superior, respecto del total de funcionarios contratados</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de funcionarios recontratados en grado superior año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_recontratados.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_recontratados.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.funcionarios_recontratados.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total Contratos efectivos año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_recontratados.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_recontratados.denominador[YearOne]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_recontratados.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje de recontratados en grado superior (a/b)</td>
                <td>{{relacion_porcentaje('funcionarios_recontratados',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('funcionarios_recontratados',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('funcionarios_recontratados',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
        </section>
        <section class="seccion-background-color-table">
          <h3 class="title">4. Capacitación y Perfeccionamiento del Personal</h3>
          <h4 class="rrhh-indicador">4.1 Porcentaje de funcionarios capacitados, respecto de la Dotación Efectiva</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de funcionarios capacitados año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_capacitados.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.funcionarios_capacitados.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.funcionarios_capacitados.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total Dotación Efectiva año t</td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.funcionarios_capacitados.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.funcionarios_capacitados.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.funcionarios_capacitados.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje de funcionarios capacitados (a/b)</td>
                <td>{{relacion_porcentaje('funcionarios_capacitados',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('funcionarios_capacitados',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('funcionarios_capacitados',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">4.2 Promedio anual de horas contratadas para Capacitación por funcionario</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) ∑(N° horas contratadas en act. de capacitación año t * N° participantes capacitados en act. de capacitación año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.horas_capacitacion.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.horas_capacitacion.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.horas_capacitacion.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total de participantes capacitados año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.horas_capacitacion.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.horas_capacitacion.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.horas_capacitacion.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Promedio de horas de capacitación por funcionario (a/b)</td>
                <td>{{relacion('horas_capacitacion',YearTwo) | formatDecimal}}</td>
                <td>{{relacion('horas_capacitacion',YearOne) | formatDecimal}}</td>
                <td>{{relacion('horas_capacitacion',anioBgi) | formatDecimal}}</td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">4.3 Porcentaje de actividades de capacitación con evaluación de transferencia</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de actividades de capacitación con evaluación de transferencia en el puesto de trabajo año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.actividades_capacitacion.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.actividades_capacitacion.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.actividades_capacitacion.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) N° de actividades de capacitación año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.actividades_capacitacion.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.actividades_capacitacion.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.actividades_capacitacion.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje de actividades con evaluación de transferencia (a/b)</td>
                <td>{{relacion_porcentaje('actividades_capacitacion',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('actividades_capacitacion',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('actividades_capacitacion',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">4.4 Porcentaje de becas otorgadas respecto a la Dotación Efectiva</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de becas otorgadas año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.becas_otorgadas.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.becas_otorgadas.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.becas_otorgadas.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total Dotación Efectiva año t</td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.becas_otorgadas.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.becas_otorgadas.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.becas_otorgadas.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje de becados (a/b)</td>
                <td>{{relacion_porcentaje('becas_otorgadas',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('becas_otorgadas',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('becas_otorgadas',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
        </section>
        <section class="seccion-background-color-table">
          <h3 class="title">5. Días no Trabajados</h3>
          <h4 class="rrhh-indicador">5.1 Promedio mensual de días no trabajados por funcionario, por concepto de licencias médicas Tipo 1</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de días de licencia médica Tipo 1, año t, / 12</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.dias_lm_1.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.dias_lm_1.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.dias_lm_1.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total Dotación Efectiva año t</td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.dias_lm_1.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.dias_lm_1.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.dias_lm_1.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Promedio mensual días no trabajados por funcionario por licencias médicas Tipo 1 (a/b)</td>
                <td>{{relacion('dias_lm_1',YearTwo) | formatDecimal}}</td>
                <td>{{relacion('dias_lm_1',YearOne) | formatDecimal}}</td>
                <td>{{relacion('dias_lm_1',anioBgi) | formatDecimal}}</td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">5.2 Promedio mensual de días no trabajados por funcionario, por concepto de licencias médicas otro tipo</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de días de licencia médica de otro tipo , año t, / 12</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.dias_lm_otras.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.dias_lm_otras.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.dias_lm_otras.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total Dotación Efectiva año t</td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.dias_lm_otras.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.dias_lm_otras.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.dias_lm_otras.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Promedio mensual días no trabajados por funcionario por licencias de otro tipo (a/b)</td>
                <td>{{relacion('dias_lm_otras',YearTwo) | formatDecimal}}</td>
                <td>{{relacion('dias_lm_otras',YearOne) | formatDecimal}}</td>
                <td>{{relacion('dias_lm_otras',anioBgi) | formatDecimal}}</td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">5.3 Promedio mensual de días no trabajados por funcionario, por concepto de permisos sin goce de remuneraciones</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de días de permisos sin goce de remuneraciones, año t, /12</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.dias_permiso.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.dias_permiso.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.dias_permiso.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total Dotación Efectiva año t</td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.dias_permiso.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.dias_permiso.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.dias_permiso.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Promedio mensual días no trabajados por permisos sin goce de remuneraciones (a/b)</td>
                <td>{{relacion('dias_permiso',YearTwo) | formatDecimal}}</td>
                <td>{{relacion('dias_permiso',YearOne) | formatDecimal}}</td>
                <td>{{relacion('dias_permiso',anioBgi) | formatDecimal}}</td>
            </tr>
          </table>
        </section>
        <section class="seccion-background-color-table">
          <h3 class="title">6. Grado de Extensión de la Jornada</h3>
          <h4 class="rrhh-indicador">6 Promedio mensual de horas extraordinarias realizadas por funcionarios</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de horas extraordinarias año t, / 12</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.horas_extra.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.horas_extra.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.horas_extra.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total Dotación Efectiva año t</td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.horas_extra.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.horas_extra.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" disabled v-model="indicadores.horas_extra.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Promedio mensual horas extraordinarias por funcionario (a/b)</td>
                <td>{{relacion('horas_extra',YearTwo) | formatDecimal}}</td>
                <td>{{relacion('horas_extra',YearOne) | formatDecimal}}</td>
                <td>{{relacion('horas_extra',anioBgi) | formatDecimal}}</td>
            </tr>
          </table>
        </section>
        <section class="seccion-background-color-table">
          <h3 class="title">7. Evaluación del Desempeño</h3>
          <h4 class="rrhh-indicador">7.1 Distribución del Personal según los resultados de las Calificaciones</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>Lista 1</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.evaluacion.l_1[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.evaluacion.l_1[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.evaluacion.l_1[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Lista 2</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.evaluacion.l_2[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.evaluacion.l_2[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.evaluacion.l_2[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Lista 3</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.evaluacion.l_3[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.evaluacion.l_3[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.evaluacion.l_3[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Lista 4</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.evaluacion.l_4[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.evaluacion.l_4[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.evaluacion.l_4[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(a) Total de funcionarios evaluados</td>
                <td>{{suma_calificaciones(YearTwo) | formatInteger}} </td>
                <td>{{suma_calificaciones(YearOne) | formatInteger}} </td>
                <td>{{suma_calificaciones(anioBgi) | formatInteger}} </td>
            </tr>
            <tr>
                <td>(b) Total Dotación Efectiva año t</td>
                <td>{{indicadores.horas_extra.denominador[YearTwo]}}</td>
                <td>{{indicadores.horas_extra.denominador[YearOne]}}</td>
                <td>{{indicadores.horas_extra.denominador[anioBgi]}}</td>
            </tr>
            <tr>
                <td>Porcentaje de funcionarios evaluados (a/b)</td>
                <td>{{relacion_porcentaje_calificaciones(YearTwo, indicadores.horas_extra.denominador[YearTwo]) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje_calificaciones(YearOne, indicadores.horas_extra.denominador[YearOne]) | formatDecimal}} %</td>
                <td>{{relacion_porcentaje_calificaciones(anioBgi, indicadores.horas_extra.denominador[anioBgi]) | formatDecimal}} %</td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">7.2 Sistema formal de retroalimentación del desempeño implementado</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>Tiene sistema de retroalimentación implementado (Sí / No)</td>
                <td><input type="text" disabled class="form-control" v-model="indicadores.retroalimentacion.numerador[YearTwo]"/></td>
                <td><input type="text" disabled class="form-control" v-model="indicadores.retroalimentacion.numerador[YearOne]"/></td>
                <td><input type="text" class="form-control" v-model="indicadores.retroalimentacion.numerador[anioBgi]"/></td>
            </tr>
          </table>
        </section>
        <section class="seccion-background-color-table">
          <h3 class="title">8. Política de Gestión de Personas</h3>
          <h4 class="rrhh-indicador">8 Política de Gestión de Personas formalizada</h4>
          <table >
            <tr>
              <th style="width:600px">Variables</th>
              <th>{{YearTwo}}</th>
              <th>{{YearOne}}</th>
              <th>{{anioBgi}}</th>
            </tr>
            <tr>
              <td>Tiene Política de Gestión de Personas formalizada vía Resolución (Sí / No)</td>
              <td><input type="text" disabled class="form-control" v-model="indicadores.politica_gestion.numerador[YearTwo]"/></td>
              <td><input type="text" disabled class="form-control" v-model="indicadores.politica_gestion.numerador[YearOne]"/></td>
              <td><input type="text" class="form-control" v-model="indicadores.politica_gestion.numerador[anioBgi]"/></td>
            </tr>
          </table>
        </section>
        <section class="seccion-background-color-table">
          <h3 class="title">9. Regularización de Honorarios</h3>
          <h4 class="rrhh-indicador">9.1 Representación en el ingreso a la Contrata</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de personas a honorarios traspasadas a la contrata año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.representacion_ingreso.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.representacion_ingreso.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.representacion_ingreso.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) Total de ingresos a la contrata año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.representacion_ingreso.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.representacion_ingreso.denominador[YearOne]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.representacion_ingreso.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje de honorarios traspasados a la contrata(a/b)</td>
                <td>{{relacion_porcentaje('representacion_ingreso',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('representacion_ingreso',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('representacion_ingreso',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">9.2 Efectividad proceso regularización</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de personas a honorarios traspasadas a la contrata año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.efectividad_regularizacion.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.efectividad_regularizacion.numerador[YearOne]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.efectividad_regularizacion.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) N° de personas a honorarios regularizables año t-1</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.efectividad_regularizacion.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.efectividad_regularizacion.denominador[YearOne]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.efectividad_regularizacion.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje de honorarios regularizados (a/b)</td>
                <td>{{relacion_porcentaje('efectividad_regularizacion',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('efectividad_regularizacion',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('efectividad_regularizacion',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
          <h4 class="rrhh-indicador">9.3 Índice honorarios regularizables</h4>
          <table >
            <tr>
                <th style="width:600px">Variables</th>
                <th>{{YearTwo}}</th>
                <th>{{YearOne}}</th>
                <th>{{anioBgi}}</th>
            </tr>
            <tr>
                <td>(a) N° de personas a honorarios regularizables año t</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.honorarios_regularizables.numerador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.honorarios_regularizables.numerador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.honorarios_regularizables.numerador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>(b) N° de personas a honorarios regularizables año t-1</td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.honorarios_regularizables.denominador[YearTwo]"/></td>
                <td><input type="number" step="1" disabled class="form-control" v-model="indicadores.honorarios_regularizables.denominador[YearOne]"/></td>
                <td><input type="number" step="1" class="form-control" v-model="indicadores.honorarios_regularizables.denominador[anioBgi]"/></td>
            </tr>
            <tr>
                <td>Porcentaje (a/b)</td>
                <td>{{relacion_porcentaje('honorarios_regularizables',YearTwo) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('honorarios_regularizables',YearOne) | formatDecimal}} % </td>
                <td>{{relacion_porcentaje('honorarios_regularizables',anioBgi) | formatDecimal}} % </td>
            </tr>
          </table>
        </section>
        </div>
      </div>
      <div v-if="!servicios_listado()">
        <br>
        <br>
        <table style="border-color: red; border-width: 2px;" border="1">
          <tr>
            <td>
              Al ser un Servicio nuevo, el personal fue contratado con cargo al presupuesto del Servicio Nacional de Menores y de la Subsecretaría de Justicia, motivo por el cual no cuenta con información para este periodo. Esta información estará disponible en el próximo Balance de Gestión Integral.
            </td>
          </tr>
        </table>
      </div>
    </b-row>
  </div>
</template>

<script>
// import Graphics from './Graphics.vue'
import Help from '@/components/tools/Modal.vue'
// import Tables from './Tables.vue'
import { mapState } from 'vuex'
import axios from 'axios'
import AlertError from '@/components/error/AlertError.vue'
// import Loading from "@/components/tools/Loading.vue";
// import { showAlert } from '@/components/tools/utils'
// import Swal from 'sweetalert2'
import graficoGeneral from './graphics/graficoGeneral.vue'
import { utils } from '@/components/tools/ViewUtils.js'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'

export default {
  mixins: [utils],
  components: {
    // Upload,
    // Graphics,
    Help,
    // Tables,
    AlertError,
    // Loading,
    graficoGeneral,
    Visualizar
  },
  data () {
    return {
      anexo: null,
      estadisticas: null,
      datos: null,
      indicadores: null,
      title: 'Recursos Humanos',
      text:
        'La información de esta sección debe ser elaborada con los antecedentes entregados a Dipres en la matriz D correspondiente al IV Informe Trimestral de Dotación de Personal 2020, con información proporcionada en anteriores BGI y de conformidad con los criterios que se indican a continuación.',
      text_one:
        'Para la elaboración de este documento, considere la versión de su matriz D que incorpore todas las correcciones solicitadas por Dipres.',
      readOnly: true,
      userPermissions: true,
      userPermission: true,
      checked: false
    }
  },

  computed: {
    // get User
    ...mapState('sesion', ['usuario']),
    isAdmin: function () {
      return this.usuario.username === process.env.VUE_APP_USER_ADMIN
    }
  },

  methods: {
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'recursos-humanos'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        // this.estadisticas = anexo.datos.estadisticas.filter(e => { return e.anio === parseInt(this.anioBgi) })
        this.estadisticas = anexo.datos.estadisticas
        var indicadores = anexo.datos.indicadores || {}
        this.inicializar_variable(indicadores, 'porcentaje_ingresos', null)
        this.inicializar_variable(indicadores, 'efectividad_seleccion', null)
        this.inicializar_variable(indicadores, 'egreso_servicio', null)
        this.inicializar_variable(indicadores, 'recuperacion_funcionarios', null)
        this.inicializar_variable(indicadores, 'funcionarios_ascendidos', null)
        this.inicializar_variable(indicadores, 'funcionarios_recontratados', null)
        this.inicializar_variable(indicadores, 'funcionarios_capacitados', null)
        this.inicializar_variable(indicadores, 'horas_capacitacion', null)
        this.inicializar_variable(indicadores, 'actividades_capacitacion', null)
        this.inicializar_variable(indicadores, 'becas_otorgadas', null)
        this.inicializar_variable(indicadores, 'dias_lm_1', null)
        this.inicializar_variable(indicadores, 'dias_lm_otras', null)
        this.inicializar_variable(indicadores, 'dias_permiso', null)
        this.inicializar_variable(indicadores, 'horas_extra', null)
        this.inicializar_variable(indicadores, 'retroalimentacion', null)
        this.inicializar_variable(indicadores, 'politica_gestion', null)

        this.inicializar_variable(indicadores, 'representacion_ingreso', null)

        this.inicializar_variable(indicadores, 'efectividad_regularizacion', null)

        this.inicializar_variable(indicadores, 'honorarios_regularizables', null)

        this.inicializar_variable(indicadores, 'evaluacion', ['l_1', 'l_2', 'l_3', 'l_4'])

        this.inicializar_variable(indicadores, 'causales_retiros', ['jubilados', 'fallecidos', 'incentivo', 'voluntario', 'otros'])
        this.indicadores = indicadores
        this.anexo = anexo
        console.log(anexo)
      })
    },
    suma_causales (year) {
      var suma = 0
      const tipos = ['jubilados', 'fallecidos', 'incentivo', 'voluntario', 'otros']
      tipos.forEach(tipo => {
        suma += parseInt(this.indicadores.causales_retiros[tipo][year] || 0)
      })
      this.indicadores.egreso_servicio.numerador[year] = suma
      this.indicadores.recuperacion_funcionarios.denominador[year] = suma
      return suma
    },
    suma_calificaciones (year) {
      var suma = 0
      const tipos = ['l_1', 'l_2', 'l_3', 'l_4']
      tipos.forEach(tipo => {
        suma += parseInt(this.indicadores.evaluacion[tipo][year] || 0)
      })
      return suma
    },
    inicializar_variable (indicadores, grupo, filas) {
      filas = filas || ['numerador', 'denominador']
      indicadores[grupo] = indicadores[grupo] || {}
      filas.forEach(element => {
        indicadores[grupo][element] = indicadores[grupo][element] || {}
      })
    },
    relacion_porcentaje (grupo, anio) {
      var numerador = this.indicadores[grupo].numerador[anio]
      var denominador = this.indicadores[grupo].denominador[anio]
      var grupo2 = ''
      if (grupo == 'porcentaje_ingresos') {
        grupo2 = 'efectividad_seleccion'
        this.indicadores[grupo2].denominador[anio] = this.indicadores[grupo].numerador[anio]

        grupo2 = 'representacion_ingreso'
        this.indicadores[grupo2].denominador[anio] = this.indicadores[grupo].denominador[anio]
      }
      if (grupo == 'representacion_ingreso') {
        grupo2 = 'efectividad_regularizacion'
        this.indicadores[grupo2].numerador[anio] = this.indicadores[grupo].numerador[anio]
      }
      if (grupo == 'honorarios_regularizables') {
        grupo2 = 'efectividad_regularizacion'
        this.indicadores[grupo2].denominador[anio] = this.indicadores[grupo].denominador[anio]
      }
      if (grupo == 'honorarios_regularizables') {
        grupo2 = 'efectividad_regularizacion'
        this.indicadores[grupo2].denominador[anio] = this.indicadores[grupo].denominador[anio]
      }
      return numerador / denominador * 100
    },
    relacion_porcentaje_calificaciones (year, total) {
      var suma = 0
      const tipos = ['l_1', 'l_2', 'l_3', 'l_4']
      tipos.forEach(tipo => {
        suma += parseInt(this.indicadores.evaluacion[tipo][year] || 0)
      })
      return suma / total * 100
    },
    relacion (grupo, anio) {
      var numerador = this.indicadores[grupo].numerador[anio]
      var denominador = this.indicadores[grupo].denominador[anio]
      return numerador / denominador
    },
    // USER SERVICE RELATIONSHIP
    obtenerRelacionUsuarioServicios () {
      const data = JSON.parse(localStorage.getItem('user'))
      const relacionUs = data.servicios.find(
        (s) => s.id === parseInt(this.$route.params.idServicio)
      )
      if (!relacionUs) return
      if (
        relacionUs.permisos.find((p) => p.permisoId === 5) ||
        relacionUs.rolId === 3 ||
        relacionUs.rolId === 4 ||
        relacionUs.rolId === 5
      ) {
        this.readOnly = false
        this.userPermissions = true
        this.userPermission = true
      }
    },
    // Save Finish
    guardarYFinalizar: function () {
      console.log('Entro')
      axios.put(`${process.env.VUE_APP_RUTA_API}/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    }
  },
  mounted () {
    this.obtenerRelacionUsuarioServicios()
    this.obtenerRevision()
  }
}
</script>

<style scoped>
.row {
  margin: 0;
}

.containers {
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.div-button-up {
  display: flex;
  justify-content: flex-end !important;
}
.up {
  color: #052333;
  background: #4f52ff;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  box-shadow: 1px 2px 1px 2px #ccc;
}

.up:hover {
  background: #6f00a3;
  color: #fff !important;
  box-shadow: 2px 2px 2px 2px #052333;
}

.up:focus {
  background: #6f00a3;
  color: #fff !important;
}

.span {
  font-size: 18px;
  color: #052333;
  font-weight: 600;
  display: grid;
  top: -10px;
}

.fnc-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.btn-humanos {
  margin-bottom: 20px;
}

.bx-download {
  font-size: 25px;
  margin-right: 10px;
}

.span-btn-text {
  font-size: 18px;
}

.bx-upload {
  font-size: 25px;
  margin-right: 10px;
}
.mt-3 {
  background: #4f52ff !important;
  color: #fff !important;
  border: none;
  margin-left: 10px;
  margin-top: -20px;
}
.button {
  border: none;
  cursor: pointer;
  width: 12%;
  height: 30px;
  margin-bottom: 20px;
  background: #0b5ed7;
  color: #fff;
  border-radius: 20px;
  margin-right: 20px;
}

.button:hover {
  color: #fff;
  background: blueviolet;
}

.div-button {
  display: flex;
  justify-content: flex-end;
}
</style>
