<template>
  <div>
    <b-row class="separar-eche">
      <b-col class="left-image" cols="4">
        <Left />
      </b-col>

      <b-col class="center-search" cols="5">
        <div style="display:none" class="cnt-search cffm"><SearchHome /></div>
        <div class="login cffm">
          <ClaveUnicaLogin />
          <!-- <BtnLogin /> -->
        </div>

        <div class="sibedar"><Sibedar /></div>
      </b-col>

      <!-- <b-col cols="2" class="login-menu">

      </b-col> -->
    </b-row>

    <b-row class="row-menu">
      <b-col cols="12">
        <Menu />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Left from './up/Left.vue'
import Menu from './up/Menu.vue'
import SearchHome from '../../input/SearchHome.vue'
import ClaveUnicaLogin from './up/ClaveUnicaLogin.vue'
// import BtnLogin from "./up/BtnLogin";
import Sibedar from './up/Sibedar.vue'
export default {
  components: {
    Left,
    Menu,
    SearchHome,
    ClaveUnicaLogin,
    Sibedar
    // BtnLogin,
  },
  data () {
    return {
      mostrar: this.$route.query.mostrar
    }
  }
}
</script>

<style scoped>
.row {
  margin: 0;
  background: #fff;
  height: 90px;
}

.row-menu {
  margin: 0;
  background: #e0e0e0;
  height: 60px;
}

.container {
  margin-left: 12px;
}

.left-image {
  align-items: center;
  display: grid;
  margin: 0 35px;
}

.separar-eche {
  justify-content: space-between;
}

.center-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 35px;
}

.login-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cnt-search {
  padding: 0 30px;
}

.cnt-login {
  padding: 0 15px;
}

.sibedar {
  display: none;
}

@media screen and (max-width: 799px) {
  .search {
    display: none;
  }

  .login {
    display: none;
  }

  .row-menu {
    display: none;
  }

  .sibedar {
    display: flex;
  }
}

@media screen and (max-width: 560px) {
  .left-image {
    width: 29% !important;
    margin-right: 0;
  }

  .center-search {
    margin-left: 0;
    /* width: 29% !important;
    margin: 0 !important; */
  }
}
</style>
