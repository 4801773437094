<template>
  <div>
    <Breacrumb :links="breadCrumbData"/>
    <h4 class="historial-content">Historial</h4>
    <Main />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import Breacrumb from '@/components/tools/Breadcrumb.vue'
import Main from './Main.vue'
export default {
  components: {
    Breacrumb,
    Main
  },
  computed: {
    ...mapState('sesion', ['usuario'])
  },
  data () {
    return {
      breadCrumbData: null
    }
  },
  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        {
          name: 'Portada BGI',
          routeName: 'bgi',
          routeParams: {
            idUsuario: this.usuario.id
          }
        },
        {
          name: 'Historial',
          routeName: 'historial',
          routeParams: {
            // ...this.$route.params,
          }
        }
      ]
    }
  },
  mounted () {
    this.setBreadcrumbData()
  }
}
</script>

<style scoped>
.historial-content {
  text-align: center;
  font-weight: bold;
}
.history {
  margin: 15px 0;
}
</style>
