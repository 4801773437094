<template>
  <div class="home">
    <Navbar />

   <Content />

    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar.vue'
import Content from './home/Content.vue'
import Footer from '../components/layout/Footer.vue'

export default {
  components: {
    Navbar,
    Content,
    Footer
  }
}
</script>

<style scoped>
.home {
  background: #fff;
}

.footer {
  position: relative;
  top: 200px;
}

@media screen and (max-width: 860px) {
  .help {
    height: 500px;
  }
}
</style>
