<template>
  <div>
    <Header />

    <div class="main">
      <Contents />
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from '../../components/layout/Footer.vue'
import Header from '../../components/private/Header'
import Contents from './components/Contents.vue'

export default {
  components: {
    Header,
    Contents,
    Footer
  },
  mounted () {
    this.$store.commit('setPrivateView', 'antecedentes')
  }
}
</script>

<style scoped>
.main {
  background: #fff;
  margin-bottom: 30px;
}
</style>
