<template>
  <div class="container">
    <div class="privado">
        <Title  />

        <div class="container">
            <Breadcrumb :links="breadCrumbData" />
            <Carrusel />

            <h1 class="privado-titulo"> Recursos Financieros</h1>

            <div>
                La información financiera de las instituciones públicas, tanto en moneda nacional como en dólares, en los casos en que corresponda, se encuentra publicada en el sitio web de la Dirección de Presupuestos, www.dipres.gob.cl.
            </div>
            <br><br>
            <div>
            Específicamente, se debe ingresar al link “Presupuestos”; en “Presupuestos por año” se debe seleccionar el año “2021”; seleccionar “Ejecución Total”; seleccionar “Capítulo”; seleccionar el ministerio correspondiente; y dentro de cada servicio seleccionar el documento “Informe Ejecución Capítulo Cuarto Trimestre”, en “Pesos” o “Dólares” cuando corresponda
            </div>
            <b-row v-if="anexo">
                <b-col>
                    <!-- <Instrucciones/> -->
                </b-col>
                <b-col>
                    <Visualizar seccion="recursos_financieros" />
                </b-col>
                <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
                    <b-form-checkbox v-model="anexo.datos.no_aplica">
                        No Aplica
                    </b-form-checkbox>
                </b-col>
            </b-row>

            <devengados/>

        </div>
    </div>
  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
// import Instrucciones from '../Instrucciones'

import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import { mapState } from 'vuex'
import Carrusel from '@/components/private/Carrusel.vue'
import Devengados from './Devengados.vue'
// import Main from './Main.vue'
export default {
  mixins: [utils],
  components: {
    Title,
    Breadcrumb,
    // Instrucciones,
    Carrusel,
    Devengados
    // Main
  },
  data () {
    return {
      breadCrumbData: null
    }
  },
  computed: {
    // get User
    ...mapState('sesion', ['usuario']),
    isAdmin: function () {
      return this.usuario.username === process.env.VUE_APP_USER_ADMIN
    }
  },
  methods: {
    // name of breadcrumb
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        {
          name: 'Portada BGI',
          routeName: 'bgi',
          routeParams: {
            idUsuario: this.usuario.id
          }
        },
        {
          name: 'Escritorio de Gestión Virtual',
          routeName: 'buttons',
          routeParams: {
            idUsuario: this.usuario.id,
            idServicio: this.$route.params.idServicio
          }
        },
        {
          name: 'Recursos Financieros',
          routeName: 'rHumanos',
          routeParams: {
            idServicio: this.$route.params.idServicio
          }
        }
      ]
    }
  },
  mounted () {
    this.setBreadcrumbData()
  }
}
</script>
