<template>
  <div class="container">

    <Title v-if="!isAdmin" :showMinister="true" :showService="true" />
    <Breadcrumb :links="breadCrumbData" />
    <Carrusel />
    <Main />
  </div>
</template>

<script>
import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Main from './Main.vue'
import Carrusel from '@/components/private/Carrusel.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Title,
    Breadcrumb,
    Main,
    Carrusel
  },
  data () {
    return {
      breadCrumbData: null
    }
  },
  computed: {
    // Get User
    ...mapState('sesion', ['usuario']),
    isAdmin: function () {
      return this.usuario.username === process.env.VUE_APP_USER_ADMIN
    }
  },
  methods: {

    // Name Breacrumb
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        {
          name: 'Portada BGI',
          routeName: 'bgi',
          routeParams: {
            idUsuario: this.usuario.id
          }
        },
        {
          name: 'Escritorio de Gestión Virtual',
          routeName: 'buttons',
          routeParams: {
            idUsuario: this.usuario.id,
            idServicio: this.$route.params.idServicio
          }
        },
        {
          name: 'Recursos Humanos',
          routeName: 'rHumanos',
          routeParams: {
            idServicio: this.$route.params.idServicio
          }
        }
      ]
    }
  },
  mounted () {
    this.setBreadcrumbData()
  }
}
</script>
