<template>
  <div class="left">
    <Logo />

    <Hacienda />
  </div>
</template>

<script>
import Logo from '../../../image/navbar/Logo.vue'
import Hacienda from '../../../image/navbar/Hacienda.vue'

export default {
  components: {
    Logo,
    Hacienda
  }
}
</script>

<style scoped>
.left {
  display: flex;
  align-items: center;
}

</style>
