<template>
  <div class="not-permisssion">
    <div class="noperms">
      <div style="display: flex; justify-content: flex-end;">
        <AlertPerms
          msg="Esta sección debe ser llenada únicamente por la persona encargada. No estás autorizado para completar este contenido, sólo puedes visualizarlo."
        />
      </div>

      <div class="locked-editor">
        <div class="head-text">
          <i class="fas fa-times-circle btn-close"></i>
        </div>
        <p class="text" v-if="!contenido">
          {{ loremIpsum }}
        </p>
        <div class="textos" id="contentEditor" v-else></div>
      </div>
    </div>
  </div>
</template>
<script>
import AlertPerms from '../alert/Watch.vue'
export default {
  components: {
    AlertPerms
  },
  data () {
    return {
      loremIpsum: ''
    }
  },
  props: ['contenido'],
  methods: {
    setHtmlData: function () {
      if (!this.contenido) return

      const element = document.getElementById('contentEditor')
      element.innerHTML = this.contenido
    }
  },
  mounted () {
    setTimeout(() => {
      this.setHtmlData()
    }, 1000)
  }
}
</script>
<style scoped>
.not-permisssion {
  margin-right: 30px;
  margin-left: 40px;
  margin-bottom: 30px;
}

.locked-editor {
  text-align: justify;
  padding: 10px 30px;
  color: gray;
  background-color: rgb(245, 245, 245);
  max-width: 100%;
  margin: 0;
}

.head-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-close {
  font-size: 1.3rem;
  cursor: pointer;
  color: #192633;
}

h6 {
  color: rgb(97, 97, 97);
  margin-bottom: 5px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .textos {
    font-size: 10px;
  }
}
</style>
