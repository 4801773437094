<template>
  <div>
    <b-button squared class="two" :to="{ name: 'servicios' }">Resumen Ejecutivo</b-button>
    <!--<b-button squared class="two" :to="{ name: 'somos' }">Quiénes Somos </b-button>-->
    <b-button squared class="one" >Principales-Autoridades</b-button>
  </div>
</template>

<script>
export default {
  methods: {
    seleccionar (nombre) {
      this.$router.push({ name: nombre })
    }
  }
}
</script>

<style scoped>
.one {
  background: #052333 !important;
  color: white !important;
  border: none;
  font-weight: 700 !important;
  max-width: 126px;
  max-height: 70px;
  font-size: 16px;
}

.two {
  color: #052333;
  background: #efefef;
  border: none;
  max-width: 126px;
  max-height: 70px;
  font-size: 16px;
}

.two:hover {
  background: #24c6ab;
  color: #052333;
}

@media screen and (max-width: 767px) {
  .btn-btn {
    margin-left: 90px;
  }
}

@media screen and (max-width: 491px) {
  .btn-btn {
    margin-left: 30px;
  }
}
</style>
