<template>
  <div>
    <br /><br /><br />
    <b-container>
      <b-row align-h="center">
        <b-col sm="12" md="3">
          <div class="tarjeta ministerios">
            <div>
              <h5>Visores</h5>
              <h2>Ministerios</h2>
            </div>
            <p>
              En los visores puedes explorar los servicios del Estado Central
              según Remuneración, Longevidad y Dotación.
            </p>
            <a href="#" v-on:click="verMinisterios">Ver más</a>
          </div>
        </b-col>
        <b-col sm="12" md="3">
          <div class="tarjeta minsal">
            <div>
              <h5>Visores</h5>
              <h2>MINSAL</h2>
            </div>
            <p>
              En los visores puedes explorar los servicios del Ministerio de
              Salud según Remuneración, Longevidad y Dotación.
            </p>
            <a href="#" v-on:click="verMinsal">Ver más</a>
          </div>
        </b-col>
        <b-col sm="12" md="3">
          <div class="tarjeta personas">
            <div>
              <h5>Buscador</h5>
              <h2>Personas</h2>
            </div>
            <p>
              Conoce el historial individual de las personas que han trabajado o
              continuan trabajando en el Estado.
            </p>
            <a href="#" v-on:click="verPersonas">Ver más</a>
          </div>
        </b-col>
        <b-col sm="12" md="3" v-if="1===2">
          <div class="tarjeta datos">
            <div>
              <h5>Descarga</h5>
              <h2>Datos</h2>
            </div>
            <p>
              Aquí puedes acceder a los links de descarga de los datos que
              utilizamos para realizar nuestra investigación.
            </p>
            <a href="#" v-on:click="verDatos">Ver más</a>
          </div>
        </b-col>
        <!--
        <b-col sm="12" md="4">
          <div class="tarjeta composicion" v-on:click="verComposision">
            <div>
              <h5>Dimensión</h5>
              <h2>Composición</h2>
            </div>
            <p>
              ¿Cuánto difieren los servicios en sus niveles salariales?, ¿Qué
              profesiones son las más contratadas?, ¿Cuál es la composición de
              cada servicio de acuerdo al género, grado y régimen de
              contratación?
            </p>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="tarjeta dotacion" v-on:click="verDotacion">
            <div>
              <h5>Dimensión</h5>
              <h2>Dotación</h2>
            </div>
            <p>
              ¿Cuántos funcionarios trabajan en el Estado, en sus distintas
              dependencias y en regiones?, ¿Cuál es la proporción de
              profesionales contratados en cada servicio?
            </p>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="tarjeta remuneracion" v-on:click="verRemuneraciones">
            <div>
              <h5>Dimensión</h5>
              <h2>Remuneración</h2>
            </div>
            <p>
              ¿Cómo ha evolucionado la rotación de personal y los salarios a lo
              largo del tiempo y también en regiones?
            </p>
          </div>
        </b-col>
        <b-col sm="12" md="4">
          <div class="tarjeta longevidad" v-on:click="verLongevidad">
            <div>
              <h5>Dimensión</h5>
              <h2>Longevidad</h2>
            </div>
            <p>
              ¿Hay diferencias relevantes en la permanencia de los funcionarios
              entre los distintos servicios?
            </p>
          </div>
        </b-col>

        <b-col sm="12" md="4">
          <div class="tarjeta detalles">
            <div>
              <h5>Páginas</h5>
              <h2>Detalles</h2>
            </div>
            <div class="listado_detalles">
              <div>
                <img src="/img_fondef/investigacion-mini.png" />
                <div>
                  <h6>Investigación</h6>
                  <span>Resultados del modelado con teoría de vanguardia</span>
                </div>
              </div>
              <div>
                <img src="/img_fondef/datos-mini.png" />
                <div>
                  <h6>Datos</h6>
                  <span
                    >Descarga los datos que usamos de portales de
                    transparencia</span
                  >
                </div>
              </div>
            </div>
            <p>
              Encuentra aquí los detalles de nuestra investigación.
            </p>
          </div>
        </b-col>
      -->
      </b-row>
    </b-container>
    <br /><br /><br />
  </div>
</template>

<script>
// import CardData from "./card/CardData.vue";
export default {
  components: {},
  async data () {
    return {
      notification:
        'Recuerda que esta informacion es total. Para conocerlos datos por institucion utiliza el buscador o pincha instituciones, desde el menu principal'
    }
  },
  methods: {
    verComposision () {
      const route = this.$router.resolve({ name: 'fondef-composicion' })
      window.location = route.href
    },
    verDotacion () {
      const route = this.$router.resolve({ name: 'fondef-dotacion' })
      window.location = route.href
    },
    verRemuneraciones () {
      const route = this.$router.resolve({ name: 'fondef-remuneraciones' })
      window.location = route.href
    },
    verLongevidad () {
      const route = this.$router.resolve({ name: 'fondef-longevidad' })
      window.location = route.href
    },
    verPersonas () {
      const route = this.$router.resolve({ name: 'fondef-personas' })
      window.location = route.href
    },
    verMinsal () {
      const route = this.$router.resolve({ name: 'fondef-minsal' })
      window.location = route.href
    },
    verMinisterios () {
      const route = this.$router.resolve({ name: 'fondef-ministerios' })
      window.location = route.href
    },
    verDatos () {
      const route = this.$router.resolve({ name: 'fondef-datos' })
      window.location = route.href
    }
  },
  mounted () {}
}
</script>

<style scoped>
.mainContainer {
  background: #f5f5f5;
  margin-top: 30px;
}

h5 {
  padding: 10px;
}
/*
.row {
  margin: 40px;
  margin-right: 0;
  margin-left: 0;
}
.row > .col,
.row > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  margin: 0 30px;
}

.col {
  border: 1px solid #fff;
}
*/

.descripcion-fondef {
  margin: 35px 0;
}

h4 {
  text-align: center;
  margin: 25px;
}

.tarjeta {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 17px;
  min-height: 415px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  margin-bottom: 30px;
}

.tarjeta:hover {
  z-index: 1;
  box-shadow: 0 5px 15px 0 rgba(202, 202, 202, 0.5);
}

.tarjeta h5 {
  margin: 0;
  padding: 0;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: none;
}

.tarjeta h2 {
  margin: 0;
  padding: 0;

  font-family: "Fira Sans", sans-serif;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.75rem;
  color: #080d46;
  text-align: center;
}

.tarjeta h2:after {
  display: block;
  content: "";
  background-color: rgb(36, 198, 171);
  height: 3px;
  max-width: 30%;
  margin: 5px auto;
  border-radius: 10;
}

.tarjeta p {
  font-size: 16px;
  color: #575757;
  letter-spacing: 0;
  line-height: 21px;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  word-wrap: break-word;
  text-align: center;
  color: #b5b5b5;
  font-family: "Fira Sans", sans-serif;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 1rem;
}

.tarjeta a {
  display: block;
  margin: 0 auto;
  background: #22c6ab;
  color: #fff;
  text-decoration: none;
  padding: 7px 20px;
  border-radius: 20px;
}

.tarjeta.composicion {
  background: #fff url("/img_fondef/composicion.png");
  background-size: cover;
}

.tarjeta.dotacion {
  background: #fff url("/img_fondef/dotacion.png") no-repeat;
  background-size: 70%;
  background-position: -5% 0%;
  justify-content: flex-end;
}

.tarjeta.remuneracion {
  background: #fff url("/img_fondef/remuneracion.png") no-repeat;
  background-size: 80%;
  background-position: 50% 10%;

  justify-content: flex-end;
}

.tarjeta.longevidad {
  background: #fff url("/img_fondef/longevidad2.png") no-repeat;
  background-size: 100%;
  background-position: 50% 0%;
}

.tarjeta.personas {
  background: #fff url("/img_fondef/tarjetas/personas.png") no-repeat;
  background-size: 60%;
  background-position: 50% 10%;
}

.tarjeta.minsal {
  background: #fff url("/img_fondef/tarjetas/minsal.png") no-repeat;
  background-size: 60%;
  background-position: 50% 10%;
}

.tarjeta.datos {
  background: #fff url("/img_fondef/tarjetas/descargas.png") no-repeat;
  background-size: 60%;
  background-position: 50% 10%;
}

.tarjeta.minsal > div {
  background: rgba(255, 255, 255, 1);
}

.tarjeta.ministerios {
  background: #fff url("/img_fondef/tarjetas/ministerios.png") no-repeat;
  background-size: 60%;
  background-position: 50% 10%;
}

.tarjeta.ministerios > div {
  background: rgba(255, 255, 255, 0.9);
}

.tarjeta.personas > div,
.tarjeta.personas > p {
  background: #fff;
  padding: 0 20px;
  margin: 0 -20px 0 -20px;
}

.tarjeta.personas > div {
  padding-top: 20px;
}

.tarjeta.detalles {
  cursor: inherit;
  justify-content: space-between;
}

.listado_detalles {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.listado_detalles img {
  flex: 0 0 28px;
  max-height: 50px;
}

.listado_detalles div:first-child img {
  border: 2px solid black;
  border-radius: 100px;
}

.listado_detalles > div {
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  background: #fff;

  transition: none;
  transition: all 0.4s cubic-bezier(0, 0, 0.2, 1);
  will-change: transform;
  -webkit-transform-style: preserve-3d;
}

.listado_detalles > div:hover {
  transform: scale(1.05);
}

.listado_detalles > div > div {
  margin-left: 20px;
}

.listado_detalles h6 {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.listado_detalles span {
  font-size: 16px;
  color: #8f8f97;
  letter-spacing: 0;
  line-height: 20px;
}
</style>
