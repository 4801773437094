// object array to identify virtual desktop buttons
export default {
  namespaced: true,
  state: {
    items: [
      {
        Servicio: 'Presentacion del ministro',
        id: 1
      },
      {
        Servicio: 'Presentacion del servicio',
        id: 2
      },
      {
        Servicio: 'resultado de la gestion',
        id: 3
      },
      {
        Servicio: 'Desafios',
        id: 4
      },
      {
        Servicio: 'Recursos humanos',
        id: 5
      },
      {
        Servicio: 'recursos financieros',
        id: 6
      },
      {
        Servicio: 'Indicadores',
        id: 7
      },
      {
        Servicio: 'Compromisos',
        id: 8
      },
      {
        Servicio: 'Evaluaciones',
        id: 9
      },
      {
        Servicio: 'Incentivo institucional',
        id: 10
      },
      {
        Servicio: 'incentivo colectivo',
        id: 11
      },
      {
        Servicio: 'Equidad de género',
        id: 12
      },
      {
        Servicio: 'Leyes',
        id: 13
      },
      {
        Servicio: 'Premios y reconocimientos',
        id: 14
      },
      {
        Servicio: 'Organigrama de Servicios',
        id: 15
      },
      {
        Servicio: 'Organigrama de Ministerio',
        id: 16
      }
    ]
  }
}
