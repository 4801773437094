<template>
  <div>
    <div>
      <Navbar />
      <Header
        :titulo="nombreServicio"
        :desc="ministerioServicio"
        :urlPdf="urlPdf"
        :showYearSelect="false"
        :showDownloadButton="true"
      />
    </div>
    <NavbarViews title="Indicadores de Gestión" :showYear="false" />
    <Contents />
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar.vue'
import Footer from '../../components/layout/Footer.vue'
import Header from '../../components/cabecera/Header.vue'
import Contents from './components/Contents.vue'
import NavbarViews from '../../components/navbar-from-inst/NavbarViews.vue'
import axios from 'axios'
export default {
  components: {
    Navbar,
    Header,
    Contents,
    Footer,
    NavbarViews
  },
  data () {
    return {
      nombreServicio: 'Cargando...',
      ministerioServicio: ' ',
      showData: true,
      urlPdf: ''

    }
  },
  async mounted () {
    try {
      const idService = this.$route.params.id
      const servicio = await axios.get(`${process.env.VUE_APP_RUTA_API}/instituciones/servicios/${idService}/retrieve_public/`)
      const ministerio = await axios.get(`${process.env.VUE_APP_RUTA_API}/instituciones/ministerios/${servicio.data.ministerio.id}`)

      this.urlPdf = servicio.data.files[0]
      if (!servicio.data || !ministerio.data) {
        this.nombreServicio = 'En este momento no hay datos de este servicio'
        this.ministerioServicio = 'Vuelva pronto'
        this.showData = false
      } else {
        this.nombreServicio = servicio.data.nombre
        this.ministerioServicio = ministerio.data.nombre
        this.showData = true
      }
      this.$store.commit('setSelectedInsView', 'indicadores')
    } catch (error) {
      console.log(error)
    }
  }
}
</script>
<style>
.no-data {
  height: 500px;
}
</style>
