<template>
  <div>
      <Header/>
      <div class="container">
    <Title/>
    <Breadcrumb :links="breadCrumbData" />
    <Carrusel />

    <h1 class="privado-titulo">Organigrama de Ministerio</h1>

    <div class="container">
        <div v-if="can_edit_anexos_de_ministerios()">
            <Upload />
        </div>
        <div v-if="url">
            <div class="cnt-img" ><img v-show="url" id="imgMinisterioOrganigrama" :src="url" alt="" /></div>
        </div>
        <NoData v-if="!can_edit_anexos_de_ministerios() " />
    </div>
    <Footer/>
  </div>
  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
import axios from 'axios'
import Header from '@/components/private/Header'
import Footer from '@/components/layout/Footer.vue'

import Upload from './components/main/UploadButton.vue'
import NoData from './NoData.vue'
import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '@/components/private/Carrusel.vue'
// import Help from '@/components/tools/Modal.vue'
// import Button from "./main/Button.vue";
// import Main from './main/Main'
import { mapState } from 'vuex'

export default {
  mixins: [utils],
  components: {
    Title,
    Header,
    Footer,
    Breadcrumb,
    Carrusel,
    // Button,
    Upload,
    NoData
    // Help
  },
  data () {
    return {
      url: null,
      breadCrumbData: null,
      anexo: null,
      text:
        'Para completar el servicio por favor seguir las siguientes instrucciones',
      uno: 'Se permite formato PNG, SVG, y JPG',
      dos: 'Tamaño de la imagen max: 2mb'
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])

  },
  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Organigrama de Servicio', routeName: 'oservicio', routeParams: { idServicio: this.$route.params.idServicio } }
      ]
    },
    obtenerContenido: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'organigrama_ministerio'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        this.anexo = anexo
        this.url = process.env.VUE_APP_RUTA_API_MEDIA + this.anexo.datos.url
      })
    }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerContenido()
  }
}
</script>

<style scoped>
.text-indicator {
  text-align: center;
}

.containers {
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
</style>
