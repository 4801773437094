<template>
  <div>
    <table class="table" style="border: 1px solid #000; margin-bottom:20px;">
      <tbody>
        <tr>
          <td class="title-datas">
            Leyes y Normativas que rigen el funcionamiento de la Institución:
          </td>
          <td class="data">{{ data }}</td>
        </tr>
      </tbody>
    </table>

    <table class="table table-striped" style="border: 1px solid #000;">
      <tbody>
        <tr>
          <td class="title-datas">
            - Misión Institucional
          </td>
          <td class="data">{{ dataOne }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['contenido'],
  data () {
    return {
      encabezado: 'Número',
      items: null,
      data: null,
      dataOne: null
    }
  },

  mounted () {
    setTimeout(() => {
      // console.log(this.contenido)
      const a = this.contenido.leyMision
      const unArray = Object.keys(this.contenido.leyMision)
      this.items = unArray.map((i) => a[i])
      this.data = this.items[0]
      this.dataOne = this.items[1]
    }, 1000)
  }
}
</script>

<style scoped>
.container {
  margin-top: 20px;
}

.title-datas {
  color: #24c6ab;
  font-weight: bold;
  font-size: 12px;
}

.data {
  color: #24c6ab;
  background: #fff;
  font-size: 12px;
}

th {
  text-align: center !important;
  font-size: 18px !important ;
}

td {
  text-align: left;
  font-size: 18px !important ;
}

@media screen and (max-width: 1800px) {
  th {
    text-align: center !important;
    font-size: 16px !important ;
  }

  td {
    text-align: left;
    font-size: 16px !important ;
  }
}

@media screen and (max-width: 1600px) {
  th {
    text-align: center !important;
    font-size: 15px !important ;
  }

  td {
    text-align: left;
    font-size: 15px !important ;
  }
}

@media screen and (max-width: 1400px) {
  th {
    text-align: center !important;
    font-size: 14px !important ;
  }

  td {
    text-align: left;
    font-size: 14px !important ;
  }
}

@media screen and (max-width: 1200px) {
  th {
    text-align: center !important;
    font-size: 12px !important ;
  }

  td {
    text-align: left;
    font-size: 12px !important ;
  }
}

@media screen and (max-width: 900px) {
  th {
    text-align: center !important;
    font-size: 10px !important ;
  }

  td {
    text-align: left;
    font-size: 10px !important ;
  }
}
</style>
