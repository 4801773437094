<template>
  <div class="left">
    <div class="text">

      <h2 class="textOne">La Plataforma <b>Gestion.gob.cl</b></h2>
      <ParagraphOne  />

    </div>

    <div class="">
      <!-- <b-button :to="{ name: 'conocer-mas' }" class="button-more">{{ button }}</b-button> -->
    </div>
  </div>
</template>

<script>
import ParagraphOne from '../components/text/ParagraphOne.vue'
// import TitleHacienda from '../components/text/TitleHacienda.vue'
export default {
  components: {
    // TitleHacienda,
    ParagraphOne
  },
  data () {
    return {
      button: 'Quiero conocer más'
    }
  }
}
</script>

<style scoped>
.left {
  position: relative;
  text-align: center;
  top: 150px;
}

/* .button-more {
  width: 274px;
  height: 37px;
  background: #24c6ab;
  border: none;
  border-radius: 22px;
}

.button-more:hover {
  background: #24c6ab;
} */

.text {
  text-align: center;
}

.textOne {
  color: #fff;
}

@media screen and (max-width: 1150px) {
  .left {
    position: relative;
    text-align: center;
    top: 113px;
  }
  .button-more {
    width: 190px;
    font-size: 16px;
  }
}

@media screen and (max-width: 960px) {
  .button-more {
    width: 170px;
    font-size: 14px;
  }
  .left {
    position: relative;
    top: 123px;
  }
}

@media screen and (max-width: 767px) {
  .left {
    position: relative;
    top: 73px;
  }
}

@media screen and (max-width: 600px) {
  .button-more {
    width: 150px;
    font-size: 12px;
  }
}
</style>
