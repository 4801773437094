<template>
  <div>
    <b-row>
      <b-container>
        <div class="container-title">
          <div class="title-container">
            <h3 class="title">{{ titulo }}</h3>
            <p class="desc">{{ desc }}</p>
          </div>

          <div v-if="showDownloadButton">
            <b-button
              class="pdf-button"
              :disabled="urlPdf ? false : true"
              @click="descargarPdf"
            >
              <i class="bx bx-download"></i> Descargar
            </b-button>
          </div>
        </div>

        <div class="search-data" v-show="showYearSelect">
          <div class="year">
            <h3 class="year-num">{{ year }}</h3>
          </div>
          <b-dropdown id="dropdown-1" text="Seleccionar otro año" size="sm" variant="light" class="apart">
            <b-dropdown-item class="item" @click="onChangeYear(2018)">2018</b-dropdown-item>
            <b-dropdown-item class="item" @click="onChangeYear(2019)">2019</b-dropdown-item>
            <b-dropdown-item class="item" @click="onChangeYear(2020)">2020</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-container>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['year'])
  },
  props: ['titulo', 'desc', 'showYearSelect', 'urlPdf', 'showDownloadButton'],
  methods: {
    onChangeYear (payload) {
      this.$store.commit('changeYear', payload)
    },
    descargarPdf () {
    //   const url = 'http://ec2-54-232-154-152.sa-east-1.compute.amazonaws.com:1337'
    //   window.open(`${url}/${this.urlPdf}`, '_blank')
      window.open(this.urlPdf, '_blank')
    }
  }
}
</script>

<style scoped>
.row {
  margin: 0;
  background: #052333;
  height: 160px;
  display: flex;
  flex-direction: column;
}
.title {
  color: #fff;
  /* margin-top: 30px; */
}

.title-container {
  display: grid;
  align-items: center;
  margin-top: 25px;
}

.container-title {
  margin: 10px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desc {
  color: #fff;
  font-size: 12px;
}

.pdf-button {
  background: #24c6ab !important;
  /* font-size: 0.9rem; */
  margin: 0 8px;
  border-radius: 40px;
  border: none;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  box-shadow: none;
}

p {
  color: #fff;
  margin-top: -10px;
}

.search-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  margin-left: 28px;
}

.apart {
  font-weight: 500;
  margin: 8px;
}

.item {
  font-size: 14px;
}

.year {
  display: flex;
  font-weight: 800;
  height: 100%;
  color: #24c6ab;
  margin: 8px;
}

.year-num {
  margin: 0;
}

@media screen and (max-width: 538px) {
  .title {
    font-size: 14px;
  }

  .desc {
    font-size: 9px;
  }

  .year-num {
    font-size: 16px;
  }
}

@media screen and (max-width: 421px) {
  .title {
    margin-bottom: 19px;
  }
  .desc {
    display: none;
  }
  .row {
    height: 120px;
  }
}
</style>
