<template>
  <div class="main-head">
    <div class="logos">
      <Logo />

      <Hacienda />
    </div>

    <div class="middle">

        <!-- <div class="redes">
            <div>
                <b-button variant="link">
                    <i class="bx bxl-facebook" style="color:#052333"></i>
                </b-button>
                </div>
                <div>
                <b-button variant="link">
                    <i class="bx bxl-twitter" style="color:#052333"></i>
                </b-button>
                </div>
                <div>
                <b-button variant="link">
                    <i class="bx bxl-instagram" style="color:#052333"></i>
                </b-button>
                </div>
                <div>
                <b-button variant="link">
                    <i class="bx bxl-linkedin" style="color:#052333"></i>
                </b-button>
            </div>
        </div> -->
        <b-link class="link" :to="{ name: 'Home' }"><i class="bx bx-exit"> </i>Salir</b-link>
    </div>

    <div class="menu">
      <Menu />
    </div>
  </div>
</template>

<script>
import Logo from '../../image/navbar/Logo.vue'
import Hacienda from '../../image/navbar/Hacienda.vue'
import Menu from '../menu/Menu.vue'
// import LogOut from '@/views/gestion/components/Nav.vue'
export default {
  components: {
    Hacienda,
    Logo,

    // LogOut,
    Menu
  }
}
</script>

<style scoped>
.main-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

.logos {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu {
  display: none;
}

@media screen and (max-width: 874px) {
  .middle {
    display: none;
  }

  .menu {
    display: initial;
    text-align: end;
  }
}

@media screen and (max-width: 343px) {
  .img {
    margin-left: -6px;
    margin-top: 5px;
    margin-right: -20px;
  }
}

/* REDES : está sacado (comentado)*/
.redes {
  display: flex;
}

.bx {
  font-size: 36px;
}

/* SALIR */
.link {
  display: flex;
  align-items: center;
  color: #052333;
  text-decoration: none;
  font-size: 32px;
}

@media screen and (max-width: 1800px) {
  .link {
    font-size: 28px;
  }
}

@media screen and (max-width: 1600px) {
  .link {
    font-size: 22px;
  }
}
</style>
