<template>
  <div class="container">
    <!-- <div> -->
      <!-- <Commentary />
    </div>

    <Collapse /> -->

    <Card />
  </div>
</template>

<script>
// import Commentary from "@/components/commentary/Commentary.vue";
// import Collapse from "./collpase/Collapse.vue";
import Card from './Card'
export default {
  components: {
    // Commentary,
    // Collapse,
    Card
  },
  data () {
    return {
      title: 'Total 2020 - Datos agregados de todo el Estado central'
    }
  }
}
</script>

<style scoped>
.title {
  text-align: center;
  color: #052333;
  margin-top: 20px;
  font-size: 25px;
}
.container {
  margin-bottom: 2rem;
}
</style>
