import axios from 'axios'
import { mapState } from 'vuex'
import { showAlert } from '@/components/tools/utils'

export const utils = {
  components: {},
  created: function () { },
  data () {
    return {
      anioBgi: process.env.VUE_APP_ANIO,
      ultimaRevision: null,
      idServicioInt: this.$route.params.idServicio ? this.$route.params.idServicio * 1 : null,
      YearTwo: 2021,
      YearOne: 2022,

      avances: null
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])
  },
  mounted () {
    if (this.idServicioInt) {
      // this.get_revision_cf()
    }
    this.get_avances()
    this.get_nombre_servicio()
  },
  methods: {
    get_nombre_servicio () {
      const userInfo = JSON.parse(localStorage.getItem('user'))
      const data = userInfo.servicios.find((e) => e.id === parseInt(this.idServicioInt))
      if (!data) return

      return data.nombre
    },
    get_nombre_ministerio () {
      const userInfo = JSON.parse(localStorage.getItem('user'))
      const data = userInfo.servicios.find((e) => e.id === parseInt(this.idServicioInt))
      if (!data) return

      return data.ministerio.nombre
    },
    get_headers () {
      return { Authorization: `JWT ${localStorage.getItem('userToken')}` }
    },

    get_perfil_bonito (str) {
      if (str === 'superadministrador') {
        return 'Superadministrador'
      }
      if (str === 'administrador') {
        return 'Administrador'
      }
      if (str === 'representante_ministerial') {
        return 'Representante Ministerial'
      }
      if (str === 'jefe_servicio') {
        return 'Jefe de Servicio'
      }
      if (str === 'encargado_bgi') {
        return 'Encargado BGI'
      }
      if (str === 'funcionario') {
        return 'Funcionario'
      }
    },
    get_estado_bonito (str) {
      if (str === 'administrador') {
        return 'En revisión Administrador'
      } else if (str === 'jefe_servicio') {
        return 'En revisión Jefe de Servicio'
      } else if (str === 'representante_ministerial') {
        return 'En revisión Representante Ministerial'
      } else if (str === 'superadministrador') {
        return 'En revisión Super Administrador'
      } else if (str === 'encargado_bgi') {
        return 'En elaboración'
      }
    },

    get_avances (force) {
      if (this.avances && !force) {
        return
      }
      this.avances = []

      axios.get('/instituciones/servicios/avance/').then(res => {
        this.dataTable = Object.values(res.data)
        this.dataTable.forEach((d) => {
          d.estado_str = this.get_estado_bonito(d.estado)
        })

        this.avances = this.dataTable
      })
    },
    /* get_revision_cf () {
      var url = `${process.env.VUE_APP_RUTA_API}/instituciones/revisiones/${this.$route.params.idServicio}/ultima_revision/`
      axios.post(url, { anio: this.anioBgi }, { headers: this.get_headers() })
        .then(res => {
          this.ultimaRevision = res.data
        }).catch(error => { console.log(error) })
    }, */
    can_edit_anexos_de_servicios () {
      var estadoModificable = false
      var personaModificable = false
      if (!this.usuario.servicios) { return }
      var servicio = this.usuario.servicios.find((s) => s.id === this.idServicioInt)

      if (!this.ultimaRevision) { estadoModificable = true }
      if (this.ultimaRevision) { if (this.ultimaRevision && !this.ultimaRevision.siguienteAccion) { estadoModificable = true } }
      if (this.ultimaRevision && this.ultimaRevision.siguienteAccion === 'jefe_servicio') { estadoModificable = true }
      if (this.ultimaRevision && this.ultimaRevision.siguienteAccion === 'encargado_bgi') { estadoModificable = true }
      // if (this.ultimaRevision && this.ultimaRevision.siguienteAccion === 'administrador') { estadoModificable = true }

      if (servicio && ['encargado_bgi', 'funcionario', 'jefe_servicio', 'superadministrador'].indexOf(servicio.rolNombre) >= 0) {
        personaModificable = true
      }
      return estadoModificable && personaModificable
    },
    can_edit_anexos_de_ministerios () {
      var estadoModificable = false
      var personaModificable = false
      if (!this.usuario.servicios) { return }
      var servicio = this.usuario.servicios.find((s) => s.id === this.idServicioInt)

      if (!this.ultimaRevision) { estadoModificable = true }
      if (this.ultimaRevision) { if (this.ultimaRevision && !this.ultimaRevision.siguienteAccion) { estadoModificable = true } }
      if (this.ultimaRevision && this.ultimaRevision.siguienteAccion === 'jefe_servicio') { estadoModificable = true }
      if (this.ultimaRevision && this.ultimaRevision.siguienteAccion === 'encargado_bgi') { estadoModificable = true }
      if (this.ultimaRevision && this.ultimaRevision.siguienteAccion === 'representante_ministerial') { estadoModificable = true }

      if (servicio && ['superadministrador', 'representante_ministerial'].indexOf(servicio.rolNombre) >= 0) {
        personaModificable = true
      }
      return estadoModificable && personaModificable
    },
    successMessage (text) {
      showAlert({ icon: 'success', text: text, timerProgressBar: true, timer: 1000 })
    },

    servicios_listado () {
      if (!this.usuario.servicios) { return }
      if (this.idServicioInt === 218) {
        return false
      } else {
        return true
      }
    },

    obtenerAnexoRRHH: async function () {
      const res = await axios.get(
        `${process.env.VUE_APP_RUTA_API}/anexos/anexo/public_list/?anexo=2&servicio=${this.$route.params.idServicio}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('userToken')}`
          }
        }
      )
      if (res.data === undefined) {
        this.datos = true
      }
      if (res.data[0].datos.indicadores[`${process.env.VUE_APP_ANIO}` - 2]) {
        this.datsa = res.data[0].datos.indicadores[`${process.env.VUE_APP_ANIO}` - 2][0]
      }
      if (res.data[0].datos.indicadores[`${process.env.VUE_APP_ANIO}` - 1]) {
        this.datsaUno = res.data[0].datos.indicadores[`${process.env.VUE_APP_ANIO}` - 1][0]
      }
      if (res.data[0].datos.indicadores[`${process.env.VUE_APP_ANIO}`]) {
        this.dasta = res.data[0].datos.indicadores[`${process.env.VUE_APP_ANIO}`][0]
      }
      try {
        this.updated()
      } catch { }
      this.loaded = true
    },
    getDotacion (data, porcentaje, columnas) {
      var dotacion = 0
      if (!data) { return 0 }
      columnas = columnas || ['Contrata', 'Planta', 'Código del Trabajo']
      columnas.forEach(columna => { dotacion += (data[columna] || 0) })
      return dotacion / (porcentaje || 100)
    }

  }
}
