<template>
  <div class="">
    <b-container fluid="md">
      <b-row>
        <div>
          <div class="left">
            <Ministerio />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Ministerio from '../../../image/layout/Ministerio.vue'
export default {
  components: {
    Ministerio
  }
}
</script>

<style scoped>
.footer-content {
  display: flex;
}

.img {
  text-align: center;
}

.left {
  position: relative;
  top: 12px;
  text-align: end;
}

</style>
