<template>
  <div class="producto">
    <Header />

    <Contents />

    <Footer />
  </div>
</template>

<script>
import Header from '../../components/private/Header'
import Footer from '../../components/layout/Footer.vue'
import Contents from './components/producto/Contents.vue'

export default {
  components: {
    Header,
    Contents,
    Footer
  }
}
</script>

<style scoped>
.producto {
  height: 100vh;
}
</style>
