<template>
    <div class="container" v-if="anexo">

        <section class="indicator">
            <h5 class="titulo">Indicadores de Desempeño</h5>
        </section>

       <div v-for="item in anexo.datos.lista" :key="item.nombre_indicador" class="seccion-background-color-table">
        <h5>
          <span>{{ item.nombre_indicador }}</span>
        </h5>
        <hr>
        <h5>
          Producto Estratégico:
          <span v-html="item.producto_estrategico"></span>
        </h5>

        <h5>
          Fórmula de cálculo:
          <span>{{ item.numerador }}</span> / <span>{{ item.denominador }}</span>
        </h5>

        <table class=" table table-striped">
          <thead>
            <tr>
              <th scope="col">Variables</th>
              <th scope="col">{{ anioBgi - 3 }}</th>
              <th scope="col">{{ anioBgi - 2 }}</th>
              <th scope="col">{{ anioBgi - 1 }}</th>
              <th scope="col">{{ anioBgi }}</th>
              <th scope="col">Meta {{ anioBgi }}</th>
              <th scope="col">% de Logro {{ anioBgi }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="tr">
              <td>Valor Efectivo</td>
              <td style = "white-space: nowrap">
                  {{item.t3.v_efectivo | formatDecimal}}
                <span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>
              </td>
              <td style = "white-space: nowrap">
                  {{item.t2.v_efectivo | formatDecimal}}
                <span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>
              </td>
              <td style = "white-space: nowrap">
                  {{item.t1.v_efectivo | formatDecimal}}
                <span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>
              </td>
              <td style = "white-space: nowrap">
                  {{item.t0.v_efectivo | formatDecimal}}
                <span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>
              </td>
              <td style = "white-space: nowrap">
                  {{item.meta.v_efectivo | formatDecimal}}
                <span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>
              </td>
              <td rowspan="3">{{ item.logro }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'

import axios from 'axios'
export default {
  mixins: [utils],
  components: {
  },
  data () {
    return {
      anexo: null
    }
  },
  computed: {
  },
  methods: {
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.id,
        anio: this.anioBgi,
        anexo_str: 'indicadores-desempeno'
      }
      console.log(params)
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.lista = anexo.datos.lista || []
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }

  },
  mounted () {
    this.obtenerRevision()
  }
}
</script>
<style>
.indicator {
  display: flex;
  flex-direction: column;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}
</style>
