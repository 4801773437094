<template>
  <div>
    <b-button v-b-toggle.sidebar-footer class="btn" style="background: #052333"
      ><i class="bx bx-menu"></i
    ></b-button>
    <b-sidebar
      id="sidebar-footer"
      aria-label="Sidebar with custom footer"
      no-header
      shadow
    >
      <div class="logo">
        <Logo />
      </div>

      <div class="buttons-group-menu">
        <div class="navbar-buttons">
          <b-button
            variant="link"
            :class="
              `nav-button ${
                privateView === 'gestion.gob' ? 'selected-view' : ''
              }`
            "
            :to="{ name: 'gestion' }"
            @click="seleccionarVista('gestion.gob')"
            >Volver a Gestion.gob</b-button
          >

          <b-button
            variant="link"
            :class="
              `nav-button ${
                privateView === 'portada-bgi' ? 'selected-view' : ''
              }`
            "
            :to="{ name: 'bgi', params: { idUsuario: usuario.id } }"
            @click="seleccionarVista('portada-bgi')"
          >
            Portada BGI
          </b-button>

          <b-button
            v-if="isAdmin"
            variant="link"
            :class="
              `nav-button ${privateView === 'settings' ? 'selected-view' : ''}`
            "
            :to="{
              name: 'accesoAdmin',
              params: { idServicio: 31, idUsuario: this.usuario.id },
            }"
            @click="seleccionarVista('settings')"
            ><span class="config">Ver Usuarios Registrados</span></b-button
          >
          <b-button
            variant="link"
            :class="
              `nav-button ${privateView === 'historial' ? 'selected-view' : ''}`
            "
            @click="redireccionarInst()"
            ><span class="config">Histórico</span></b-button
          >

          <b-button
            variant="link"
            :class="
              `nav-button ${
                privateView === 'antecedentes' ? 'selected-view' : ''
              }`
            "
            :to="{ name: 'antecedente' }"
            @click="seleccionarVista('antecedentes')"
            >Antecedentes para la elaboración</b-button
          >
          <b-button
            v-if="isAdmin"
            :class="
              `nav-button ${
                privateView === 'comentarios' ? 'selected-view' : ''
              }`
            "
            variant="link"
            :to="{ name: 'comentario' }"
            @click="seleccionarVista('comentarios')"
            >Comentarios</b-button
          >

          <b-button
            :class="
              `nav-button ${
                privateView === 'comentarios' ? 'selected-view' : ''
              }`
            "
            variant="link"
            >Salir</b-button
          >
        </div>
      </div>

      <template #footer="{ hide }">
        <div class="d-flex  text-light align-items-center px-3 py-2">
          <b-button class="btn-close-sibedar" size="sm" @click="hide">Cerrar</b-button>
        </div>
      </template>
      <div class="px-3 py-2"></div>
    </b-sidebar>
  </div>
</template>

<script>
import Logo from '../../image/navbar/Logo.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    Logo
  },

  computed: {
    ...mapGetters(['privateView']),
    ...mapState('sesion', ['usuario']),
    isAdmin: function () {
      return this.usuario.username === process.env.VUE_APP_USER_ADMIN
    }
  },
  methods: {
    seleccionarVista (nombre) {
      this.$store.commit('setPrivateView', nombre)
    },
    redireccionarInst: function () {
      const routeData = this.$router.resolve({
        name: 'instituciones'
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.logo {
  display: flex !important;
  margin-left: 20px;
  margin-top: 10px;
}

.navbar-buttons {
  display: grid;
}

.selected-view {
  border: none;
  color: #052333;
  background: #24c6ab;
  font-weight: 700;
}

.btn:hover {
  border: none;
  color: #052333;
  background: #24c6ab;
  font-weight: 700;
}

.selected-view {
  border: none;

  color: #052333 !important;
  background: #24c6ab;
  font-weight: 700;
}

.btn {
  border: none;
  font-size: 18px;
  text-decoration: none;
  border-radius: 0;
}

.nav-button {
  color: #24c6ab;
  height: 70px;
  justify-content: center;
  display: grid;
  align-items: center;
}

.btn-close-sibedar{
  color: #052333;
  border-radius: 20px;
  background: transparent;
  border: 1px solid #052333;
  font-weight: 500;
  font-size: 1.1rem;
  width: 90px
}

.btn-close-sibedar:hover{
  color: #fff;
  border-radius: 20px;
  background: #052333;
  font-weight: 500;
  font-size: 1.1rem;
  width: 90px
}
</style>
