import Vue from 'vue'
import Vuex from 'vuex'
import items from './items'
import sesion from './sesion'
import rHumanos from './rHumanos'

Vue.use(Vuex)

/**
 * @param state
 *  personasTotales: set dates state people
 *  selectedService: set select of intitution
 *  searchService: search
 *  servicesList: save services
 *  comparatorService_1: comparate service one
 *  comparatorService_2: comparate service two
 *  rrhhComparator: object datos comparate RRHH
 *  rrhhComparator: object datos comparate RRFF
 *  commentary: set commentary
 *  selectedInsView: institition => RRFF, RRHH, indicator
 *  selectedMainView: site public
 *  selectedPrivateView: site private
 */

/**
 * @param mutations
 *  set datos od state
 */

/**
 * @param getters
 *  get data of state
 */

export default new Vuex.Store({
  state: {
    year: 2019,
    personasTotales: 0,
    selectedService: null,
    typeService: null,
    searchService: null,
    servicesList: [],
    comparatorService_1: null,
    comparatorService_2: null,
    rrhhComparator: {
      genero: null,
      edad: null,
      tipo_contrato: null,
      estamento: null,
      permanencia: null
    },
    rrffComparator: {
      comportamiento: null,
      resultado: null
    },
    commentary: {
      email: '',
      text: ''
    },
    selectedInsView: 'rrhh',
    selectedMainView: 'home',
    selectedPrivateView: 'gestion.gob'
  },
  mutations: {
    setSelectedInsView (state, payload) {
      state.selectedInsView = payload
    },
    setSelectedMainView (state, payload) {
      state.selectedMainView = payload
    },
    changeYear (state, payload) {
      state.year = payload
    },
    updateServicesList (state, payload) {
      state.servicesList = payload
    },
    setTypeService (state, payload) {
      state.typeService = payload
    },
    setSearchService (state, payload) {
      state.searchService = payload
    },
    addPersonasTotales (state, payload) {
      state.personasTotales = 0
      state.personasTotales += payload
    },
    setSelectedService (state, payload) {
      state.selectedService = payload
    },
    setRHGen (state, payload) {
      state.rrhhComparator.genero = payload
    },
    setRHEdad (state, payload) {
      state.rrhhComparator.edad = payload
    },
    setRHTipoContrato (state, payload) {
      state.rrhhComparator.tipo_contrato = payload
    },
    setRHEstamento (state, payload) {
      state.rrhhComparator.estamento = payload
    },
    setRHPerm (state, payload) {
      state.rrhhComparator.permanencia = payload
    },
    setService1 (state, payload) {
      state.comparatorService_1 = payload
    },
    setService2 (state, payload) {
      state.comparatorService_2 = payload
    },
    setComportamiento (state, payload) {
      state.rrffComparator.comportamiento = payload
    },
    setResultado (state, payload) {
      state.rrffComparator.resultado = payload
    },
    resetRRHHComparator (state) {
      state.comparatorService_1 = null
      state.comparatorService_2 = null
      state.rrhhComparator = {
        genero: null,
        edad: null,
        tipo_contrato: null,
        estamento: null,
        permanencia: null
      }
    },
    resetRRFFComparator (state) {
      state.comparatorService_1 = null
      state.comparatorService_2 = null
      state.rrffComparator = {
        comportamiento: null,
        resultado: null
      }
    },
    setCommentary (state, payload) {
      state.commentary = payload
    },
    setPrivateView (state, payload) {
      state.selectedPrivateView = payload
    }
  },
  actions: {},
  getters: {
    commentary (state) {
      return state.commentary
    },
    servicesList (state) {
      return state.servicesList
    },
    searchService (state) {
      return state.searchService
    },
    year (state) {
      return state.year
    },
    personasTotales (state) {
      return state.personasTotales
    },
    typeService (state) {
      return state.typeService
    },
    selectedService (state) {
      return state.selectedService
    },
    rrhhComparator (state) {
      return state.rrhhComparator
    },
    rrffComparator (state) {
      return state.rrffComparator
    },
    selectedInsView (state) {
      return state.selectedInsView
    },
    selectedMainView (state) {
      return state.selectedMainView
    },
    comparatorService_1 (state) {
      return state.comparatorService_1
    },
    comparatorService_2 (state) {
      return state.comparatorService_2
    },
    privateView (state) {
      return state.selectedPrivateView
    }
  },
  modules: {
    items,
    sesion,
    rHumanos
  }
})
