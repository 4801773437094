// function created to feed the tables
// it is not being used but the configuration will be used for future updates

export default {
  namespaced: true,
  state: {
    tablas: {
      // mainTableOne
      porcentajeIngreso: [],
      efectividad: [],
      // mainTableTwo
      PorcentajeEgresos: [],
      ceseRetiro: [],
      porcentajeRecuperacion: [],
      // mainTableThree
      funcionarioAscendidos: [],
      funcionariosRecontratados: [],
      // mainTableFour
      porcentajeFuncionariosCapacitados: [],
      promedioAnualHoras: [],
      porcentajeActividades: [],
      becasOrtogadas: [],
      // mainTableFive
      diasNoLaboradoTipoOne: [],
      diasNoLaboradoOtroTipo: [],
      permisoNoRemunerado: [],
      // mainTableSix
      horasExtraordinarias: [],
      // mainTableSeven
      distribuccionPersonal: [],
      retroalimentacionDesempenio: [],
      // mainTableEight
      politicaGestion: [],
      // mainTableNien
      ingresoContrata: [],
      procesoRegularizacion: [],
      honorariosRegularizado: []
    }
  },
  mutations: {
    updateTables (state, { value, nameTable }) {
      state.tablas[nameTable] = value
    }
  },
  actions: {
    actionsTable ({ commit }, data) {
      commit('updateTables', data)
    }
  }
}
