<template>
  <div>
    <div class="alert">
        <div class="alert-image">
        <img class="img" src="@/assets/img/alert/precaucion.png" alt="Alert Sign"/>
        </div>
        <div class="alert-text">
        Esta sección debe ser llenada únicamente por la persona encargada. No estás autorizado para completar este contenido, sólo puedes visualizarlo.
        </div>
    </div>
  </div>
</template>
<script>
// REEMPLAZAR POR sinAutorizacion.vue
// import Watch from '@/components/alert/Watch.vue'

export default {
  components: {
    // Watch
  },
  data () {
    return {
      alertMessage:
        ''
    }
  }
}
</script>

<style scoped>
.img {
  width: 25px;
}

.alert {
  border-radius: 25px !important;
  background-color: #24c6ab !important;
  display: flex;
  align-items: center;
}

.alert-image {
  justify-content: left;
}

.alert-text {
  align-self: left;
  font-size: 1em;
  font-weight: bold;
  margin: 1rem;
}

.img {
  width: 60px;
}

@media screen and (max-width: 1500px) {
  .alert-text {
    font-size: 1em;
  }
}

@media screen and (max-width: 1000px) {
  .img {
    width: 20px;
  }
  .alert-text {
    font-size: 0.5em;
  }
}
@media screen and (max-width: 491px) {
  .watch {
    display: none;
  }
}
</style>
