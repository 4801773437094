<template>
  <div class="container">
    <div class="card-container">
      <div v-show="!items" class="loading">
        <Loading />
      </div>

      <div v-if="items" class="items-container">
        <InstitutionCard
          v-for="item in items"
          :key="item.id"
          :title="item.nombre"
          :id_servicio="item.id"
          :tipo_servicio="
            typeService === 'ministerios' ? 'Ministerio' : 'Institución'
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import InstitutionCard from './card/InstitutionCard.vue'
import Loading from '@/components/tools/Loading.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    InstitutionCard,
    Loading
  },
  data () {
    return {
      items: null
    }
  },
  computed: {
    ...mapGetters(['servicesList', 'typeService'])
  },
  watch: {
    servicesList: function () {
      this.items = this.$store.getters.servicesList
    }
  }
}
</script>
<style>
.card-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.items-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
}
.loading {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
