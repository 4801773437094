<template>
  <div>
    <b-button variant="link" class="modal-button" id="show-btn" @click="$bvModal.show('bv-modal-example')">
      <Config /> {{ button }}
    </b-button>
    <b-modal  size="lg" id="bv-modal-example" hide-footer>
        <template #modal-title><h4 class="title-modal">{{ title }}</h4></template>
        <div class="container" style="margin-top:5px">
          Presentar los resultados (logros y no logros) del desempeño institucional con efecto en los usuarios, beneficiarios, ciudadanos, asociados a los compromisos del Programa de Gobierno y mensaje presidencial del año 2023, en lo que corresponda a la institución. Todo ello, previamente, revisado con la SEGPRES. Como también aquellos referidos a los aspectos relevantes de las leyes de presupuestos, prioridades ministeriales y Prioridades del jefe de Servicio, entre los principales, u otro instrumento formal con declaraciones públicas de compromisos institucionales . En todos los casos, centrado en todo aquello que corresponda dar cuenta del año 2023.
        </div>
    </b-modal>
  </div>
</template>

<script>
import Config from '@/components/image/icon/Config'
export default {
  props: ['html'],
  components: {
    Config
  },
  data () {
    return {
      button: 'Instrucciones: ¿Cómo completar esta sección?'
    }
  }
}
</script>

<style scoped>
.modal-button {
  display: flex;
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.modal-button:hover {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.modal-button:focus {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.title-modal {
  color: #052333;
  font-size: 20px;
  text-align: center;
}

</style>
