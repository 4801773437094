<template>
  <div id="app">
    <div id="nav"></div>
    <router-view />
  </div>
</template>

<script>
import axios from 'axios'
export default {
  methods: {
    // decode jet
    decodeJwt: function (token) {
      var base64Payload = token.split('.')[1]
      var payload = Buffer.from(base64Payload, 'base64')
      return JSON.parse(payload.toString())
    },
    // auth
    login: async function () {
      if (!window.localStorage.userToken) return
      const a = this.decodeJwt(window.localStorage.userToken)

      const user = await axios.get(
          `${process.env.VUE_APP_RUTA_API}/usuarios/usuarios/${a.user_id}/`,
          {
            headers: {
              Authorization: `JWT ${window.localStorage.userToken}`
            }
          }
      )
      this.setUsuarioObj(user.data)
    }
  },
  mounted () {
    // this.login();
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap");

* {
  font-family: "Fira Sans", sans-serif;
}
</style>
