<template>
  <div>
    <div class="home">
      <Navbar />
    </div>

    <div>
      <div>
        <Header
          title="Tableros de 24 Ministerios "
          postTitle="Se analizan datos de 24 ministerios que incluyen 264 servicios."
          paragraph="Los siguientes visores permiten explorar datos de dotación de personal de 24 ministerios (el Ministerio de Salud es estudiado a parte debido a su tamaño) que incorporan 264 servicios. Cada visor cuenta con una breve explicación o ejemplo de uso para facilitar su comprensión. Se muestran indicadores de Dotación, Remuneración y Longevidad filtrados de acuerdo a distintos parámetros: Sexo, Estamento, Profesión, Grado y Región."
          image="/img_fondef/portada/portada.png"
        />
      </div>
      <b-container>
        <NavBar2 />
        <br />
        <br />

        <blockquote class="blockquote">
          <p class="lead">
            REMUNERACIÓN - DOTACIÓN -LONGEVIDAD (MINISTERIOS)
          </p>
          <footer class="blockquote-footer">
            Este visor explora los servicios del Estado Central (no se incluye
            el Ministerio de Salud, que será analizado de manera separada) de
            acuerdo a tres indicadores relacionados con su dotación de personal.
            Estos tres indicadores son:
            <strong>Remuneración, Longevidad y Dotación</strong>, que están
            dados para cada servicio. Tanto la remuneración como la longevidad
            son valores promediados por cada servicio. La longevidad se mide en
            meses. El periodo de observación máximo es de 11 años (132 meses),
            por lo que la longevidad máxima de un funcionario no puede
            sobrepasar este valor. Es bueno destacar que no todos los servicios
            tienen igual longevidad y algunos de ellos son de reciente creación.
          </footer>
        </blockquote>
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiNzBmNGNhNzktNTU0My00Nzk5LWFmN2YtNzg4ZTdkOWI0NWE4IiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSection"
        />
        <br /><br /><br />

        <blockquote class="blockquote">
          <p class="lead">
            DOTACIÓN - REGIONES
          </p>
          <footer class="blockquote-footer">
            Este visor muestra la
            <strong>Dotación, Remuneración y Longevidad</strong> del personal
            del Estado distribuidas por Región. Puede usar los filtros para
            responder a una gran variedad de consultas. Por ejemplo: Suponga que
            se requiere hacer un estudio sobre la distribución por región de la
            dotación de abogados, que responden ante las demandas por conflictos
            relacionados con derechos de agua, en la Dirección General de Aguas
            (DGA).
          </footer>
        </blockquote>
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiM2RhYTUzN2EtZjc2YS00NWJiLWIwNDItNTQ4N2UyMmM3OTAzIiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSectione1476ef7b9aba0250cac"
        />
        <p>
          Los pasos sugeridos para esta consulta son:
        </p>
        <ol>
          <li>Coloque el filtro de años en 2020.</li>
          <li>
            Borre cualquier selección de género en Sexo ( a menos que quiera
            saber en particular, cuántos abogados mujeres/hombres prestan
            servicios en la DGA).
          </li>
          <li>Seleccione el "Ministerio de Obras Públicas" en Ministerios.</li>
          <li>Seleccione "Dirección General de Aguas (DGA)" en Servicios.</li>
          <li>Finalmente, seleccione "abogado" en Profesión.</li>
        </ol>
        <br /><br /><br />

        <blockquote class="blockquote">
          <p class="lead">
            REMUNERACIÓN - LONGEVIDAD
          </p>
          <footer class="blockquote-footer">
            En este visor se ofrece una perspectiva de acuerdo al Sexo de las
            distribuciones de <strong>Remuneración</strong> y
            <strong>Longevidad</strong> del personal del Estado. Puede utilizar
            los filtros para evidenciar, por ejemplo, las diferencias salariales
            entre hombres y mujeres por Ministerio y Servicios. Estas
            diferencias pueden estar matizadas además por Región y por Grado. El
            análisis puede incorporar también la evolución temporal, al
            considerar once años de datos en la visualización de
            <i>barras agrupadas</i>. Los diagramas de violín que aparecen en el
            lado izquierdo del visor se construyen con muestras de los datos y
            visualizan la distribución de salarios y longevidad para dar una
            idea mas allá de los valores promedios que aparecen en las
            etiquetas. Como referencia, se incluye la Dotación del Servicio y su
            Longevidad máxima (Max.Longevidad).
          </footer>
        </blockquote>
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiOGU4YTZkYjItNGFhNy00MzQwLWE4MmUtZWYzNTZiMTIzZTVjIiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSection99da1f3b7e181b8199cd"
        />
        <br /><br /><br />

        <blockquote class="blockquote">
          <p class="lead">
            COMPARACIONES
          </p>
          <footer class="blockquote-footer">
            Este visor consta de cuatro partes:<br />
            <b>Visor 1 y 2:</b> Comparación de Remuneraciones promedios por
            Ministerio y Servicio respectivamente
            <br />
            <b>Visor 2 y 4:</b> Comparación de Dotación por Ministerio y
            Servicio respectivamente.
            <br />
            De manera dinámica se posicionan los primeros 15 servicios con los
            valores más altos. Haciendo uso de los filtros se puede hacer
            seguimiento a uno o varios servicios de interés a través de los
            años. También se puede filtrar por Sexo y Régimen.
          </footer>
        </blockquote>
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiMjFkZjViY2UtODBiYi00NGIxLTgxY2MtMThhNGMzMmYwM2I3IiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSection891cd0403a420ce383ce"
        />
        <br /><br /><br />
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from './components/header/Header.vue'
import PowerBI from './components/powerbi/PowerBI.vue'
import NavBar2 from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navbar,
    Header,
    PowerBI,
    NavBar2,
    Footer
  }
}
</script>

<style scoped>
p {
  font-family: Georgia, serif;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin: 2rem 0;
}

.lead {
  font-size: 1.7rem;
  margin: 3.4rem 0 1.7rem 0;
  font-family: sans-serif;
  padding:20px;
}

.center {
  text-align: center;
}

.blockquote .lead {
  font-weight: bold;
  color: #bdc3c7;
}

.blockquote-footer::before {
  content: "";
}

strong {
  color: #980100;
}

p {
  font-size: 0.875em;
  color: #6c757d;
  font-family: "Fira Sans", sans-serif;
}
</style>
