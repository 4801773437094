<template>
  <div>
    <b-card tag="article" class="mb-2 card">
      <div class="img">
        <CardImageRight />
      </div>
      <div>
        <h3 class="title">{{ title }}</h3>
      </div>

      <div class="hr"></div>

      <div class="text">
        <p>
          {{ text }}
        </p>
      </div>

      <div class="button-more">
        <b-button type="button" class="btn">{{ btn }}</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import CardImageRight from '../../../image/home/CardImageRight.vue'

export default {
  components: {
    CardImageRight
  },
  data () {
    return {
      title: 'Indicadores de Gestión',
      text: 'Indicadores de Desempeño. Servicios del Gobierno Central.',
      btn: 'Ver más'
    }
  }
}
</script>

<style scoped>
.mb-2 {
  width: 17rem;
  border-radius: 10px;
}

.card {
  border: 1px solid #e0e0e0;
  background: #e0e0e0;
  padding: 1.5rem;
}

.card:hover {
  box-shadow: rgb(0 0 0 / 20%) 5px -5px 18px 2px;
  background: #fff;
}

.card {
  transition: background 0.3s linear 0.2s;
}

.img {
  text-align: center;
  margin-bottom: 5px;
}

.title {
  color: #080d46;
  text-align: center;
}
.hr {
  border: 1px solid #24c6ab;
  width: 119px;
  max-width: 100%;
  position: relative;
  left: 53px;
  margin-top: 13px;
  margin-bottom: 11px;
}

.text {
  text-align: center;
  color: #080d46;
}

.button-more {
  text-align: center;
}

.btn {
  width: 105px;
  border-radius: 42px;
  background: #24c6ab;
  color: #fff;
  border: none;
}

.btn:hover {
  background: #24c6ab;
  color: #fff;
}
</style>
