<template>
  <div>
    <b-row>
      <b-col cols="5" class="cols-left">
        <div class="container container-div">
          <h3 class="title">Personas en el Estado</h3>
          <p class="desc">Corresponde a la cobertura de instituciones que elaboran Balance de Gestión Integral</p>
        </div>

        <div class="search-data container">
          <div class="year">
            <h3 class="year-num">{{ year }}</h3>
          </div>
          <b-dropdown id="dropdown-1" text="Seleccionar otro año" size="sm"  variant="light" class="apart">
            <b-dropdown-item class="item" @click="onChangeYear(2018)">2018</b-dropdown-item>
            <b-dropdown-item class="item" @click="onChangeYear(2019)">2019</b-dropdown-item>
            <b-dropdown-item class="item" @click="onChangeYear(2020)">2020</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>

      <b-col cols="3" class="btn-col">
        <b-button class="button-left">
          <div>
            <img class="img-button-left" src="./img/balance.svg" alt="" />
          </div>
          <div>
            <span class="text-button">Balance Gestión Integral</span>
          </div>
        </b-button>

        <b-button class="Button-right" href="/min-hacienda/persona-en-el-estado/fondef">
          <div>
            <img class="img-button-left" src="./img/introduccion.svg" alt="" />
          </div>
          <div>
            <span class="text-button">Consejo para la transparencia</span>
          </div>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data () {
    return {
      year: this.$route.params.anio || '2020',
      notification:
        'Para los datos por institución, utiliza el buscador o pincha "instituciones" en el menú principal.',
      showNotification: true
    }
  },

  methods: {
    onChangeYear (year) {
      this.$router.push({ name: this.$route.name, params: { anio: year } })
    }
  },
  mounted () {
    setTimeout(() => {
      this.showNotification = false
    }, 5000)
  }
}
</script>

<style scoped>
p {
  margin: 0 !important
}
.row {
  margin: 0;
  background: #052333;
}

.title {
  color: #fff;
  margin-top: 30px;
}
.desc {
  color: #fff;
  font-size: 12px;
}
.col-3 {
  display: flex;
  align-items: center;
  width: 22% !important;
}
.col-4 {
  display: flex;
  align-items: center;
  padding: 1rem;
}

p {
  color: #fff;
  margin-top: -10px;
}

.container-div {
  margin-left: 8px;
}
#dropdown-1__BV_toggle_ {
  padding: 5px 10px;
}

.search-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 6px;
}

.apart {
  font-weight: 500;
  margin-left: 10px;
  position: relative;
  /* top: -5px; */
}

.item {
  font-size: 14px;
}

.year {
  display: flex;
  font-weight: 800;
  height: 100%;
  color: #24c6ab;
}
h3.year-num {
  margin: 0 !important;
}

.button-left {
  background: #e0e0e0;
  color: #052333;
  height: 136px;
  width: 136px;
  margin-top: 0px;
  border-radius: 0px;
  border: none;
}

.button-left:hover {
  background: #e0e0e0;
  color: #052333 !important;
}

.button-left:focus {
  background: #e0e0e0;
}

.text-button {
  font-size: 15px;

}

.img-button-left {
  width: 40px;
  font-weight: 600;
}

.Button-right {
  background: #052333;
  height: 136px;
  width: 136px;
  margin-top: 0px;
  border-radius: 0px;
  border: none;
}
.Button-right:hover {
  background: #24c6ab;
   color: #052333;
}

.Button-right:focus {
  background: #24c6ab;
    color: #052333;
}

@media screen and (max-width: 1309px) {
  .text-button {
    font-size: 11px;
  }
}

@media screen and (max-width: 1293px) {
  .title {
    font-size: 24px;
  }
  .desc {
    color: #fff;
    font-size: 10px;
  }

  p {
    color: #fff;
    margin-top: -10px;
  }

  .button-left {
    height: 142px;
  }
}

@media screen and (max-width: 1106px) {
  .title {
    font-size: 22px;
  }

  .desc {
    font-size: 8px;
  }
  .img-button-left {
    width: 30px;
  }

  .text-button {
    font-size: 9px;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 897px) {
  .cols-left {
    margin-right: 160px;
  }

  .container-div {
    width: 190%;
  }

  .alert-persons {
    display: none;
  }
  .title {
    font-size: 22px;
  }

  .desc {
    font-size: 9px;
  }
  .img-button-left {
    width: 30px;
  }

  .Button-right {
    width: 180px;
  }

  .button-left {
    display: none;
  }

  .text-button {
    font-size: 9px;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 441px) {
  .btn-col {
    display: none;
  }
  .title {
    font-size: 16px;
  }

  .desc {
    font-size: 7px;
  }
}
</style>
