<template>
  <div>
    <div class="home">
      <Navbar />
      <Header />
    </div>

    <Contents />

    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from './components/Header.vue'
import Contents from './components/Contents.vue'
import Footer from '../../components/layout/Footer.vue'
export default {
  components: {
    Navbar,
    Header,
    Contents,
    Footer
  },
  mounted () {
    this.$store.commit('setSelectedMainView', 'instituciones')
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap");
.help {
  height: 62px;
  width: 200px;
}

.contents {
  height: 80vh;
}
</style>
