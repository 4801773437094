<template>
  <div class="container">
    <Title/>
    <Breadcrumb :links="breadCrumbData" />
    <Carrusel />
    <br />
    <Main/>
  </div>
</template>

<script>
import Title from '@/components/private/Title'
import Main from './Main.vue'
import Carrusel from '@/components/private/Carrusel.vue'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
// import axios from 'axios'
import { mapState } from 'vuex'

export default {
  components: {
    Title,
    Breadcrumb,
    Carrusel,
    Main
  },
  data () {
    return {

      breadCrumbData: [],
      readOnly: true,
      userPermissions: true,
      userPermission: true
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])

  },
  methods:
  {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        {
          name: 'Portada BGI',
          routeName: 'bgi',
          routeParams: {
            idUsuario: this.usuario.id
          }
        },
        {
          name: 'Escritorio de Gestión Virtual',
          routeName: 'buttons',
          routeParams: {
            idUsuario: this.usuario.id,
            idServicio: this.$route.params.idServicio
          }
        },
        {
          name: 'Presentación del Servicio - Principales Autoridades',
          routeName: 'principalesAutoridades',
          routeParams: {
            idServicio: this.$route.params.idServicio
          }
        }
      ]
    }
    // obtenerRelacionUsuarioServicio () {
    //   const data = JSON.parse(localStorage.getItem('user'))
    //   const relacionUs = data.servicios.find(
    //     (s) => s.id === parseInt(this.$route.params.idServicio)
    //   )
    //   if (!relacionUs) return
    //   if (
    //     relacionUs.permisos.find((p) => p.permisoId === 2) ||
    //     relacionUs.rolId === 3 ||
    //     relacionUs.rolId === 4 ||
    //     relacionUs.rolId === 5
    //   ) {
    //     this.readOnly = false
    //   }
    // },

    // obtenerRevision: async function () {
    //   try {
    //     const toSend = process.env.VUE_APP_ANIO
    //     const res = await axios.post(
    //       `${process.env.VUE_APP_RUTA_API}/instituciones/revisiones/${this.$route.params.idServicio}/ultima_revision/`,
    //       {
    //         anio: toSend
    //       },
    //       {
    //         headers: {
    //           Authorization: `JWT ${localStorage.getItem('userToken')}`
    //         }
    //       }
    //     )

    //     this.datosUser = res.data

    //     const data = JSON.parse(localStorage.getItem('user'))
    //     const relacionUs = data.servicios.find(
    //       (s) => s.id === parseInt(this.$route.params.idServicio)
    //     )
    //     if (!relacionUs) return

    //     // FUNCIONARIO
    //     if (relacionUs.rolId === 5) {
    //       this.userPermission = false
    //     }
    //     // FUNCIONARIO
    //     if (
    //       (this.datosUser.siguienteAccion === 'jefe_servicio' &&
    //         relacionUs.rolId === 5) ||
    //       (this.datosUser.siguienteAccion === 'representante_ministerial' &&
    //         relacionUs.rolId === 5) ||
    //       (this.datosUser.siguienteAccion === 'administrador' &&
    //         relacionUs.rolId === 5) ||
    //       (this.datosUser.siguienteAccion === 'superadministrador' &&
    //         relacionUs.rolId === 5) ||
    //       (this.datosUser.a === 'finalizado' && relacionUs.rolId === 5)
    //     ) {
    //       this.userPermissions = false
    //     }

    //     // ENCARGADO BGI
    //     if (
    //       (this.datosUser.siguienteAccion === 'jefe_servicio' &&
    //         relacionUs.rolId === 4) ||
    //       (this.datosUser.siguienteAccion === 'representante_ministerial' &&
    //         relacionUs.rolId === 4) ||
    //       (this.datosUser.siguienteAccion === 'administrador' &&
    //         relacionUs.rolId === 4) ||
    //       (this.datosUser.siguienteAccion === 'superadministrador' &&
    //         relacionUs.rolId === 4) ||
    //       (this.datosUser.a === 'finalizado' && relacionUs.rolId === 4)
    //     ) {
    //       this.userPermissions = false
    //       this.userPermission = false
    //     }

    //     // JEFE DE SERVICIO
    //     if (
    //       (this.datosUser.siguienteAccion === 'representante_ministerial' &&
    //         relacionUs.rolId === 3) ||
    //       (this.datosUser.siguienteAccion === 'administrador' &&
    //         relacionUs.rolId === 3) ||
    //       (this.datosUser.siguienteAccion === 'superadministrador' &&
    //         relacionUs.rolId === 3) ||
    //       (this.datosUser.a === 'finalizado' && relacionUs.rolId === 3)
    //     ) {
    //       this.userPermissions = false
    //       this.userPermission = false
    //     }

    //     console.log('rolId', this.datosUser)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRelacionUsuarioServicio()
    this.obtenerRevision()
  }
}
</script>
