<template>
  <div>
    <b-button variant="link" class="modal-button" id="show-btn" @click="$bvModal.show('bv-modal-example')">
      <Config /> {{ button }}
    </b-button>
    <b-modal size="lg" id="bv-modal-example" hide-footer>
      <template #modal-title><h4 class="title-modal">{{ title }}</h4></template>

        <div class="container" style="margin-top:5px">
            Reporte pre-llenado con evaluaciones realizadas por DIPRES en período 2020-2021.
            <br><br>
            Las instituciones deben reportar las evaluaciones adicionales, institucionales y de programas públicos, que se hayan desarrollado dentro del período 2020 – 2021.
            <br><br>
            Los procesos evaluativos deberán ser informado por el Servicio en el aplicativo BGI 2021.

            <br><br>
            Descripción de cada columna:
            <br><br>
            <ul>
                <li>Tipo de la evaluación: Corresponde al alcance de la evaluación reportada. Puede ser evaluación de implementación, construcción o medición de línea base, satisfacción usuaria, de resultados intermedios o finales, de efectos, de calidad, de impacto, entre otros. En el caso de las evaluaciones Dipres se indica la línea de evaluación. Puede ser Evaluación de Programas Gubernamentales (EPG), Evaluación Focalizada de Ámbito (EFA), Evaluación de Impacto (EI), Evaluación Sectorial (ES) o Evaluación de Gasto Institucional (EGI)</li>
                <li>Programa/Institución: Se señala el o los programas de la Oferta Programática vigente de la institución que fueron parte de la evaluación. En caso de ser una evaluación institucional se debe señalar el nombre de la institución.</li>
                <li>Año: Año de publicación. Si está en curso, se considera año de inicio de la evaluación.</li>
                <li>Estado: Corresponde al estado en que se encuentra la evaluación. Puede ser finalizada o en curso.</li>
                <li>Costo (M$2021): Recursos utilizados para llevar a cabo la evaluación. Si la evaluación es de carácter interno, favor señalar un monto estimado. El monto se debe informar en M$ 2021.</li>
                <li>Link de descarga: En caso de que el estado sea finalizada señalar link de publicación del documento.</li>
                <li>Evaluación Dipres: Se señala con un SI o NO si corresponde a una evaluación Dipres.</li>
            </ul>
      </div>

    </b-modal>
  </div>
</template>

<script>
import Config from '@/components/image/icon/Config'
export default {
  props: ['html'],
  components: {
    Config
  },
  data () {
    return {
      button: 'Instrucciones: ¿Cómo completar esta sección?'
    }
  }
}
</script>

<style scoped>
.modal-button {
  display: flex;
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.modal-button:hover {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.modal-button:focus {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.title-modal {
  color: #052333;
  font-size: 20px;
  text-align: center;
}

</style>
