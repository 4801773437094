<template>
  <div>
    <div class="btn-btn">
      <b-button squared class="one">Asociados al Programa de Gobierno</b-button>
      <b-button squared class="two" :to="{ name: 'productoEstrategicos' }"
        >Resultados de Productos Estratégicos</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    seleccionar (nombre) {
      this.$router.push({ name: nombre })
    }
  }
}
</script>

<style scoped>
.div-button {
  margin-left: 30px;
}
.one {
  background: #052333 !important;
  color: white !important;
  border: none;
  font-weight: 700 !important;
  max-width: 200px;
  max-height: 70px;
  font-size: 16px;
  /* margin-left: 30px; */
}

.two {
  color: #052333;
  background: #efefef;
  border: none;
  max-width: 200px;
  max-height: 70px;
  font-size: 16px;
}

.two:hover {
  background: #24c6ab;
  color: #052333;
}

@media screen and (max-width: 1000px) {
  .one {
    width: 130px;
    font-size: 10px;
  }

  .two {
    width: 130px;
    font-size: 10px;
  }
}
</style>
