<template>
  <div class="container">
    <h1>Bienvenidos al proceso BGI 2023 - Etapa 2, en esta oportunidad les informamos lo siguiente:</h1>
    <ul>
      <li>Plazos: 13 al 17 de mayo de 2024.</li>
      <li>Sólo se podrá trabajar en el anexo de Recursos Humanos - Indicadores. Para mayor información revisar el manual de instrucciones disponible en la plataforma.</li>
      <li>Al igual que el año pasado, para el presente BGI las cifras del anexo responden al IV Informe Trimestral de los Recusos Humanos del Sector Público del año 2023 de la Dirección de Presupuestos, situación que explica eventuales diferencias con la información de la respectiva Institución.</li>
      <li>El PDF estará disponible al final del proceso.</li>
    </ul>
      <br><br>
        <div class="button-gestion">
            <b-button :to="{name: 'bgi', params: { id: array.idServicio, idUsuario: array.idUsuario },}"
            class="btn-gestion" pill >Continuar</b-button>
        </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { formatRole } from '@/components/tools/utils'
export default {
  components: { },
  data () {
    return {
      title: 'Bienvenidos',
      array: []
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])
  },
  methods: {
    mapServices: function () {
      if (!this.usuario) return
      this.array = this.usuario.servicios.map((s) => ({
        idUsuario: this.usuario.id,
        idServicio: s.id,
        ministerio: s.ministerio,
        perfil: formatRole(s.rolNombre),
        nombreServicio: s.nombre,
        usuarioServicioId: s.usuarioServicioId
      }))
    }
  },
  mounted () {
    this.mapServices()
  }
}
</script>

<style scoped>

.container {
  padding:20px;
  margin-top:50px;
  background: #f5f5f5;
  box-shadow: 1px 1px 1px 0.5px #052333;
  border-radius: 7px;
}

</style>
