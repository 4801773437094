<template>
  <div class="content">
    <div class="container cards">
      <Cards />
    </div>
  </div>
</template>

<script>
import Cards from '@/components/home/content/Cards.vue'
export default {
  components: {
    Cards
  }
}
</script>
