<template>
  <div class="gestion">
    <div class="img-logout">
      <ImageNav />
        <b-link class="link" :to="{ name: 'Home' }">    <i class="bx bx-exit"> </i>Salir</b-link>
    </div>

    <Header />

    <Content />

    <Footer />
  </div>
</template>

<script>
// import Nav from './components/_Nav'
import Header from './components/nav/Header.vue'
import Content from './components/Content'
import Footer from '../../components/layout/Footer.vue'
import ImageNav from './components/nav/ImageNav.vue'
export default {
  components: {
    // Nav,
    Header,
    Content,
    Footer,
    ImageNav
  }
}
</script>
<style scoped>
.img-logout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 50px;
}

.gestion{
  height: 100vh;
}

/* SALIR */
.link {
  display: flex;
  align-items: center;
  color: #052333;
  text-decoration: none;
  font-size: 32px;
}

@media screen and (max-width: 1800px) {
  .link {
    font-size: 28px;
  }
}

@media screen and (max-width: 1600px) {
  .link {
    font-size: 22px;
  }
}
</style>
