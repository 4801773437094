<template>
    <div v-if="anexo">
    <!--
    <h1 class="privado-titulo">Evaluaciones</h1>-->
    <Buttons />
    <b-row>
        <b-col>
            <Instrucciones/>
        </b-col>
        <b-col>
            <Visualizar seccion="evaluaciones_programa"  />
        </b-col>
        <b-col style="text-align:right">
            <b-form-checkbox v-model="anexo.datos.no_aplica" :disabled="!can_edit_anexos_de_servicios()">
                No Aplica
            </b-form-checkbox>
        </b-col>
    </b-row>
    <br><br>
    <h3>Cuadro 8</h3>
    <h3>Cumplimiento de Compromisos de Programas / Instituciones Evaluadas</h3>

    <b-button v-if="can_edit_anexos_de_servicios()" @click="guardar" pill variant="primary" class="btn-one">Guardar</b-button>
    <table class="table">
        <thead>
            <tr>
            <th scope="col">Tipo de Evaluacion</th>
            <th scope="col">Programa/Institución</th>
            <th scope="col">Año</th>
            <th scope="col">Estado</th>
            <th scope="col">Monto</th>
            <th scope="col">Link de descarga</th>
            <th scope="col">Evaluación Dipres (SI/NO)</th>
            <th scope="col">Nota</th>
            <th scope="col">
                <b-button v-if="can_edit_anexos_de_servicios()" class="button-add" @click="addItemList">+</b-button>
            </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in itemList" :key="index">
                <td>
                <b-form-input :disabled="!can_edit_anexos_de_servicios() || item.importado" class="inputs" v-model="item.tipo_evaluacion" type="text" ></b-form-input>
                </td>
                <td>
                <b-form-input :disabled="!can_edit_anexos_de_servicios() || item.importado" class="inputs" v-model="item.programa" type="text" ></b-form-input>
                </td>
                <td>
                <b-form-input :disabled="!can_edit_anexos_de_servicios() || item.importado" class="inputs" v-model="item.anio" type="number" ></b-form-input>
                </td>
                <td>
                <b-form-input :disabled="!can_edit_anexos_de_servicios() || item.importado" class="inputs" v-model="item.estado" ></b-form-input> </td>
                <td>
                <b-form-input :disabled="!can_edit_anexos_de_servicios() || item.importado" class="inputs" v-model="item.monto" type="number" ></b-form-input>
                </td>
                <td>
                <b-form-input :disabled="!can_edit_anexos_de_servicios() || item.importado" class="inputs" v-model="item.link" ></b-form-input> </td>
                <td>
                    <select class="form-control" v-model="item.evaluacion_dipres" :disabled="!can_edit_anexos_de_servicios() || item.importado">
                        <option :value="true">Sí</option>
                        <option :value="false">No</option>
                    </select>
                </td>
                <td>{{item.nota}}</td>
                <td>
                <i v-if="can_edit_anexos_de_servicios() && !item.importado" @click="removeItem(item)" class="bx bxs-trash"></i>
                </td>
            </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
import axios from 'axios'
import Instrucciones from './Instrucciones.vue'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'
import Buttons from './Buttons.vue'

export default {
  mixins: [utils],
  components: {
    Instrucciones,
    Visualizar,
    Buttons
  },
  data () {
    return {
      itemList: [],
      anexo: null,
      idServicio: this.$route.params.idServicio

    }
  },
  methods: {
    addItemList: function () {
      const toAddObj = { importado: false }
      this.itemList.push(toAddObj)
    },

    removeItem: function (index) {
      const pos = this.itemList.indexOf(index)
      this.itemList.splice(pos, 1)
    },
    guardar: function () {
      axios.put(`/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    },
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'evaluaciones_programa'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.listado = anexo.datos.listado || []
        this.itemList = anexo.datos.listado
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }

  },
  mounted () {
    this.obtenerRevision()
  }
}
</script>

<style scoped>
.row {
  margin: 0;
}

.btn-one {
  margin-right: 20px;
}

.btn-direction-flex {
  text-align: end;
}
.title {
  margin-top: 20px;
  margin-bottom: 21px;
  text-align: center;
  color: #052333;
}

h5 {
  color: #052333;
  font-size: 30px;
  font-weight: bold;
}

.span {
  font-size: 18px;
  color: #052333;
  font-weight: 600;
}

@media screen and (max-width: 1800px) {
  h5 {
    color: #052333;
    font-size: 26px;
    font-weight: bold;
  }

  .btn-one {
    font-size: 16px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 16px;
  }
}

@media screen and (max-width: 1600px) {
  h5 {
    font-size: 21px;
  }

  .btn-one {
    font-size: 15px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 15px;
  }
}

@media screen and (max-width: 1400px) {
  h5 {
    font-size: 27px;
  }

  .btn-one {
    font-size: 14px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 14px;
  }
}

@media screen and (max-width: 1100px) {
  h5 {
    font-size: 22px;
  }

  .btn-one {
    font-size: 12px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  h5 {
    font-size: 18px;
  }

  .btn-one {
    font-size: 10px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 10px;
  }
}

@media screen and (max-width: 560px) {
  .btn-one {
    font-size: 7px;
    margin-right: 2px;
    width: 60px;
  }
  .btn-two {
    font-size: 7px;
    width: 60px;
    height: 45px;
  }
}
</style>
