<template>
  <div class="container">
    <div class="main container">
      <div class="buttons">
        <input type="file" ref="file" id="file-upload" @change="onChangeFileUpload"/>
        <label for="file-upload" class="button-pill-up">          <i class="bx bx-upload"></i> Subir</label>
      </div>
      <p v-if="file" class="archivo">{{ file.name }}</p>
      <b-button  v-if="file" class="button-pill-up" @click="uploadFile" >Confirmar</b-button>
      <div v-if="loading" class="loading">
        <Loading />
      </div>
    </div>
  </div>
</template>
<script>
import Loading from '@/components/tools/Loading.vue'
import axios from 'axios'
import { mapState } from 'vuex'

import { showAlert } from '@/components/tools/utils'
export default {
  components: {
    Loading
  },
  data () {
    return {
      file: null,
      xfile: null,
      loading: false
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])
  },
  methods: {
    uploadFile: function () {
      if (!this.file) return
      const formData = new FormData()
      this.$route.params.idServicio
      formData.append('servicio', this.$route.params.idServicio.toString())
      formData.append('organigrama', this.file, this.file.name)
      //   this.loading = true
      //   setTimeout(() => { this.loading = false }, 3000)
      axios.post(`${process.env.VUE_APP_RUTA_API_IMG}/instituciones/ministerios/organigrama/`, formData, { headers: { 'content-type': 'multipart/form-data' } }).then(r => {
        this.file = null
        this.$parent.obtenerContenido()
      }).catch(e => {
        showAlert({ icon: 'error', text: e.response ? e.response.data[Object.keys(e.response.data)[0]] : 'error', timerProgressBar: true, timer: 3000 })
        this.file = null
      })
    },
    onChangeFileUpload (event) {
      this.xfile = event.target.files[0]
      if (this.xfile) {
        if (['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'].indexOf(this.xfile.type) <= 0) {
          this.file = null
          showAlert({ icon: 'error', text: 'Tipo de archivo invalido', timerProgressBar: true, timer: 3000 })
          return
        }

        this.file = this.xfile
      }
    }

  },
  mounted () {
    this.$parent.obtenerContenido()
    // this.obtenerRevision()
    // this.obtenerRelacionUsuarioServicios()
  }
}
</script>
<style scoped>
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 10px 0;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.archivo {
  margin: 10px 0 !important;
}

.button-pill-up {
  margin-left: 10px;
  width: 150px;
  background: #4f52ff;
  border: none;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  color: white;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-pill-up:hover {
  background: #3d185f;
}
input[type="file"] {
  display: none;
}
i {
  margin-right: 4px;
}
.main-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b8f4e9;
  height: 50px;
}
.main-text > h6 {
  font-weight: bold;
  color: #515151;
  margin: 0;
  text-align: center;
  margin: 15px;
}
</style>
