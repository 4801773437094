<template>
  <div class="compromisos">
    <Header />

    <div class="container">
      <Title :showMinister="true" :showService="true" />
      <Breadcrumb :links="breadCrumbData" />
      <Carrusel />
        <h1 class="privado-titulo">Compromisos</h1>

        <b-row>
            <b-col>
                <!-- <Instrucciones/> -->
            </b-col>
            <b-col>
                <Visualizar seccion="compromisos" />
            </b-col>
            <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
                <b-form-checkbox v-model="anexo.datos.no_aplica">
                    No Aplica
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-button v-if="can_edit_anexos_de_servicios()" @click="guardar" pill variant="primary" class="btn-one">Guardar</b-button>
        <table class="table table-striped" v-show="!anexo.datos.no_aplica">
            <thead>
                <tr>
                    <th class="apply-width" scope="col">Iniciativa</th>
                    <th class="apply-width-four" scope="col">Estado de Avance</th>
                    <th class="apply-width-one" scope="col"></th>
                </tr>
            </thead>
            <tbody v-if="can_edit_anexos_de_servicios()">
                <tr v-for="(item, index) in itemList" :key="index">
                    <td class="align-left">{{ item.iniciativa}}</td>
                    <td class="align-left">{{ item.estado_avance}}</td>
                    <td class="align-left">
                        <b-form-checkbox
                            v-model="item.seleccionado"
                            :value="true"
                            :disabled="!can_edit_anexos_de_servicios()"
                            :unchecked-value="false"
                        />
                    </td>
                </tr>
            </tbody>
            <tbody v-if="!can_edit_anexos_de_servicios()">
                <tr v-for="(item, index) in filtrados()" :key="index">
                    <td class="align-left">{{ item.iniciativa}}</td>
                    <td class="align-left">{{ item.estado_avance}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <Footer />
  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
import axios from 'axios'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'

import Header from '../../components/private/Header'
import Footer from '../../components/layout/Footer.vue'
import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '@/components/private/Carrusel.vue'
// import Instrucciones from './Instrucciones.vue'

export default {
  mixins: [utils],
  components: {
    Header,
    Title,
    Breadcrumb,
    Carrusel,
    Visualizar,
    Footer
  },
  data () {
    return {
      breadCrumbData: null,
      itemList: null,
      anexo: null
    }
  },
  methods: {
    // name of breadcrumb
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Compromisos', routeName: 'compromisos', routeParams: { idServicio: this.$route.params.idServicio } }
      ]
    },

    guardar: function () {
      axios.put(`/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    },
    obtenerContenido: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'compromisos'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.listado = anexo.datos.listado || []
        this.itemList = anexo.datos.listado
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    },
    filtrados () {
      if (!this.itemList) { return [] }
      return this.itemList.filter(i => { return i.seleccionado })
    }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerContenido()
    // this.obtenerRelacionUsuarioServicio()
  }
}
</script>

<style scoped>
.compromisos {
  height: 100vh;
}
</style>
