<template>
  <div>
    <Header :showService="false" :showMinister="false" />
    <div class="container">
         <div class="search">
            <div class="input-icono">
            <input
                placeholder="Buscar un servicio"
                v-model="toSearch"
                type="text"
                name="input"
            />
            <b-button class="btn class"><p class="text-btn">Buscar</p></b-button>
            </div>
        </div>
    <Data :toSearch="toSearch" />
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from '../../components/layout/Footer.vue'
import Header from '../../components/private/Header'
import Data from './components/Data.vue'

export default {
  components: {
    Header,
    Data,
    Footer
  },
  data () {
    return {
      toSearch: ''
    }
  },

  mounted () {
    this.$store.commit('setPrivateView', 'portada-bgi')
  }
}
</script>

<style scoped>
.profile {
  height: 100vh;
}

.search {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.input-icono {
  background-color: white;
  background-image: url("../../assets/img/input/bx-search-alt.svg");
  background-repeat: no-repeat;
  background-position: 4px center;
  background-size: 20px;
  display: flex;
  align-items: center;
  width: 450px;
  padding-left: 28px;
  height: 40px;
  border: 1px solid rgb(5, 35, 51);
  border-radius: 20px;
  font-size: 17px;
}

.input-icono input {
  width: 100%;
  font-size: 0.9em;
  border: none;
}

.input-icono input:focus {
  outline: none;
}

.btn {
  height: 40px;
  background: #052333;
  border-radius: 0;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

@media screen and (max-width: 1750px) {
  .input-icono {
    width: 400px;
    height: 35px;
    font-size: 15px;
  }

  .btn {
    height: 35px;
  }
}

@media screen and (max-width: 1200px) {
  .input-icono {
    width: 300px;
    height: 30px;
    font-size: 13px;
  }

  .btn {
    height: 30px;
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .input-icono {
    width: 250px;
    height: 28px;
    font-size: 12px;
  }

  .btn {
    height: 28px;
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .input-icono {
    width: 200px;
    height: 24px;
    font-size: 10px;
  }

  .btn {
    height: 24px;
    font-size: 10px;
  }
}
</style>
