<template>
  <div class="financiero">
    <Header />

    <Contents />

    <Footer />
  </div>
</template>

<script>
import Header from '../../components/private/Header'
import Contents from './components/Contents.vue'
import Footer from '../../components/layout/Footer.vue'

export default {
  components: {
    Header,
    Contents,
    Footer
  },
  mounted () {
    this.$store.commit('setPrivateView', 'portada-bgi')
  }
}
</script>

<style scoped>
.financiero {
height: 100vh;
}

</style>
