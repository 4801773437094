<template>
  <div>
    <div class="home">
      <Navbar />
      <Header
        titulo="Ejecución Presupuestaria"
        desc="Corresponde a la cobertura que elaboran Balance e Gestión Integral"
        :showYearSelect="false"
        :showDownloadButton="false"
      />
    </div>
      <Contents />
      <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from '../../components/cabecera/Header.vue'
import Contents from './components/Contents.vue'
import Footer from '../../components/layout/Footer.vue'
export default {
  components: {
    Navbar,
    Contents,
    Header,
    Footer
  },
  mounted () {
    this.$store.commit('setSelectedMainView', 'people')
  }
}
</script>
