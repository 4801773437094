<template>
  <div>
    <header class="bg-header">
      <h3 class="header-title">Comentario de Revisión</h3>
    </header>
    <form>
      <div class="input-textarea">
        <label for="input" class="input-commentary">Estado de Revisión</label>
        <input
          type="text"
          disabled
          class="commentary"
          placeholder="Correo"
          v-model="status"
        />
      </div>
      <div class="input-textarea">
        <label for="textarea" class="input-commentary">Mensaje</label>
        <b-form-textarea
          id="textarea"
          placeholder="Escribe algo"
          rows="6"
          max-rows="6"
          v-model="revisionData.mensaje"
          class="coso-comentario"
        ></b-form-textarea>
      </div>

      <div class="botones">
        <b-button class="btn-send enviar" @click="sendCommentary"
          >Enviar</b-button
        >
        <b-button class="btn-send cerrar" @click="closeCommentModal"
          >Cerrar</b-button
        >
      </div>
      <div style="display:flex; justify-content: center;">
        <div v-if="showAlert" class="alerta-cv">
          <span>{{ alert }}</span>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { utils } from '@/components/tools/ViewUtils.js'

import { showAlert } from '@/components/tools/utils'
import axios from 'axios'

export default {
  mixins: [utils],
  data () {
    return {
      alert: 'El campo mensaje es obligatorio',
      showAlert: false
    }
  },
  props: ['revisionData', 'closeCommentModal', 'actualizar'],
  computed: {
    status: function () {
      return (
        this.revisionData.tipo[0].toUpperCase() +
        this.revisionData.tipo.slice(1)
      )
    }
  },
  methods: {
    sendCommentary: async function () {
      if (this.revisionData.mensaje.length === 0) {
        this.showAlert = true
        return
      }
      this.showAlert = false
      axios.post('/instituciones/revisiones/', this.revisionData).then(res => {
        showAlert({
          icon: 'success',
          text: 'Revisión enviada correctamente',
          timerProgressBar: true,
          timer: 1000
        })
        this.closeCommentModal()
        this.actualizar()
      })
      //   } catch (error) {
      //     const errorText =
      //       error.response.data[Object.keys(error.response.data)[0]]

    //     if (
    //       errorText.includes('esta revision debe ser realizada por los roles')
    //     ) {
    //       const rolFaltante = errorText.split(': ')[1]
    //       showAlert({
    //         icon: 'error',
    //         text: errorText.split(': ')[0] + `: ${formatRole(rolFaltante)}`,
    //         timerProgressBar: true,
    //         timer: 3000
    //       })
    //     } else {
    //       showAlert({
    //         icon: 'error',
    //         text: errorText,
    //         timerProgressBar: true,
    //         timer: 3000
    //       })
    //     }
    //   }
    }
  }

}
</script>

<style scoped>
.input-textarea {
  display: grid;
  margin: 5px 5px;
  justify-content: center;
  border: none;
}

.commentary {
  width: 300px;
  border: none;
  background: #eee;
  padding: 0.5rem;
  cursor: not-allowed;
}

.botones {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 20px 0;
}

.input-commentary {
  text-align: left;
  color: 052333;
  font-weight: bold;
}

.coso-comentario {
  width: 300px;
  border: none;
  background: #eee;
  padding: 0.5rem;
}

.alerta-cv {
  border: 1px solid #fd5959;
  background: rgba(253, 131, 131, 0.514);
  color: #c23535;
  padding: 0.5rem;
  margin-bottom: 10px;
  width: 40%;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 50px;
}

.coso-comentario:focus {
  background: #eee !important;
  border: none !important;
  box-shadow: none !important;
}

.btn-send {
  color: #fff;
  border: none;
  font-size: 0.9rem;
  border-radius: 50px;
  margin: 0 10px;
  padding: 4px 10px;
}

.cerrar {
  background: #fd5959;
}
.enviar {
  background: #4f52ff;
}
.btn-send:hover {
  background: #4e0058;
  color: #fff;
}

.bg-header {
  background: #24c6ab !important;
}

.header-title {
  text-align: center;
  padding: 1rem 0;
}
</style>
