<template>
  <div>
    <p class="parrafo">

        Es la herramienta que tiene el Estado para recolectar los datos de gestión anual de cada organismo e institución del gobierno central y disponer de esa información a la ciudadanía.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.parrafo {
  color: #fff;
  font-size: 19px;
}

@media screen and (max-width: 1150px) {
  .parrafo {
    font-size: 17px;
  }
}

@media screen and (max-width: 960px) {
  .parrafo {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .parrafo {
    font-size: 17px;
    margin-right: 20px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 600px) {
  .parrafo {
    font-size: 14px;
  }
}

@media screen and (max-width: 344px) {
  .parrafo {
    font-size: 12 px;
  }
}
</style>
