<template>
  <div class="header">
    <b-container>
      <b-row>
        <b-col sm="12" md="6">
          <div class="title">
            <span>{{ pretitle }}</span>
            <h5>{{ title }}</h5>
            <span class="greenPostitle">{{ postTitle }}</span>
            <p>{{ paragraph }}<br /><br /><br /></p>
          </div>
        </b-col>
        <b-col sm="12" md="6" style="text-align: right;"
          ><b-img v-bind:src="image" fluid
        /></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    pretitle: String,
    title: String,
    postTitle: String,
    paragraph: String,
    image: String
  }
}
</script>

<style scoped>
.header {
  background: #080d46;
}

.row {
  padding: 40px 0 0 0;
  display: flex;
  align-items: center;
}

.title span {
  font-size: 18px;
  color: #45cc70;

  letter-spacing: 0.3px;
  display: block;
  text-align: center;
  font-weight: 600;
  margin-top: 30px;
}

h5 {
  color: #fff;
  font-size: 40px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}

p {
  color: #fff;
  margin-top: -10px;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0.3px;
  line-height: 28px;
  margin-top: 45px;
  margin-bottom: 25px;
  text-align: justify;
}

img {
  max-height: 260px;
}

.row {
  display: flex;
  align-items: flex-end;
}
</style>
