<template>
  <div class="">
    <b-container fluid="md">
      <b-row>
        <div>
          <div class="left">
            <Modernizacion />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Modernizacion from '../../../image/layout/Modernizacion.vue'
export default {
  components: {
    Modernizacion
  }
}
</script>

<style scoped>
.left {
  top: 22px;
}
</style>
