<template>
  <div>
    <b-button variant="link" class="modal-button" id="show-btn" @click="$bvModal.show('bv-modal-example')">
      <Config /> {{ button }}
    </b-button>
    <b-modal  size="lg" id="bv-modal-example" hide-footer>
        <template #modal-title><h4 class="title-modal">{{ title }}</h4></template>
        <div class="container" style="margin-top:5px">
          Presentar los resultados (logros y no logros) de la gestión de la institución en términos de la provisión de sus productos estratégicos a los usuarios, beneficiarios y/o ciudadanos, así como aspectos que sean de interés para la ciudadanía en el marco de la función que le compete al Servicio.
          <br><br>
          Para la presentación de cada resultado relevante (logros y no logros), se deberá presentar primero el producto estratégico (o programa si corresponde) sobre el que se dará cuenta pública, descrito brevemente. Luego se deberá realizar un análisis cuantitativo y cualitativo, el que puede apoyarse con gráficos y tablas auto explicativas.
          <br><br>
          Se entiende por logros las acciones desarrolladas que tuvieron resultados satisfactorios, que pueden expresarse de manera cuantitativa a través de indicadores de desempeño y/o niveles de actividad, o de manera cualitativa. Estas acciones pueden corresponder a actividades de su gestión normal y actividades extraordinarias no programadas por la institución.
          <br><br>
          Por otro lado, se entiende por no logros a las acciones desarrolladas por la institución que tuvieron resultados no satisfactorios, es decir, no se cumplieron los compromisos, metas o la programación definida para la institución. En este caso se solicita explicar las causas de dicha situación precisando si éstas se deben a factores internos o externos a la gestión de la institución. En los casos que corresponda y sea factible, informar las medidas que se están adoptando para mejorar los resultados obtenidos.
        </div>
    </b-modal>
  </div>
</template>

<script>
import Config from '@/components/image/icon/Config'
export default {
  props: ['html'],
  components: {
    Config
  },
  data () {
    return {
      button: 'Instrucciones: ¿Cómo completar esta sección?'
    }
  }
}
</script>

<style scoped>
.modal-button {
  display: flex;
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.modal-button:hover {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.modal-button:focus {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.title-modal {
  color: #052333;
  font-size: 20px;
  text-align: center;
}

</style>
