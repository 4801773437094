<template>
  <Main />
</template>

<script>
import Main from './navbar/Main.vue'
export default {
  components: { Main }
}
</script>

<style scoped>
.menu {
  background-color: #fff;
}
</style>
