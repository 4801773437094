<template>
  <div class="graphics">
    <canvas class="canvas" id="torta"></canvas>
    </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import { mapGetters } from 'vuex'
Chart.register(...registerables)
export default {
  name: 'torta',
  data () {
    return {
      img: '',
      graphicLabels: null,
      graphicData: null,
      myChart: null
    }
  },
  props: ['labels', 'data'],
  computed: {
    ...mapGetters(['personasTotales'])
  },
  watch: {
    labels: function () {
      this.graphicLabels = this.$props.labels[0]
    },
    data: function () {
      this.graphicData = this.$props.data[0]
      this.grafica()
    }
  },
  methods: {
    grafica: function () {
      var ctx = document.getElementById('torta').getContext('2d')

      if (this.myChart) {
        this.myChart.destroy()
      }

      const footer = (tooltipItems) => {
        let sum = 0

        tooltipItems.forEach((tooltipItem) => {
          sum += (tooltipItem.parsed * 100) / this.personasTotales
        })
        return Math.round((sum + Number.EPSILON) * 100) / 100 + '%'
      }

      this.myChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: this.graphicLabels,
          datasets: [
            {
              label: 'hola ',
              data: this.graphicData,
              backgroundColor: [
                '#2ECC71',
                '#770BAA',
                '#AD0800',
                '#F1C40F',
                '#096496',
                '#d9534f',
                '#5bc0de',
                '#C9EE00',
                '#FF2301'
              ],
              borderColor: [
                '#2ECC71',
                '#770BAA',
                '#AD0800',
                '#F1C40F',
                '#096496',
                '#d9534f',
                '#5bc0de',
                '#C9EE00',
                '#FF2301'
              ],
              borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                display: false,
                drawBorder: false
              },
              ticks: {
                display: false
              }
            },
            x: {
              grid: {
                display: false,
                drawBorder: false
              },
              ticks: {
                display: false
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            },
            tooltip: {
              callbacks: {
                footer: footer
              }
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.graphics {
  max-width: 400px;
}
.canvas {
  font-size: 14px;
  color: #052333;
  padding-bottom: 2rem;
  padding: 1rem 1rem;
}
</style>
