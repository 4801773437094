<template>
  <div v-if="userData && 1===2" class="container">
    <vue-glide>
      <!-- minister's presentation -->
      <vue-glide-slide
        class="glide"
        style="width:90px !important"
        v-if="checkPerm(1)"
      >
        <span @click="redirect('ministerio')">Presentación del Ministro</span>
      </vue-glide-slide>
      <!-- service presentation -->
      <vue-glide-slide class="glide" v-if="checkPerm(2)"
        ><span @click="redirect('servicios')">Presentación del Servicio</span>
      </vue-glide-slide>
      <!-- management result -->
      <vue-glide-slide class="glide" v-if="checkPerm(3)">
        <span @click="redirect('programaGobierno')"
          >Resultados de la Gestión</span>
      </vue-glide-slide>
      <!-- challenges -->
      <vue-glide-slide class="glide" v-if="checkPerm(4)"
        ><span @click="redirect('desafio')">Desafíos</span></vue-glide-slide
      >
      <!-- human Resources -->
      <vue-glide-slide class="glide" v-if="checkPerm(5)">
        <span @click="redirect('rHumanos')">Recursos Humanos</span>
      </vue-glide-slide>
      <!-- financial resources -->
      <vue-glide-slide class="glide" v-if="checkPerm(6)">
        <span @click="redirect('rfinancieross')">Recursos Financieros</span>
      </vue-glide-slide>
      <!-- indicators -->
      <vue-glide-slide class="glide" v-if="checkPerm(7)">
        <span @click="redirect('indicador')">Indicadores</span>
      </vue-glide-slide>
      <!-- compromises -->
      <vue-glide-slide class="glide" v-if="checkPerm(8)">
        <span @click="redirect('compromisos')">Compromisos</span>
      </vue-glide-slide>
      <!-- Evaluations -->
      <vue-glide-slide class="glide" v-if="checkPerm(9)">
        <span @click="redirect('informeprograma')">Evaluaciones</span>
      </vue-glide-slide>
      <!-- Institutional incentive -->
      <vue-glide-slide class="glide" v-if="checkPerm(10)">
        <span @click="redirect('iinstitucional')"
          >Incentivo Institucional</span
        ></vue-glide-slide
      >
      <!-- Collective Incentive -->
      <vue-glide-slide class="glide" v-if="checkPerm(11)">
        <span @click="redirect('icolectivo')"
          >Incentivo Colectivo</span
        ></vue-glide-slide
      >
      <!-- gender equality -->
      <vue-glide-slide class="glide" v-if="checkPerm(12)">
        <span @click="redirect('genero')">Equidad de Género</span>
      </vue-glide-slide>
      <!-- laws -->
      <vue-glide-slide class="glide" v-if="checkPerm(13)">
        <span @click="redirect('leyesTramite')">Leyes</span>
      </vue-glide-slide>
      <!-- awards -->
      <vue-glide-slide class="glide" v-if="checkPerm(14)">
        <span @click="redirect('premios')">Premios y Reconocimientos</span>
      </vue-glide-slide>
      <!-- Organization chart (Service) -->
      <vue-glide-slide class="glide">
        <span @click="redirect('oservicio')">Organigrama del Servicio</span>
      </vue-glide-slide>
      <!-- Organization chart (Ministry) -->
      <vue-glide-slide class="glide" v-if="checkPerm(17)">
        <span @click="redirect('oministerio')">Organigrama de Ministerio</span>
      </vue-glide-slide>
      <!-- controls -->
      <template slot="control">
        <b-button variant="link" class="left" data-glide-dir="<">
          <i class="bx bxs-chevron-left-circle"></i>
        </b-button>
        <b-button variant="link" class="rigth" data-glide-dir=">">
          <i class="bx bxs-chevron-right-circle"></i>
        </b-button>
      </template>
    </vue-glide>
  </div>
</template>

<script>
import { Glide, GlideSlide } from 'vue-glide-js'
import axios from 'axios'
import { formatRole } from '../tools/utils'
import { mapState } from 'vuex'
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data () {
    return {
      userData: null
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])
  },
  methods: {
    // carousel according to permission
    checkPerm: function (idPerm) {
      const { perfilId } = this.userData
      if (
        perfilId === 1 ||
        perfilId === 6 ||
        perfilId === 2 ||
        perfilId === 4 ||
        perfilId === 3
      ) {
        if (idPerm === 1 || idPerm === 17) {
          if (perfilId === 1) return true
          if (perfilId === 6) {
            return true
          } else {
            return false
          }
        }
        return true
      } else {
        if (this.userData.permisos.find((p) => p.permisoId === idPerm)) {
          return true
        } else {
          return false
        }
      }
    },
    // UserPermission
    requestUserData: async function () {
      const user = JSON.parse(localStorage.getItem('user'))
      console.log(user)
      if (user.username === process.env.VUE_APP_USER_ADMIN) {
        this.userData = {
          perfil: 'SuperAdministrador',
          perfilId: 1,
          servicioId: this.$route.params.idServicio
        }

        return
      }
      try {
        const usuario = await axios.get(
          `${process.env.VUE_APP_RUTA_API}/usuarios/usuarios/${user.id}/`,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('userToken')}`
            }
          }
        )

        const isUserService = usuario.data.servicios.find(
          (s) => s.id === parseInt(this.$route.params.idServicio)
        )

        if (isUserService) {
          this.userData = {
            perfil: formatRole(isUserService.rolNombre),
            perfilId: isUserService.rolId,
            servicioNombre: isUserService.nombre,
            servicioMinis: isUserService.ministerio,
            servicioId: this.$route.params.idServicio,
            permisos: isUserService.permisos
          }
        }
      } catch (error) {
        console.log(error.response)
      }
    },
    redirect: function (name) {
      this.$router.push({
        name: name,
        params: { idServicio: this.$route.params.idServicio }
      })
    }
  },
  mounted () {
    this.requestUserData()
  }
}
</script>

<style scoped>
.glide {
  cursor: pointer;
  text-align: center;
  /* color: #6F00A3; */
  /* color: #24C6AB; */
  /* color: #052333; */
  color: #0b5ed7;
  font-weight: bold;
  font-size: 18px;
}

.glide:hover {
  color: #6f00a3;
}

.left {
  position: absolute;
  left: 0;
  top: -5px;
}

.rigth {
  position: absolute;
  right: 0;
  top: -5px;
}

@media screen and (max-width: 1050px) {
  .container{
    display: none;
  }
}
</style>
