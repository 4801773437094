<template>
  <div class="graphics" v-if="datos">
    <canvas class="canvas" :id="`horizontal_${name}`" ></canvas>
    <div>

      <table class="table table-striped" v-if="!hidetable">
        <thead>
          <tr>
            <th scope="col">Tipo de Contrato</th>
            <th scope="col" colspan="2">Mujeres</th>
            <th scope="col" colspan="2">Hombres</th>
            <th scope="col" colspan="2">Total</th>
          </tr>
          <tr>
            <th scope="col"></th>
            <th scope="col">N°</th>
            <th scope="col">%</th>
            <th scope="col">N°</th>
            <th scope="col">%</th>
            <th scope="col">N°</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="dimension in get_dimensiones()" :key="dimension">
                <td>
                    {{ dimension }}
                </td>
                <td>{{ get_cantidad(dimension, 'Mujer') | formatInteger }}</td>
                <td>{{ get_porcentaje(dimension, 'Mujer') | formatDecimal }} %</td>
                <td>{{ get_cantidad(dimension, 'Hombre') | formatInteger }}</td>
                <td>{{ get_porcentaje(dimension, 'Hombre') | formatDecimal }} %</td>
                <td>{{ get_cantidad(dimension, null) | formatInteger }}</td>
            </tr>
            <tr>
                <td>Total</td>
                <td>{{ get_cantidad(null, 'Mujer') | formatInteger }}</td>
                <td>{{ get_porcentaje(null, 'Mujer') | formatDecimal }} %</td>
                <td>{{ get_cantidad(null, 'Hombre') | formatInteger }}</td>
                <td>{{ get_porcentaje(null, 'Hombre') | formatDecimal }} %</td>
                <td>{{ get_cantidad(null, null) | formatInteger }}</td>
            </tr>
        </tbody>
      </table>
      <small>Fuente: Subdepartamento de Estadísticas del Sector Público de la Dirección de Presupuestos</small>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default {
  name: 'PlanetChart',
  props: ['datos_completos', 'columna', 'filtro', 'hidetable'],
  data () {
    return {
      name: null,
      datos: null
    }
  },

  watch: {

  },
  methods: {
    graficas: function () {
      var ctx = document.getElementById(`horizontal_${this.name}`).getContext('2d')
      if (this.myChart) {
        this.myChart.destroy()
      }
      this.myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.get_dimensiones(),
          datasets: [
            {
              label: 'Hombres',
              data: this.get_cantidades('Hombre'),
              backgroundColor: ['#2ECC71', '#2ECC71'],
              borderColor: ['#2ECC71', '#2ECC71'],
              barThickness: 20
            },
            {
              label: 'Mujeres',
              data: this.get_cantidades('Mujer'),
              backgroundColor: ['#770BAA', '#770BAA'],
              borderColor: ['#770BAA', '#770BAA'],
              barThickness: 20
            },
            {
              label: 'Total Dotación ',
              data: this.get_cantidades(null),
              backgroundColor: ['#EFEFEF'],
              borderColor: ['#EFEFEF'],
              barThickness: 20
            }
          ]
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: 'Relación Laboral',
                font: {
                  weight: 'bold'
                }
              },
              grid: {
                display: false
              }
            },
            y: {
              max: this.highestValue,
              display: true,
              title: {
                display: true,
                text: 'Personas',
                font: {
                  weight: 'bold'
                }
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            },
            tooltip: {}
          }
        },

        responsive: true,
        plugins: {
          legend: {
            position: 'right'
          }
        }
      })
    },

    get_dimensiones () {
      var valores = this.datos.map(d => { return d[this.columna] })
      valores = valores.filter((value, index) => {
        return valores.indexOf(value) === index
      })

      return valores
    },

    get_cantidades (sexo) {
      var valores = []
      this.get_dimensiones().forEach(dimension =>
        valores.push(this.get_cantidad(dimension, sexo))
      )
      return valores
    },

    get_cantidad (dimension, sexo) {
      var valores = this.datos
      if (sexo) {
        valores = valores.filter(d => { return d.sexo === sexo })
      }
      if (dimension) {
        valores = valores.filter(d => { return d[this.columna] === dimension })
      }
      return valores.reduce((partialSum, a) => partialSum + a.cantidad, 0)
    },

    get_porcentaje (dimension, sexo) {
      const denominador = this.datos.filter(d => { return d.sexo === sexo }).reduce((partialSum, a) => partialSum + a.cantidad, 0)
      const numerador = this.get_cantidad(dimension, sexo)
      return numerador / denominador * 100
    }
  },
  mounted () {
    this.name = `${this.filtro}_${this.columna}`
    // var calidadesDotacion = ['Contrata', 'Jornal', 'Planta', 'Honorario asimilado a grado']
    var tipo1 = ['Dotación']
    var tipo2 = ['Fuera de Dotación']
    var datos = this.datos_completos
    if (this.filtro === 'dotacion') {
      datos = datos.filter(v => { return tipo1.indexOf(v.tipo) >= 0 })
      // datos = datos.filter(v => { return calidadesDotacion.indexOf(v.calidad_juridica) >= 0 })
    } else if (this.filtro === 'fuera_dotacion') {
      datos = datos.filter(v => { return tipo2.indexOf(v.tipo) >= 0 })
      // datos = datos.filter(v => { return calidadesDotacion.indexOf(v.calidad_juridica) < 0 })
    } else if (this.filtro === 'honorarios') {
      var calidadesHonorarios = ['Honorarios']
      datos = datos.filter(v => { return calidadesHonorarios.indexOf(v.calidad_juridica) >= 0 })
    } else {
      debugger
    }
    this.datos = datos

    setTimeout(() => {
      this.graficas()
    }, 1000)
  }

}
</script>

<style scoped>
.graphics {
  width: 100%;
}
.canvas {
  font-size: 14px;
  color: #052333;
}

th {
  text-align: left !important;
}

td {
  text-align: left !important;
}
</style>
