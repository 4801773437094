<template>
  <div>
    <div class="container">
        <Breadcrumb :links="breadCrumbData" />
        <h4 class="antecedente-content">Antecedentes para la Elaboración del BGI</h4>
        <br><br>

        <div class="Button-antecedentes">
            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/Oficio_N03_Ministros_BGI_2023.pdf">
                    Oficio Circular N03 de 2024, conjunto Ministros de Hacienda y SEGPRES
                </a>
            </b-button>
            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/Oficio_N015_DIPRES_BGI_2023.pdf">
                    Oficio Circular N011 de 2024, Directora de Presupuestos
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/Formato_Instrucciones_BGI_2023.pdf">
                Formato e Instrucciones BGI 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/Presentacion_Representantes_Ministeriales.pptx">
                Presentación a Representantes Ministeriales
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/Manual_de_Usuarios_BGI.pdf">
                Manual de Usuario BGI
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/Guía_Carga_Imagenes_en_Textos.pdf">
                Guía carga imágenes
                </a>
            </b-button>
            <!--
            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/01_Presidencia_2022.pdf">
                Prioridades de la Ley de Presupuesto Presidencia año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/01_Presidencia_2023.pdf">
                Prioridades de la Ley de Presupuesto Presidencia año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/05_Interior_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio del Interior año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/05_Interior_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio del Interior año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/06_Relaciones_Exteriores_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Relaciones Exteriores año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/06_Relaciones_Exteriores_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Relaciones Exteriores año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/07_Economia_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Economía año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/07_Economia_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Economía año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/08_Hacienda_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Hacienda año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/08_Hacienda_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Hacienda año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/09_Educacion_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Educación año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/09_Educacion_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Educación año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/10_Justicia_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Justicia y Derechos Humanos año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/10_Justicia_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Justicia y Derechos Humanos año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/11_Defensa_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Defensa año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/11_Defensa_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Defensa año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/12_Obras_Publicas_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Obras Públicas año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/12_Obras_Publicas_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Obras Públicas año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/13_Agricultura_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Agricultura año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/13_Agricultura_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Agricultura año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/14_Bienes_Nacionales_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Bienes Nacionales año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/14_Bienes_Nacionales_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Bienes Nacionales año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/15_Trabajo_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio del Trabajo y Previsión año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/15_Trabajo_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio del Trabajo y Previsión año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/16_Salud_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Salud año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/16_Salud_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Salud año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/17_Mineria_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Miniería año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/17_Mineria_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Miniería año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/18_Vivienda_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Vivienda y Urbanismo año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/18_Vivienda_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Vivienda y Urbanismo año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/19_Trasporte_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Transporte y Telecomunicaciones año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/19_Trasporte_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Transporte y Telecomunicaciones año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/20_Secretaria_General_de_Gobierno_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio Secretaría General de Gobierno año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/20_Secretaria_General_de_Gobierno_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio Secretaría General de Gobierno año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/21_Desarrollo_Social_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Desarrollo Social año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/21_Desarrollo_Social_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Desarrollo Social año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/22_Secretaria_General_de_la_Presidencia_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio Secretaría General de la Presidencia año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/22_Secretaria_General_de_la_Presidencia_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio Secretaría General de la Presidencia año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/24_Energia_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Energía año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/24_Energia_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Energía año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/25_Medio_Ambiente_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio del Medio Ambiente año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/25_Medio_Ambiente_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio del Medio Ambiente año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/26_Deporte_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio del Deporte año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/26_Deporte_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio del Deporte año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/27_Mujer_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de la Mujer y Equidad de Género año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/27_Mujer_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de la Mujer y Equidad de Género año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/29_Culturas_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de las Culturas, Las Artes y el Patrimonio Cultural año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/29_Culturas_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de las Culturas, Las Artes y el Patrimonio Cultural año 2023
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/30_Ciencia_2022.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Ciencia, Tecnología, Conocimiento e Innovación año 2022
                </a>
            </b-button>

            <b-button class="btn-antecedentes" variant="link">
                <i class="bx bx-download" style="color:#052333"></i>
                <a href="/datos/30_Ciencia_2023.pdf">
                Prioridades de la Ley de Presupuesto Ministerio de Ciencia, Tecnología, Conocimiento e Innovación año 2023
                </a>
            </b-button>-->
        </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import { mapState } from 'vuex'
export default {
  components: {
    Breadcrumb
  },
  computed: {
    ...mapState('sesion', ['usuario'])
  },
  data () {
    return {
      breadCrumbData: null
    }
  },
  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        {
          name: 'Portada BGI',
          routeName: 'bgi',
          routeParams: {
            idUsuario: this.usuario.id
          }
        },
        {
          name: 'Antecedentes para la Declaración',
          routeName: 'antecedente',
          routeParams: {}
        }
      ]
    }
  },
  mounted () {
    this.setBreadcrumbData()
  }
}
</script>

<style scoped>
.antecedente-content {
  text-align: center;
}

.bx-download {
  margin-right: 10px;
  font-size: 20px;
}

.btn-antecedentes {
  text-align: left;
  font-size: 20px;
}

.Button-antecedentes {
  display: grid;
  justify-content: left;
}
</style>
