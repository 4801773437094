<template>
  <section class="menu container">
    <b-link @click="setActive('home')" :class="`nav-link ${selectedMainView === 'home' ? 'active' : ''}`" variant="link"
      :to="{ name: 'Home' }">
      <span>Inicio</span>
    </b-link>
<!--
    <b-link @click="setActive('people')" :class="`nav-link ${selectedMainView === 'people' ? 'active' : ''}`" variant="link"
      :to="{ name: 'people' }">
      <span>Personas en el Estado</span>
    </b-link>

    <b-link @click="setActive('ejecucion')" :class="`nav-link ${selectedMainView === 'ejecucion' ? 'active' : ''}`" variant="link"
      :to="{ name: 'ejecucion' }">
      <span>Ejecución Presupuestaria</span>
    </b-link>

    <b-link @click="setActive('instituciones')" :class="`nav-link ${selectedMainView === 'instituciones' ? 'active' : ''}`" variant="link"
      :to="{ name: 'instituciones' }">
      <span> Instituciones</span>
    </b-link>
-->
    <!-- <b-link @click="setActive('comparator')" :class="`nav-link ${selectedMainView === 'comparator' ? 'active' : ''}`" variant="link"
      :to="{ name: 'comparate' }">
      <span> Comparador</span>
    </b-link> -->

    <b-link @click="setActive('bgi2023')" :class="`nav-link ${selectedMainView === 'bgi2023' ? 'active' : ''}`" variant="link"
      :to="{ name: 'bgi2023' }">
      <span> BGI 2023</span>
    </b-link>

    <b-link @click="setActive('bgi2022')" :class="`nav-link ${selectedMainView === 'bgi2022' ? 'active' : ''}`" variant="link"
      :to="{ name: 'bgi2022' }">
      <span> BGI 2022</span>
    </b-link>

    <b-link @click="setActive('bgi2021')" :class="`nav-link ${selectedMainView === 'bgi2021' ? 'active' : ''}`" variant="link"
      :to="{ name: 'bgi2021' }">
      <span> BGI 2021</span>
    </b-link>

    <b-link @click="setActive('bgi2020')" :class="`nav-link ${selectedMainView === 'bgi2020' ? 'active' : ''}`" variant="link"
      :to="{ name: 'bgi2020' }">
      <span> BGI 2020</span>
    </b-link>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['selectedMainView'])
  },
  methods: {
    seleccionar (nombre) {
      this.$router.push({ name: nombre })
    },
    setActive (xd) {
      switch (xd) {
        case 'home':
          this.$store.commit('setSelectedMainView', 'home')
          break
        case 'people':
          this.$store.commit('setSelectedMainView', 'people')
          break
        case 'ejecucion':
          this.$store.commit('setSelectedMainView', 'ejecucion')
          break
        case 'instituciones':
          this.$store.commit('setSelectedMainView', 'instituciones')
          break
        case 'comparator':
          this.$store.commit('setSelectedMainView', 'comparator')
          break
        default:
          break
      }
    }
  }
}
</script>

<style>
.menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.nav-link {
  font-size: 0.8rem;
  font-weight: 500;
  color: #052333 !important;
  width: 120px;
  height: 60px;
  text-align: left;
  display: flex !important;
  align-items: center;
}
.menu .active {
  color: #052333 !important;
  background: #24c6ab;
  font-weight: bold;
}
.nav-link:hover {
  color: #052333 !important;
  background: #24c6ab;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .nav-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .menu {
    width: 100%;
  }
}
</style>
