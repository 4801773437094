<template>
  <div>
    <b-card tag="article" class="mb-2 card">
      <div class="img">
        <CardImgCenter />
      </div>
      <div>
        <h3 class="title">{{ title }}</h3>
      </div>

      <div class="hr"></div>

      <div class="text">
        <p>
          {{ text }}
        </p>
      </div>

      <div class="button-more">
        <b-button type="button" class="btn" :to="{ name: 'ejecucion' }">{{
          btn
        }}</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import CardImgCenter from '../../../image/home/CardImgCenter.vue'

export default {
  components: {
    CardImgCenter
  },
  data () {
    return {
      title: 'Ejecución Presupuestaria',
      text: 'Información financiera. Gobierno Central.',
      btn: 'Ver más'
    }
  }
}
</script>
<style scoped>
.mb-2 {
  width: 16rem;
  margin-top: 5px;
  border-radius: 10px;
  height: 420px;
  background-color: #fff;
}

.mb-2:hover {
  box-shadow: rgb(0 0 0 / 20%) 5px -5px 18px 2px;
  background-color: #f5f5f5;
}

.mb-2 {
  transition: background-color 0.3s linear 0.2s;
}

.card {
  border: 1px solid #e0e0e0;
}

.img {
  margin-top: 35px;
  text-align: center;
  margin-bottom: 5px;
}

.title {
  color: #080d46;
  text-align: center;
}

.hr {
  border: 1px solid #24c6ab;
  width: 119px;
  max-width: 100%;
  position: relative;
  left: 53px;
  margin-top: 13px;
  margin-bottom: 11px;
}

.text {
  text-align: center;
  color: #080d46;
}

.button-more {
  text-align: center;
}

.btn {
  width: 105px;
  border-radius: 42px;
  background: #24c6ab;
  color: #fff;
  border: none;
}

.btn:hover {
  background: #24c6ab;
  color: #fff;
}

@media screen and (max-width: 991px) {
  .mb-2 {
    height: 346px;
  }

  .img {
    margin-top: 0;
  }
}
</style>
