<template>
  <div>
    <Head />
    <Navbar />
    <Title />
  </div>
</template>

<script>
import Head from './header/Head'
import Navbar from './header/Navbar.vue'
import Title from './header/Title.vue'
export default {
  components: {
    Head,
    Navbar,
    Title
  }
}
</script>
