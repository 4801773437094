import { render, staticRenderFns } from "./CardImg.vue?vue&type=template&id=2662e468&scoped=true&"
var script = {}
import style0 from "./CardImg.vue?vue&type=style&index=0&id=2662e468&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2662e468",
  null
  
)

export default component.exports