<template>
  <div class="containers cnt-btns">
    <b-button
      squared
      :class="!this.routerPath ? 'one' : 'two'"
      :to="{ name: 'leyesTramite' }"
      >Proyectos de Ley en Trámite en el Congreso Nacional 2018-2021</b-button
    >
    <b-button
      squared
      :class="`${this.routerPath ? 'one' : 'two'} center`"
      :to="{ name: 'leyesPromulgadas' }"
    >
      <span> Leyes Promulgadas durante 2018-2021 </span>
    </b-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      anio: process.env.VUE_APP_ANIO
    }
  },
  computed: {
    routerPath: function () {
      const { path } = this.$route

      return path.includes('/promulgadas')
    }
  }
}
</script>

<style scoped>
.containers {
  margin-left: 30px;
}
.one {
  background: #052333 !important;
  color: white !important;
  border: none;
  font-weight: 700 !important;
  max-width: 220px;
  height: 80px;
  font-size: 16px;
  /* margin-left: 30px; */
}

.two {
  color: #052333;
  background: #efefef;
  border: none;
  max-width: 220px;
  height: 80px;
  font-size: 16px;
}

.two:hover {
  background: #24c6ab;
  color: #052333;
}

@media screen and (max-width: 1000px) {
  .one {
    width: 130px;
    font-size: 10px;
    height: 70px;
  }

  .two {
    width: 130px;
    font-size: 10px;
    height: 70px;
  }
}
</style>
