<template>
  <div class="profile">
    <Header />

    <Contents />

    <Footer />
  </div>
</template>

<script>
import Footer from '../../components/layout/Footer.vue'
import Header from '../../components/private/Header'
import Contents from './components/Contents.vue'

export default {
  components: {
    Header,
    Contents,
    Footer
  }
}
</script>

<style scoped>
.profile {
height: 100vh;
}
</style>
