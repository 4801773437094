<template>
  <b-container>
    <div class="pills">
      <b-button
        class="button"
        variant="outline-secondary"
        pill
        v-on:click="verInicio"
      >
        Inicio
      </b-button>

      <b-button
        class="button"
        variant="outline-secondary"
        pill
        v-on:click="verMinisterios"
      >
        Ministerios
      </b-button>
      <b-button
        class="button"
        variant="outline-secondary"
        pill
        v-on:click="verMinsal"
      >
        Minsal
      </b-button>
      <b-button
        class="button"
        variant="outline-secondary"
        pill
        v-on:click="verPersonas"
      >
        Personas
      </b-button>
      <!-- <b-button
        class="button cffm"
        variant="outline-secondary"
        pill
        v-on:click="verDatos"
      >
        Datos
      </b-button> -->
    </div>
  </b-container>
</template>

<script>
export default {
  props: {
    selected: String
  },
  data () {
    return { isActive: true }
  },

  methods: {
    verInicio () {
      const route = this.$router.resolve({ name: 'fondef-index' })
      window.location = route.href
    },
    verMinsal () {
      const route = this.$router.resolve({ name: 'fondef-minsal' })
      window.location = route.href
    },
    verMinisterios () {
      const route = this.$router.resolve({ name: 'fondef-ministerios' })
      window.location = route.href
    },
    verPersonas () {
      const route = this.$router.resolve({ name: 'fondef-personas' })
      window.location = route.href
    },
    verDatos () {
      const route = this.$router.resolve({ name: 'fondef-datos' })
      window.location = route.href
    }
  }
}
</script>

<style scoped>
.pills {
  margin: 25px 0;
}
.button {
  margin-right: 10px;
}
</style>
