<template>
  <div
    class="editor"
    v-if="config && config.filebrowserImageUploadUrl"
  >
    <vue-ckeditor
      v-model="editorData"
      :config="config"
      @blur="onBlur($event)"
      @focus="onFocus($event)"
      @contentDom="onContentDom($event)"
      @dialogDefinition="onDialogDefinition($event)"
      @fileUploadRequest="onFileUploadRequest($event)"
      @fileUploadResponse="onFileUploadResponse($event)"
    ></vue-ckeditor>
  </div>
</template>

<script>
import VueCkeditor from 'vue-ckeditor2'
export default {
  components: { VueCkeditor },
  data () {
    return {
      editorData: '',
      editorConfig: {
        extraPlugins:
          'image2,colorbutton,justify,exportpdf,font,tableresize,uploadimage,preview,pagebreak',
        toolbar: [
          // {
          //   name: "document",
          //   items: ["-", "ExportPdf", "Preview", "-"],
          // },
          {
            name: 'clipboard',
            items: ['-', 'Undo', 'Redo']
          },
          {
            name: 'basicstyles',
            items: [
              'Bold',
              'Italic',
              'Underline',
              'Strike',
              '-',
              'CopyFormatting',
              'RemoveFormat'
            ]
          },
          {
            name: 'paragraph',
            items: [
              'NumberedList',
              'BulletedList',
              '-',
              'Outdent',
              'Indent',
              '-',
              'Blockquote',
              '-',
              'JustifyLeft',
              'JustifyCenter',
              'JustifyRight',
              'JustifyBlock'
            ]
          },
          { name: 'links', items: ['Link', 'Unlink'] },
          { name: 'styles', items: ['Format', 'Font', 'FontSize'] },
          {
            name: 'insert',
            items: [
              'Image',
              'Table',
              'HorizontalRule',
              'SpecialChar',
              'PageBreak',
              'mediaEmded'
            ]
          },
          { name: 'colors', items: ['TextColor', 'BGColor'] }
        ],
        removePlugins: 'image'
      },
      config: {
        // funciona
        extraPlugins:
          'xml,ajax,filetools,popup,imgbrowse, pastetools,pastefromword,uploadimage, wordcount, notification',
        // filebrowserImageBrowseUrl: process.env.VUE_APP_RUTA_API + '/ckeditor/upload/',
        filebrowserImageUploadUrl: process.env.VUE_APP_RUTA_API + '/ckeditor/upload/',
        // fileUploadRequest: process.env.VUE_APP_RUTA_API + '/ckeditor/upload/',
        // filebrowserUploadMethod: 'form',
        // fin funciona
        // pruebas que no funcionaron
        // filebrowserBrowseUrl: 'http://localhost/vue/uploaded/upload.php',
        // filebrowserUploadUrl : "/vue/ckeditor/plugins/iaupload.php",
        // filebrowserUploadUrl: '/uploader/upload.php',
        // filebrowserUploadUrl: 'http://localhost/vue/upload.php?',
        // filebrowserUploadMethod : 'form',
        toolbar:
        [
          // ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript','mediaEmbed']
        //   {
        //     name: 'document',
        //     items: [
        //       'Source',
        //       '-',
        //       'Save',
        //       'NewPage',
        //       'ExportPdf',
        //       'Preview',
        //       'Print',
        //       '-',
        //       'Templates'
        //     ]
        //   },

          //   {
          //     name: 'editing',
          //     items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt']
          //   },
          //   {
          //     name: 'forms',
          //     items: [
          //       'Form',
          //       'Checkbox',
          //       'Radio',
          //       'TextField',
          //       'Textarea',
          //       'Select',
          //       'Button',
          //       'ImageButton',
          //       'HiddenField'
          //     ]
          //   },

          {
            name: 'basicstyles',
            items: [
              'Bold',
              'Italic',
              'Underline',
              'Strike',
              'Subscript',
              'Superscript',
              '-',
              'CopyFormatting',
              'RemoveFormat'
            ]
          },
          {
            name: 'paragraph',
            items: [
              'NumberedList',
              'BulletedList',
              '-',
              'Outdent',
              'Indent',
              '-',
              //   'Blockquote',
              //   'CreateDiv',
              '-',
              'JustifyLeft',
              'JustifyCenter',
              'JustifyRight',
              'JustifyBlock',
              '-',
              'BidiLtr',
              'BidiRtl'
            //   'Language'
            ]
          },
          { name: 'links', items: ['Link', 'Unlink'] },
          {
            name: 'insert',
            items: [
              'Image',
              //   'Flash',
              'Table',
              'HorizontalRule',
              //   'Smiley',
              'SpecialChar'
            //   'PageBreak',
            //   'Iframe'
            ]
          },
          '/',
          {
            name: 'styles',
            items: [
            //   'Styles','Format', 'Font',
              'FontSize']
          },
          {
            name: 'colors',
            items: ['TextColor'
            //   'BGColor'
            ]
          },
          { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
          {
            name: 'clipboard',
            items: [
              'Cut',
              'Copy',
              'Paste',
              'PasteText',
              'PasteFromWord',
              '-',
              'Undo',
              'Redo'
            ]
          }
        //   { name: 'about', items: ['About']           }
        ],
        height: 300
      }
    }
  },
  wordcount: {
    showParagraphs: true,
    showWordCount: true,
    showCharCount: true,
    countSpacesAsChars: true,
    countHTML: false,
    maxWordCount: -1,
    maxCharCount: 104000
  },
  props: ['modifyContent', 'borrador'],
  watch: {
    borrador: function () {
      // console.log(this.borrador)
      if (this.borrador) {
        this.editorData = this.borrador
      }
    },
    editorData: function () {
      this.modifyContent(this.editorData)
    }
  },
  methods: {
    onBlur (evt) {
      console.log(evt)
    },
    onFocus (evt) {
      console.log(evt)
    },
    onContentDom (evt) {
      console.log(evt)
    },
    onDialogDefinition (evt) {
      console.log(evt)
    },
    onFileUploadRequest (evt) {
      console.log(evt)
    },
    onFileUploadResponse (evt) {
      evt.stop()
      var data = evt.data
      var xhr = data.fileLoader.xhr
      var response = xhr.responseText

      const respJson = JSON.parse(response)

      if (respJson[0].error == '1') {
        evt.cancel()
        alert('Error al cargar la imagen')
      } else {
        window.parent.CKEDITOR.tools.callFunction('3', respJson[0].url, 'Cargado Correctamente')
      }
      console.log(evt)
    }
  },
  mounted () {
    this.editorData = this.borrador
    console.log(this.borrador)
    // this.editorUrl= process.env.VUE_APP_RUTA_API_CKEDITOR
    this.config.filebrowserImageUploadUrl = 'https://gestion.gob.cl'
    this.config.defaultLanguage = 'es'
    this.config.language = 'es'

    if (window.location.href.indexOf('www') >= 0) {
      this.config.filebrowserImageUploadUrl = 'https://www.gestion.gob.cl'
    }

    window.setInterval(() => {
      document
        .getElementsByClassName('cke_dialog_ui_input_file')
        .forEach(frame => {
          if (frame.contentWindow) {
            frame.contentWindow.postMessage('', '*')
          }
        })
    }, 10000)
  }
}
</script>

<style scoped>
.vueditor {
  min-width: 300px;
  min-height: 400px;
}
</style>
