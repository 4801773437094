<template>
  <div class="div">
    <b-breadcrumb>
      <b-breadcrumb-item
        v-for="(link, index) in links"
        :key="index"
        :to="
          index === links.length - 1
            ? null
            : { name: link.routeName, params: link.routeParams }
        "
        :class="index === links.length - 1 ? 'seleccionado' : ''"
      >
        {{ link.name }}
      </b-breadcrumb-item>
    </b-breadcrumb>
  </div>
</template>

<script>
export default {
  props: ['links']
}
</script>

<style scoped>
.div {
  /* margin-left: 30px; */
  margin-top: 15px;
  margin-bottom: 15px;
}

.breadcrumb {
  background-color: white !important;
  font-size: 1rem;
}

.breadcrumb-item > a {
  color: #052333 !important;
  text-decoration: none;
}
.breadcrumb-item > a:hover {
  text-decoration: none;
}
.seleccionado {
  font-weight: 600;
  color: #052333 !important;
  cursor: pointer;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}

</style>
