<template>
  <div class="indicador">
    <Header />

    <Contents />

    <Footer />
  </div>
</template>

<script>
import Header from '../../components/private/Header'
import Contents from './components/Contents.vue'
import Footer from '../../components/layout/Footer.vue'
export default {
  components: {
    Header,
    Contents,
    Footer
  }
}
</script>

<style>
.indicador {
  height: 100vh;
}
</style>
