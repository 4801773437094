<template>
  <div>
    <div class="home">
      <Navbar />
    </div>

    <div>
      <div>
        <Header
          pretitle="REMUNERACIONES"
          title="Análisis de las Remuneraciones del Estado"
        />
      </div>
      <b-container>
        <br /><br /><br />
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiMWY0MWJmYWItMzAyNC00NDI5LTkzZDItYmViNWMzMmUxOTFjIiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSection1777f60985bc911968d7"
        />
      </b-container>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from './components/header/Header.vue'
import PowerBI from './components/powerbi/PowerBI.vue'

export default {
  components: {
    Navbar,
    Header,
    PowerBI
  }
}
</script>

<style scoped>
p {
  font-family: Georgia, serif;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin: 2rem 0;
}

.lead {
  font-size: 1.7rem;
  margin: 3.4rem 0 1.7rem 0;
  font-family: sans-serif;
}

.center {
  text-align: center;
}
</style>
