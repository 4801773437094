<template>
    <div class="container">
        <Title/>

        <div>
            <Breadcrumb :links="breadCrumbData" />

            <h1 class="privado-titulo">Género</h1>

            <b-button v-if="can_edit_anexos_de_servicios()" @click="guardar" pill variant="primary" class="btn-one">Guardar</b-button>
            <br><br><br>

            <h2>Medidas</h2>

            Debe presentar los resultados de la implementación de los compromisos en materia de género del año {{ anioBgi }} para reducir brechas, barreras e inequidades de género en la provisión de bienes y servicios, considerando información objetiva respaldada en valores cuantitativos, o hechos específicos, y cualitativos. Precisando el efecto en los usuarios/as, beneficiarios/as, ciudadanos/as.
            <br>
            En aquellos compromisos que no fue posible implementar las medidas, se debe señalar las causas y las medidas adoptadas (si las hay).

            <b-row v-if="anexo && anexo.datos">
                <b-col>
                    <!-- <Instrucciones/> -->
                </b-col>
                <b-col>
                    <!-- <Visualizar seccion="genero" /> -->
                </b-col>
                <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
                    <b-form-checkbox v-model="anexo.datos.no_aplica">
                        No Aplica
                    </b-form-checkbox>
                </b-col>
            </b-row>

            <div v-if="itemList_parte_uno && !anexo.datos.no_aplica">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Medidas</th>
                            <th>Resultados</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr  v-for="(item, index) in itemList_parte_uno" :key="index">
                            <td>{{item.medida}}</td>
                            <td>
                                <b-form-textarea v-model="item.resultado" class="input-leyes " :disabled="!can_edit_anexos_de_servicios()"></b-form-textarea>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>

            <br><br><br>
            <h2>Iniciativas</h2>
            <br><br>

            <br><br>
            Señalar alguna otra iniciativa en materia de igualdad de género que el Servicio quiera relevar. Por ejemplo: Norma Chilena 3262 de igualdad de género y conciliación de la vida laboral, familiar y personal; Instructivo Presidencial Maltrato, Acoso Laboral y Acoso Sexual; orgánica de género institucional; presupuesto para género; otros.
            <br>

            <div v-if="itemList_parte_dos && !anexo.datos.no_aplica">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Iniciativas</th>
                            <th>Resultados</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr  v-for="(item, index) in itemList_parte_dos" :key="index">
                            <td>
                                <b-form-textarea v-model="item.iniciativa" class="input-leyes " :disabled="!can_edit_anexos_de_servicios()"></b-form-textarea>
                            </td>
                            <td>
                                <b-form-textarea v-model="item.resultado" class="input-leyes " :disabled="!can_edit_anexos_de_servicios()"></b-form-textarea>
                            </td>
                            <td>
                                <i class="bx bxs-trash" @click="removeItem(item)" style="color:#24c6ab; cursor: pointer;"></i>
                            </td>
                        </tr>
                    </tbody>

                </table>

            </div>
            <div class="add-btn" @click="addItemList" v-b-tooltip.hover title="Agregar">
                <hr class="separator-hr" />
                <b-button class="button-leyes"><i class="bx bx-plus"></i></b-button>
                <hr class="separator-hr" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { utils } from '@/components/tools/ViewUtils.js'
import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'

export default {
  mixins: [utils],
  components: {
    Title,
    Breadcrumb

  },
  data () {
    return {
      anexo: null,
      itemList_parte_uno: null,
      itemList_parte_dos: null,
      breadCrumbData: null
    }
  },

  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Equidad de Género', routeName: 'genero', routeParams: { idServicio: this.$route.params.idServicio } }
      ]
    },
    addItemList: function () {
      const toAddObj = {}
      this.itemList_parte_dos.push(toAddObj)
    },
    removeItem: function (index) {
      if (this.readOnly) return
      const pos = this.itemList_parte_dos.indexOf(index)

      this.itemList_parte_dos.splice(pos, 1)
    },
    guardar: function () {
      axios.put(`/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    },
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'genero'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.listado_parte_uno = anexo.datos.listado_parte_uno || []
        anexo.datos.listado_parte_dos = anexo.datos.listado_parte_dos || []
        this.itemList_parte_uno = anexo.datos.listado_parte_uno
        this.itemList_parte_dos = anexo.datos.listado_parte_dos

        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }

  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRevision()
  }
}
</script>

<style scoped>
.containers {
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
</style>
