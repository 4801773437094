<template>
  <div class="views-navbar container">
    <h5 class="titulo">{{ title }} {{ showYear ? `- año ${year}` : `` }}</h5>
    <div class="ycs">
      <p style="font-weight: 600; text-decoration: underline;">
        También puedes revisar:
      </p>
        <b-link :class="`option ${selectedInsView === 'rrhh' ? 'view-active' : ''}`" :to="{ name: 'rhumanos', params: { id: idServicio } }" @click="setActive('rrhh')">
            Recursos Humanos
        </b-link>
        <b-link :class="`option ${selectedInsView === 'rrff' ? 'view-active' : ''}`" :to="{ name: 'rfinancieros', params: { id: idServicio } }" @click="setActive('rrff')">
            Recursos Financieros
        </b-link>
        <b-link :class="`option ${selectedInsView === 'indicadores' ? 'view-active' : ''}`" :to="{ name: 'indicadores', params: { id: idServicio } }" @click="setActive('indicadores')">
            Indicadores de Gestión
        </b-link>

    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      idServicio: this.$route.params.id
    }
  },
  props: ['title', 'showYear'],
  computed: {
    ...mapGetters(['year', 'selectedInsView'])
  },
  methods: {

    setActive: function (xd) {
      switch (xd) {
        case 'rrhh': {
          this.$store.commit('setSelectedInsView', 'rrhh')
          break
        }
        case 'rrff': {
          this.$store.commit('setSelectedInsView', 'rrff')
          break
        }
        case 'indicadores': {
          this.$store.commit('setSelectedInsView', 'indicadores')
          break
        }
      }
    }
  },
  mounted () {
    this.$store.commit('setSelectedMainView', 'instituciones')
  }
}
</script>

<style scoped>
.row-navbar {
  margin: 18px;
}
.ycs {
  display: flex;
  justify-content: space-around;
}
.titulo {
  color: #414bb2;
}
.views-navbar {
  padding: 1rem;
}

.option {
  color: #052333;
}
.view-active {
  color: #24c6ab;
}
.option:hover {
  color: #24c6ab;
}
</style>
