<template>
  <div class="i-header">
    <div class="contenedor-texto container">
      <div class="i-title">{{ ministryName }}</div>
      <span class="i-desc">Lista de Servicios del Ministerio</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      ministryName: 'Cargando...'
    }
  },
  methods: {
    requestServices: async function () {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_RUTA_API}/instituciones/ministerios/${this.$route.params.id}`
        )
        this.ministryName = res.data.nombre
        this.$store.commit('updateServicesList', res.data.servicios)
        this.$store.commit('setTypeService', 'servicios')
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted () {
    this.requestServices()
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap");

.container-forms {
  display: flex;
}
.i-header {
  padding-top: 20px;
  padding-bottom: 10px;
  background: #052333;
}
.i-title > p {
  font-family: "Fira Sans", sans-serif;
}
span.i-desc {
  font-family: "Fira Sans", sans-serif;
}
.contenedor-texto {
  color: #fff;
  margin: 6px 0;
}
.i-title {
  font-size: 22px;
  /* font-weight: bold; */
}
.i-desc {
  font-size: 14px;
}
.options {
  margin-right: 0.5rem;
  width: 230px;
  font-size: 0.75rem;
  padding: 0.5rem;
}
.controller-forms {
  width: 300px !important;
}
</style>
