<template>
  <div class="search">
    <span class="details">Ver el detalle de gestión</span>
    <div class="input-icono field">
      <input
        type="text"
        name="input"
        placeholder="Buscar institución o servicio"
        v-model="search"
      />
      <button class="btn" @click="handleOnSearch">
        <p class="text-btn">Buscar</p>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      search: ''
    }
  },
  methods: {
    handleOnSearch: async function () {
      this.$store.commit('setSearchService', this.search)
      if (this.$router.currentRoute.name === 'instituciones') return
      this.$router.push({ name: 'instituciones' })
    }
  }
}
</script>
<style scoped>
.details {
  font-size: 0.8rem;
  color: #f3f3f3;
}
.search {
  background-color: rgb(36, 198, 171);
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  display: inline-flex;

  /* width: 400px; */
}
.field {
  margin: 10px;
}
.input-icono {
  background-image: url("../../assets/img/input/bx-search-alt.svg");
  background-repeat: no-repeat;
  background-position: 4px center;
  background-size: 15px;
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 230px;
  padding-left: 20px;
  height: 30px;
  border: 1px solid rgb(5, 35, 51);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.input-icono input {
  width: 100%;
  font-size: 0.65rem;
  border: none;
}
.input-icono input:focus {
  outline: none;
}

.btn {
  height: 30px;
  background: #052333;
  border-radius: 0;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-btn {
  margin-top: 17px;
  font-size: 0.7em;
  color: white;
}

@media screen and (max-width: 799px) {
  .details {
    display: none;
  }
  .search {
    background-color: rgb(36, 198, 171);
    width: 320px;
  }
}
</style>
