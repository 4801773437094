<template>
  <div>
    <div class="home">
      <Navbar />
    </div>

    <div>
      <div>
        <Header
          pretitle=""
          title="Dotación de Personal con datos de Transparencia Activa*"
          postTitle="El Estado a través de las personas que trabajan en él."
          paragraph="Aquí se reúne y se analiza información generada a partir de los datos de dotación de personal publicados en los distintos portales de transparencia desde el año 2006 hasta la fecha. Los datos son mostrados en sus distintas dimensiones con ayuda de visores y visualizaciones."
          image="/img_fondef/portada/portada2.png"
        />
      </div>

      <Contents />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from './components/header/Header.vue'
import Footer from './components/Footer.vue'

import Navbar from '@/components/layout/Navbar'
import Contents from './components/Contents.vue'
export default {
  components: {
    Navbar,
    Contents,
    Header,
    Footer
  }
}
</script>

<style scoped></style>
