<template>
  <tr>
    <td>
      <i v-if="can_edit_anexos_de_servicios()" id="tooltip-target-1" class="bx bx-menu icono"></i>
      <b-tooltip target="tooltip-target-1" triggers="hover">
        ↕
      </b-tooltip>
    </td>

    <td>
      <b-form-input :disabled="!can_edit_anexos_de_servicios()" v-model="objTest.cargo" type="text" ></b-form-input>
    </td>
    <td>
      <b-form-input :disabled="!can_edit_anexos_de_servicios()" v-model="objTest.nombre" type="text" ></b-form-input>
    </td>

    <td>
      <i v-if="can_edit_anexos_de_servicios()" @click="removeItem(objTest)" class="bx bxs-trash icono"></i>
    </td>
  </tr>
</template>
<script>
import { utils } from '@/components/tools/ViewUtils.js'

export default {
  mixins: [utils],
  props: ['removeItem', 'objTest', 'readOnly']
}
</script>
<style>
.controller-forms {
  font-size: 0.8rem !important;
  width: 100% !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.icono {
  font-size: 1.5rem;
  color: #25a2b7;
  cursor: pointer;
}
.select-item {
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

</style>
