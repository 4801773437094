import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'
import './app.css'
import Toasted from 'vue-toasted'
import Vuex from 'vuex'
import CKEditor from 'ckeditor4-vue'

Vue.use(Toasted)
Vue.use(VueGlide)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(CKEditor)
Vue.use(Vuex)

Vue.config.productionTip = false

Vue.filter('formatDecimal', function (value) {
  if (isNaN(value)) { return '-' }
  return new Intl.NumberFormat('es-CL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)
})

Vue.filter('formatDecimal_1', function (value) {
  if (isNaN(value)) { return '-' }
  return new Intl.NumberFormat('es-CL', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  }).format(value)
})

Vue.filter('formatInteger', function (value) {
  if (isNaN(value)) { return '-' }
  try {
    value = parseInt(value)
  } catch { }

  return new Intl.NumberFormat('es-CL', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value)
})

Vue.filter('formatNumberDecimal', function (value) {
  return value !== null && value !== undefined ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
