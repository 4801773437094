<template>
  <div class="acceso">
    <Header />

    <Content />

    <Footer />
  </div>
</template>

<script>
import Header from '../../components/private/Header'
import Content from './components/Content.vue'
import Footer from '../../components/layout/Footer.vue'
import { mapState } from 'vuex'
export default {
  components: {
    Header,
    Content,
    Footer
  },
  computed: {
    ...mapState('sesion', ['usuario'])
  },
  mounted () {
    const { path } = this.$route
    if (
      path.includes('/admin') &&
      this.usuario.username !== process.env.VUE_APP_USER_ADMIN
    ) {
      this.$router.push({
        name: 'bgi',
        params: { idUsuario: this.usuario.id }
      })
    } else {
      this.$store.commit('setPrivateView', 'settings')
    }
  }
}
</script>

<style scoped>
.acceso {
  height: 100vh;
}
</style>
