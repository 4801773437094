<template>
  <div>
    <b-row>
      <b-col>
        <b-button variant="link" class="link" :href="getUrl()" target="_blank" ><VisualizarIcon /> Visualizar</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VisualizarIcon from '../../image/icon/Visualizar.vue'
// import axios from 'axios'
export default {
  components: {
    VisualizarIcon
  },
  props: ['idServicio', 'seccion'],
  methods: {

    getUrl () {
      var idServicio = this.idServicio || this.$route.params.idServicio
      return `${process.env.VUE_APP_RUTA_API_PDF}/instituciones/servicios/${idServicio}/pdf_generate/?anio=${process.env.VUE_APP_ANIO}&seccion_pdf=${this.seccion}`
    }
    // showSectionPdf: async function () {
    //   this.idServicio = this.idServicio || this.$route.params.idServicio
    //   debugger
    //   const res = await axios.get(`${process.env.VUE_APP_RUTA_API}/instituciones/servicios/${this.idServicio}/pdf_generate/?anio=${process.env.VUE_APP_ANIO}&seccion_pdf=${this.seccion}`,
    //     {
    //       responseType: 'blob',
    //       headers: { Authorization: `JWT ${localStorage.getItem('userToken')}` }
    //     }
    //   )

    //   var blob = new Blob([res.data], { type: 'application/pdf' })

    //   var URL = window.URL || window.webkitURL
    //   const url = window.URL.createObjectURL(blob)

    //   window.open(url)
    //   URL.revokeObjectURL(url)
    // }
  }
}
</script>

<style scoped>
.row {
  margin: 0;
  text-align: end;
}

.link {
  color: #052333;
  display: inline-flex;
  margin-bottom: 10px;
  font-size: 32px;
  text-decoration: none;
  margin-right: 20px;
}

.link:hover {
  color: #052333;
}

@media screen and (max-width: 1600px) {
  .link {
    font-size: 24px;
  }
}

@media screen and (max-width: 1300px) {
  .link {
    font-size: 18px;
  }
}

@media screen and (max-width: 950px) {
  .link {
    font-size: 14px;
  }
}

</style>
