'use strict'
/**
 * @param state
 *  User: saves all user information in storage
 * usuario: Save the user and service relationship
 */

/**
 * @param mutations
 *  actualizarUsuario
 * setUsuario
 * establecerRelacion
 */

export default {
  namespaced: true,
  state: {
    user: window.localStorage.userToken ? window.localStorage.userToken : null,
    usuario: window.localStorage.user
      ? JSON.parse(window.localStorage.user)
      : null,
    relacionUS: null
  },
  mutations: {
    async actualizarUsuario (state, user) {
      state.user = user
    },
    async setUsuario (state, userObj) {
      state.usuario = userObj
    },
    async establecerRelacion (state, payload) {
      state.relacionUS = payload
    }
  },
  actions: {
    cerrarSesion ({ commit }) {
      commit('actualizarUsuario', null)
    },

    async inicioSesion ({ commit }, user) {
      commit('actualizarUsuario', user)
    },
    async setUsuarioObj ({ commit }, user) {
      commit('setUsuario', user)
    },
    async setData ({ commit }, payload) {
      commit('establecerRelacion', payload)
    }
  },
  getters: {
    saludo (state) {
      if (!state.usuario) {
        return ''
      }

      const vocal = state.usuario.sexo && state.usuario.sexo === 'F' ? 'a' : 'o'
      return `¡Bienvenid${vocal} ${state.usuario.nombres}!`
    },

    getUsuario (state) {
      return state.usuario
    }
  }
}
