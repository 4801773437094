<template>
  <div class="containers">
    <Buttons />
    <h1 class="privado-titulo"> Presentación del Servicio - Principales Autoridades</h1>

    <b-row>
        <b-col>
            <!-- <Instrucciones/> -->
        </b-col>
        <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
                <b-form-checkbox v-model="anexo.datos.no_aplica">
                No Aplica
            </b-form-checkbox>
        </b-col>
    </b-row>

    <b-button @click="guardar" pill variant="primary" class="btn-one" v-if="can_edit_anexos_de_servicios()">Guardar</b-button>
    <table class="table" v-show="!anexo.datos.no_aplica">
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col">Cargo</th>
                <th scope="col">Nombre</th>
                <th scope="col">
                    <b-button v-if="!readOnly" class="button-add" @click="addItemList">+</b-button>
                </th>
            </tr>
        </thead>

        <tbody v-if="readOnly">
            <Row  v-for="(item, index) in itemList" :key="index" :removeItem="removeItem" :objTest="item" :readOnly="readOnly"/>
        </tbody>

        <draggable v-else :list="itemList" tag="tbody">
            <Row v-for="(item, index) in itemList" :key="index" :removeItem="removeItem" :objTest="item" :readOnly="readOnly"/>
        </draggable>
    </table>
  </div>
</template>
<script>

import { utils } from '@/components/tools/ViewUtils.js'

import Buttons from './Buttons.vue'
// import Moda from '../../presentacion-servicio/components/else/Moda.vue'
// import Swal from 'sweetalert2'
import axios from 'axios'
// import { showAlert } from '@/components/tools/utils'
// import Visualizar from '@/components/button/escritorio/Visualizar.vue'
import Row from './Tabla/Row.vue'
import draggable from 'vuedraggable'

export default {
  mixins: [utils],
  components: {
    // List,
    draggable,
    Row,
    Buttons
    // Moda
    // Visualizar
  },
  data () {
    return {
      itemList: [],
      anexo: null,
      nombre: 'servicios_c',
      arrayButton: [],
      buttonViews: false,
      dataViews: null,
      datosUser: null,
      idServicio: this.$route.params.idServicio
    }
  },
  props: ['readOnly', 'title', 'userPermissions', 'userPermission'],

  methods: {
    addItemList: function () {
      const toAddObj = {
        cargo: '',
        nombre: ''
      }
      this.itemList.push(toAddObj)
    },
    removeItem: function (index) {
      const pos = this.itemList.indexOf(index)

      this.itemList.splice(pos, 1)
    },
    // buildData: function (type) {
    //   return {
    //     nombre: this.nombre,
    //     elaboracion: type,
    //     data: {
    //       content: this.itemList
    //     },
    //     anio: process.env.VUE_APP_ANIO,
    //     idServicio: this.$route.params.idServicio
    //   }
    // },
    guardar: function () {
      axios.put(`/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    },
    obtenerContenido: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'principales_autoridades'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.lista = anexo.datos.lista || []
        this.itemList = anexo.datos.lista
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }

  },
  mounted () {
    this.obtenerContenido()
    // this.sssssssssobtenerRelacionUsuarioServicio()
  }
}
</script>
<style scoped>

.titulo-c3 {
  font-size: 1.1rem;
  color: #2b425f;
  font-weight: bold;
  margin: 10px 15px;
}
.table > tbody,
.table > thead {
  vertical-align: middle;
}
.table {
  border: 1px solid #ccc;
}
.button-add {
  background: #25a2b7 !important;
  color: white !important;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 14px;
  padding: 0 !important;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 0;
}
/* /////////////////////// */
.containers{
  margin-left: 30px;
  margin-bottom: 30px;
  margin-right: 30px;;
}

.title {
  margin: 25px 0;
  font-weight: bold;
  text-align: center;
}
.btn-one {
  margin-right: 20px;
}

.btn-direction-flex {
  text-align: end;
}
.row-button {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

@media screen and (max-width: 784px) {
  .btn-one {
    font-size: 11px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 11px;
  }
}

@media screen and (max-width: 569px) {
  .btn-one {
    font-size: 10px;
    margin-right: 5px;
    width: 90px;
  }
  .btn-two {
    font-size: 10px;
    width: 90px;
  }
}

@media screen and (max-width: 417px) {
  .one {
    display: none;
  }
  .btn-one {
    font-size: 10px;
    margin-right: 5px;
    width: 90px;
    margin-bottom: 15px;
  }
  .btn-two {
    font-size: 10px;
    width: 90px;
    position: relative;
    top: -6px;
  }
  .btn-direction-flex {
    text-align: start;
    margin-left: 30px;
  }
}
</style>
