<template>
  <div>
    <HeaderPeople/>
    <Collapse />
  </div>
</template>

<script>
import HeaderPeople from '@/components/cabecera/HeaderPeople.vue'
// import Commentary from '@/components/commentary/Commentary.vue'
import Collapse from './collapse/Collapse.vue'
export default {
  components: {
    HeaderPeople,
    // Commentary,
    Collapse
  }
}
</script>

<style scoped>
.graphic {
  margin-bottom: 10px;
}

.toast {
  background: blue !important;
}

.some {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  margin-bottom: 25px;
}
</style>
