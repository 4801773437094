<template>
  <div class="alert">
    <div class="alert-image">
      <img
        class="img"
        src="@/assets/img/alert/precaucion.png"
        alt="Alert Sign"
      />
    </div>
    <div class="alert-text">
      {{ msg }}
    </div>
  </div>
</template>

<script>
// REEMPLAZAR POR sinAutorizacion.vue

export default {
  props: {
    msg: {
      type: String,
      default: ''
    }
  }
}

</script>

<style scoped>
.img {
  width: 50px;
}

.alert {
  border-radius: 50px !important;
  background-color: #24c6ab !important;
  display: flex;
  align-items: center;
}

.alert-image {
  justify-content: left;
}

.alert-text {
  align-self: left;
  font-size: 1em;
  font-weight: bold;
  margin: 1rem;
}

.img {
  width: 60px;
}

@media screen and (max-width: 1500px) {
  .alert-text {
    font-size: 1em;
  }
}

@media screen and (max-width: 1000px) {
  .img {
    width: 30px;
  }
  .alert-text {
    font-size: 0.5em;
  }
}

</style>
