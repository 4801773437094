<template>
  <section class="i-header">
    <div class="contenedor-texto ">
      <h3 class="i-title">Instituciones</h3>
      <span class="i-desc">Lista de Servicios e Instituciones del Estado</span>
    </div>
    <div class="container-forms ">
      <div class="type-selected">
        <h4 class="something">Ministerios</h4>
      </div>
      <b-form-select
        class="options"
        v-model="selected"
        :options="options"
      ></b-form-select>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      selected: null,
      options: null
    }
  },
  computed: {
    // get data Service
    ...mapGetters(['searchService'])
  },
  methods: {
    // get minister
    requestMinister: async function () {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_RUTA_API}/instituciones/ministerios/`
        )
        this.options = res.data.map((m) => ({
          value: m.id,
          text: m.nombre
        }))
        this.options.unshift({
          value: null,
          text: '- Seleccione datos de busqueda -'
        })
      } catch (error) {
        console.log(error)
      }
    },
    // get data service
    requestServices: async function () {
      try {
        const query = this.searchService || ''
        const res = await axios.get(
          `${process.env.VUE_APP_RUTA_API}/instituciones/servicios/public_list/?search=${query}`
        )
        this.$store.commit('updateServicesList', res.data)
        this.$store.commit('setTypeService', 'servicios')
        this.$store.commit('setSearchService', null)
      } catch (error) {
        console.log(error)
      }
    },
    // get select minister
    requestMinisterService: async function () {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_RUTA_API}/instituciones/ministerios/${this.selected}`
        )
        this.$store.commit('updateServicesList', res.data.servicios)
        this.$store.commit('setTypeService', 'servicios')
      } catch (error) {
        console.log(error)
      }
    },
    // get data
    handleOnLoad: async function () {
      const res = await axios.get(
        `${process.env.VUE_APP_RUTA_API}/instituciones/servicios/public_list/`
      )
      this.$store.commit('updateServicesList', res.data)
      this.$store.commit('setTypeService', 'servicios')
      this.$store.commit('setSearchService', null)
    }
  },
  watch: {
    // data obtained from the select
    selected: async function () {
      if (!this.selected) {
        this.handleOnLoad()
      } else {
        this.requestMinisterService()
      }
    },
    // data obtained from the select
    searchService: function () {
      if (!this.searchService) return
      this.requestServices()
    }
  },
  async mounted () {
    if (this.searchService) {
      this.requestServices()
    } else if (!this.selected) {
      this.handleOnLoad()
    }

    this.requestMinister()
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap");

.container-forms {
  display: flex;
  margin: 0 50px;
}
.i-header {
  padding-top: 20px;
  padding-bottom: 10px;
  background: #052333;
}
.i-title > p {
  font-family: "Fira Sans", sans-serif;
  color: #fff;
}
span.i-desc {
  font-family: "Fira Sans", sans-serif;
}
.contenedor-texto {
  color: #fff;
  margin: 6px 50px !important;
}

.i-title{
  font-size: 28px !important;
}

.i-desc {
  font-size: 14px;
}
.options {
  margin-right: 0.5rem;
  width: 230px;
  font-size: 0.75rem;
  padding: 0.5rem;
  border-radius: 5px !important;
}
.type-selected {
  margin: 0 1rem 0 0;
}
.something {
  color: #24c6ab;
  margin: 0 !important;
  text-align: center;
  line-height: 30px;
}
.optionss {
  margin-right: 0.5rem;
  width: 230px;
  font-size: 0.75rem;
  padding: 0.4rem;
}
.controller-forms {
  width: 300px !important;
}

@media screen and (max-width: 1293px) {
  .i-title {
    font-size: 24px;
  }
}

@media screen and (max-width: 897px) {
  .i-title {
    font-size: 22px;
  }
}

@media screen and (max-width: 440px) {
  .i-title {
    font-size: 16px !important;
  }
}
</style>
