<template>
    <div class="desafios">
      <Header />
      <Contents />
      <Footer />
    </div>
  </template>
<script>
import Header from '../../components/private/Header'
import Footer from '../../components/layout/Footer.vue'
import Contents from './components/Contents.vue'
export default {
  components: {
    Header,
    Contents,
    Footer
  }
}
</script>
<style scoped>
  .desafios {
    height: 100vh;
  }
</style>
