<template>
  <div>
    <b-button variant="link" class="modal-button" id="show-btn" @click="$bvModal.show('bv-modal-example')">
      <Config /> {{ button }}
    </b-button>
    <b-modal  size="lg" id="bv-modal-example" hide-footer>
        <template #modal-title><h4 class="title-modal">{{ title }}</h4></template>
        <div class="container" style="margin-top:5px">
          Presentar una breve descripción del Servicio, señalando su razón de ser, las funciones que desempeñan, los clientes que atiende, así como también una breve reseña de su estructura organizacional; número de funcionarios, número de oficinas regionales, entre otros aspectos que sea relevante destacar.
          <br><br>
          Hacer una breve descripción del tamaño de la institución, medido según: presupuesto en moneda nacional, como % del PIB (si corresponde), como % del gasto público, como % del gasto del ministerio; dotación total, y por variables relevantes como tipo de contrato, sexo, tipo de cargo, y como porcentaje de la dotación total del ministerio.
          <br><br>
          Describir en un relato claro y preciso, un resumen de los principales resultados del desempeño (logros y no logros) de la institución a la fecha (2023), con base en la información presentada en el Capítulo 3 “Resultados de la Gestión 2023”, señalando las principales circunstancias o factores internos y externos que han afectado el desempeño institucional, y las medidas a adoptar, cuando corresponda.
          <br><br>
          Finalmente, presentar una breve descripción de los desafíos para el periodo 2022-2026, señalados en detalle en el Capítulo 4 de este balance.
        </div>
    </b-modal>
  </div>
</template>

<script>
import Config from '@/components/image/icon/Config'
export default {
  props: ['html'],
  components: {
    Config
  },
  data () {
    return {
      button: 'Instrucciones: ¿Cómo completar esta sección?'
    }
  }
}
</script>

<style scoped>
.modal-button {
  display: flex;
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.modal-button:hover {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.modal-button:focus {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.title-modal {
  color: #052333;
  font-size: 20px;
  text-align: center;
}

</style>
