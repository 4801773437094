<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="img">
          <img src="./img/gobierno de chile.png" alt="" />
        </div>
      </b-col>
    </b-row>
    <div class="center">
      <b-card tag="article" class="cards-login">
        <div class="head-login">
          <h3 class="title">{{ title }}</h3>
          <div class="div">
            <p class="parrafo">{{ label }}</p>
          </div>
        </div>
        <div class="body-login">
          <div class="form-login">
            <form>
              <div class="user">
                <label for="username" class="input-label">Ingrese su Nombre de Usuario</label>
                <b-form-input id="username" class="input" placeholder="Ingresa tu Username" required v-model="username"></b-form-input>
              </div>

              <div class="password">
                <label for="password" class="input-label">Ingrese su Contraseña</label>
                <b-form-input id="password" class="input" placeholder="Ingresa tu Clave" type="password" required v-model="password"></b-form-input>
              </div>

              <div class="">
                <div class="btn-login">
                  <b-button variant="primary" class="button-login" @click="login">Continuar</b-button>
                  <!-- <ClaveUnicaLogin /> -->
                  <!-- <b-button variant="primary" :to="{ name: 'gestion' }">Continuar</b-button> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import ClaveUnicaLogin from "@/components/layout/navbar/up/ClaveUnicaLogin.vue"
import { mapActions } from 'vuex'
export default {
  // components: {ClaveUnicaLogin},
  data () {
    return {
      title: 'PLATAFORMA DE GESTIÓN GUBERNAMENTAL',
      label: 'Requiere autenticación',
      username: '',
      password: ''
    }
  },
  methods: {
    ...mapActions('sesion', ['inicioSesion']),
    decodeJwt: function (token) {
      var base64Payload = token.split('.')[1]
      var payload = Buffer.from(base64Payload, 'base64')
      return JSON.parse(payload.toString())
    },
    login () {
      axios.post(`${process.env.VUE_APP_RUTA_API}/token-auth/`, {
        username: this.username,
        password: this.password
      }).then(res => {
        const a = this.decodeJwt(res.data.token)
        localStorage.setItem('userToken', res.data.token)
        axios.get(
              `${process.env.VUE_APP_RUTA_API}/usuarios/usuarios/${a.user_id}/`,
              { headers: { Authorization: `JWT ${res.data.token}` } }
        ).then(user => {
          localStorage.setItem('user', JSON.stringify(user.data))
          this.$router.push({ name: 'gestion' }).catch(() => {})
        })
      })
    }
  }
}
</script>

<style scoped>
.center {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  margin: 0;
}
.img {
  margin-left: 94px;
}
.input-label {
  color: #052333;
  font-size: 0.9rem;
  font-weight: bold;
}
.row-card {
  height: 80vh;
}
.col-card {
  display: flex;
  justify-content: center;
  align-content: center;
}
.cards-login {
  border: 1px solid #000;
  height: 480px;
  width: 500px;
}
.body-login {
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
}
.head-login {
  height: 20%;
  background: #24c6ab;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.img-login {
  position: relative;
  top: -1px;
}
.title {
  text-align: center;
  /* margin-top: 20px; */
  font-size: 18px;
  font-weight: bold;
  font-family: "Fira Sans", sans-serif;
}
.parrafo {
  color: #525659;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  font-family: "Fira Sans", sans-serif;
}
.form-login {
  display: flex;
  justify-content: center;
}
.input {
  border-bottom: 1px solid #b7b7b7;
  border-radius: 0px;
  margin-bottom: 10px;
  color: #052333;
}
.btn-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.button-login {
  width: 155px;
  background: #4f52ff;
  /* border-radius: 40px; */
  margin-bottom: 15px;
}
.button-login:hover {
  background: #4f52ff;
}
</style>
