<template>
  <div class="profile">
    <Header />

    <Content />

    <Footer />
  </div>
</template>

<script>
import Footer from '../../components/layout/Footer.vue'
import Header from '../../components/private/Header'
import Content from './components/Content.vue'

export default {
  components: {
    Header,
    Content,
    Footer
  },
  mounted () {
    this.$store.commit('setPrivateView', 'historial')
  }
}
</script>

<style scoped>
.main {
  background: #fff;
  margin-bottom: 30px;
}
</style>
