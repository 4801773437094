<template>
  <div class="footer">
    <b-row class="footer-one">
      <b-col class="one"><ContentOne /></b-col>
      <b-col class="two"><ContentCenterOne /></b-col>
      <b-col class="three"><ContentCenterTwo /></b-col>
      <b-col class="for"><ContentOneRight /></b-col>
    </b-row>
    <b-row class="footer-two">
      <b-col class="col-btn" cols="12">
        <b-button class="btn-one" variant="link"
          >Políticas de Privacidad</b-button
        >
        <b-button class="btn-two" variant="link">Condiciones de Uso</b-button>
        <b-button class="btn-three" variant="link">Contacto</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ContentOne from './footer/contentOne/ContentOne.vue'
import ContentCenterOne from './footer/contentOne/ContentCenterOne.vue'
import ContentCenterTwo from './footer/contentOne/ContentCenterTwo.vue'
import ContentOneRight from './footer/contentOne/ContentOneRight.vue'

export default {
  components: {
    ContentOne,
    ContentCenterOne,
    ContentCenterTwo,
    ContentOneRight
  }
}
</script>

<style scoped>
.row {
  margin: 0;
}

.footer-one {
  background: #052333;
  height: 350px;
  display: flex;
  align-items: center;
}

.footer-two {
  background: rgb(224, 224, 224, 1);
  height: 60px;
}

.one {
  text-align: center;
}

.two {
  text-align: end;
}

.for {
  text-align: center;
}

.col-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-one {
  text-decoration: none;
  color: #b1b1b1;
  border-right: 2px solid #b1b1b1;
  font-size: 16px;
  height: 30px;
  align-items: center;
  border-radius: 0px;
  color: #052333 !important;
}

.btn-one:hover {
  color: #052333 !important;
}

.btn-two {
  text-decoration: none;
  color: #052333 !important;
  border-right: 2px solid #b1b1b1;
  font-size: 16px;
  height: 30px;
  align-items: center;
  border-radius: 0px;
}

.btn-two:hover {
  color: #052333 !important;
}

.btn-three {
  text-decoration: none;
  font-size: 16px;
  height: 30px;
  align-items: center;
  border-radius: 0px;
  color: #052333 !important;
}

.btn-three:hover {
  color: #052333 !important;
}

@media screen and (max-width: 1800px) {
  .footer-one {
    background: #052333;
    height: 250px;
    display: flex;
    align-items: center;
  }

  .footer-two {
    background: rgb(224, 224, 224, 1);
    height: 50px;
  }
  .one {
    text-align: center;
  }

  .two {
    text-align: end;
  }

  .for {
    text-align: center;
  }

  .col-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-one {
    text-decoration: none;
    color: #b1b1b1;
    border-right: 2px solid #b1b1b1;
    font-size: 12px;
    height: 30px;
    align-items: center;
    border-radius: 0px;
  }

  .btn-one:hover {
    color: #b1b1b1;
  }

  .btn-two {
    text-decoration: none;
    color: #b1b1b1;
    border-right: 2px solid #b1b1b1;
    font-size: 12px;
    height: 30px;
    align-items: center;
    border-radius: 0px;
  }

  .btn-two:hover {
    color: #b1b1b1;
  }

  .btn-three {
    text-decoration: none;
    color: #b1b1b1;
    font-size: 12px;
    height: 30px;
    align-items: center;
    border-radius: 0px;
  }

  .btn-three:hover {
    color: #b1b1b1;
  }
}

@media screen and (max-width: 1600px) {
  .footer-one {
    height: 240px;
  }
}

@media screen and (max-width: 915px) {
  .footer-one {
    height: 260px;
  }
  .two {
    text-align: center;
  }

  .for {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .for {
    display: grid;
    justify-content: center;
  }
}

@media screen and (max-width: 710px) {
  .three {
    display: grid;
    justify-content: center;
  }
}

@media screen and (max-width: 463px) {
  .footer-one {
    height: 500px;
    display: grid;
  }

  .one {
    display: flex;
    justify-content: center;
  }
  .two {
    display: flex;
    justify-content: center;
  }
  .tree {
    display: flex;
    justify-content: center;
  }
  .for {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 381px) {
  .footer-two {
    height: 70px;
  }
}
</style>
