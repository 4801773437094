<template>
  <div class="">
    <b-container fluid="md">
      <b-row>
        <div>
          <div class="left">
            <Dipres />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Dipres from '../../../image/layout/Dipres'
export default {
  components: {
    Dipres
  }
}
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .left {
    max-width: 17%;
  }
}
</style>
