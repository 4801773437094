<template>
  <div class="main">
    <Main />
  </div>
</template>

<script>
import Main from './info/Main.vue'
export default {
  components: {
    Main
  },
  data () {
    return {
      notification:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      msg: 'Para dudas y consultas por favor escribenos a',
      h: 'consultasbgl@dipress.gob.cl'
    }
  }
}
</script>

<style scoped>
.main {
  background: #fff !important;
  margin-bottom: 40px;
}

.row {
  margin: 0;
  height: 120px;
}

.text {
  display: flex;
}

.correo {
  margin-left: 4px;
  font-weight: 600;
}

@media screen and (max-width: 554px) {
  .row {
    height: 60px;
  }
  p {
    font-size: 10px;
  }
}

@media screen and (max-width: 357px) {
  .row {
    display: none;
  }
}
</style>
