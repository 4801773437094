<template>
  <div class="container">
    <Title  />
    <Breadcrumb :links="breadCrumbData" />

    <Carrusel />
    <div class="container">
        <Button />

        <h1  class="privado-titulo">Resultado de la Gestión - Asociados al Programa de Gobierno</h1>

        <b-row>
            <b-col>
                <Instrucciones/>
            </b-col>
            <b-col>
                <Visualizar seccion="resultado_gestion_programa" />
            </b-col>
            <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
                    <b-form-checkbox v-model="anexo.datos.no_aplica">
                    No Aplica
                </b-form-checkbox>
            </b-col>
        </b-row>

        <div v-if="!can_edit_anexos_de_servicios() && anexo">
            <br>
            <AlertPerms/>
            <div v-html="anexo.datos.texto" class="seccion-background-color-table"/>
        </div>
        <div v-if="can_edit_anexos_de_servicios()">
            <br><br>
            <b-button @click="guardar" pill variant="primary" class="btn-one">Guardar</b-button>
            <br><br>
            <ck-editor-text :modifyContent="modifyContent" :borrador="anexo.datos.texto" v-if="anexo && !anexo.datos.no_aplica"></ck-editor-text>

        </div>

    </div>
  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'
import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import CkEditorText from '@/components/private/CkEditorText.vue'
import Button from './main/Button.vue'
import { mapState } from 'vuex'
import axios from 'axios'
import Carrusel from '@/components/private/Carrusel.vue'
import Instrucciones from './Instrucciones.vue'

export default {
  mixins: [utils],
  components: {
    Title,
    Breadcrumb,
    CkEditorText,
    Button,
    Instrucciones,
    Carrusel,
    Visualizar
  },
  data () {
    return {
      anexo: null,
      permiso: false,
      breadCrumbData: null,
      content: '',
      nombre: 'resultados_gestion',
      idServicio: this.$route.params.idServicio,
      borrador: '',
      contenido: null,
      userPermissions: true,
      userPermission: true,
      checked: false,
      text:
        'Presentar los resultados (logros y no logros) de la gestión de la institución en términos de la provisión de sus productos estratégicos a los usuarios, beneficiarios y/o ciudadanos, así como aspectos que sean de interés para la ciudadanía en el marco de la función que le compete al Servicio.',
      text_Two:
        'Para la presentación de cada resultado relevante (logros y no logros), se deberá presentar primero el producto estratégico (o programa si corresponde) sobre el que se dará cuenta pública, descrito brevemente. Luego se deberá realizar un análisis cuantitativo y cualitativo, el que puede apoyarse con gráficos y tablas auto-explicativas. ',

      text_One:
        'Se entiende por logros las acciones desarrolladas que tuvieron resultados satisfactorios, que pueden expresarse de manera cuantitativa o cualitativa. ',
      text_three:
        'Por otro lado, se entiende por no logros a las acciones desarrolladas por la institución que tuvieron resultados no satisfactorios, es decir, no se cumplieron los compromisos, metas o la programación definida para la institución. En este caso se solicita explicar las causas de dicha situación precisando si éstas se deben a factores internos o externos a la gestión de la institución. En los casos que corresponda y sea factible, informar las medidas que se están adoptando para mejorar los resultados obtenidos.'
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])

  },
  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Resultados de Gestión - Programa de Gobierno', routeName: 'programaGobierno', routeParams: { idServicio: this.$route.params.idServicio } }]
    },

    modifyContent: function (text) {
      this.anexo.datos.texto = text
    },
    guardar: function () {
      axios.put(`/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    },
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'resultado_gestion_programa'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.texto = anexo.datos.texto || ''
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRevision()
  }
}
</script>

<style scoped>
.row {
  margin: 0;
}

.div-container {
  margin-left: 30px;
  margin-right: 40px;
  margin-bottom: 30px;
}

.btn-one {
  margin-right: 20px;
}

.btn-direction-flex {
  text-align: end;
}
.title {
  margin-top: 20px;
  margin-bottom: 21px;
  text-align: center;
  color: #052333;
}

.span {
  font-size: 18px;
  color: #052333;
  font-weight: 600;
}
h5 {
  color: #052333;
  font-size: 30px;
  font-weight: bold;
}

@media screen and (max-width: 1800px) {
  h5 {
    color: #052333;
    font-size: 26px;
    font-weight: bold;
  }

  .btn-one {
    font-size: 16px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 16px;
  }
}

@media screen and (max-width: 1600px) {
  h5 {
    font-size: 21px;
  }

  .btn-one {
    font-size: 15px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 15px;
  }
}

@media screen and (max-width: 1400px) {
  h5 {
    font-size: 17px;
  }

  .btn-one {
    font-size: 14px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 14px;
  }
}

@media screen and (max-width: 1100px) {
  h5 {
    font-size: 17px;
  }

  .btn-one {
    font-size: 12px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  h5 {
    font-size: 18px;
  }

  .btn-one {
    font-size: 10px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 10px;
  }
}

@media screen and (max-width: 560px) {
  .btn-one {
    font-size: 7px;
    margin-right: 2px;
    width: 60px;
  }
  .btn-two {
    font-size: 7px;
    width: 60px;
    height: 45px;
  }
}
</style>
