<template>
  <div>
    <Header />
    <div class="container">
      <Title />
      <Carrusel />
      <Breadcrumb :links="breadCrumbData" />
      <Button />
      <h1 class="privado-titulo">Leyes</h1>
      <b-row>
        <b-col>
          <!-- <Instrucciones/> -->
        </b-col>
        <b-col>
          <Visualizar seccion="leyes_tramite" />
        </b-col>
        <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
          <b-form-checkbox v-model="anexo.datos.no_aplica">
            No Aplica
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-button v-if="can_edit_anexos_de_servicios()" @click="guardar" pill variant="primary" class="btn-one">Guardar</b-button>
        <div v-if="itemList && !anexo.datos.no_aplica">
            <h3 class="title-leyes">Anexo 10a: Proyectos de Ley en Tramitación en el Congreso Nacional 2018-2021</h3>

            <div  v-for="(item, index) in itemList" :key="index">
                <hr />
                <b-row>
                    <b-col cols="12">
                    <label class="label-leyes">
                        <span class="label-leyes-span">Boletín</span>
                        <i class="bx bxs-trash" @click="removeItem(item)" style="color:#24c6ab; cursor: pointer;"></i></label>
                    <b-form-input v-model="item.boletin" class="input-leyes" type="text" :disabled="!can_edit_anexos_de_servicios()"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <label class="label-leyes"><span class="label-leyes-span">Descripción</span></label>
                        <b-form-textarea v-model="item.descripcion" class="input-leyes " :disabled="!can_edit_anexos_de_servicios()"></b-form-textarea>
                    </b-col>
                    <b-col cols="6"> <label class="label-leyes">
                        <span class="label-leyes-span">Objetivo</span> </label>
                        <b-form-textarea v-model="item.objetivo" class="input-leyes" :disabled="!can_edit_anexos_de_servicios()"></b-form-textarea>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="6">
                        <label class="label-leyes">
                            <span class="label-leyes-span">Fecha de ingreso</span>
                        </label>
                        <b-form-datepicker class="input-leyes" :id="`datepicker-${index}`" :hide-header="true" locale="es" :disabled="!can_edit_anexos_de_servicios()" v-model="item.fecha_ingreso" ></b-form-datepicker>
                    </b-col>
                    <b-col cols="6">
                        <label class="label-leyes">
                            <span class="label-leyes-span">Estado de Tramitación</span>
                        </label>
                        <b-form-input v-model="item.estado_tramitacion" class="input-leyes" type="text" :disabled="!can_edit_anexos_de_servicios()"/>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <label class="label-leyes"><span class="label-leyes-span">Beneficiarios Directos</span></label>
                        <b-form-input v-model="item.beneficiarios" class="input-leyes" type="text" :disabled="!can_edit_anexos_de_servicios()"/>
                    </b-col>
                </b-row>
            </div>
            <div class="add-btn" @click="addItemList" v-b-tooltip.hover title="Agregar otro Boletín">
                <hr class="separator-hr" />
                <b-button class="button-leyes"><i class="bx bx-plus"></i></b-button>
                <hr class="separator-hr" />
            </div>
        </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../../components/private/Header'
import Footer from '../../components/layout/Footer.vue'
import { utils } from '@/components/tools/ViewUtils.js'
import axios from 'axios'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'

import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '@/components/private/Carrusel.vue'
import Button from './components/Button.vue'
import { mapState } from 'vuex'

export default {
  mixins: [utils],
  components: {
    Title,
    Breadcrumb,
    Carrusel,
    Header,
    Footer,
    Visualizar,
    Button
  },
  data () {
    return {
      breadCrumbData: null,
      itemList: null,
      anexo: null
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])

  },
  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Leyes', routeName: 'leyes', routeParams: { idServicio: this.$route.params.idServicio } }
      ]
    },

    addItemList: function () {
      const toAddObj = {}
      this.itemList.push(toAddObj)
    },
    removeItem: function (index) {
      if (this.readOnly) return
      const pos = this.itemList.indexOf(index)

      this.itemList.splice(pos, 1)
    },
    guardar: function () {
      axios.put(`/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    },
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'leyes_tramite'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.listado = anexo.datos.listado || []
        this.itemList = anexo.datos.listado
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }

  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRevision()
  }
}
</script>

<style scoped>

/* INICIO SEPARATOR */
.add-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}
.separator-hr {
  width: 45%;
}
.button-leyes {
  font-size: 20px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  border: 2px solid #ccc;
  background: white;
  padding: 0;
  color: #ccc;
}
.bx-plus {
  position: relative;
  left: -2px;
  top: -5px;
}
.btn-check:focus + .btn,
.btn-secondary:hover,
.btn:focus {
  box-shadow: none;
  color: #ccc;
  border: 2px solid #ccc;
  background: white;
}
/* FIN SEPARATOR */
.containers {
margin-bottom: 30px;
margin-left: 30px;
margin-right: 30px;
}
</style>
