<template>
  <div class="ii">
    <Header />
    <Contents />
    <Footer />
  </div>
</template>

<script>
import Header from '../../components/private/Header'
import Footer from '../../components/layout/Footer.vue'
import Contents from './components/Contents.vue'

export default {
  components: {
    Header,
    Footer,
    Contents
  }
}
</script>

<style scoped>
.ii{
  height: 100vh
}

</style>
