<template>
  <div>
    <div class="div-title" v-if="userData">
      <h4 class="title">Gestionar Accesos Para:</h4>
      <h4 class="nombre">{{ userData.username }}</h4>
    </div>
    <div v-if="!selectedService" class="div-service-list">
      <div class="loading-data">
        <Loading />
      </div>
    </div>
    <div v-if="selectedService" class="div-perms-list">
      <div class="modal-body">
        <div class="draggable1">
          <draggable :list="list1" group="perms">
            <div
              class="list-group-item list-one-item"
              v-for="element in list1"
              :key="element.name"
            >
              <span class="drag-element">
                {{ element.name }}
              </span>
            </div>
          </draggable>
        </div>
        <div class="middle">
          <i class="bx bx-right-arrow-alt arrow"></i>
          <p class="middle-text">Arrastre para activar</p>
        </div>
        <div class="draggable2">
          <draggable :list="list2" group="perms" @change="logListTwo">
            <div class="no-perms" v-if="list2.length === 0">
              <span class="no-perms-text">
                Arrastrar Aquí
              </span>
            </div>
            <div
              class="list-group-item list-two-item"
              v-for="element in list2"
              :key="element.name"
              v-else
            >
              <span class="drag-element">
                {{ element.name }}
              </span>
            </div>
          </draggable>
        </div>
      </div>
      <div class="div-footer">
        <div style="display: flex; justify-content: center;">
          <b-button class="btn-confirm" @click="sendData">
            <span class="btn-text">
              Dar acceso
            </span>
          </b-button>
          <b-button class="btn-cerrar" @click="closeModal">
            <span class="btn-text">
              Cerrar
            </span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import Loading from '@/components/tools/Loading.vue'
import { showAlert } from '@/components/tools/utils'
export default {
  components: {
    draggable,
    Loading
  },
  data () {
    return {
      list1: [
        // { name: "Presentación del Ministro", id: 1 },
        { name: 'Presentación de Servicio', id: 2 },
        { name: 'Resultado de la Gestion', id: 3 },
        { name: 'Desafios', id: 4 },
        { name: 'Recursos Humanos', id: 5 },
        { name: 'Recursos Financieros', id: 6 },
        { name: 'Indicadores', id: 7 },
        { name: 'Compromisos', id: 8 },
        { name: 'Evaluaciones', id: 9 },
        { name: 'Incentivo Institucional', id: 10 },
        { name: 'Incentivo Colectivo', id: 11 },
        { name: 'Equidad de Genero', id: 12 },
        { name: 'Leyes', id: 13 },
        { name: 'Premios y Reconocimientos', id: 14 },
        { name: 'Organigrama de Servicios', id: 15 }
      ],
      list2: [],
      removedPerms: [],
      selectedService: null,
      userData: null,
      usuarioServicioId: null,
      userPermListRemove: []
    }
  },
  props: ['datosUsuario', 'closeModal', 'getUsers'],
  methods: {
    sendData: function () {
      const token = localStorage.getItem('userToken')
      let relObj
      let reqError = null

      this.list2.forEach(async (np) => {
        relObj = {
          usuarioServicio: this.selectedService.usuarioServicioId,
          permiso: np.id
        }
        if (
          !this.datosUsuario.permisos[0].find((p) => p.id === relObj.permiso)
        ) {
          try {
            await axios.post(
              `${process.env.VUE_APP_RUTA_API}/instituciones/usuarioserviciopermiso/`,
              relObj,
              {
                headers: {
                  Authorization: `JWT ${token}`
                }
              }
            )
          } catch (error) {
            reqError = error.response.data[Object.keys(error.response.data)[0]]
          }
        }
      })

      setTimeout(() => {
        this.userPermListRemove.forEach(async (p) => {
          try {
            await axios.delete(
              `${process.env.VUE_APP_RUTA_API}/instituciones/usuarioserviciopermiso/${p.usuarioPermisoId}/`,
              {
                headers: {
                  Authorization: `JWT ${token}`
                }
              }
            )
          } catch (error) {
            reqError = error.response.data[Object.keys(error.response.data)[0]]
          }
        })

        if (reqError) {
          showAlert({
            icon: 'error',
            text: reqError,
            timerProgressBar: true,
            timer: 3000
          })
        } else {
          showAlert({
            icon: 'success',
            text: 'Módulos asignados correctamente',
            timerProgressBar: true,
            timer: 3000
          })
        }
      }, 500)

      setTimeout(() => {
        this.getUsers()
        this.closeModal()
      }, 1000)
    },
    setService: function () {
      this.selectedService = this.userData.servicios.find(
        (s) => s.id === parseInt(this.$route.params.idServicio)
      )
      this.setAlreadyPerms()
    },
    setAlreadyPerms: function () {
      const perms = this.selectedService.permisos
      let p
      for (let i = 0; i < this.list1.length; i++) {
        p = this.list1[i]
        if (perms.find((a) => a.permisoId === p.id)) {
          this.list2.push(p)
        }
      }

      this.list1 = this.list1.filter((r) => !this.list2.includes(r))
    },
    logListTwo: function (evt) {
      if (evt.removed) {
        this.removedPerms.push(evt.removed.element.id)
      } else if (evt.added) {
        this.removedPerms = this.removedPerms.filter(
          (rp) => rp !== evt.added.element.id
        )
      }
      console.log('datos u', this.userData)
      const userService = this.userData.servicios.find(
        (s) => s.id === parseInt(this.$route.params.idServicio)
      )
      this.userPermListRemove = userService.permisos.filter((p) =>
        this.removedPerms.includes(p.permisoId)
      )

      console.log('lista permisos r', this.userPermListRemove)
    }
  },
  async mounted () {
    const res = await axios.get(
        `${process.env.VUE_APP_RUTA_API}/usuarios/usuarios/${this.datosUsuario.id}/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('userToken')}`
          }
        }
    )

    this.userData = res.data
    this.usuarioServicioId = this.userData.servicios.find(
      (s) => s.id === parseInt(this.$route.params.idServicio)
    ).usuarioServicioId
    this.setService()
  }
}
</script>
<style scoped>
.title {
  color: #052333;
  margin: 0 !important;
  font-weight: bold;
}
.nombre {
  font-weight: 200;
  margin: 0 !important;
}
.loading-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.div-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #24c6ab;
  height: 80px;
}
.modal-body {
  margin: 15px;
  background: white;
  display: flex;
  align-items: center;
}
.div-footer {
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-confirm {
  background: #4f52ff;
  border-radius: 40px;
  border: none;
  margin: 0 20px;
}
.btn-cerrar {
  background: #e73f4b;
  border-radius: 40px;
  border: none;
  margin: 0 20px;
}
.btn-text {
  font-weight: 200;
}
.btn-skip {
  display: flex;
  align-items: center;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #052333;
  border-color: #052333;
}
.btn-skip-text {
  font-size: 0.8rem;
  color: gray;
}
.color-gray {
  color: gray;
}
.draggable1 {
  width: 40%;
}
.draggable2 {
  width: 40%;
}
.no-perms {
  height: 40px;
  border: 1px dashed #adadad;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-perms-text {
  color: #adadad;
  font-size: 0.8rem;
}
.list-group-item {
  font-size: 12px;
  margin: 4px;
  border: none;
  border-radius: 0;
  max-width: 180px;
  max-height: 50px;
  height: 40px;
  width: 180;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.div-service-list {
  margin: 20px 20px;
}

.service-item {
  font-size: 15px;
  margin: 4px;
  border: none;
  border-radius: 0;
  max-height: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #052333;
  background: #e0e0e0;
  padding: 0.5rem;
  justify-content: space-between;
}

.list-one-item {
  color: #052333;
  background: #e0e0e0;
}
.list-two-item {
  background: #052333;
  color: white;
}
.drag-element {
  font-weight: bold;
}
.middle {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.arrow {
  font-size: 3rem;
  color: #adadad;
  text-align: center;
}

.middle-text {
  font-size: 0.8rem;
  text-align: center;
  color: #052333;
}
</style>
