<template>
  <div class="div">
    <b-button variant="link" class="config">
      <Config /> Instrucciones: ¿Cómo completar esta sección?</b-button
    >
  </div>
</template>

<script>
import Config from '../../image/icon/Config.vue'
export default {
  components: {
    Config
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>
.config {
  display: flex !important;
  color: #052333;
  font-weight: bold;
}

.config:hover {
  color: #052333;
}

@media screen and (max-width: 784px) {
  .config {
    font-size: 10px;
  }
}

</style>
