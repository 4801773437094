<template>
  <div>
    <Title v-if="!isAdmin" :showMinister="true" :showService="true" />

    <Breadcrumb :links="breadCrumbData" />
    <Carrusel />
    <b-row>
      <b-col class="container">
        <Button />
      </b-col>
    </b-row>

    <div v-if="permission">
      <Main />
    </div>

    <div v-else>
      <NotPermission :contenidoLock="contenidoLock" />
    </div>
  </div>
</template>

<script>
import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '@/components/private/Carrusel.vue'
import Button from './Button.vue'
import Main from './permission/Main.vue'
import NotPermission from '@/components/private/TextNotPermisionPrueba.vue'
import { mapState } from 'vuex'
import axios from 'axios'
export default {
  components: {
    Title,
    Breadcrumb,
    Button,
    Carrusel,
    Main,
    NotPermission
  },
  computed: {
    ...mapState('sesion', ['usuario']),
    isAdmin: function () {
      return this.usuario.username === process.env.VUE_APP_USER_ADMIN
    }
  },
  data () {
    return {
      permission: false,
      breadCrumbData: null,
      contenidoLock: null
    }
  },
  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        {
          name: 'Portada BGI',
          routeName: 'bgi',
          routeParams: {
            idUsuario: this.usuario.id
          }
        },
        {
          name: 'Escritorio de Gestión Virtual',
          routeName: 'buttons',
          routeParams: {
            idUsuario: this.usuario.id,
            idServicio: this.$route.params.idServicio
          }
        },
        {
          name: 'Presentación del Ministro - Avance de la Gestión',
          routeName: 'ministroAvance',
          routeParams: {
            idServicio: this.$route.params.idServicio
          }
        }
      ]
    },
    obtenerRelacionUsuarioServicio: function () {
      const relacionUs = this.usuario.servicios.find(
        (s) => s.id === parseInt(this.$route.params.idServicio)
      )
      if (!relacionUs) return
      if (
        relacionUs.rolId === 6 ||
        relacionUs.permisos.find((p) => p.permisoId === 18)
      ) {
        this.permission = true
      }
    },
    obtenerContenido: async function () {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_RUTA_API}/instituciones/secciones/${this.$route.params.idServicio}/`,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('userToken')}`
            }
          }
        )
        const datosAnio = res.data[process.env.VUE_APP_ANIO]
        if (datosAnio) {
          if (datosAnio.avanceGestion) {
            // if (datosAnio.avanceGestion.elaboracion === "borrador") {
            this.contenidoLock = datosAnio.avanceGestion.info.content
            // }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRelacionUsuarioServicio()
    this.obtenerContenido()
  }
}
</script>
