<template>
  <div>
    <b-row>
      <div class="h2">
        <h3 style="font-weight: bold;">{{ gestion }}</h3>
      </div>
      <div class="h3">
        <h3 style="font-weight: bold;">{{ plataforma }}</h3>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      gestion: 'Gestión.gob',
      plataforma: 'Plataforma de Gestión Gubernamental'
    }
  }
}
</script>

<style scoped>
.row {
  margin: 0;
  background: #052333;
  height: 140px;
}

.h2 {
  text-align: center;
  color: #fff;
  position: relative;
  top: 25px;
}

.h3 {
  text-align: center;
  color: #fff;
  position: relative;
  top: -4px;
}

@media screen and (max-width: 430px) {
  .row {
    height: 100px !important;
  }

  h3 {
    font-size: 16px;
  }
}
</style>
