<template>
  <div class="container">

    <div class="div-title" v-if="items">
      <h4 class=" title">
        Total {{ this.year }} - Datos agregados
        <span v-if="$route.name==='people'">(de todo el Estado central)</span>
      </h4>
      <h5 class="title">
        Dotación total año {{ this.year }}:        {{ this.personasTotales() }}
      </h5>
    </div>

    <div v-show="items">
      <details open>
        <summary>
          Dotación de personal por tipo de relación laboral año {{ this.year }}
        </summary>
        <div class="data">
          <TortaData :datos="this.relacionLaboral" />
        </div>
      </details>

      <details>
        <summary>
          Dotación de personal por tipo de relación laboral y género año
          {{ this.year }}
        </summary>
        <div v-if="items && items.length>0">
            <grafico-general :datos_completos="items" filtro="dotacion" columna='calidad_juridica' hidetable="true" />
        </div>
      </details>

      <!-- collapse tres -->
      <details>
        <summary>
          Dotación de personal por estamento año {{ this.year }}
        </summary>
        <div v-if="items && items.length>0">
            <grafico-general :datos_completos="items" filtro="dotacion" columna='estamento' hidetable="true" />
        </div>
      </details>
      <!-- collapse cuatro -->
      <details>
        <summary>
          Dotación de personal por edad año {{ this.year }}
        </summary>
        <div v-if="items && items.length>0">
            <grafico-general :datos_completos="items" filtro="dotacion" columna='edad' hidetable="true" />
        </div>
      </details>
      <details>
        <summary>
          Personal a honorarios año {{this.year}}, según función desempeñada
        </summary>
        <div v-if="items && items.length>0">
            <grafico-general :datos_completos="items" filtro="fuera_dotacion" columna='estamento'  hidetable="true"/>
        </div>
      </details>
    </div>
  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
import graficoGeneral from '@/views/rHumanos/components/graphics/graficoGeneral.vue'

import TortaData from '../data/TortaData.vue'
import axios from 'axios'
export default {
  mixins: [utils],

  components: {

    graficoGeneral,
    TortaData
  },

  data () {
    return {
      items: null,
      relacionLaboral: null,
      estamentoGenero: null,
      contratoGenero: null,
      dotacionEdad: null,
      loading: true,
      year: null
    }
  },

  computed: {
  },

  methods: {

    personasTotales () {
      return this.items.reduce((accumulator, object) => {
        return accumulator + object.cantidad
      }, 0)
    },
    obtenerRevision: function () {
      var params = { anio: 2020, servicio: this.$route.params.id }

      axios.get('/anexos/anexo_cf/rrhh', { params: params }).then(res => {
        var items = res.data[0].datos.estadisticas.filter(item => { return parseInt(item.anio) == parseInt(this.year) })

        const labels = [...new Set(items.map(i => { return i.calidad_juridica }))]
        var data = []
        labels.forEach(label => {
          data.push(items.filter(item => { return item.calidad_juridica === label }).reduce((accumulator, object) => {
            return accumulator + object.cantidad
          }, 0))
        })
        this.relacionLaboral = {
          labels: [...new Set(items.map(i => { return i.calidad_juridica }))],
          data: data
        }
        this.items = items
      })
    }
  },

  watch: {
    $route: {
      handler (newValue, oldValue) {
        this.obtenerRevision()
        this.year = this.$route.params.anio || '2020'
      },
      deep: true
    }
  },

  mounted () {
    console.log('aaaa')
    this.year = this.$route.params.anio || '2020'
    this.obtenerRevision()
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap");

*,
*:after,
*:before {
  box-sizing: border-box;
}

.div-title {
  margin-top: 25px;
  margin-bottom: 20px;
}
.title {
  /* font-size: 20px; */
  color: #052333;
  margin-left: 50px;
  font-weight: 600;
}
.loading {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  margin: 0;
}

.data {
  justify-content: center;
  align-items: center;
}

:root {
  font-size: 16px;
}

body {
  font-family: "Fira Sans", sans-serif;
  line-height: 1.5;
  min-height: 100vh;
  font-size: 1.25rem;
}

*:focus {
  outline: none;
}

body > div {
  width: 90%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

data {
  padding: 2.5em;
}

data > * + * {
  margin-top: 1.5em;
}

details + details {
  margin-top: 20px;
  margin-bottom: 20px;
}

summary {
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

summary {
  padding: 0.75em 1em;
  cursor: pointer;
  position: relative;
  padding-left: calc(1.75rem + 0.75rem + 0.75rem);
  background: #e0e0e0;
  font-weight: 600;
}

details[open] summary {
  background-color: #eee;
}

details[open] summary:before {
  content: "↑";
}

summary:hover {
  background-color: #eee;
}

a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 1px 0 0;
}

a:hover {
  box-shadow: 0 3px 0 0;
}

code {
  font-family: "Fira Sans", sans-serif;
  font-weight: 600;
}
</style>
