<template>
  <div>
    <div class="div-title">
      <h4 class="title">Agregar Usuario A Servicio :</h4>
      <h4 class="nombre">
        {{servicio.nombre}}
      </h4>
    </div>

    <div class="form">
      <div class="error" v-if="errorMsg">
        {{ errorMsg }}
      </div>
      <form>
        <div class="div">
          <div class="text-width">
            <label class="label-text">Perfil</label>
          </div>
          <b-form-select
            class="input"
            v-model="nuevoPermiso.perfil"
            :options="get_profile_options()"
          ></b-form-select>
        </div>
         <div class="div">
          <div class="text-width">
            <label class="label-text">RUT</label>
          </div>

          <input
            v-model="nuevoPermiso.run"
            type="text"
            placeholder="44444444-4"
            class="input"
          />

        </div>
        <div class="div">
          <div class="text-width">
            <label class="label-text">E - mail</label>
          </div>

          <input
            v-model="nuevoPermiso.email"
            type="email"
            placeholder="correo@correo.com"
            class="input"
          />
        </div>
            <div class="boton">
            <b-button  @click="registrarUsuarioServicio" class="crear"
            >Añadir</b-button>
          <!-- <b-button  @click="registrarMultipleServicio" class="crear"
            >Añadir</b-button
          > -->
          <b-button class="btn-cerrar" @click="closeModal">
            Cerrar
          </b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { showAlert } from '@/components/tools/utils'
// import ServicesCheckbox from './ServicesCheckbox.vue'
export default {
  components: {
    // ServicesCheckbox
  },
  data () {
    return {
      nuevoPermiso: {},
      errorMsg: null,
      servicio: null,
      profileOptions: [
        { value: null, text: '- Seleccionar Perfil -' },
        { value: 5, text: 'Funcionario' },
        { value: 4, text: 'Encargado BGI' },
        { value: 3, text: 'Jefe de Servicio' },
        { value: 6, text: 'Representante Ministerial' },
        { value: 2, text: 'Administrador de la DIPRES' },
        { value: 1, text: 'Super Adminstrador', disabled: true }
      ]
    }
  },
  props: [
    'listServices',
    'closeModal',
    'getUsers'
  ],
  mounted () {
    this.nuevoPermiso.service = this.$route.params.idServicio
    this.servicio = this.listServices.find(servicio => { return servicio.id === parseInt(this.$route.params.idServicio) })
  },
  //   watch: {
  //     selectedProfile: function () {
  //       if (this.selectedProfile !== 2 || this.selectedProfile !== 6) {
  //         this.selectedMinister = null
  //       }
  //     }
  //   },
  methods: {
    get_profile_options () {
      return this.profileOptions.filter(option => { return option.value > this.servicio.rolId })
    },
    registrarUsuarioServicio: async function () {
      if (!this.nuevoPermiso.perfil) {
        this.activarError('El perfil es obligatorio')
        return
      }
      if (!this.nuevoPermiso.run) {
        this.activarError('El run es obligatorio')
        return
      }

      axios.post('/instituciones/usuarioservicio/', this.nuevoPermiso).then(res => {
        showAlert({
          icon: 'success',
          text: 'Servicio Agregado Correctamente',
          timerProgressBar: true,
          timer: 3000
        })
      })
    },
    // registrarMultipleServicio: async function () {
    //   if (this.selectedService.length === 0) {
    //     this.activarError('No ha sido seleccionado ningún servicio.')
    //     return
    //   }
    //   const token = localStorage.getItem('userToken')
    //   const { id } = this.datosUsuario

    //   if (!id) return
    //   const reqError = []
    //   let usrObj

    //   this.selectedService.forEach(async (idService) => {
    //     try {
    //       usrObj = {
    //         usuario: id,
    //         servicio: idService,
    //         rol: this.selectedProfile
    //       }

    //       const res = await axios.post(
    //         `${process.env.VUE_APP_RUTA_API}/instituciones/usuarioservicio/`,
    //         usrObj,
    //         {
    //           headers: {
    //             Authorization: `JWT ${token}`
    //           }
    //         }
    //       )
    //       console.log(res)
    //     } catch (error) {
    //       reqError.push(error.response.data[Object.keys(error.response.data)[0]])
    //     }
    //     if (reqError.length === 0) {
    //       showAlert({
    //         icon: 'success',
    //         text: 'Usuario registrado correctamente',
    //         timerProgressBar: true,
    //         timer: 3000
    //       })
    //       this.getUsers()
    //       this.closeModal()
    //     } else {
    //       showAlert({
    //         icon: 'error',
    //         text: reqError.join('\n'),
    //         timerProgressBar: true,
    //         timer: 3000
    //       })
    //     }
    //   })
    // },
    activarError: function (msg) {
      this.errorMsg = `*${msg}`
      setTimeout(() => {
        this.errorMsg = null
      }, 4000)
    }
    // selectedServicesToAdd: function (data) {
    //   this.selectedService = data
    // }
  }
}
</script>

<style scoped>
.row {
  margin: 0;
  text-align: center;
}
.title {
  color: #052333;
  margin: 0 !important;
  font-weight: bold;
}
.nombre {
  font-weight: 200;
  margin: 0 !important;
}
.div-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #24c6ab;
  height: 80px;
}

.form {
  margin: 30px;
}

.div {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  background: #e0e0e0;
  border: none;
  width: 250px;
  font-size: 14px;
  color: #595959;
  padding: 0.4rem;
}

.label-text {
  font-size: 14px;
}
.text-width {
  width: 20%;
}

.boton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}
.crear {
  background: #0069d9;
  border: none;
  border-radius: 40px;
  margin: 0 20px;
}
.btn-cerrar {
  background: #e73f4b;
  border-radius: 40px;
  border: none;
  margin: 0 20px;
}

.cnt-error {
  display: flex;
  justify-content: center;
}

.error {
  color: #c23535;
  font-size: 0.8rem;
  text-align: center;
}
</style>
