<template>
    <tbody>
        <tr v-for="item in objetivos.lista" :key="item.numero">
            <td>{{ item.numero }}</td>
            <td>{{ item.nombre }}</td>
            <td>{{ item.meta }}</td>
            <td>{{ item.efectivo }}</td>
            <td>{{ item.cumplimiento }}</td>
            <td>{{ item.ponderacion }}%</td>
            <td>{{ item.ponderacion_final }}%</td>
        </tr>
    </tbody>

</template>

<script>
export default {
  props: ['objetivos']

}
</script>

<style>

</style>
