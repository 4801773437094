<template>
  <div class="container">
    <b-row>
      <b-col>
        <Visualizar />
      </b-col>
    </b-row>

    <b-row class="row-button">
      <b-col class="one"> <Help /> </b-col>
      <b-col class="btn-direction-flex">
        <b-button
          pill
          variant="primary"
          class="btn-one"
          @click="guardarBorrador"
          >Guardar como borrador</b-button
        >
        <b-button
          pill
          variant="primary"
          class="btn-two"
          @click="guardarFinalizado"
          >Guardar y finalizar</b-button
        >
      </b-col>
    </b-row>

    <b-row>
      <b-col class="title">
        <h5>Presentación del Ministro - Avance de la Gestión</h5>
      </b-col>
    </b-row>

    <b-row>
      <CkEditorText :modifyContent="modifyContent" :borrador="borrador" />
    </b-row>
  </div>
</template>

<script>
import Visualizar from '@/components/button/escritorio/Visualizar.vue'
import Help from '@/components/button/escritorio/Help.vue'
import CkEditorText from '@/components/private/CkEditorText'
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import { showAlert } from '@/components/tools/utils'
export default {
  components: {
    Visualizar,
    Help,
    CkEditorText
  },
  data () {
    return {
      content: '',
      nombre: 'avance_gestion',
      borrador: ''
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])
  },
  methods: {
    modifyContent: function (text) {
      this.content = text
    },
    buildData: function (type) {
      return {
        nombre: this.nombre,
        elaboracion: type,
        data: {
          content: this.content
        },
        anio: process.env.VUE_APP_ANIO,
        idServicio: this.$route.params.idServicio
      }
    },
    guardarBorrador: async function () {
      try {
        const toSend = this.buildData('borrador')

        await axios.post(
          `${process.env.VUE_APP_RUTA_API}/instituciones/secciones/`,
          toSend,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('userToken')}`
            }
          }
        )
        showAlert({
          icon: 'success',
          text: 'Guardado como Borrador Exitoso',
          timerProgressBar: true,
          timer: 3000
        })
      } catch (error) {
        if (error.response.status === 422) {
          const toSend = {
            nombre: this.nombre,
            elaboracion: 'borrador',
            data: {
              content: this.content
            },
            anio: process.env.VUE_APP_ANIO
          }

          await axios.put(
            `${process.env.VUE_APP_RUTA_API}/instituciones/secciones/${this.$route.params.idServicio}/`,
            toSend,
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem('userToken')}`
              }
            }
          )
          showAlert({
            icon: 'success',
            text: 'Guardado como Borrador Exitoso',
            timerProgressBar: true,
            timer: 3000
          })
        } else {
          console.log(error)
          showAlert({
            icon: 'error',
            text: error.response.data[Object.keys(error.response.data)[0]],
            timerProgressBar: true,
            timer: 3000
          })
        }
      }
    },
    guardarFinalizado: async function () {
      Swal.fire({
        title: 'Guardar y Finalizar',
        text: '¿Desea guardar el documento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const toSend = this.buildData('finalizado')

            await axios.post(
              `${process.env.VUE_APP_RUTA_API}/instituciones/secciones/`,
              toSend,
              {
                headers: {
                  Authorization: `JWT ${localStorage.getItem('userToken')}`
                }
              }
            )
            showAlert({
              icon: 'success',
              text: 'Guardando documento',
              timerProgressBar: true,
              timer: 3000
            })
          } catch (error) {
            if (error.response.status === 422) {
              const toSend = {
                nombre: this.nombre,
                elaboracion: 'finalizado',
                data: {
                  content: this.content
                },
                anio: process.env.VUE_APP_ANIO
              }

              await axios.put(
                `${process.env.VUE_APP_RUTA_API}/instituciones/secciones/${this.$route.params.idServicio}/`,
                toSend,
                {
                  headers: {
                    Authorization: `JWT ${localStorage.getItem('userToken')}`
                  }
                }
              )
              showAlert({
                icon: 'success',
                text: 'Guardando documento',
                timerProgressBar: true,
                timer: 3000
              })
            } else {
              console.log(error)
              showAlert({
                icon: 'error',
                text: error.response.data[Object.keys(error.response.data)[0]],
                timerProgressBar: true,
                timer: 3000
              })
            }
          }
          this.$router.push({
            name: 'bgi',
            params: { idUsuario: this.usuario.id }
          })
        }
      })
    },
    obtenerBorrador: async function () {
      try {
        const obj = {
          anio: process.env.VUE_APP_ANIO,
          nombre: this.nombre
        }
        const res = await axios.get(
          `${process.env.VUE_APP_RUTA_API}/instituciones/secciones/${this.$route.params.idServicio}/?anio=${obj.anio}&nombre=${obj.nombre}`,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('userToken')}`
            }
          }
        )

        if (res.data) {
          res.data.info = res.data.info || {}
          this.borrador = res.data.info.content
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted () {
    this.obtenerBorrador()
  }
}
</script>

<style scoped>
.row {
  margin: 0;
}
.btn-one {
  margin-right: 20px;
}

.btn-direction-flex {
  text-align: end;
}

.title {
  margin-top: 20px;
  margin-bottom: 21px;
  text-align: center;
  color: #052333;
}

.title > h5 {
  font-weight: bold;
}

.row-button {
  margin-bottom: 30px;
}
@media screen and (max-width: 784px) {
  .btn-one {
    font-size: 11px;
    margin-right: 5px;
  }
  .btn-two {
    font-size: 11px;
  }
}

@media screen and (max-width: 569px) {
  .btn-one {
    font-size: 10px;
    margin-right: 5px;
    width: 90px;
  }
  .btn-two {
    font-size: 10px;
    width: 90px;
  }
}

@media screen and (max-width: 417px) {
  .one {
    display: none;
  }
  .btn-one {
    font-size: 10px;
    margin-right: 5px;
    width: 90px;
    margin-bottom: 15px;
  }
  .btn-two {
    font-size: 10px;
    width: 90px;
    position: relative;
    top: -6px;
  }
  .btn-direction-flex {
    text-align: start;
    margin-left: 30px;
  }
}
</style>
