<template>
  <div class="div">
    <b-row>
      <b-col class="logo"><Logo /></b-col>

      <b-col class="hacienda"><Hacienda /></b-col>
    </b-row>
  </div>
</template>

<script>
import Logo from '@/components/image/navbar/Logo.vue'
import Hacienda from '@/components/image/navbar/Hacienda.vue'
export default {
  components: {
    Logo,
    Hacienda
  }
}
</script>

<style scoped>
.div {
  display: flex;
}

.logo {
  margin-top: 8px;
}

@media screen and (max-width: 1003px) {
  .div {
    display: flex;
    margin-left: 30px;
  }
}

@media screen and (max-width: 763px) {
  .div {
    margin-right: -147px;
  }
}

@media screen and (max-width: 410px) {
  .div {
    margin-left: 0px;
  }

  .hacienda {
    display: none;
  }
}
</style>
