<template>
  <section>
    <div class="main-text">
      <h6>
        No hay datos en este anexo.
      </h6>
    </div>
  </section>
</template>
<script>
export default {
  components: {
  },
  data () {
    return {

    }
  },
  props: ['listData']
}
</script>
<style>
.main-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b8f4e9;
  height: 50px;
}
.main-text > h6 {
  font-weight: bold;
  color: #515151;
  margin: 0;
  text-align: center;
  margin: 15px;
}
</style>
