<template>
  <div>
    <b-button variant="link" class="modal-button" id="show-btn" @click="$bvModal.show('bv-modal-example')">
      <Config /> {{ button }}
    </b-button>
    <b-modal id="bv-modal-example" hide-footer>
      <template #modal-title><h4 class="title-modal">{{ title }}</h4></template>

      <div class="container" style="margin-top:5px">
        Presentar los desafíos institucionales más relevantes para el período de gobierno 2022-2026 y que, en opinión de la actual Dirección del Servicio, resulte altamente recomendable abordar. Para estos efectos, se priorizarán aquellas iniciativas que tengan un efecto directo en el cumplimiento de las funciones legales del Servicio o en los usuarios, beneficiarios o ciudadanos.
        <br><br>
        Para esto, se deben describir brevemente los desafíos recomendados para el siguiente período, priorizando aquellos que pudiesen estar relacionados con:
        <ul>
          <li>Fortalecer alguna función del esencial del Servicio, para lo cual sea necesario realizar algún cambio normativo, generar una nueva iniciativa o programa, introducir mayor inteligencia en los servicios mediante tecnología, cubrir algún área que actualmente no se está abordando, entre otras.</li>
          <li>Mejorar, modernizar u optimizar aspectos relacionados a las áreas de soporte de la institución que permitirán una mayor eficiencia y/o eficacia en la gestión del Servicio.</li>
          <li>Iniciativas, proyectos o programas de alta importancia y que se encuentran actualmente en ejecución, para los cuales se recomienda mantener los esfuerzos institucionales que garanticen su correcta ejecución.</li>
          <li>Otros que se consideren relevantes.</li>
        </ul>
        En caso de que lo amerite, el desafío recomendado se debe sustentar a través de alguna evidencia objetiva. Para esto, es altamente recomendable que sea sustentado mediante antecedentes cuantitativos, apoyados en tablas o gráficos, y que señale los recursos que se destinarán en concordancia con aquellos aprobados en la Ley de Presupuestos 2024, o que se requerirán en los años del período 2022-2026.
        </div>

    </b-modal>
  </div>
</template>

<script>
import Config from '@/components/image/icon/Config'
export default {
  props: ['html'],
  components: {
    Config
  },
  data () {
    return {
      button: 'Instrucciones: ¿Cómo completar esta sección?'
    }
  }
}
</script>

<style scoped>
.modal-button {
  display: flex;
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.modal-button:hover {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.modal-button:focus {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.title-modal {
  color: #052333;
  font-size: 20px;
  text-align: center;
}

</style>
