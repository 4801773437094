<template>
  <div>
    <div class="home">
      <Navbar />
    </div>

    <div>
      <Contents />
    </div>

    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Contents from './components/Contents.vue'
import Footer from '../../../components/layout/Footer.vue'
export default {
  components: {
    Navbar,
    Contents,
    Footer
  },
  mounted () {
    this.$store.commit('setSelectedMainView', 'people')
  }
}
</script>
