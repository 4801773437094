<template>
  <div class="menu-button">
    <b-row>
      <b-container>
        <div class="navbar-buttons">
            <b-button variant="link" :class="`nav-button ${privateView === 'gestion.gob' ? 'selected-view' : ''}`"
            :to="{ name: 'gestion' }"
            @click="seleccionarVista('gestion.gob')">Volver a Gestion.gob
            </b-button>

            <b-button variant="link" :class="`nav-button ${privateView === 'portada-bgi' ? 'selected-view' : ''}`"
            :to="{ name: 'bgi', params: { idUsuario: usuario.id } }"
            @click="seleccionarVista('portada-bgi')">
            Portada BGI
            </b-button>

            <b-button v-if="isAdmin" variant="link" :class="`nav-button ${privateView === 'settings' ? 'selected-view' : ''}`"
                :to="{name: 'accesoAdmin', params: { idServicio: 31, idUsuario: this.usuario.id },}"
                @click="seleccionarVista('settings')">
                <span class="config">Ver Usuarios Registrados</span>
            </b-button>

            <b-button v-show="1===2" class="cffm">
                variant="link" :class="`nav-button ${privateView === 'historial' ? 'selected-view' : ''}`"@click="redireccionarInst()"><span class="config">Histórico</span>
            </b-button>

            <b-button variant="link" :class="`nav-button ${privateView === 'antecedentes' ? 'selected-view' : ''}`"
                :to="{ name: 'antecedente' }"
                @click="seleccionarVista('antecedentes')">
                Antecedentes para la elaboración
            </b-button>

            <b-button v-if="isAdmin" :class="`nav-button ${privateView === 'comentarios' ? 'selected-view' : ''}`" variant="link"
                :to="{ name: 'comentario' }"
                @click="seleccionarVista('comentarios')">
                Comentarios
            </b-button>
        </div>
      </b-container>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapGetters(['privateView']),
    ...mapState('sesion', ['usuario']),
    isAdmin: function () {
      return this.usuario.username === process.env.VUE_APP_USER_ADMIN
    }
  },
  methods: {
    seleccionarVista (nombre) {
      this.$store.commit('setPrivateView', nombre)
    },
    redireccionarInst: function () {
      const routeData = this.$router.resolve({
        name: 'instituciones'
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.row {
  margin: 0;
  text-align: initial;
  background: #e0e0e0;
  height: 70px;
}

.btn-link {
  text-decoration: none !important;
  font-weight: 200;
}
.btn {
  color: #515151;
  border: none;
  font-size: 16px;
  height: 100%;
  border-radius: 0;
}

.btn:hover {
  border: none;
  color: #052333;
  background: #24c6ab;
  font-weight: 100;
  height: 70px;
}

.selected-view {
  border: none;
  color: #052333;
  background: #24c6ab;
  font-weight: 100;
  height: 100%;
  height: 70px;
}
.navbar-buttons {
  display: flex;
  flex-direction: row;
  height: 60px;
}
.nav-button {
  display: flex;
  align-items: center;
  max-width: 190px;
}

@media screen and (max-width: 1500px) {
  .row {
    height: 60px;
  }

  .btn {
    font-size: 16px;
  }

  .btn:hover {
    height: 60px;
  }

  .selected-view {
    height: 60px;
  }
}

@media screen and (max-width: 900px) {
  .menu-button {
    display: none;
  }
}
</style>
