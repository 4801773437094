<template>
  <div class="tables">
    <div v-if="!avances" style="display: flex; justify-content: center; align-items: center; margin: 60px 0;">
      <Loading />
    </div>
    <table v-if="avances" class="table table-striped table-resumen-servicios">
      <thead>
        <tr>
          <th>Ministerio</th>
          <th>Servicio</th>
          <th>Estado</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="servicio in avances_filtrados()" :key="servicio.id">
          <td class="td-datos">{{ servicio.ministerio }}</td>
          <td class="td-datos">{{ servicio.nombre }}</td>
          <td class="td-datos">{{servicio.estado_str}}</td>
          <td>
            <div class="actions-buttons">
              <b-button class="bgi-buttons" @click="goToDesktop(servicio)" v-b-tooltip.hover.left :title="setPermissions(servicio).tooltipMsg">
                <i :class="setPermissions(servicio).icon"></i>
              </b-button>
              <b-button class="bgi-buttons" v-b-tooltip.hover.left title="Configuraciones" v-if="showButton(servicio)" @click="redirect(servicio)">
                <i class="bx bx-wrench"></i>
              </b-button>
              <b-button v-if="showButton(servicio)" class="bgi-buttons" v-b-tooltip.hover.left title="Descargar PDF" :href="getUrl(servicio)" target="_blank">
                <i class="bx bx-download"></i>
              </b-button>
              <b-button v-if="estadoButton(servicio)" @click="approveReject('aprobado', servicio)" class="bgi-buttons" v-b-tooltip.hover.left title="Aprobar">
                <i class="bx bx-check"></i>
              </b-button>
              <b-button v-if="estadoButtonRechazar(servicio)" @click="approveReject('rechazado', servicio)" class="bgi-buttons" v-b-tooltip.hover.left title="Rechazar">
                <i class="bx bx-x"></i>
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal v-model="commentsModal" hide-footer hide-header>
      <MsgModal :revisionData="revisionData" :closeCommentModal="closeCommentModal" :actualizar="actualizar" />
    </b-modal>
  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'

import axios from 'axios'
import Loading from '@/components/tools/Loading.vue'
import { showAlert } from '@/components/tools/utils'
import MsgModal from './MsgModal.vue'
import Swal from 'sweetalert2'
export default {
  mixins: [utils],
  props: ['toSearch'],

  components: {
    MsgModal,
    Loading
  },
  data () {
    return {
      commentsModal: false,
      revisionData: null
    }
  },
  methods: {
    avances_filtrados () {
      return this.avances.filter(s => { return s.nombre.toLowerCase().includes(this.toSearch.toLowerCase()) }
      )
    },
    actualizar () {
      this.avances = null
      this.get_avances()
    },
    getUrl (item) {
      return `${process.env.VUE_APP_RUTA_API}/instituciones/servicios/${item.id}/pdf_generate/?anio=${process.env.VUE_APP_ANIO}`
    },

    setPermissions: function (servicio) {
      const objRet = {}
      if (servicio.estado == 'encargado_bgi') {
        objRet.tooltipMsg = 'Completar'
        objRet.icon = 'bx bx-edit-alt'
        return objRet
      }
      objRet.tooltipMsg = 'Revisar'
      objRet.icon = 'bx bx-show'

      return objRet
    },
    // hide button from official
    showButton: function (item) {
      if (this.usuario.username === process.env.VUE_APP_USER_ADMIN) return true

      if (item.rol !== 'funcionario') {
        return true
      } else {
        return false
      }
    },
    // redirect to desktop by service id
    goToDesktop (item) {
      this.$router.push({
        name: 'buttons',
        params: {
          idUsuario: this.$route.params.idUsuario,
          idServicio: item.id
        }
      })
    },
    // redirect to cobnfig by service id
    redirect: function (item) {
      if (item.rol === 'funcionario') return
      this.$router.push({
        name: 'acceso',
        params: {
          idServicio: item.id,
          idUsuario: this.$route.params.idUsuario
        }
      })
    },
    // show or hide button depending on the review status aprove
    estadoButton: function (servicio) {
    //   return true
      if (servicio.rol === 'funcionario') {
        return false
      } else if (
        servicio.rol === 'encargado_bgi' &&
        servicio.estado === 'encargado_bgi'
      ) {
        return true
      } else if (
        servicio.rol === 'encargado_bgi' &&
        servicio.estado === 'Sin registro'
      ) {
        return true
      } else if (
        servicio.rol === 'jefe_servicio' &&
        servicio.estado === 'jefe_servicio'
      ) {
        return true
      } else if (
        servicio.rol === 'representante_ministerial' &&
        servicio.estado === 'representante_ministerial'
      ) {
        return true
      } else if (
        (servicio.rol === 'administrador' || servicio.rol === 'superadministrador') &&
        servicio.estado === 'administrador'
      ) {
        return true
      } else if (
        servicio.rol === 'superadministrador' &&
        servicio.estado === 'superadministraddor'
      ) {
        return true
      } else {
        return false
      }
    },
    // show or hide button depending on the review status refuse
    estadoButtonRechazar: function (item) {
    //   return true
      if (item.rol === 'funcionario') {
        return false
      } else if (
        item.rol === 'encargado_bgi'
      ) {
        return false
      } else if (
        item.rol === 'jefe_servicio' &&
        item.estado === 'jefe_servicio'
      ) {
        return true
      } else if (
        item.rol === 'representante_ministerial' &&
        item.estado === 'representante_ministerial'
      ) {
        return true
      } else if (
        (item.rol === 'administrador' || item.rol === 'superadministrador') &&
        item.estado === 'administrador'
      ) {
        return true
      } else if (
        item.rol === 'superadministrador' &&
        item.estado === 'superadministraddor'
      ) {
        return true
      } else {
        return false
      }
    },
    // download pdf
    descargarPdf: async function (item) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 15000,
        timerProgressBar: true
      })
      try {
        Toast.fire({
          icon: 'success',
          title: 'El PDF se está generando (puede tardar unos 45 segundos)'
        })

        const res = await axios.get(
          `/instituciones/servicios/${item.id}/pdf_generate/?anio=${this.anioBgi}`,
          {
            responseType: 'blob',
            headers: {
              Authorization: `JWT ${localStorage.getItem('userToken')}`
            }
          }
        )

        var blob = new Blob([res.data], { type: 'application/pdf' })

        var URL = window.URL || window.webkitURL
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.target = '_blank'
        link.href = url
        link.setAttribute(
          'download',
          `BGI_${item.servicio}_${this.anioBgi}.pdf`
        )

        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)
        URL.revokeObjectURL(url)
      } catch (error) {
        Toast.close()
        showAlert({
          icon: 'error',
          text: 'Ha ocurrido un error en la generación del PDF.',
          timerProgressBar: true,
          timer: 3000
        })
      }
    },

    closeCommentModal: function () {
      this.commentsModal = false
    },

    approveReject: async function (type, item) {
      this.commentsModal = true
      console.log('Hola mundo')
      this.revisionData = {
        tipo: type,
        anio: this.anioBgi,
        idServicio: item.id,
        mensaje: ''
      }
    }
  },
  mounted () {

  }
}
</script>

<style scoped>
.actions-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.bgi-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #052333;
  color: #fff;
}

.bgi-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #052333;
  color: #fff;
  width: 35px;
  height: 35px;
  font-size: 17px;
  border-radius: 50%;
  margin-right: 5px;
}

.bgi-buttons:hover {
  background: #052333;
  color: #fff;
}

@media screen and (max-width: 1800px) {
  .bgi-buttons {
    width: 33px;
    height: 33px;
    font-size: 15px;
  }
}

@media screen and (max-width: 1600px) {
  .bgi-buttons {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }
}

@media screen and (max-width: 1600px) {
  .bgi-buttons {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }
}

@media screen and (max-width: 1195px) {
  .bgi-buttons {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1195px) {
  .bgi-buttons {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
}
.table-resumen-servicios td {
  text-align: left;
}
</style>
