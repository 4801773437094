<template>
  <div>
    <b-button squared class="one">Resumen Ejecutivo</b-button>
    <!--<b-button squared class="two" :to="{ name: 'somos' }"
      >Quiénes Somos
    </b-button>-->
    <b-button squared class="two" :to="{ name: 'pricipalesAutoridades' }"
      >Principales-Autoridades</b-button
    >
  </div>
</template>

<script>
export default {
  methods: {
    seleccionar (nombre) {
      this.$router.push({ name: nombre })
    }
  }
}
</script>

<style scoped>
.one {
  background: #052333 !important;
  color: white !important;
  border: none;
  font-weight: 700 !important;
  max-width: 126px;
  max-height: 70px;
  font-size: 16px;
  margin-left: 30px;
}

.two {
  color: #052333;
  background: #efefef;
  border: none;
  max-width: 126px;
  max-height: 70px;
  font-size: 16px;
}

.two:hover {
  background: #24c6ab;
  color: #052333;
}

@media screen and (max-width: 1000px) {
  .one {
    width: 80px;
    height: 50px;
    font-size: 11px;
    margin-left: 30px;
  }

  .two {
    width: 80px;
    height: 50px;
    font-size: 11px;
  }
}
</style>
