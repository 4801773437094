<template>
  <div class="i-card" variant="link">
    <div class="separator"></div>

    <p class="titulo">{{ title }}</p>
    <button class="go-institution" @click="redirigir" href="">
      Ir a {{ tipo_servicio }}
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['title', 'id_servicio', 'tipo_servicio'],
  computed: {
    ...mapGetters(['typeService'])
  },
  methods: {
    redirigir: async function () {
      if (this.typeService === 'ministerios') {
        this.$router.push({
          name: 'instituciones-by-ministry',
          params: { id: this.id_servicio }
        })
      } else {
        this.$router.push({
          name: 'people-institucion',
          params: { id: this.id_servicio, anio: 2020 }
        })
      }
    }
  }
}
</script>
<style>
.i-card {
  padding: 0.8rem;
  padding-bottom: 3rem;
  margin: 1rem;
  width: 210px;
  height: 250px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dddddd;
}

.i-card:hover {
  box-shadow: 0px 0px 17px -5px rgba(0, 0, 0, 0.75);
}
.separator {
  height: 2px;
  width: 70%;
  background-color: #24c6ab;
  margin-bottom: 1rem;
}
.titulo {
  font-weight: 600;
  text-decoration: none;
  position: relative;
  text-decoration: none;
  text-align: center;
  color: #052333;
}
.go-institution {
  cursor: pointer;
  background-color: #24c6ab;
  color: white;
  padding: 4px 12px;
  border: none;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 50px;
}

/* .titulo:after {
  position: absolute;
  content: "";
  height: 4px;
  bottom: -10px;
  margin: 0 auto;
  left: 0;
  right: 10;
  width: 20%;
  background: #38b37a;
} */
</style>
