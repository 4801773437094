<template>
  <div>
    <div class="home">
      <Navbar />
      <Header
        titulo="BGI 2023"
        desc="Balances de Gestión Integral del año 2023"
        :showYearSelect="false"
        :showDownloadButton="false"
      />
    </div>
        <div class="container" style="min-height:500px">
            <div class="container-box">
                <br>
                <br>
                <h4>En esta sección podrá descargar los Balance de Gestión Integral del año <b>2023</b></h4>
                <br>
                <br>

                <select class="form-control" v-model="ministerioSel" placeholder="Seleccione Ministerio">
                    <option value="0" disabled selected>Seleccione Ministerio</option>
                    <option class="list-group-item" v-for="ministerio in getMinisterios()" :key="ministerio">
                        {{ministerio}}
                    </option>
                </select>

                <br><br>
                <h2 v-if="ministerioSel!=='0'">{{ministerioSel}}</h2>
                <br>
                <br>
                <ul class="list-group">
                    <li class="list-group-item" v-for="servicio in getServicios(ministerioSel)" :key="servicio">
                        <a :href="`https://github.com/pdiaza/BGI/raw/main/2023/${servicio.archivo}`">BGI {{servicio.Servicio}}</a>
                    </li>
                </ul>
                <br>
                <br>
                <br>
            </div>
        </div>
      <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from '../../components/cabecera/Header.vue'
// import Contents from './components/Contents.vue'
import Footer from '../../components/layout/Footer.vue'
export default {
  components: {
    Navbar,
    // Contents,
    Header,
    Footer
  },
  data () {
    return {
      ministerioSel: '0',
      servicios: [
        {
          Ministerio: 'Presidencia de la República',
          Servicio: 'Dirección Administrativa Presidencia de la República',
          archivo: '2_Dirección Administrativa Presidencia de la República.pdf'
        },
        {
          Ministerio: 'Ministerio del Interior y Seguridad Pública',
          Servicio: 'Servicio de Gobierno Interior',
          archivo: '3_Servicio de Gobierno Interior.pdf'
        },
        {
          Ministerio: 'Ministerio del Interior y Seguridad Pública',
          Servicio: 'Oficina Nacional de Emergencia',
          archivo: '4_Oficina Nacional de Emergencia.pdf'
        },
        {
          Ministerio: 'Ministerio del Interior y Seguridad Pública',
          Servicio: 'Subsecretaría de Desarrollo Regional y Administrativo',
          archivo: '5_Subsecretaría de Desarrollo Regional y Administrativo.pdf'
        },
        {
          Ministerio: 'Ministerio del Interior y Seguridad Pública',
          Servicio: 'Agencia Nacional de Inteligencia',
          archivo: '6_Agencia Nacional de Inteligencia.pdf'
        },
        {
          Ministerio: 'Ministerio del Interior y Seguridad Pública',
          Servicio: 'Subsecretaría de Prevención del Delito',
          archivo: '7_Subsecretaría de Prevención del Delito.pdf'
        },
        {
          Ministerio: 'Ministerio del Interior y Seguridad Pública',
          Servicio: 'Servicio Nacional Para Prevención y Rehabilitación Consumo de Drogas y Alcohol',
          archivo: '8_Servicio Nacional Para Prevención y Rehabilitación Consumo de Drogas y Alcohol.pdf'
        },
        {
          Ministerio: 'Ministerio del Interior y Seguridad Pública',
          Servicio: 'Subsecretaría del Interior',
          archivo: '9_Subsecretaría del Interior.pdf'
        },
        {
          Ministerio: 'Ministerio de Relaciones Exteriores',
          Servicio: 'Secretaría y Administración General y Servicio Exterior',
          archivo: '26_Secretaría y Administración General y Servicio Exterior.pdf'
        },
        {
          Ministerio: 'Ministerio de Relaciones Exteriores',
          Servicio: 'Dirección de Fronteras y Límites del Estado',
          archivo: '28_Dirección de Fronteras y Límites del Estado.pdf'
        },
        {
          Ministerio: 'Ministerio de Relaciones Exteriores',
          Servicio: 'Instituto Antártico Chileno',
          archivo: '29_Instituto Antártico Chileno.pdf'
        },
        {
          Ministerio: 'Ministerio de Relaciones Exteriores',
          Servicio: 'Agencia de Cooperación Internacional de Chile',
          archivo: '30_Agencia de Cooperación Internacional de Chile.pdf'
        },
        {
          Ministerio: 'Ministerio de Relaciones Exteriores',
          Servicio: 'Subsecretaria de Relaciones Económicas Internacionales',
          archivo: '31_Subsecretaria de Relaciones Económicas Internacionales.pdf'
        },
        {
          Ministerio: 'Ministerio de Relaciones Exteriores',
          Servicio: 'Dirección General de Promoción de Exportaciones',
          archivo: '32_Dirección General de Promoción de Exportaciones.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Subsecretaría de Economía y Empresas de Menor Tamaño',
          archivo: '33_Subsecretaría de Economía y Empresas de Menor Tamaño.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Servicio Nacional del Consumidor',
          archivo: '34_Servicio Nacional del Consumidor.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Subsecretaría de Pesca y Acuicultura',
          archivo: '35_Subsecretaría de Pesca y Acuicultura.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Servicio Nacional de Pesca y Acuicultura',
          archivo: '36_Servicio Nacional de Pesca y Acuicultura.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Corporación de Fomento de la Producción',
          archivo: '37_Corporación de Fomento de la Producción.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Instituto Nacional de Estadísticas',
          archivo: '38_Instituto Nacional de Estadísticas.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Fiscalía Nacional Económica',
          archivo: '39_Fiscalía Nacional Económica.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Servicio Nacional de Turismo',
          archivo: '40_Servicio Nacional de Turismo.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Servicio de Cooperación Técnica',
          archivo: '41_Servicio de Cooperación Técnica.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Comité Innova Chile',
          archivo: '42_Comité Innova Chile.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Agencia de Promoción de la Inversión Extranjera',
          archivo: '43_Agencia de Promoción de la Inversión Extranjera.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Instituto Nacional de Propiedad Industrial',
          archivo: '44_Instituto Nacional de Propiedad Industrial.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Subsecretaría de Turismo',
          archivo: '45_Subsecretaría de Turismo.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Superintendencia de Insolvencia y Reemprendimiento',
          archivo: '46_Superintendencia de Insolvencia y Reemprendimiento.pdf'
        },
        {
          Ministerio: 'Ministerio de Economía, Fomento y Turismo',
          Servicio: 'Instituto Nacional de Desarrollo Sustentable de Pesca Artesanal y de Acuicultura',
          archivo: '47_Instituto Nacional de Desarrollo Sustentable de Pesca Artesanal y de Acuicultura.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Secretaría y Administración General, Min. Hacienda',
          archivo: '48_Secretaría y Administración General, Min. Hacienda.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Dirección de Presupuestos',
          archivo: '49_Dirección de Presupuestos.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Servicio de Impuestos Internos',
          archivo: '50_Servicio de Impuestos Internos.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Servicio Nacional de Aduanas',
          archivo: '51_Servicio Nacional de Aduanas.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Servicio de Tesorerías',
          archivo: '52_Servicio de Tesorerías.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Dirección de Compras y Contratación Pública',
          archivo: '53_Dirección de Compras y Contratación Pública.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Dirección Nacional del Servicio Civil',
          archivo: '55_Dirección Nacional del Servicio Civil.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Unidad de Análisis Financiero',
          archivo: '56_Unidad de Análisis Financiero.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Superintendencia de Casinos de Juego',
          archivo: '57_Superintendencia de Casinos de Juego.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Consejo de Defensa del Estado',
          archivo: '58_Consejo de Defensa del Estado.pdf'
        },
        {
          Ministerio: 'Ministerio de Hacienda',
          Servicio: 'Comisión para el Mercado Financiero',
          archivo: '59_Comisión para el Mercado Financiero.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Subsecretaría de Educación',
          archivo: '60_Subsecretaría de Educación.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Superintendencia de Educación',
          archivo: '61_Superintendencia de Educación.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Agencia de Calidad de la Educación',
          archivo: '62_Agencia de Calidad de la Educación.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Subsecretaría de Educación Parvularia',
          archivo: '63_Subsecretaría de Educación Parvularia.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Junta Nacional de Auxilio Escolar y Becas',
          archivo: '65_Junta Nacional de Auxilio Escolar y Becas.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Junta Nacional de Jardines Infantiles',
          archivo: '66_Junta Nacional de Jardines Infantiles.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Consejo Nacional de Educación',
          archivo: '67_Consejo Nacional de Educación.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Dirección de Educación Pública',
          archivo: '68_Dirección de Educación Pública.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Barrancas',
          archivo: '69_Servicio Local de Educación Barrancas.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Puerto Cordillera',
          archivo: '70_Servicio Local de Educación Puerto Cordillera.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Huasco',
          archivo: '71_Servicio Local de Educación Huasco.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Costa Araucanía',
          archivo: '72_Servicio Local de Educación Costa Araucanía.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Chinchorro',
          archivo: '73_Servicio Local de Educación Chinchorro.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Gabriela Mistral',
          archivo: '74_Servicio Local de Educación Gabriela Mistral.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Andalién  Sur',
          archivo: '75_Servicio Local de Educación Andalién  Sur.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Subsecretaría de Educación Superior',
          archivo: '76_Subsecretaría de Educación Superior.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Superintendencia de Educación Superior',
          archivo: '77_Superintendencia de Educación Superior.pdf'
        },
        {
          Ministerio: 'Ministerio de Justicia y Derechos Humanos',
          Servicio: 'Secretaría y Administración General, Min. Justicia',
          archivo: '78_Secretaría y Administración General, Min. Justicia.pdf'
        },
        {
          Ministerio: 'Ministerio de Justicia y Derechos Humanos',
          Servicio: 'Servicio de Registro Civil e Identificación',
          archivo: '79_Servicio de Registro Civil e Identificación.pdf'
        },
        {
          Ministerio: 'Ministerio de Justicia y Derechos Humanos',
          Servicio: 'Servicio Médico Legal',
          archivo: '80_Servicio Médico Legal.pdf'
        },
        {
          Ministerio: 'Ministerio de Justicia y Derechos Humanos',
          Servicio: 'Gendarmería de Chile',
          archivo: '81_Gendarmería de Chile.pdf'
        },
        {
          Ministerio: 'Ministerio de Justicia y Derechos Humanos',
          Servicio: 'Subsecretaría de Derechos Humanos',
          archivo: '82_Subsecretaría de Derechos Humanos.pdf'
        },
        {
          Ministerio: 'Ministerio de Justicia y Derechos Humanos',
          Servicio: 'Servicio Nacional de Menores',
          archivo: '83_Servicio Nacional de Menores.pdf'
        },
        {
          Ministerio: 'Ministerio de Justicia y Derechos Humanos',
          Servicio: 'Defensoría Penal Pública',
          archivo: '84_Defensoría Penal Pública.pdf'
        },
        {
          Ministerio: 'Ministerio de Defensa Nacional',
          Servicio: 'Dirección General de Movilización Nacional',
          archivo: '85_Dirección General de Movilización Nacional.pdf'
        },
        {
          Ministerio: 'Ministerio de Defensa Nacional',
          Servicio: 'Instituto Geográfico Militar',
          archivo: '86_Instituto Geográfico Militar.pdf'
        },
        {
          Ministerio: 'Ministerio de Defensa Nacional',
          Servicio: 'Servicio Hidrográfico y Oceanográfico de la Armada de Chile',
          archivo: '87_Servicio Hidrográfico y Oceanográfico de la Armada de Chile.pdf'
        },
        {
          Ministerio: 'Ministerio de Defensa Nacional',
          Servicio: 'Dirección General de Aeronáutica Civil',
          archivo: '88_Dirección General de Aeronáutica Civil.pdf'
        },
        {
          Ministerio: 'Ministerio de Defensa Nacional',
          Servicio: 'Servicio Aerofotogramétrico de la Fuerza Aérea de Chile',
          archivo: '89_Servicio Aerofotogramétrico de la Fuerza Aérea de Chile.pdf'
        },
        {
          Ministerio: 'Ministerio de Defensa Nacional',
          Servicio: 'Subsecretaría para las Fuerzas Armadas',
          archivo: '90_Subsecretaría para las Fuerzas Armadas.pdf'
        },
        {
          Ministerio: 'Ministerio de Defensa Nacional',
          Servicio: 'Subsecretaría de Defensa',
          archivo: '91_Subsecretaría de Defensa.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Secretaría y Administración General, Min. OOPP',
          archivo: '92_Secretaría y Administración General, Min. OOPP.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Dirección General de Concesiones de Obras Públicas',
          archivo: '94_Dirección General de Concesiones de Obras Públicas.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Dirección General de Aguas',
          archivo: '95_Dirección General de Aguas.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Instituto Nacional de Hidráulica',
          archivo: '96_Instituto Nacional de Hidráulica.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Superintendencia de Servicios Sanitarios',
          archivo: '97_Superintendencia de Servicios Sanitarios.pdf'
        },
        {
          Ministerio: 'Ministerio de Agricultura',
          Servicio: 'Subsecretaría de Agricultura',
          archivo: '98_Subsecretaría de Agricultura.pdf'
        },
        {
          Ministerio: 'Ministerio de Agricultura',
          Servicio: 'Oficina de Estudios y Políticas Agrarias',
          archivo: '99_Oficina de Estudios y Políticas Agrarias.pdf'
        },
        {
          Ministerio: 'Ministerio de Agricultura',
          Servicio: 'Instituto de desarrollo Agropecuario',
          archivo: '100_Instituto de desarrollo Agropecuario.pdf'
        },
        {
          Ministerio: 'Ministerio de Agricultura',
          Servicio: 'Servicio Agrícola y Ganadero',
          archivo: '101_Servicio Agrícola y Ganadero.pdf'
        },
        {
          Ministerio: 'Ministerio de Agricultura',
          Servicio: 'Corporación Nacional Forestal',
          archivo: '102_Corporación Nacional Forestal.pdf'
        },
        {
          Ministerio: 'Ministerio de Agricultura',
          Servicio: 'Comisión Nacional de Riego',
          archivo: '103_Comisión Nacional de Riego.pdf'
        },
        {
          Ministerio: 'Ministerio de Bienes Nacionales',
          Servicio: 'Subsecretaría de Bienes Nacionales',
          archivo: '104_Subsecretaría de Bienes Nacionales.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Subsecretaría del Trabajo',
          archivo: '105_Subsecretaría del Trabajo.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Dirección del Trabajo',
          archivo: '106_Dirección del Trabajo.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Subsecretaría de Previsión Social',
          archivo: '107_Subsecretaría de Previsión Social.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Dirección General de Crédito Prendario',
          archivo: '108_Dirección General de Crédito Prendario.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Servicio Nacional de Capacitación y Empleo',
          archivo: '109_Servicio Nacional de Capacitación y Empleo.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Superintendencia de Seguridad Social',
          archivo: '110_Superintendencia de Seguridad Social.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Superintendencia de Pensiones',
          archivo: '111_Superintendencia de Pensiones.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Instituto de Previsión Social',
          archivo: '112_Instituto de Previsión Social.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Instituto de Seguridad Laboral',
          archivo: '113_Instituto de Seguridad Laboral.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Caja de Previsión de la Defensa Nacional',
          archivo: '114_Caja de Previsión de la Defensa Nacional.pdf'
        },
        {
          Ministerio: 'Ministerio del Trabajo y Previsión Social',
          Servicio: 'Dirección de Previsión de Carabineros de Chile',
          archivo: '115_Dirección de Previsión de Carabineros de Chile.pdf'
        },
        // {
        //   Ministerio: 'Ministerio de Salud',
        //   Servicio: 'Servicios de Salud',
        //   archivo: '116_Servicios de Salud.pdf'
        // },
        {
          Ministerio: 'Ministerio de Salud',
          Servicio: 'Fondo Nacional de Salud',
          archivo: '117_Fondo Nacional de Salud.pdf'
        },
        {
          Ministerio: 'Ministerio de Salud',
          Servicio: 'Instituto de Salud Pública de Chile',
          archivo: '118_Instituto de Salud Pública de Chile.pdf'
        },
        {
          Ministerio: 'Ministerio de Salud',
          Servicio: 'Central de Abastecimiento del Sistema Nacional de Salud',
          archivo: '119_Central de Abastecimiento del Sistema Nacional de Salud.pdf'
        },
        {
          Ministerio: 'Ministerio de Salud',
          Servicio: 'Subsecretaría de Salud Pública',
          archivo: '120_Subsecretaría de Salud Pública.pdf'
        },
        {
          Ministerio: 'Ministerio de Salud',
          Servicio: 'Subsecretaría de Redes Asistenciales',
          archivo: '121_Subsecretaría de Redes Asistenciales.pdf'
        },
        {
          Ministerio: 'Ministerio de Salud',
          Servicio: 'Superintendencia de Salud',
          archivo: '122_Superintendencia de Salud.pdf'
        },
        {
          Ministerio: 'Ministerio de Minería',
          Servicio: 'Secretaría y Administración General, Min. Minería',
          archivo: '123_Secretaría y Administración General, Min. Minería.pdf'
        },
        {
          Ministerio: 'Ministerio de Minería',
          Servicio: 'Comisión Chilena del Cobre',
          archivo: '124_Comisión Chilena del Cobre.pdf'
        },
        {
          Ministerio: 'Ministerio de Minería',
          Servicio: 'Servicio Nacional de Geología y Minería',
          archivo: '125_Servicio Nacional de Geología y Minería.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'Subsecretaría de Vivienda y Urbanismo',
          archivo: '126_Subsecretaría de Vivienda y Urbanismo.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'Parque Metropolitano',
          archivo: '127_Parque Metropolitano.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Tarapacá',
          archivo: '128_SERVIU Región de Tarapacá.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Antofagasta',
          archivo: '129_SERVIU Región de Antofagasta.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Atacama',
          archivo: '130_SERVIU Región de Atacama.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Coquimbo',
          archivo: '131_SERVIU Región de Coquimbo.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Valparaíso',
          archivo: '132_SERVIU Región de Valparaíso.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: "SERVIU Región del Libertador General Bernardo O'Higgins",
          archivo: "133_SERVIU Región del Libertador General Bernardo O'Higgins.pdf"
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región del Maule',
          archivo: '134_SERVIU Región del Maule.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región del Biobío',
          archivo: '135_SERVIU Región del Biobío.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de la Araucanía',
          archivo: '136_SERVIU Región de la Araucanía.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Los Lagos',
          archivo: '137_SERVIU Región de Los Lagos.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Aysén del Gral. Carlos Ibáñez del Campo',
          archivo: '138_SERVIU Región de Aysén del Gral. Carlos Ibáñez del Campo.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Magallanes y Antártica Chilena',
          archivo: '139_SERVIU Región de Magallanes y Antártica Chilena.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región Metropolitana',
          archivo: '140_SERVIU Región Metropolitana.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Los Ríos',
          archivo: '141_SERVIU Región de Los Ríos.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Arica y Parinacota',
          archivo: '142_SERVIU Región de Arica y Parinacota.pdf'
        },
        {
          Ministerio: 'Ministerio de Vivienda y Urbanismo',
          Servicio: 'SERVIU Región de Ñuble',
          archivo: '143_SERVIU Región de Ñuble.pdf'
        },
        {
          Ministerio: 'Ministerio de Transportes y Telecomunicaciones',
          Servicio: 'Secretaría y Administración General de Transportes',
          archivo: '144_Secretaría y Administración General de Transportes.pdf'
        },
        {
          Ministerio: 'Ministerio de Transportes y Telecomunicaciones',
          Servicio: 'Subsecretaría de Telecomunicaciones',
          archivo: '145_Subsecretaría de Telecomunicaciones.pdf'
        },
        {
          Ministerio: 'Ministerio de Transportes y Telecomunicaciones',
          Servicio: 'Junta de Aeronáutica Civil',
          archivo: '146_Junta de Aeronáutica Civil.pdf'
        },
        {
          Ministerio: 'Ministerio Secretaría General de Gobierno',
          Servicio: 'Secretaría General de Gobierno',
          archivo: '147_Secretaría General de Gobierno.pdf'
        },
        {
          Ministerio: 'Ministerio Secretaría General de Gobierno',
          Servicio: 'Consejo Nacional de Televisión',
          archivo: '148_Consejo Nacional de Televisión.pdf'
        },
        {
          Ministerio: 'Ministerio de Desarrollo Social y Familia',
          Servicio: 'Subsecretaría de Servicios Sociales',
          archivo: '149_Subsecretaría de Servicios Sociales.pdf'
        },
        {
          Ministerio: 'Ministerio de Desarrollo Social y Familia',
          Servicio: 'Fondo de Solidaridad e Inversion Social',
          archivo: '150_Fondo de Solidaridad e Inversion Social.pdf'
        },
        {
          Ministerio: 'Ministerio de Desarrollo Social y Familia',
          Servicio: 'Instituto Nacional de la Juventud',
          archivo: '151_Instituto Nacional de la Juventud.pdf'
        },
        {
          Ministerio: 'Ministerio de Desarrollo Social y Familia',
          Servicio: 'Corporación Nacional de Desarrollo Indígena',
          archivo: '152_Corporación Nacional de Desarrollo Indígena.pdf'
        },
        {
          Ministerio: 'Ministerio de Desarrollo Social y Familia',
          Servicio: 'Servicio Nacional de la Discapacidad',
          archivo: '153_Servicio Nacional de la Discapacidad.pdf'
        },
        {
          Ministerio: 'Ministerio de Desarrollo Social y Familia',
          Servicio: 'Servicio Nacional del Adulto Mayor',
          archivo: '154_Servicio Nacional del Adulto Mayor.pdf'
        },
        {
          Ministerio: 'Ministerio de Desarrollo Social y Familia',
          Servicio: 'Subsecretaría de Evaluación Social',
          archivo: '155_Subsecretaría de Evaluación Social.pdf'
        },
        {
          Ministerio: 'Ministerio de Desarrollo Social y Familia',
          Servicio: 'Subsecretaría de la Niñez',
          archivo: '156_Subsecretaría de la Niñez.pdf'
        },
        {
          Ministerio: 'Ministerio Secretaría General de la Presidencia',
          Servicio: 'Secretaría General de la Presidencia de la República',
          archivo: '157_Secretaría General de la Presidencia de la República.pdf'
        },
        {
          Ministerio: 'Ministerio de Energía',
          Servicio: 'Subsecretaría de Energía',
          archivo: '158_Subsecretaría de Energía.pdf'
        },
        {
          Ministerio: 'Ministerio de Energía',
          Servicio: 'Comisión Nacional de Energía',
          archivo: '159_Comisión Nacional de Energía.pdf'
        },
        {
          Ministerio: 'Ministerio de Energía',
          Servicio: 'Comisión Chilena de Energía Nuclear',
          archivo: '160_Comisión Chilena de Energía Nuclear.pdf'
        },
        {
          Ministerio: 'Ministerio de Energía',
          Servicio: 'Superintendencia de Electricidad y Combustibles',
          archivo: '161_Superintendencia de Electricidad y Combustibles.pdf'
        },
        {
          Ministerio: 'Ministerio del Medio Ambiente',
          Servicio: 'Subsecretaría del Medio Ambiente',
          archivo: '162_Subsecretaría del Medio Ambiente.pdf'
        },
        {
          Ministerio: 'Ministerio del Medio Ambiente',
          Servicio: 'Servicio de Evaluación Ambiental',
          archivo: '163_Servicio de Evaluación Ambiental.pdf'
        },
        {
          Ministerio: 'Ministerio del Medio Ambiente',
          Servicio: 'Superintendencia del Medio Ambiente',
          archivo: '164_Superintendencia del Medio Ambiente.pdf'
        },
        {
          Ministerio: 'Ministerio del Deporte',
          Servicio: 'Subsecretaría del Deporte',
          archivo: '165_Subsecretaría del Deporte.pdf'
        },
        {
          Ministerio: 'Ministerio del Deporte',
          Servicio: 'Instituto Nacional de Deportes',
          archivo: '166_Instituto Nacional de Deportes.pdf'
        },
        {
          Ministerio: 'Ministerio de la Mujer y la Equidad de Género',
          Servicio: 'Subsecretaría de la Mujer y la Equidad de Género',
          archivo: '167_Subsecretaría de la Mujer y la Equidad de Género.pdf'
        },
        {
          Ministerio: 'Ministerio de la Mujer y la Equidad de Género',
          Servicio: 'Servicio Nacional de la Mujer y la Equidad de Género',
          archivo: '168_Servicio Nacional de la Mujer y la Equidad de Género.pdf'
        },
        {
          Ministerio: 'Ministerio de las Culturas, las Artes y el Patrimonio',
          Servicio: 'Subsecretaría de las Culturas y las Artes',
          archivo: '169_Subsecretaría de las Culturas y las Artes.pdf'
        },
        {
          Ministerio: 'Ministerio de las Culturas, las Artes y el Patrimonio',
          Servicio: 'Subsecretaría del Patrimonio Cultural',
          archivo: '170_Subsecretaría del Patrimonio Cultural.pdf'
        },
        {
          Ministerio: 'Ministerio de las Culturas, las Artes y el Patrimonio',
          Servicio: 'Servicio Nacional del Patrimonio Cultural',
          archivo: '171_Servicio Nacional del Patrimonio Cultural.pdf'
        },
        {
          Ministerio: 'Ministerio de Ciencia, Tecnología, Conocimiento e Innovación',
          Servicio: 'Subsecretaría de Ciencia, Tecnología, Conocimiento e Innovación',
          archivo: '172_Subsecretaría de Ciencia, Tecnología, Conocimiento e Innovación.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Atacama',
          archivo: '174_Servicio Local de Educación Atacama.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Colchagua',
          archivo: '175_Servicio Local de Educación Colchagua.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Llanquihue',
          archivo: '176_Servicio Local de Educación Llanquihue.pdf'
        },
        {
          Ministerio: 'Ministerio de Defensa Nacional',
          Servicio: 'Estado Mayor Conjunto',
          archivo: '177_Estado Mayor Conjunto.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Dirección General de Obras Públicas',
          archivo: '178_Dirección General de Obras Públicas.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Fiscalía de Obras Públicas',
          archivo: '179_Fiscalía de Obras Públicas.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Dirección de Contabilidad y Finanzas',
          archivo: '180_Dirección de Contabilidad y Finanzas.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Dirección de Arquitectura',
          archivo: '181_Dirección de Arquitectura.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Dirección de Obras Hidráulicas',
          archivo: '182_Dirección de Obras Hidráulicas.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Dirección de Vialidad',
          archivo: '183_Dirección de Vialidad.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Dirección de Obras Portuarias',
          archivo: '184_Dirección de Obras Portuarias.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Dirección de Aeropuertos',
          archivo: '185_Dirección de Aeropuertos.pdf'
        },
        {
          Ministerio: 'Ministerio de Obras Públicas',
          Servicio: 'Dirección de Planeamiento',
          archivo: '186_Dirección de Planeamiento.pdf'
        },
        {
          Ministerio: 'Ministerio de Ciencia, Tecnología, Conocimiento e Innovación',
          Servicio: 'Agencia Nacional de Investigación y Desarrollo',
          archivo: '187_Agencia Nacional de Investigación y Desarrollo.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Valparaíso',
          archivo: '192_Servicio Local de Educación Valparaíso.pdf'
        },
        {
          Ministerio: 'Ministerio del Interior y Seguridad Pública',
          Servicio: 'Servicio Nacional de Migraciones',
          archivo: '205_Servicio Nacional de Migraciones.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Iquique',
          archivo: '206_Servicio Local de Educación Iquique.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Punilla Cordillera',
          archivo: '207_Servicio Local de Educación Punilla Cordillera.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Aysén',
          archivo: '208_Servicio Local de Educación Aysén.pdf'
        },
        {
          Ministerio: 'Ministerio de Educación',
          Servicio: 'Servicio Local de Educación Magallanes',
          archivo: '209_Servicio Local de Educación Magallanes.pdf'
        },
        // {
        //   Ministerio: 'Ministerio de Obras Públicas',
        //   Servicio: 'Programa Agua Potable Rural',
        //   archivo: '203_Programa Agua Potable Rural.pdf'
        // },
        {
          Ministerio: 'Ministerio de Desarrollo Social y Familia',
          Servicio: 'Servicio Nacional de Protección Especializada a la Niñez y Adolescencia',
          archivo: '204_Servicio Nacional de Protección Especializada a la Niñez y Adolescencia.pdf'
        }
        // ,
        // {
        //   Ministerio: 'Ministerio del Interior y Seguridad Pública',
        //   Servicio: 'Servicio Nacional de Migraciones',
        //   archivo: '205_Servicio Nacional de Migraciones.pdf'
        // }
      ]
    }
  },
  methods: {
    getMinisterios () {
      return this.servicios.map(m => { return m.Ministerio }).filter(function (value, index, array) {
        return array.indexOf(value) === index
      })
    },
    getServicios (ministerio) {
      return this.servicios.filter(s => { return s.Ministerio === ministerio })
    }
  }
}
</script>

<style scoped>
.container-box{
    padding:20px;
  margin: 5px;
  margin-bottom:20px;
  margin-top:20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 25px;
}

h4::after {
  content: "";
  display: block;
  width: 90%;
  padding-top: 8px;
  /* margin-left:20px; */
  border-bottom: 2px solid #4f52ff;
}

</style>
