<template>
  <b-row class="div-torta">
    <Torta :labels="this.labels" :data="this.data" />
  </b-row>
</template>

<script>
import Torta from '@/components/graphics/Graphics/Torta.vue'

export default {
  components: {
    Torta
  },
  data () {
    return {
      labels: null,
      data: null
    }
  },
  props: ['datos'],
  watch: {
    datos: function () {
      this.labels = [this.$props.datos.labels]
      this.data = [this.$props.datos.data]
    }
  }
}
</script>

<style scoped>
.row {
  margin: 0;
}

.icons {
  text-align: end;
  margin-top: 10px;
}

.bxs-down-arrow-circle {
  font-size: 36px;
  margin-right: 10px;
}

.bxs-image {
  font-size: 36px;
  margin-right: 10px;
}

.bx-file {
  font-size: 36px;
  margin-right: 30px;
}

.div-torta {
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}
</style>
