<template>
  <div>
    <div>
      <ContentUno />
    </div>
    <!-- <div class="div">
      <ContentTwo />
    </div> -->
    <div class="cards">
      <Cards />
    </div>
    <!--
    <div class="container">
      <br>
      En los próximos días la Dirección de Presupuestos informará mediante Oficio el cronograma e instituciones para el desarrollo de la Fase II del BGI 2021, consistente en la incorporación de los módulos no incluidos en la versión acotada publicada el 10 de marzo pasado.
      <br><br>
      Asimismo, próximamente se realizará una reunión con los Representantes Ministeriales, quienes son los encargados de coordinar este proceso al interior de sus ministerios.

    </div>
    -->
  </div>
</template>

<script>
import ContentUno from './components/ContentUno.vue'
// import ContentTwo from "./components/ContentTwo.vue";
import Cards from './components/Cards.vue'
export default {
  components: {
    ContentUno,
    // ContentTwo,
    Cards
  }

}
</script>

<style scoped>
.cards {
  position: relative;
  top: 105px;
}
</style>
