<template>
  <div>
    <Header />
    <div class="container">
      <Title />
      <Carrusel />
        <Breadcrumb :links="breadCrumbData" />
        <h1 class="privado-titulo">Incentivo Colectivo</h1>
        <b-row>
            <b-col>
                <!-- <Instrucciones/> -->
            </b-col>
            <b-col>
                <Visualizar seccion="incentivo-colectivo" />
            </b-col>
            <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
                 <b-form-checkbox v-model="anexo.datos.no_aplica">
                    No Aplica
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-button v-if="can_edit_anexos_de_servicios()" @click="guardar" pill variant="primary" class="btn-one">Guardar</b-button>
        <div v-if="itemList && !anexo.datos.no_aplica">
            <h3 class="title-leyes">Cumplimiento Convenio de Desempeño Colectivo {{anioBgi}}</h3>

            <div  v-for="(item, index) in itemList" :key="index">
                <hr />
                <b-row>
                    <!-- <b-col cols="3">

                        <label class="label-leyes"><div class="label-incentivo-colectivo-span">Año</div></label>
                        <b-form-input v-model="item.anio" class="input-leyes" type="text" :disabled="!can_edit_anexos_de_servicios()"/>
                    </b-col> -->
                    <b-col cols="1">
                        <i class="bx bxs-trash" @click="removeItem(item)" style="color:#24c6ab; cursor: pointer;"></i>
                    </b-col>
                    <b-col cols="5">
                        <label class="label-leyes"><div class="label-incentivo-colectivo-span">N° total de Equipos de Trabajo</div></label>
                        <b-form-textarea v-model="item.equipos_trabajo" class="input-leyes " :disabled="!can_edit_anexos_de_servicios()"></b-form-textarea>
                    </b-col>
                    <b-col cols="6"> <label class="label-leyes">
                        <div class="label-incentivo-colectivo-span">Número promedio de personas por Equipo de Trabajo</div> </label>
                        <b-form-textarea v-model="item.n_personas_equipo" class="input-leyes" :disabled="!can_edit_anexos_de_servicios()"></b-form-textarea>
                    </b-col>
                    <b-col cols="4"> <label class="label-leyes">
                        <div class="label-incentivo-colectivo-span">N° promedio de metas de gestión comprometidas por Equipo de Trabajo</div> </label>
                        <b-form-textarea v-model="item.metas_gestion" class="input-leyes" :disabled="!can_edit_anexos_de_servicios()"></b-form-textarea>
                    </b-col>
                    <b-col cols="4"> <label class="label-leyes">
                        <div class="label-incentivo-colectivo-span">Porcentaje promedio de Cumplimiento de Metas</div> </label>
                        <b-form-textarea v-model="item.perc_cump" class="input-leyes" :disabled="!can_edit_anexos_de_servicios()"></b-form-textarea>
                    </b-col>
                    <b-col cols="4"> <label class="label-leyes">
                        <div class="label-incentivo-colectivo-span">Gasto total por Incremento por Desempeño Colectivo (miles de pesos)</div> </label>
                        <b-form-textarea v-model="item.incremento_des" class="input-leyes" :disabled="!can_edit_anexos_de_servicios()"></b-form-textarea>
                    </b-col>
                </b-row>

            </div>
            <div class="add-btn" @click="addItemList" v-b-tooltip.hover title="Agregar">
                <hr class="separator-hr" />
                <b-button class="button-leyes"><i class="bx bx-plus"></i></b-button>
                <hr class="separator-hr" />
            </div>
        </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/private/Header'
import Footer from '@/components/layout/Footer.vue'
import { utils } from '@/components/tools/ViewUtils.js'
import axios from 'axios'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'

import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '@/components/private/Carrusel.vue'
import { mapState } from 'vuex'

export default {
  mixins: [utils],
  components: {
    Title,
    Breadcrumb,
    Carrusel,
    Header,
    Footer,
    Visualizar
  },
  data () {
    return {
      breadCrumbData: null,
      itemList: null,
      anexo: null
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])

  },
  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Incentivo Colectivo', routeName: 'icolectivo', routeParams: { idServicio: this.$route.params.idServicio } }
      ]
    },

    addItemList: function () {
      const toAddObj = {}
      this.itemList.push(toAddObj)
    },
    removeItem: function (index) {
      if (this.readOnly) return
      const pos = this.itemList.indexOf(index)

      this.itemList.splice(pos, 1)
    },
    guardar: function () {
      axios.put(`/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    },
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'incentivo-colectivo'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.listado = anexo.datos.listado || []
        this.itemList = anexo.datos.listado
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }

  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRevision()
  }
}
</script>

<style scoped>

/* INICIO SEPARATOR */
.add-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}
.separator-hr {
  width: 45%;
}
.button-leyes {
  font-size: 20px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  border: 2px solid #ccc;
  background: white;
  padding: 0;
  color: #ccc;
}
.bx-plus {
  position: relative;
  left: -2px;
  top: -5px;
}
.btn-check:focus + .btn,
.btn-secondary:hover,
.btn:focus {
  box-shadow: none;
  color: #ccc;
  border: 2px solid #ccc;
  background: white;
}
/* FIN SEPARATOR */
.containers {
margin-bottom: 30px;
margin-left: 30px;
margin-right: 30px;
}

.container {
  margin-bottom: 2rem;
}
</style>
