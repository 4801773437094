<template>
  <div class="container">
    <Title  />
    <div class="container" v-if="anexo">
        <Breadcrumb :links="breadCrumbData" />
        <Carrusel />

        <h1 class="privado-titulo">Presentación del Ministro o Ministra</h1>

        <b-row>
            <b-col>
                <Instrucciones/>
            </b-col>
            <b-col>
                <Visualizar seccion="presentacion_ministro" />
            </b-col>
            <b-col v-if="can_edit_anexos_de_ministerios()" style="text-align:right">
                 <b-form-checkbox v-model="anexo.datos.no_aplica">
                    No Aplica
                </b-form-checkbox>
            </b-col>
        </b-row>

        <!-- <span class="span" v-if="checked"> No Aplica</span>
        <span class="span" v-else>No Aplica</span> -->

        <div v-if="!can_edit_anexos_de_ministerios() && anexo">
            <br>
            <sin-autorizacion/>
            <div v-html="anexo.datos.texto" class="seccion-background-color-table"/>
        </div>
        <div v-if="can_edit_anexos_de_ministerios()">
            <br><br>
            <b-button @click="guardar" pill variant="primary" class="btn-one">Guardar</b-button>
            <br><br>
            <ck-editor-text :modifyContent="modifyContent" :borrador="anexo.datos.texto" v-if="anexo"></ck-editor-text>
        </div>

    </div>
  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
import Instrucciones from './Instrucciones'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'

import Title from '../../../components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '../../../components/private/Carrusel.vue'
import CkEditorText from '@/components/private/CkEditorText.vue'

import sinAutorizacion from '@/components/sinAutorizacion.vue'

import { mapState } from 'vuex'
import axios from 'axios'

export default {
  mixins: [utils],
  components: {
    sinAutorizacion,
    Instrucciones,
    Title,
    Breadcrumb,
    Carrusel,
    CkEditorText,
    Visualizar
  },
  data () {
    return {
      anexo: null,
      breadCrumbData: null

    }
  },
  computed: {
    // get User
    ...mapState('sesion', ['usuario'])

  },
  methods: {
    // name of breadcrumb
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Presentación del Ministro', routeName: 'ministerio', routeParams: { idServicio: this.$route.params.idServicio } }]
    },

    modifyContent: function (text) {
      this.anexo.datos.texto = text
    },
    guardar: function () {
      axios.put(`/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    },
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'presentacion_ministro'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.texto = anexo.datos.texto || ''
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRevision()
  }
}
</script>
