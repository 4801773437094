<template>
  <div>
    <Title  />

    <div class="container">
        <Breacrumb :links="breadCrumbData" />
        <div v-if="loading" style="display: flex; justify-content: center; align-items: center; margin: 60px 0;">
            <Loading />
        </div>

        <h2  class="title">Configuraciones</h2>

        <b-button v-if="!loading" type="button" class="btn" style="float:right" @click="showServiceModal=true">Agregar</b-button>
        <br><br>
        <table v-if="!loading" class="table table-striped">
            <thead class="head">
                <tr>
                    <th class="head-text">Nombre</th>
                    <th>RUT</th>
                    <th>Email</th>
                    <th>Perfil</th>
                    <th>Acceso a Modulo</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data" :key="item.id">
                    <td class="text-ne">
                        {{item.firstName}} {{item.lastName}}
                    </td>
                    <td class="run">
                        {{ item.username }}
                    </td>
                    <td class="text-ne">
                        {{ item.email }}
                    </td>
                    <td class="text-paam">
                        <!-- {{index}} -->
                        {{get_perfil_bonito(item.rol.nombre)}}
                    </td>
                    <td class="text-paam">
                        {{modulos_asignados(item)}}
                    </td>
                    <td  class="acciones">
                        <b-button
                        :disabled="!posibleEditar(item) || item.rol.nombre!=='funcionario'"
                        @click="addUserPerms(item)"
                        class="actions-btn add"
                        v-b-tooltip.hover.left
                        title="Gestionar Accesos a Módulos"
                        >+</b-button
                        >
                        <b-button
                        :disabled="!posibleEditar(item)"
                        @click="removeUserService(item)"
                        class="actions-btn remove"
                        v-b-tooltip.hover.left
                        title="Eliminar Usuario de Este Servicio"
                        ><i class="bx bx-trash"></i
                        ></b-button>
                        <!-- <Roles /> -->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <b-modal v-model="showPermsModal" hide-header hide-footer>
      <AddPerms
        :datosUsuario="selectedUser"
        :getUsers="getUsers"
        :closeModal="closeModal"
      />
    </b-modal>
    <b-modal v-model="showServiceModal" hide-header hide-footer>
      <AddService
        :listServices="usuario.servicios"
        :closeModal="closeModal"
        :getUsers="getUsers"
      />
    </b-modal>

  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
import Title from '@/components/private/Title.vue'
import Loading from '@/components/tools/Loading.vue'
import AddPerms from './user/AddPerms.vue'
import AddService from './user/AddService.vue'
import Breacrumb from '@/components/tools/Breadcrumb.vue'
import Swal from 'sweetalert2'
// import Modal from './user/Modal.vue'
import { mapState, mapActions } from 'vuex'
import { formatPerm } from '@/components/tools/utils'
import axios from 'axios'
export default {
  mixins: [utils],
  data () {
    return {
      breadCrumbData: null,
      data: [],
      selectedUser: null,
      ministerList: null,
      loading: true,
      showListUsers: false,
      showPermsModal: false,
      showServiceModal: false

    }
  },
  components: {
    Title,
    Breacrumb,
    // User,
    // Modal,
    AddPerms,
    AddService,
    Loading
  },
  computed: {
    ...mapState('sesion', ['usuario', 'relacionUS']),
    showTitle: function () {
      const { path } = this.$route
      if (path.includes('/admin')) return false
      return true
    }
  },
  methods: {
    ...mapActions('sesion', ['setData']),

    posibleEditar: function (obj) {
      const rolLogeada = this.usuario.servicios.find(servicio => { return servicio.id === parseInt(this.$route.params.idServicio) }).rolNombre
      var permitido = []
      if (rolLogeada === 'superadministrador') {
        permitido = ['administrador', 'representante_ministerial', 'jefe_servicio', 'encargado_bgi', 'funcionario']
      }
      if (rolLogeada === 'administrador') {
        permitido = ['representante_ministerial', 'jefe_servicio', 'encargado_bgi', 'funcionario']
      }
      if (rolLogeada === 'representante_ministerial') {
        permitido = ['jefe_servicio', 'encargado_bgi', 'funcionario']
      }
      if (rolLogeada === 'jefe_servicio') {
        permitido = ['encargado_bgi', 'funcionario']
      }
      if (rolLogeada === 'encargado_bgi') {
        permitido = ['funcionario']
      }
      var rolFuncionario = obj.rol.nombre

      return permitido.indexOf(rolFuncionario) >= 0
    },

    removeUserService: function (obj) {
      Swal.fire({
        title: 'Está seguro de realizar esta acción?',
        text: 'Esta acción es Irreversible',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(result => {
        if (!result.isConfirmed) { return }
        var usuarioEliminar = {
          service: this.$route.params.idServicio,
          usuario: obj.id
        }

        axios.post('/instituciones/usuarioservicio/borrar_con_parametros/', usuarioEliminar)
        this.getUsers()
        // Swal.fire(
        //   'Eliminado!',
        //   'Usuario eliminado de este servicio',
        //   'success'
        // )
      })
    },

    modulos_asignados: function (item) {
      var modulos = item.permisos[0]
      if (modulos.length === 0) { return null }
      return modulos.map(m => { return formatPerm(m.nombre) }).join(', ')
    },
    addUserPerms: function (obj) {
      this.showPermsModal = true
      this.selectedUser = obj
    },
    closeModal: function () {
      this.showServiceModal = false
      this.showPermsModal = false
      this.showEditServicesModal = false
    },
    // establecerRelacion: function () {
    //   if (!this.usuario) return

    // //   this.setData({
    // //     idUsuario: this.$route.params.idUsuario,
    // //     idServicio: service.id,
    // //     ministerio: service.ministerio,
    // //     perfil: formatRole(service.rolNombre),
    // //     rolId: service.rolId,
    // //     nombreServicio: service.nombre,
    // //     usuarioServicioId: service.usuarioServicioId
    // //   })
    // },
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        {
          name: 'Portada BGI',
          routeName: 'bgi',
          routeParams: {
            idUsuario: this.$route.params.idUsuario
          }
        },
        {
          name: 'Configuraciones',
          routeName: 'acceso',
          routeParams: {
            ...this.$route.params
          }
        }
      ]
    },
    getUsers () {
      this.data = []
      axios.get(`${process.env.VUE_APP_RUTA_API}/usuarios/usuarios?servicio=${this.$route.params.idServicio}`, { headers: this.get_headers() }).then(res => {
        this.data = res.data.filter(
          (u) => u.username !== process.env.VUE_APP_USER_ADMIN
        ).filter(u => { return u.username.indexOf('-') > 0 })

        this.loading = false
        this.showListUsers = true
      }
      )
    },
    getMinisterList: async function () {
      const res = await axios.get(`${process.env.VUE_APP_RUTA_API}/instituciones/ministerios/`)
      this.ministerList = res.data.map((m) => ({ value: m.id, text: m.nombre }))
      this.ministerList.unshift({ value: null, text: '- Seleccione un Ministerio -' })
    }
  },
  mounted () {
    // this.establecerRelacion()
    this.setBreadcrumbData()
    this.getMinisterList()
    setTimeout(() => {
      this.getUsers()
    }, 200)
  }
}
</script>
<style scoped>
.modal-breadcrumb {
  display: flex;
  padding: 10px 0 !important;
  justify-content: space-between;
}

.containers {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
