<template>
  <div>
    <div class="home">
      <Navbar />
    </div>

    <div>
      <div>
        <Header
          pretitle="LONGEVIDAD"
          title="Longevidad y supervivencia en el servicio"
          paragraph="El periodo de observación de este estudio comprende 11 años, (2010 – 2020). La longevidad del funcionario, observada en este intervalo de tiempo, se calcula en meses a partir de la información de fechas de ingreso y salida del servicio reportadas en los datos de transparencia. Hay que aclarar que algunos servicios (ejemplo, Hospital El Carmen Dr. Luis Valentín Ferrada), tienen menos años desde su creación y esto afecta el estimado del riesgo, por lo que la interpretación de los resultados en los modelos debe incorporar estos casos."
        />
      </div>
      <b-container>
        <br />
        <br />
        <b-img
          src="/img_fondef/dotacion/Minsal_dotacion_por_regimen.png"
          fluid
        />

        <blockquote class="blockquote text-center">
          <p class="lead">
            Dotación del Ministerio de Salud
          </p>
          <footer class="blockquote-footer">
            Diferencias por régimen de contratación y a través de 11 años.
          </footer>
        </blockquote>
        <br /><br /><br />
        <b-row>
          <b-col sm="12" md="7">
            <b-img src="/img_fondef/dotacion/Minsal_dotacion1.png" fluid />
            <p class="lead center">
              Ministerio de Salud. Dotación total por año*
            </p>
            <p>
              *Estos números están dados a partir de personas correctamente
              identificadas.
            </p>
          </b-col>
          <b-col sm="12" md="5">
            <b-img src="/img_fondef/dotacion/Minsal_dotacion_sexo.png" fluid />
            <p class="lead center">
              Proporción de mujeres y hombres en la dotación del Ministerio de
              Salud
            </p>
            <p></p>
          </b-col>
        </b-row>
        <br /><br /><br />
      </b-container>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from './components/header/Header.vue'

export default {
  components: {
    Navbar,
    Header
  }
}
</script>

<style scoped>
p {
  font-family: Georgia, serif;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin: 2rem 0;
}

.lead {
  font-size: 1.7rem;
  margin: 3.4rem 0 1.7rem 0;
  font-family: sans-serif;
}

.center {
  text-align: center;
}
</style>
