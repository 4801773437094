<template>
    <div class="container">
        <Title />

        <div >
            <Breadcrumb :links="breadCrumbData" />
            <Carrusel />

            <h1 class="privado-titulo"> Incentivo Institucional</h1>

            Corresponde al resultado de la evaluación del cumplimiento de los compromisos de los mecanismos de incentivo institucional del año 2021, proporcionado por el Departamento de Gestión Pública de la Dirección de Presupuestos

            <b-row>
                <b-col>
                    <Instrucciones/>
                </b-col>
                <b-col>
                    <Visualizar seccion="desafios" />
                </b-col>
                <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
                    <b-form-checkbox v-model="anexo.datos.no_aplica">
                        No Aplica
                    </b-form-checkbox>
                </b-col>
            </b-row>

            <div v-if="anexo">
                <h3 class="title">
                INFORME DE CUMPLIMIENTO DEL PROGRAMA DE MEJORAMIENTO DE LA GESTIÓN AÑO {{ anioBgi }}
                </h3>

                <b-row>
                <b-col cols="8">
                    <div class="div">
                    <span class="title">MINISTERIO:<span class="text">{{ get_nombre_servicio() }}</span></span>
                    </div>
                    <div class="div">
                    <span class="title">SERVICIO: <span class="text"></span>{{ get_nombre_ministerio() }}</span                    >
                    </div>
                    <!--
                    <div class="div">
                    <span class="title">DOTACIÓN:<span class="text">{{ anexo.datos.dotacion }}</span></span>
                    </div> -->
                    </b-col>
                </b-row>

                <h4>OBJETIVOS DE GESTIÓN</h4>
                <table class="table table-striped">
                <thead>
                    <tr>
                    <th >Objetivos de Gestión</th>
                    <th scope="col">N° Indicadores comprometidos</th>
                    <th scope="col">% Ponderación Comprometida</th>
                    <th scope="col">% Ponderación obtenida</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1.- Gestión Eficaz</td>
                    <td class="td-table">{{ anexo.datos.objetivo1.cantidad}}</td>
                    <td class="td-table">{{ anexo.datos.objetivo1.ponderador}}%</td>
                    <td class="td-table">{{ anexo.datos.objetivo1.obtenido}}%</td>
                    </tr>
                    <tr>
                    <td>2.- Eficiencia Institucional</td>
                    <td class="td-table">{{ anexo.datos.objetivo2.cantidad}}</td>
                    <td class="td-table">{{ anexo.datos.objetivo2.ponderador}}%</td>
                    <td class="td-table">{{ anexo.datos.objetivo2.obtenido}}%</td>
                    </tr>
                    <tr>
                    <td>3.- Calidad de Servicio</td>
                    <td class="td-table">{{ anexo.datos.objetivo3.cantidad}}</td>
                    <td class="td-table">{{ anexo.datos.objetivo3.ponderador}}%</td>
                    <td class="td-table">{{ anexo.datos.objetivo3.obtenido}}%</td>
                    </tr>
                    <tr>
                    <td>Total</td>
                    <td class="td-table">{{ anexo.datos.cantidad }}</td>
                    <td class="td-table">{{ anexo.datos.ponderador }}%</td>
                    <td class="td-table">{{ anexo.datos.obtenido }}%</td>
                    </tr>
                </tbody>
                </table>

                <div class="section">
                    <h3>Detalle Compromisos</h3>

                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Nombre Indicador</th>
                                <th>COMPROMISO / META {{ anioBgi }}</th>
                                <th>EFECTIVO {{ anioBgi }}</th>
                                <th>CUMPLIMIENTO</th>
                                <th>Ponderación comprometida {{ anioBgi }}</th>
                                <th>Ponderación obtenida {{ anioBgi }}</th>
                            </tr>
                        </thead>
                        <tr>
                            <th style="color:#052333;" colspan="5">
                                Objetivo 1: Gestión Eficaz
                            </th>
                            <th>{{anexo.datos.objetivo1.ponderador}}%</th>
                            <th>{{anexo.datos.objetivo1.obtenido}}%</th>
                        </tr>
                        <seccion :objetivos="anexo.datos.objetivo1"/>
                        <tr>
                            <th style="color:#052333;" colspan="5">
                                Objetivo 2: Eficiencia Institucional
                            </th>
                            <th>{{anexo.datos.objetivo2.ponderador}}%</th>
                            <th>{{anexo.datos.objetivo2.obtenido}}%</th>
                        </tr>
                        <seccion :objetivos="anexo.datos.objetivo2"/>
                        <tr>
                            <th style="color:#052333;" colspan="5">
                                Objetivo 3: Calidad de Servicio
                            </th>
                            <th>{{anexo.datos.objetivo3.ponderador}}%</th>
                            <th>{{anexo.datos.objetivo3.obtenido}}%</th>
                        </tr>
                        <seccion :objetivos="anexo.datos.objetivo3"/>
                    </table>
                </div>

                <div v-html="anexo.datos.resultado_texto"/>
            </div>
            Fuente: Departamento de Gestión Pública de la Dirección de Presupuestos
        </div>
    </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '@/components/private/Carrusel.vue'
import Seccion from './main/Seccion.vue'
// import Main from "./main/Main.vue";
// import { mapState } from 'vuex'
import axios from 'axios'
// // import NoData from '../../indicador/components/NoData.vue'
// // import Upload from "./main/Upload.vue";
// import { showAlert } from '@/components/tools/utils'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'

export default {
  mixins: [utils],

  components: {
    Title,
    Breadcrumb,
    Carrusel,
    // Table,
    // NoData,
    // Upload,
    // Main,
    Seccion,
    Visualizar
  },
  data () {
    return {
      anexo: null
    }
  },
  methods: {
    // name of breacrumb
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        {
          name: 'Portada BGI',
          routeName: 'bgi',
          routeParams: {
            idUsuario: this.usuario.id
          }
        },
        {
          name: 'Escritorio de Gestión Virtual',
          routeName: 'buttons',
          routeParams: {
            idUsuario: this.usuario.id,
            idServicio: this.$route.params.idServicio
          }
        },
        {
          name: 'Incentivo Institucional',
          routeName: 'iinstitucional',
          routeParams: {
            idServicio: this.$route.params.idServicio
          }
        }
      ]
    },

    formattedNumber: function (value) {
      return value
    },
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'incentivo-institucional'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRevision()
  }
}
</script>

<style scoped>
.row {
  margin: 0;
}
.containers {
  margin-bottom: 30px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.text-indicator {
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
}

.title {
  text-align: center;
  font-size: 1.4rem;
  color: #052333;
  font-weight: 500;
}

.main-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b8f4e9;
  height: 50px;
}

.main-text > h6 {
  font-weight: bold;
  color: #515151;
  margin: 0;
  text-align: center;
  margin: 15px;
}

h4 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text {
  color: #052333;
  font-size: 1.3rem;
  font-weight: 400;
}

th {
  font-size: 1.5rem !important;
}

td {
  font-size: 1.3rem !important;
  text-align: left !important;
}

.tr-Two {
  background: #f7f7f7;
}

.tr-two:hover {
  background: #052333 !important;
}

.td-table {
  text-align: center !important;
}

.td-table-end {
  text-align: center;
  font-weight: bold;
}

@media screen and (max-width: 1800px) {
  .text-indicator {
    font-size: 1.5rem;
  }
  .title {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  .text {
    font-size: 1.2rem;
  }

  th {
    font-size: 1.4rem !important;
  }
  td {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 1600px) {
  .text-indicator {
    font-size: 1.4rem;
  }
  .title {
    font-size: 1.1rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  .text {
    font-size: 1rem;
  }

  th {
    font-size: 1.2rem !important;
  }
  td {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 1400px) {
  .text-indicator {
    font-size: 1.3rem;
  }
  .title {
    font-size: 1rem;
  }

  h4 {
    font-size: 1rem;
  }

  .text {
    font-size: 0.9rem;
  }

  th {
    font-size: 1.1rem !important;
  }
  td {
    font-size: 0.9rem !important;
  }
}

@media screen and (max-width: 1200px) {
  .text-indicator {
    font-size: 1.2rem;
  }
  .title {
    font-size: 0.9rem;
  }

  h4 {
    font-size: 0.9rem;
  }

  .text {
    font-size: 0.8rem;
  }

  th {
    font-size: 1rem !important;
  }
  td {
    font-size: 0.8rem !important;
  }
}

@media screen and (max-width: 1000px) {
  .text-indicator {
    font-size: 1.1rem;
  }
  .title {
    font-size: 0.8rem;
  }

  h4 {
    font-size: 0.8rem;
  }

  .text {
    font-size: 0.7rem;
  }

  th {
    font-size: 0.9rem !important;
  }
  td {
    font-size: 0.7rem !important;
  }
}

@media screen and (max-width: 800px) {
  .text-indicator {
    font-size: 0.9rem;
  }
  .title {
    font-size: 0.6rem;
  }

  h4 {
    font-size: 0.6rem;
  }

  .text {
    font-size: 0.5rem;
  }

  th {
    font-size: 0.7rem !important;
  }
  td {
    font-size: 0.5rem !important;
  }
}
</style>
