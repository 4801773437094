<template>
  <div>
    <div class="home">
      <Navbar />
    </div>

    <div>
      <div>
        <Header
          title="Descarga de Datos"
          postTitle="Descarga de los datos usados en este estudio"
          paragraph="Los datos de Dotación de Personal extraídos desde distintos
           portales de transparencia han sido limpiados y validados.
           Las identidades han sido desambiguadas en su mayor parte.
            Los datos han sido parcelados para un mejor manejo y descarga."
          image="/img_fondef/portada/datos.png"
        />
      </div>

      <NavBar2 />

      <b-container>
        <br />
        <br />
        <p>
          Las tablas disponibles han sido construidas usando datos descargados
          de distintos Portales de Transparencia. Según la ley 20285 sobre el
          acceso a información pública, los datos de dotación de personal son
          accesibles. Sin embargo, los datos que se pueden descargar aquí han
          sido previamente curados. En particular, se ha dado un identificador
          único a cada empleado, logrando un alto nivel de precisión en su
          seguimiento a lo largo del tiempo. Los datos incluyen información
          mensual o anual sobre salarios, región administrativa, educación y
          funciones de cada empleado del Estado para las tres modalidades de
          contrato principales (planta, contrata y honorarios). Se incluyen
          además otros indicadores tales como: longevidad individualizada,
          remuneraciones promedios anuales, dotación por servicio, entre otros.
        </p>

        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-1 variant="info">
                Datos Agregados por Servicio Ministerio de Salud (112 servicios,
                11 años)
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <div>
                    <a href="https://doi.org/10.7910/DVN/RLG0OP" class="cffm" style="display:none">Descarga los datos aquí</a>
                  </div>
                  Datos agregados de dotación de personal en 112 servicios del
                  Ministerio de Salud de Chile. En esta tabla se consideran
                  únicamente Planta y Contrata. Las variables contenidas en el
                  conjunto de datos son:
                  <b>Servicios, ID_servicio, Dotacion</b> (número de empleados
                  por año), <b>longevidadMaxServicio</b> (la antigüedad máxima
                  entre los empleados del servicio), <strong>RQ1</strong> (
                  cuantil 0.25 en la distribución de remuneraciones),
                  <strong>RQ3</strong> (cuantil 0.75 en la distribución de
                  remuneraciones), <strong>LQ3</strong> (cuantil 0.75 de la
                  distribución de longevidad), <strong>year</strong>,
                  <strong>RQ2_miles</strong> (cuantil 0.5 o mediana expresada en
                  miles de pesos de la distribución de remuneraciones),
                  <strong>Longevidad_escalada</strong> (longevidad
                  promedio/longevidad máxima del servicio,
                  <strong>Remuneracion_promedio</strong>,
                  <strong>Longevidad_promedio</strong>,
                  <strong>RQ2</strong> (mediana de la distribución de
                  remuneraciones), <strong>RSD</strong> (desviación estándar de
                  la distribución de remuneraciones),
                  <strong>LQ1</strong> (cuantil 0.25 de la distribución de
                  longevidad), <strong>LQ2</strong> (cuantil 0.5 o mediana de la
                  distribución de longevidad ), <strong>LSD</strong> (desviación
                  estándar de la distribución de longevidad). El conjunto de
                  datos comprende 11 años (2010 - 2020)</b-card-text
                >
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-2 variant="info">
                Datos de Dotación de personal por Servicio y año (24
                ministerios, 264 servicios, 11 años)
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <div>
                    <a href="https://doi.org/10.7910/DVN/KYFJ9L" class="cffm" style="display:none"
                      >Descarga los datos aquí</a
                    >
                  </div>
                  El conjunto de datos corresponde a la dotación por régimen/año
                  obtenida a partir de los datos de Transparencia. Es decir, el
                  número de empleados públicos en 24 ministerios (264 servicios)
                  de acuerdo al tipo básico de contratación (Honorario, Planta,
                  Contrata) distribuidos desde el 2010 al 2020. El conjunto
                  tiene 8 variables: "<strong>Servicio</strong>"
                  ,"<strong>ID_servicio</strong>", "<strong>Year</strong>",
                  "<strong>Ministerio</strong>",
                  "<strong>ID_ministerio</strong>", "<strong>Contrata</strong>",
                  "<strong>Honorario</strong>", "<strong>Planta</strong>" y 2340
                  observaciones.
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-3 variant="info">
                Datos desagregados de dotación de personal en 24 Ministerios del
                Estado de Chile.
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-3"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <div>
                    <a href="https://doi.org/10.7910/DVN/H4LKEA" class="cffm" style="display:none"
                      >Descarga los datos aquí</a
                    >
                  </div>
                  El conjunto de datos corresponde a la dotación de personal de
                  24 Ministerios (264 servicios) del Estado de Chile. Comprende
                  un intervalo de tiempo entre el 2010 y 2020. Las variables
                  incluidas son: <strong>ID, Nombre, Sexo</strong> (que
                  corresponden a la identificación, nombre y sexo
                  respectivamente del funcionario),
                  <strong
                    >Ministerio, ID_Ministerio, Servicio, ID_Servicio</strong
                  >
                  (que corresponden al Ministerio, el nombre corto del
                  Ministerio, el servicio y nombre corto del Servicio donde
                  trabaja el funcionario), <strong>Year</strong> (año en el que
                  trabaja el funcionario),
                  <strong
                    >Estamento, Grado, Profesión, Remuneración, Régimen</strong
                  >
                  (corresponden al estamento [administrativo, técnico, …], grado
                  EUS [1,2, …] , profesión y remuneración promedio anual
                  respectivamente del funcionario),
                  <strong>FechaIn</strong> (fecha de entrada al servicio,
                  <strong>FechaOut</strong> (fecha de salida del servicio),
                  <strong>Longevidad</strong> (antigüedad del funcionario en el
                  servicio, medido en el intervalo máximo de 11 años entre el
                  2010 y el 2020, <strong>MaxLongevidadServicio</strong> (es la
                  longevidad máxima observada entre los funcionarios del
                  servicio), <strong>Status</strong> (1 si la longevidad del
                  funcionario es menor a la longevidad máxima de servicio, 0 si
                  es igual).</b-card-text
                >
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-4 variant="info">
                Datos crudos de dotación de personal en 24 Ministerios del
                Estado de Chile.
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-4"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <div>
                    <a
                      href="http://datagob-github.s3-website-us-east-1.amazonaws.com/%20"
                      class="cffm" style="display:none"
                    >
                      Descarga los datos aquí
                    </a>
                  </div>
                  El conjunto de datos corresponde a la dotación de personal de
                  24 Ministerios (269 servicios) del Estado de Chile. Comprende
                  un intervalo de tiempo entre el 2006 y 2020, con el mayor
                  nivel desagregado en los datos. La data está organizada en
                  distintos niveles. Ministerio, Servicios, año y tipo de
                  contrato: Honorario, Contrata y Planta.
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from './components/header/Header.vue'
import NavBar2 from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navbar,
    Header,
    NavBar2,
    Footer
  }
}
</script>

<style scoped>
p {
  font-family: sans-serif;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin: 2rem 0;
}

.lead {
  font-size: 1.7rem;
  margin: 3.4rem 0 1.7rem 0;
  font-family: sans-serif;
}

.center {
  text-align: center;
}

.btn,
.btn:hover {
  background: #f7f7f7 !important;
  border: none;
}
</style>
