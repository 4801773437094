<template>
  <div>
    <div class="home">
      <Navbar />
    </div>

    <div>
      <div>
        <Header
          postTitle="Sigue las historias individualizadas de las personas que han trabajado o continuan trabajando en el Estado"
          title="Personas: Historias Individualizadas"
          paragraph="Estos visores ofrecen un mayor nivel desagregado de acuerdo con los datos publicados en los portales de transparencia. Puede buscar personas usando sus apellidos y nombres."
          image="/img_fondef/portada/personas.png"
        />
      </div>
      <b-container>
        <NavBar2 />
        <br />
        <br />

        <blockquote class="blockquote">
          <p class="lead">
            PERSONAS EN 24 MINISTERIOS
          </p>
          <footer class="blockquote-footer">
            Puede buscar personas individualizadas usando sus apellidos y
            nombres. Al ir escribiendo, en el buscador irán apareciendo
            resultados parciales. Para facilitar la búsqueda puede además
            emplear filtros. Debido a que los nombres de los funcionarios
            (originalmente extraídos desde los portales de transparencia) pueden
            estar escritos de distinta manera (por ejemplo, usando uno solo de
            los nombres en una ocasión y dos nombres en otra) es posible obtener
            dos o más nombres con información sobre el mismo funcionario. En
            todo caso en la sección Reporte detallado podrá igual encontrar
            información útil.
          </footer>
        </blockquote>
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiM2E5YzU2N2EtMmI1Yi00MWFiLTk0MTYtNjk2ZjAzNTk4MTc3IiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSection48208f041c110e242eab"
        />
        <br /><br /><br />
        <blockquote class="blockquote">
          <p class="lead">
            PERSONAS EN EL MINISTERIO DE SALUD
          </p>
          <footer class="blockquote-footer">
            Mediante este visor puede encontrar información de personas que
            trabajan en el Ministerio de Salud usando sus apellidos y nombres.
            El reporte detallado indicará <strong>Nombre</strong>,
            <strong>Remuneración</strong>, <strong>Servicio</strong>,
            <strong>Profesión</strong>, <strong>Longevidad</strong> y
            <strong>Régimen</strong> de contratación. Dado que en los datos de
            origen, publicados en los Portales de Transparencia, una misma
            persona puede eventualmente aparecer con nombres diferentes (por
            ejemplo, algunas veces con el nombre completo, incluyendo primero y
            segundo nombre, y en otras solo con solo su primer nombre) es
            posible que en las búsquedas con el visor se observen estos casos.
            En todos ellos, el reporte detallado permitirá obtener información.
            Es recomendable mientras se escribe dar tiempo para que el sistema
            ubique todos los nombres que hacen match y así comprobar la
            existencia o no de nombres distintos para una misma persona.
          </footer>
        </blockquote>
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiODM5OWZkZTMtYjFlYi00MTg5LTgxZGMtNjIwNmE1NDJmNWE0IiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSectionb878cd947f9581d32c6d"
        />
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from './components/header/Header.vue'
import PowerBI from './components/powerbi/PowerBI.vue'
import NavBar2 from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navbar,
    Header,
    PowerBI,
    NavBar2,
    Footer
  }
}
</script>

<style scoped>
p {
  font-family: Georgia, serif;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin: 2rem 0;
}

.lead {
  font-size: 1.7rem;
  margin: 3.4rem 0 1.7rem 0;
  font-family: sans-serif;
  padding:20px;
}

.center {
  text-align: center;
}

.blockquote .lead {
  font-weight: bold;
  color: #bdc3c7;
}

.blockquote-footer::before {
  content: "";
}

strong {
  color: #980100;
}
</style>
