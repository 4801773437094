<template>
  <div class="cnt-head">
    <b-col class="uno">
      <div class="left">
        <div>
          <img class="img" src="@/assets/img/private/growth (2).png" alt="" />
        </div>
        <div class="text">
          <h4 class="tetxOne">Perfil:</h4>
          &nbsp;
          <h4 class="textDos">{{ perfil }}</h4>
        </div>
      </div>
    </b-col>
    <b-col class="dos" v-if="showMinister">
      <h4 class="tetxOne">Ministerio:</h4>
      &nbsp;
      <h4 class="textDos">{{ ministerio }}</h4></b-col
    >
    <b-col class="tres" v-if="showService">
      <h4 class="tetxOne">Servicio:</h4>
      &nbsp;
      <h4 class="textDos">{{ servicio }}</h4></b-col
    >
  </div>
</template>
<script>
import { formatRole } from '../../components/tools/utils'
export default {
  data () {
    return {
      perfil: '',
      ministerio: '',
      servicio: ''
    }
  },
  props: {
    showService: {
      type: Boolean,
      default: true
    },
    showMinister: {
      type: Boolean,
      default: true
    }
  },

  async mounted () {
    const idServicio = this.$route.params.idServicio
    if (!idServicio) return

    const userInfo = JSON.parse(localStorage.getItem('user'))
    const data = userInfo.servicios.find((e) => e.id === parseInt(idServicio))
    if (!data) return

    this.perfil = formatRole(data.rolNombre)
    this.servicio = data.nombre
    this.ministerio = data.ministerio.nombre
  }
}
</script>
<style scoped>
.cnt-head {
  margin: 0;
  background: #24c6ab;
  height: 120px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.uno {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.dos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.tres {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.text {
  color: #052333;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.left{
  display: inline-flex;
}

.img {
  width: 70px;
}

.tetxOne {
  font-weight: 600;
  color: #052333;
  margin: 0 !important;
  font-size: 25px;
}

.textDos {
  font-weight: 350;
  color: #052333;
  margin: 0 !important;
  text-align: center;
  font-size: 24px;
}

@media screen and (max-width: 1850px) {
  .cnt-head {
    height: 120px;
  }

  .text {
    margin-left: 14px;
  }

  .img {
    width: 65px;
  }

  .tetxOne {
    font-size: 21px;
  }

  .textDos {
    font-size: 20px;
  }
}

@media screen and (max-width: 1560px) {
  .cnt-head {
    height: 90px;
  }

  .text {
    margin-left: 12px;
  }

  .img {
    width: 55px;
  }

  .tetxOne {
    font-size: 18px;
  }

  .textDos {
    font-size: 17px;
  }
}

@media screen and (max-width: 1560px) {
  .cnt-head {
    height: 88px;
  }

  .img {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  .tetxOne {
    font-size: 14px;
  }

  .textDos {
    font-size: 13px;
  }
}

@media screen and (max-width: 1000px) {
  .dos {
    display: none;
  }
}

@media screen and (max-width: 660px) {
  .tetxOne {
    font-size: 11px;
  }

  .textDos {
    font-size: 10px;
  }
}

@media screen and (max-width: 460px) {
  .uno {
    display: none;
  }

  .tetxOne {
    font-size: 13px;
  }

  .textDos {
    font-size: 12px;
  }
}
</style>
