<template>
  <div class="container">
    <Title/>
    <Breadcrumb :links="breadCrumbData" />
    <Carrusel />

    <h1 class="privado-titulo"> Indicadores de Desempeño año 2018-2021</h1>

    Corresponde al resultado de la evaluación del logro de las metas de los indicadores comprometidos para el año 2021, proporcionado por el Departamento de Gestión Pública de la Dirección de Presupuestos.

    <b-row v-if="anexo">
        <b-col>
            <!-- <Instrucciones/> -->
        </b-col>
        <b-col>
            <!-- <Visualizar seccion="indicadores" /> -->
        </b-col>
        <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
            <b-form-checkbox v-model="anexo.datos.no_aplica">
                No Aplica
            </b-form-checkbox>
        </b-col>
    </b-row>

    <div v-if="anexo">

      <h5 class="text-indicator">Cuadro 6</h5>
      <h5 class="text-indicator">
        RESULTADO GLOBAL AÑO 2021: {{anexo.datos.lista[0].logro_total | formatDecimal_1}}%
      </h5>
      <div v-for="item in anexo.datos.lista" :key="item.nombre_indicador" class="seccion-background-color-table">
        <h5>
          <span>{{ item.nombre_indicador }}</span>
        </h5>
        <hr>
        <h5>
          Producto Estratégico:
          <span v-html="item.producto_estrategico"></span>
        </h5>

        <h5>
          Fórmula de cálculo:
          <span>{{ item.numerador }}</span> / <span>{{ item.denominador }}</span>
        </h5>

        <table class=" table table-striped">
          <thead>
            <tr>
              <th scope="col">Variables</th>
              <th scope="col">{{ anioBgi - 3 }}</th>
              <th scope="col">{{ anioBgi - 2 }}</th>
              <th scope="col">{{ anioBgi - 1 }}</th>
              <th scope="col">{{ anioBgi }}</th>
              <th scope="col">Meta {{ anioBgi }}</th>
              <th scope="col">% de Logro {{ anioBgi }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="tr">
              <td>Valor Efectivo</td>
              <td style = "white-space: nowrap">
                  {{item.t3.v_efectivo | formatDecimal}}
                <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
              </td>
              <td style = "white-space: nowrap">
                  {{item.t2.v_efectivo | formatDecimal}}
                <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
              </td>
              <td style = "white-space: nowrap">
                  {{item.t1.v_efectivo | formatDecimal}}
                <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
              </td>
              <td style = "white-space: nowrap">
                  {{item.t0.v_efectivo | formatDecimal}}
                <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
              </td>
              <td style = "white-space: nowrap">
                  {{item.meta.v_efectivo | formatDecimal}}
                <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
              </td>
              <td rowspan="3">{{ item.logro }}%</td>
            </tr>

            <tr class="tr">
                <td>{{item.numerador}}</td>
                <td style = "white-space: nowrap">
                    {{item.t3.numerador | formatDecimal}}
                    <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
                </td>
                <td style = "white-space: nowrap">
                    {{item.t2.numerador | formatDecimal}}
                    <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
                </td>
                <td style = "white-space: nowrap">
                    {{item.t1.numerador | formatDecimal}}
                    <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
                </td>
                <td style = "white-space: nowrap">
                    {{item.t0.numerador | formatDecimal}}
                    <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
                </td>
                <td style = "white-space: nowrap">
                    {{item.meta.numerador | formatDecimal}}
                    <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
                </td>
            </tr>

             <tr class="tr">
                <td>{{item.denominador}}</td>
                <td style = "white-space: nowrap">
                    {{item.t3.denominador | formatDecimal}}
                    <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
                </td>
                <td style = "white-space: nowrap">
                    {{item.t2.denominador | formatDecimal}}
                    <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
                </td>
                <td style = "white-space: nowrap">
                    {{item.t1.denominador | formatDecimal}}
                    <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
                </td>
                <td style = "white-space: nowrap">
                    {{item.t0.denominador | formatDecimal}}
                    <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
                </td>
                <td style = "white-space: nowrap">
                    {{item.meta.denominador | formatDecimal}}
                    <!--<span class="data" v-if="item.unidad === '%'">{{item.unidad}}</span>-->
                </td>
            </tr>
          </tbody>
        </table>
        <small>Fuente: Departamento de Gestión Pública de la Dirección de Presupuestos</small>
      </div>
    </div>

  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'

import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '@/components/private/Carrusel.vue'

import axios from 'axios'

export default {
  mixins: [utils],
  components: {
    Title,
    Breadcrumb,
    Carrusel

  },

  data () {
    return {
      breadCrumbData: null,
      anexo: null
    }
  },
  computed: {

  },
  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Indicadores de Desempeño', routeName: 'oservicio', routeParams: { idServicio: this.$route.params.idServicio } }
      ]
    },
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'indicadores-desempeno'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.lista = anexo.datos.lista || []
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }

  },
  mounted () {
    this.obtenerRevision()
    this.setBreadcrumbData()
  }
}
</script>

<style scoped>
.text-indicator {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

th {
  text-align: center !important;
  font-size: 1.4rem !important ;
}

td {
  text-align: left !important;
  font-size: 1.3rem !important;
}

table {
  margin-top: 10px;
  margin-bottom: 20px;
}

.col-Left {
  color: #052333;
  font-weight: bold;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  width: 92%;
  padding: 5px;
  text-align: left !important;
  font-size: 13px;
}
h5 {
  color: #052333;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}

span {
  font-weight: 400;
  margin-left: 5px;
}

.div-span-table {
  position: relative;
  top: 32px;
}
</style>
