<template>
  <div >
    <div>
      <div class="seccion-background-color-table">
        <h4>Cuadro 1</h4>
        <h4 class="subtitulo-c3">
          Ingresos y Gastos devengados año 2019 – 2020 (miles de pesos)
        </h4>

      <table class="table table-striped table-white">
        <thead>
          <tr>
            <th colspan="4">
              <h4 class="subtitulo-c3">Ingresos</h4>
            </th>
          </tr>
          <tr>
            <th>Denominación</th>
            <th>{{ anio - 1 }}</th>
            <th>{{ anio }}</th>
            <th style="width:200px">notas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in ingresos" :key="index">
            <td style="text-align:left">{{ item.denominacion }}</td>
            <td style="text-align:right">$ {{ item.anio_2 }}</td>
            <td style="text-align:right">$ {{ item.anio_1 }}</td>
            <td v-if="can_edit_anexos_de_servicios()"><input type="text" class="form-control" size="45" v-model="item.nota"></td>
            <td v-if="!can_edit_anexos_de_servicios()">{{ item.nota }}</td>
          </tr>
        </tbody>
        <tbody>
            <tr class="">
                <th colspan="1" class="">
                    Total Ingresos
                </th>
                <th colspan="1">
                    {{suma(ingresos, 'anio2019') | formatInteger}}
                </th>
                <th colspan="1">
                    {{suma(ingresos, 'anio2020') | formatInteger}}
                </th>
                <th></th>
            </tr>
        </tbody>
        <thead>
          <tr>
            <th colspan="4">
              <h4 class="subtitulo-c3">Gastos</h4>
            </th>
          </tr>
          <tr>
            <th>Denominación</th>
            <th>{{ anio - 1 }}</th>
            <th>{{ anio }}</th>
            <th>notas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in gastos" :key="index">
            <td style="text-align:left">{{ item.denominacion }}</td>
            <td style="text-align:right">$ {{ item.anio_2 }}</td>
            <td style="text-align:right">$ {{ item.anio_1 }}</td>
            <td v-if="can_edit_anexos_de_servicios()"><input type="text" class="form-control" size="45" v-model="item.nota"></td>
            <td v-if="!can_edit_anexos_de_servicios()">{{ item.nota }}</td>
          </tr>
        </tbody>
        <tbody>
            <tr class="">
                <th colspan="1" class="">
                    Total Gastos
                </th>
                <th colspan="1">
                    {{suma(gastos, 'anio2019') | formatInteger}}
                </th>
                <th colspan="1">
                    {{suma(gastos, 'anio2020') | formatInteger}}
                </th>
                <th></th>
            </tr>
        </tbody>
        <thead>
          <tr>
            <th colspan="4">
              <h4 class="subtitulo-c3">Resultado</h4>
            </th>
          </tr>
          <tr>
            <th style="text-align:left">Denominación</th>
            <th style="text-align:right">{{ anio - 1 }}</th>
            <th style="text-align:right">{{ anio }}</th>
            <th>notas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in resultados" :key="index">
            <td>{{ item.denominacion }}</td>
            <td>{{ item.anio_2 }}</td>
            <td>{{ item.anio_1 }}</td>
            <td v-if="can_edit_anexos_de_servicios()"><input type="text" class="form-control" size="45" v-model="item.nota"></td>
            <td v-if="!can_edit_anexos_de_servicios()">{{ item.nota }}</td>
          </tr>
        </tbody>
        <tfoot>
          <th colspan="4">
            <button v-if="can_edit_anexos_de_servicios()" class="boton-primario" @click="guardar_notas" style="float:right">
              Guardar Notas
            </button>
          </th>
        </tfoot>

      </table>
    </div>

    <!-- INICIO DOLARES -->
    <div class="seccion-background-color-table" v-if="hasDolares">
        <h4>Cuadro 1</h4>
        <h4 class="subtitulo-c3">
          Ingresos y Gastos devengados año 2019 – 2020 (Dólares)
        </h4>

      <table class="table table-striped table-white">
        <thead>
          <tr>
            <th colspan="4">
              <h4 class="subtitulo-c3">Ingresos</h4>
            </th>
          </tr>
          <tr>
            <th>Denominación</th>
            <th>{{ anio - 1 }}</th>
            <th>{{ anio }}</th>
            <th style="width:200px">notas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in ingresosUsd" :key="index">
            <td style="text-align:left">{{ item.denominacion }}</td>
            <td style="text-align:right">$ {{ item.anio_2 }}</td>
            <td style="text-align:right">$ {{ item.anio_1 }}</td>
            <td v-if="can_edit_anexos_de_servicios()"><input type="text" class="form-control" size="45" v-model="item.nota"></td>
            <td v-if="!can_edit_anexos_de_servicios()">{{ item.nota }}</td>
          </tr>
        </tbody>
        <tbody>
            <tr class="">
                <th colspan="1" class="">
                    Total Ingresos
                </th>
                <th colspan="1">
                    {{suma(ingresosUsd, 'anio2019') | formatInteger}}
                </th>
                <th colspan="1">
                    {{suma(ingresosUsd, 'anio2020') | formatInteger}}
                </th>
                <th></th>
            </tr>
        </tbody>
        <thead>
          <tr>
            <th colspan="4">
              <h4 class="subtitulo-c3">Gastos</h4>
            </th>
          </tr>
          <tr>
            <th>Denominación</th>
            <th>{{ anio - 1 }}</th>
            <th>{{ anio }}</th>
            <th>notas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in gastosUsd" :key="index">
            <td style="text-align:left">{{ item.denominacion }}</td>
            <td style="text-align:right">$ {{ item.anio_2 }}</td>
            <td style="text-align:right">$ {{ item.anio_1 }}</td>
            <td v-if="can_edit_anexos_de_servicios()"><input type="text" class="form-control" size="45" v-model="item.nota"></td>
            <td v-if="!can_edit_anexos_de_servicios()">{{ item.nota }}</td>
          </tr>
        </tbody>
        <tbody>
            <tr class="">
                <th colspan="1" class="">
                    Total Gastos
                </th>
                <th colspan="1">
                    {{suma(gastosUsd, 'anio2019') | formatInteger}}
                </th>
                <th colspan="1">
                    {{suma(gastosUsd, 'anio2020') | formatInteger}}
                </th>
                <th></th>
            </tr>
        </tbody>
        <thead>
          <tr>
            <th colspan="4">
              <h4 class="subtitulo-c3">Resultado</h4>
            </th>
          </tr>
          <tr>
            <th>Denominación</th>
            <th>{{ anio - 1 }}</th>
            <th>{{ anio }}</th>
            <th>notas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in resultadosUsd" :key="index">
            <td style="text-align:left">{{ item.denominacion }}</td>
            <td style="text-align:right">$ {{ item.anio_2 }}</td>
            <td style="text-align:right">$ {{ item.anio_1 }}</td>
            <td v-if="can_edit_anexos_de_servicios()"><input type="text" class="form-control" size="45" v-model="item.nota"></td>
            <td v-if="!can_edit_anexos_de_servicios()">{{ item.nota }}</td>
          </tr>
        </tbody>
        <tfoot>
          <th colspan="4">
            <button v-if="can_edit_anexos_de_servicios()" class="boton-primario" @click="guardar_notas" style="float:right">
              Guardar Notas
            </button>
          </th>
        </tfoot>

      </table>
    </div>
    <!-- FINDOLARES -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { utils } from '@/components/tools/ViewUtils.js'

export default {
  props: ['state'],
  mixins: [utils],
  components: {},
  data () {
    return {
      dataTable: null,
      ingresos: null,
      hasDolares: false,
      dataTableUsd: null,
      gastos: null,
      result: null,
      gastosUsd: [],
      ingresosUsd: [],
      resultadosUsd: [],
      gastoss: [],
      resultados: [],
      anio: process.env.VUE_APP_ANIO,
      url: `${process.env.VUE_APP_RUTA_API}/instituciones/secciones_cf/${this.$route.params.idServicio}/`
    }
  },
  methods: {

    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'rrhh_devengados'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.texto = anexo.datos.texto || ''
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }
    // suma (datos, columna) {
    //   if (!datos) { return null }
    //   return datos.reduce((sum, item) => sum + parseFloat(item[columna]), 0)
    // },
    // guardar_notas: async function () {
    //   var notas = []
    //   this.gastos.forEach(item => {
    //     if (item && item.nota) { notas.push({ subtitulo: item.subtitulo, nota: item.nota, moneda: item.moneda }) }
    //   })

    //   this.ingresos.forEach(item => {
    //     if (item && item.nota) { notas.push({ subtitulo: item.subtitulo, nota: item.nota, moneda: item.moneda }) }
    //   })

    //   this.resultados.forEach(item => {
    //     if (item && item.nota) { notas.push({ subtitulo: item.subtitulo, nota: item.nota, moneda: item.moneda }) }
    //   })

    //   this.gastosUsd.forEach(item => {
    //     if (item && item.nota) { notas.push({ subtitulo: item.subtitulo, nota: item.nota, moneda: item.moneda }) }
    //   })

    //   this.ingresosUsd.forEach(item => {
    //     if (item && item.nota) { notas.push({ subtitulo: item.subtitulo, nota: item.nota, moneda: item.moneda }) }
    //   })

    //   this.resultadosUsd.forEach(item => {
    //     if (item && item.nota) { notas.push({ subtitulo: item.subtitulo, nota: item.nota, moneda: item.moneda }) }
    //   })

    //   console.log(notas)

    //   var params = { nombre: 'recursos_financieros_resultados_notas' }
    //   axios.put(this.url, notas, { params: params, headers: this.get_headers() }).then(
    //     this.successMessage('Notas guardadas')
    //   )
    // },
    // obtener_seccion () {
    //   axios.get(this.url, { params: { anio: 2020, nombre: 'recursos_financieros_resultados_notas' }, headers: this.get_headers() })
    //     .then(res => {
    //       res.data.forEach(item => { item.moneda = item.moneda || 'CLP' })

    //       this.gastos.forEach(item => {
    //         var filtrado = res.data.filter(nota => { return nota.subtitulo === item.subtitulo && (nota.moneda === 'CLP' || !nota.moneda) })
    //         item.nota = filtrado.length > 0 ? filtrado[0].nota : null
    //       })

    //       this.ingresos.forEach(item => {
    //         var filtrado = res.data.filter(nota => { return nota.subtitulo === item.subtitulo && (nota.moneda === 'CLP' || !nota.moneda) })
    //         item.nota = filtrado.length > 0 ? filtrado[0].nota : null
    //       })

    //       this.resultados.forEach(item => {
    //         var filtrado = res.data.filter(nota => { return nota.subtitulo === item.subtitulo && (nota.moneda === 'CLP' || !nota.moneda) })
    //         item.nota = filtrado.length > 0 ? filtrado[0].nota : null
    //       })

    //       this.gastosUsd.forEach(item => {
    //         var filtrado = res.data.filter(nota => { return nota.subtitulo === item.subtitulo && nota.moneda === 'USD' })
    //         item.nota = filtrado.length > 0 ? filtrado[0].nota : null
    //       })

    //       this.ingresosUsd.forEach(item => {
    //         var filtrado = res.data.filter(nota => { return nota.subtitulo === item.subtitulo && nota.moneda === 'USD' })
    //         item.nota = filtrado.length > 0 ? filtrado[0].nota : null
    //       })

    //       this.resultadosUsd.forEach(item => {
    //         var filtrado = res.data.filter(nota => { return nota.subtitulo === item.subtitulo && nota.moneda === 'USD' })
    //         item.nota = filtrado.length > 0 ? filtrado[0].nota : null
    //       })

    //       this.$forceUpdate()
    //     }
    //     )
    // },

    // obtener: async function () {
    //   try {
    //     const res = await axios.get(
    //       `${process.env.VUE_APP_RUTA_API}/anexos/anexo/public_list/?anexo=13&servicio=${this.$route.params.idServicio}`,
    //       {
    //         headers: {
    //           Authorization: `JWT ${localStorage.getItem('userToken')}`
    //         }
    //       }
    //     )

    //     if (res.data[0].datos.modificadoUsd) {
    //       this.hasDolares = true
    //       this.dataTableUsd = res.data[0].datos.modificadoUsd[`${process.env.VUE_APP_ANIO}`]
    //     }

    //     this.dataTable = res.data[0].datos.modificado[`${process.env.VUE_APP_ANIO}`]

    //     // Gastos
    //     this.gastos = this.dataTable.filter((d) => d.subtitulo > 20 && d.subtitulo !== 99)
    //     this.gastos.forEach((item) => {
    //       item.anio_2 = new Intl.NumberFormat('de-DE').format(item.anio2019)
    //       item.denominacion = item.nombre
    //       item.moneda = 'CLP'
    //       item.anio_1 = new Intl.NumberFormat('de-DE').format(item.anio2020)
    //     })

    //     // Ingresos
    //     this.ingresos = this.dataTable.filter((d) => d.subtitulo < 20)
    //     this.ingresos.forEach((item) => {
    //       item.anio_2 = new Intl.NumberFormat('de-DE').format(item.anio2019)
    //       item.denominacion = item.nombre
    //       item.moneda = 'CLP'
    //       item.anio_1 = new Intl.NumberFormat('de-DE').format(item.anio2020)
    //     })

    //     // Resultado
    //     this.resultados = this.dataTable.filter((d) => d.subtitulo === 99)
    //     this.resultados.forEach((item) => {
    //       item.anio_2 = new Intl.NumberFormat('de-DE').format(item.anio2019)
    //       item.denominacion = item.nombre
    //       item.moneda = 'CLP'
    //       item.anio_1 = new Intl.NumberFormat('de-DE').format(item.anio2020)
    //     })

    //     // Gastos
    //     if (this.dataTableUsd) {
    //       this.gastosUsd = this.dataTableUsd.filter((d) => d.subtitulo > 20 && d.subtitulo !== 99)

    //       this.gastosUsd.forEach((item) => {
    //         item.anio_2 = new Intl.NumberFormat('de-DE').format(item.anio2019)
    //         item.denominacion = item.nombre
    //         item.moneda = 'USD'
    //         item.anio_1 = new Intl.NumberFormat('de-DE').format(item.anio2020)
    //       })

    //       // Ingresos
    //       this.ingresosUsd = this.dataTableUsd.filter((d) => d.subtitulo < 20)

    //       this.ingresosUsd.forEach((item) => {
    //         item.anio_2 = new Intl.NumberFormat('de-DE').format(item.anio2019)
    //         item.denominacion = item.nombre
    //         item.moneda = 'USD'
    //         item.anio_1 = new Intl.NumberFormat('de-DE').format(item.anio2020)
    //       })

    //       // Resultado
    //       this.resultadosUsd = this.dataTableUsd.filter((d) => d.subtitulo === 99)

    //       this.resultadosUsd.forEach((item) => {
    //         item.anio_2 = new Intl.NumberFormat('de-DE').format(item.anio2019)
    //         item.denominacion = item.nombre
    //         item.moneda = 'USD'
    //         item.anio_1 = new Intl.NumberFormat('de-DE').format(item.anio2020)
    //       })
    //     }

    //     console.log(this.gastosUsd)
    //     console.log(this.gastosUsd)
    //     console.log(this.gastosUsd)
    //     console.log(this.gastosUsd)

    //     this.obtener_seccion()
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  },
  mounted () {
    this.obtenerRevision()
  }
}
</script>

<style scoped>

.subtitulo-c3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  color: #2b425f;
  text-align: center;
}

h4 {
  text-align: center;
  font-size: 1.1rem;
  color: #2b425f;
  font-weight: bold;
  margin: 0;
}

hr {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

table {
  margin: 0;
  border: 1px solid #f5f5f5;
}
thead {
  background-color: #f5f5f5;
  color: #24c6ab;
  text-align: center !important;
}

tr:hover {
  background: none !important;
  /* text-align: left !important; */
}

.table-resaltar {
  font-weight: bold;
  background: #f5f5f5;
}

/* .th-one {
  width: 100px !important;
} */

@media screen and (max-width: 1800px) {
  th {
    font-size: 1.2rem !important;
  }
  td {
    font-size: 1.1rem !important;
  }
}
td {
  /* text-align: left !important; */
}

@media screen and (max-width: 1300px) {
  .subtitulo-c3 {
    font-size: 0.8rem;
  }

  th {
    font-size: 1rem !important;
  }
  td {
    font-size: 0.9rem !important;
  }
}

@media screen and (max-width: 1000px) {
  th {
    font-size: 0.8rem !important;
  }
  td {
    font-size: 0.7rem !important;
  }
}

@media screen and (max-width: 700px) {
  th {
    font-size: 0.6rem !important;
  }
  td {
    font-size: 0.5rem !important;
  }
}
</style>
