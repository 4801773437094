<template>
  <div class="profile">
    <Header/>
  <div class="container">
    <b-row>
      <b-col class="section-button">
        <b-button class="decoration-button" :to="{name: 'ministerio', params: { idServicio: idServicio }}">
          <Ministro />Presentación del Ministro o Ministra
        </b-button>
      </b-col>

        <b-col class="section-button">
            <b-button class="decoration-button" :to="{name: 'servicios', params: { idServicio: idServicio }}">
            <PServicio /> Presentación del Servicio
            </b-button>
        </b-col>

        <b-col class="section-button">
            <b-button class="decoration-button" :to="{name: 'programaGobierno', params: { idServicio: idServicio }}">
            <RGestion />Resultado de la Gestión
            </b-button>
        </b-col>

        <b-col class="section-button">
            <b-button class="decoration-button" :to="{ name: 'desafio', params: { idServicio: idServicio } }">
            <DesafiosIcon /><span style="margin-left:20px">Desafíos
            </span>
            </b-button>
        </b-col>

        <b-col class="section-button">
            <b-button class="decoration-button" :to="{name: 'rHumanos', params: { idServicio: idServicio }}">
                <RHumanos /><span style="margin-left:5px">Recursos Humanos
                </span>
            </b-button>
        </b-col>
<!--
        <b-col class="section-button">
            <b-button class="decoration-button"  :to="{name: 'rfinancieross', params: { idServicio: idServicio }}" >
                <RFinancieros /> Recursos Financieros
            </b-button>
        </b-col>

        <b-col class="section-button">
            <b-button class="decoration-button" :to="{name: 'indicador', params: { idServicio: idServicio }}" >
            <Barra /> <span style="margin-left:18px"> Indicadores
            </span>
            </b-button>
        </b-col>
-->
        <b-col class="section-button"><b-button class="decoration-button" :to="{name: 'compromisos', params: { idServicio: idServicio }}">
            <Compromisos /><span style="margin-left:15px">Compromisos
            </span>
            </b-button>
        </b-col>
<!--
        <b-col class="section-button">
            <b-button class="decoration-button"  :to="{name: 'informeprograma', params: { idServicio: idServicio }}" >
                <Evaluacion /><span style="margin-left:13px">Evaluaciones
            </span>
            </b-button>
        </b-col>

        <b-col class="section-button">
            <b-button class="decoration-button"  :to="{name: 'iinstitucional',params: { idServicio: idServicio }}">
              <IInstitucional />Incentivo Institucional
            </b-button>
        </b-col>
-->
        <b-col class="section-button">
        <b-button class="decoration-button" :to="{name: 'icolectivo', params: { idServicio: idServicio }}">
            <IColectivo /><span style="margin-left:4px">Incentivo Colectivo
            </span>
            </b-button>
        </b-col>

        <b-col class="section-button">
          <b-button class="decoration-button" :to="{ name: 'genero', params: { idServicio: idServicio } }">
          <Igualdad /><span style="margin-left:4px">Equidad de Género
            </span> </b-button>
        </b-col>
<!--
        <b-col class="section-button">
            <b-button class="decoration-button" :to="{name: 'leyesTramite', params: { idServicio: idServicio }}">
                <LeyesIcon /><span style="margin-left:30px">Leyes
            </span>
            </b-button>
        </b-col>

        <b-col class="section-button"><b-button class="decoration-button" :to="{ name: 'premios', params: { idServicio: idServicio } }">
            <Reconocimiento />Premios y Reconocimientos
            </b-button>
        </b-col>
-->
        <b-col class="section-button">
            <b-button class="decoration-button"  :to="{name: 'oservicio', params: { idServicio: idServicio }}">
                <OServicio />Organigrama del Servicio
            </b-button>
        </b-col>

        <b-col class="section-button"><b-button class="decoration-button"  :to="{name: 'oministerio', params: { idServicio: idServicio }}">
            <OMinisterio /> Organigrama de Ministerio
            </b-button>
        </b-col>

        <b-col class="section-button"><b-button class="decoration-button"  :to="{name: 'oInfo', params: { idServicio: idServicio }}">
            <Barra />  Info Estratégica, Financiera e Instrumentos de Gestión
            </b-button>
        </b-col>
    </b-row>
  </div>
        <Footer />
  </div>

</template>

<script>
import Footer from '../../components/layout/Footer.vue'
import Header from '../../components/private/Header'
import { utils } from '@/components/tools/ViewUtils.js'
// import { mapState } from 'vuex'

import Ministro from '@/components/icons/button/Ministro.vue'
// import axios from 'axios'
import PServicio from '@/components/icons/button/PServicio.vue'
import RGestion from '@/components/icons/button/RGestion.vue'
import DesafiosIcon from '@/components/icons/button/DesafiosIcon.vue'
import RHumanos from '@/components/icons/button/RHumanos.vue'
// import RFinancieros from '@/components/icons/button/RFinancieros.vue'
import Barra from '@/components/icons/button/Barra'
import Compromisos from '@/components/icons/button/Compromisos'
// import Evaluacion from '@/components/icons/button/Evaluacion'
// import IInstitucional from '@/components/icons/button/IInstitucional'
import IColectivo from '@/components/icons/button/IColectivo'
import Igualdad from '@/components/icons/button/Igualdad'
// import LeyesIcon from '@/components/icons/button/LeyesIcon.vue'
import OMinisterio from '@/components/icons/button/OMinisterio.vue'
// import Reconocimiento from '@/components/icons/button/Reconocimiento.vue'
import OServicio from '@/components/icons/button/OServicio'

export default {
  mixins: [utils],
  components: {
    Header,
    Footer,
    Ministro,
    PServicio,
    RGestion,
    DesafiosIcon,
    RHumanos,
    // RFinancieros,
    Barra,
    Compromisos,
    // Evaluacion,
    // IInstitucional,
    IColectivo,
    Igualdad,
    // LeyesIcon,
    OMinisterio,
    // Reconocimiento,
    OServicio
  },
  data () {
    return {
      idServicio: this.$route.params.idServicio,
      perfilId: null,
      permisos: []

    }
  },
  methods: {
  }
}
</script>

<style scoped>
.row {
  margin: 0;
  text-align: center;
  background: #fff;
  /* margin: 30px; */
}

.decoration-button {
    display: flex;
  background: #3d185f;
  text-align: center;
  border: none;
  height: 60px;
  width: 260px;
  font-size: 15px;
  border-radius: 40px;
  align-items: center;
}

.decoration-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 25px;
  background: #052333;
  color: #fff !important;
}

.section-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  background: #052333;
}
</style>
