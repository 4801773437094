<template>
  <div class="programa">
    <Header />
    <Contents />
    <Footer />
  </div>
</template>

<script>
import Header from '../../components/private/Header'
import Footer from '../../components/layout/Footer.vue'
import Contents from './components/programa/Contents.vue'

export default {
  components: {
    Header,
    Contents,
    Footer
  },
  mounted () {
    this.$store.commit('setPrivateView', 'portada-bgi')
  }
}
</script>

<style scoped>
.programa {
  height: 100vh;
}
</style>
