import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

import IndexFondef from '../views/publico/people-fondef/Index.vue'
import ComposicionFondef from '../views/publico/people-fondef/Composicion.vue'
import DotacionFondef from '../views/publico/people-fondef/Dotacion.vue'
import RemuneracionesFondef from '../views/publico/people-fondef/Remuneraciones.vue'
import LongevidadFondef from '../views/publico/people-fondef/Longevidad.vue'
import PersonasFondef from '../views/publico/people-fondef/Personas.vue'
import MinsalFondef from '../views/publico/people-fondef/Minsal.vue'
import DatosFondef from '../views/publico/people-fondef/Datos.vue'
import MinisteriosFondef from '../views/publico/people-fondef/Ministerios.vue'

import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import People from '../views/publico/people/People.vue'
import Ejecucion from '../views/ejecucionPresupuestaria/Ejecucion'
import Bgi2020 from '../views/bgi2020/Home.vue'
import Bgi2021 from '../views/bgi2021/Home.vue'
import Bgi2022 from '../views/bgi2022/Home.vue'
import Bgi2023 from '../views/bgi2023/Home.vue'
import Instituciones from '../views/institutions/Institutions.vue'
import InstitutionsByMinistry from '../views/institutionsByMinistry/ServicesByMinisID.vue'
// import RHumanosPublic from '../views/recursos-humanos/RHumanos'
// import RFinanciero from '../views/recursos-financiero/RFinanciero'
import Indicadores from '../views/public-indicadores/Indicadores'
import AcercaDe from '../views/acerca-de/AcercaDe'
// import Comparate from '../views/comparate/Comparate'
// import Comparator from '../views/comparator/Comparator.vue'
// import ComparateFinanciera from '../views/comparateFinanciero/ComparateFinanciera'
import NotFound from '../views/NotFound/NotFound'
import Callback from '../views/callbackClaveUnica/Callback.vue'
// vistas privadas
import Gestion from '../views/gestion/Gestion.vue'
import PortadaBgi from '../views/portada/PortadaBgi.vue'
import Escritorio from '../views/escritorio/Main.vue'
import Ministerio from '../views/presentacion-ministerio/Ministerio.vue'
import MinistroAvance from '../views/avanceDegestion/MinistroAvance'
import PricipalesAutoridades from '../views/pricipalesAutoridades/PricipalesAutoridades'
import Servicios from '../views/presentacion-servicio/Servicios.vue'
import Somos from '../views/presentacion-servicio-somos/Somos.vue'
import Genero from '../views/genero/Genero.vue'
import LeyTramite from '@/views/leyes/LeyTramite'
import LeyPromulgada from '@/views/leyes/LeyPromulgada'
import ProgramaGobierno from '../views/resultadoGestion/ProgramaGobierno'
import productoEstrategicos from '../views/resultadoGestion/productoEstrategicos'
import Historial from '../views/historial/Historial'
import Antecedente from '../views/antecedente/Antecedente'
// import Comentario from '../views/comentario/Comentario'
import Acceso from '../views/acceso/Acceso.vue'
import RHumano from '../views/rHumanos/RHumanos'
import Desafios from '../views/desafios/Desafios.vue'
import Indicador from '../views/indicador/Indicador'
import Compromisos from '../views/compromisos/Compromisos'
import InformePrograma from '../views/evaluaciones/informePrograma/InformePrograma'
import InformePreliminar from '../views/evaluaciones/informesPreliminar/InformePreliminar'
import IInstitucional from '../views/incentivoInstitucional/IInstitucional'
import OServicio from '../views/organigramaServicio/main'
import OMinisterio from '../views/organigramaMinisterio/main'
import RFinancieross from '../views/rFinanciero/RFinancieross'
import IColectivo from '../views/incentivoColectivo/IColectivo'
import Premios from '../views/premios/Premios'
import oInfo from '../views/info/Main'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/callback/claveunica', name: 'claveunicaCallback', component: Callback },
  { path: '/persona-en-el-estado/:anio', name: 'people', component: People },
  { path: '/persona-institucion/:id/:anio', name: 'people-institucion', component: People },
  //   { path: '/recursos-humanos/:id', name: 'rhumanos', component: RHumanosPublic },
  //   { path: '/recursos-financieros/:id', name: 'rfinancieros', component: RFinanciero },
  { path: '/Indicadores/:id', name: 'indicadores', component: Indicadores },
  { path: '/ejecucion-presupuestaria', name: 'ejecucion', component: Ejecucion },
  { path: '/bgi2020', name: 'bgi2020', component: Bgi2020 },
  { path: '/bgi2021', name: 'bgi2021', component: Bgi2021 },
  { path: '/bgi2022', name: 'bgi2022', component: Bgi2022 },
  { path: '/bgi2023', name: 'bgi2023', component: Bgi2023 },

  { path: '/instituciones', name: 'instituciones', component: Instituciones },
  { path: '/instituciones/:id', name: 'instituciones-by-ministry', component: InstitutionsByMinistry },
  { path: '/login', name: 'login', component: Login },
  { path: '/acerca-de/:id', name: 'acercade', component: AcercaDe },
  //   { path: '/comparate', name: 'comparate', component: Comparate },
  //   { path: '/comparator', name: 'comparator', component: Comparator },
  //   { path: '/comparate-financiera', name: 'ComparateFinanciera', component: ComparateFinanciera },
  // VISTAS DE ADMINISTRADOR

  // VISTA DE HOME ADMINISTRATIVO
  { path: '/gestion', name: 'gestion', component: Gestion, meta: { autenticado: true } },
  { path: '/:idUsuario/portada-bgi', name: 'bgi', component: PortadaBgi, meta: { autenticado: true } },
  { path: '/:idUsuario/:idServicio/escritorio-de-gestion-virtual', name: 'buttons', component: Escritorio, meta: { autenticado: true } },

  { path: '/escritorio-de-gestion-virtual/:idServicio/presentacion-de-servicio/resumen', name: 'servicios', component: Servicios, meta: { autenticado: true } },
  { path: '/escritorio-de-gestion-virtual/:idServicio/presentacion-de-servicio/somos', name: 'somos', component: Somos, meta: { autenticado: true } },
  { path: '/escritorio-de-gestion-virtual/:idServicio/presentacion-de-servicio/pricipales-autoridades', name: 'pricipalesAutoridades', component: PricipalesAutoridades, meta: { autenticado: true } },
  { path: '/escritorio-de-gestion-virtual/:idServicio/presentacion-del-ministro', name: 'ministerio', component: Ministerio, meta: { autenticado: true } },
  { path: '/escritorio-de-gestion-virtual/:idServicio/presentacion-del-ministro/avance-de-la-gestion', name: 'ministroAvance', component: MinistroAvance, meta: { autenticado: true } },
  { path: '/escritorio-de-gestion-virtual/:idServicio/equidad-de-genero', name: 'genero', component: Genero, meta: { autenticado: true } },
  { path: '/:idUsuario/:idServicio/portada-bgi/configuraciones', name: 'acceso', component: Acceso, meta: { autenticado: true } },
  { path: '/:idUsuario/:idServicio/portada-bgi/configuraciones/admin', name: 'accesoAdmin', component: Acceso, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/resultado-de-la-gestion/programa-de-gobierno', name: 'programaGobierno', component: ProgramaGobierno, meta: { autenticado: true } },

  { path: '/portada-bgi/:idServicio/resultado-de-la-gestion/resultado-de-productos-estrategicos', name: 'productoEstrategicos', component: productoEstrategicos, meta: { autenticado: true } },
  { path: '/portada-bgi/historial', name: 'historial', component: Historial, meta: { autenticado: true } },
  { path: '/portada-bgi/antecedente-para-la-declaracion', name: 'antecedente', component: Antecedente, meta: { autenticado: true } },
  // { path: '/comentarios', name: 'comentario', component: Comentario, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/leyes/en-tramite', name: 'leyesTramite', component: LeyTramite, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/leyes/promulgadas', name: 'leyesPromulgadas', component: LeyPromulgada, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/recursos-humanos', name: 'rHumanos', component: RHumano, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/desafios', name: 'desafio', component: Desafios, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/indicador', name: 'indicador', component: Indicador, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/compromisos', name: 'compromisos', component: Compromisos, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/evaluaciones-informe-programa', name: 'informeprograma', component: InformePrograma, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/evaluaciones-informe-preliminar', name: 'informepreliminar', component: InformePreliminar, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/incentivo-institucional', name: 'iinstitucional', component: IInstitucional, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/organigrama-servicio', name: 'oservicio', component: OServicio, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/organigrama-ministro', name: 'oministerio', component: OMinisterio, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/recursos-financieros', name: 'rfinancieross', component: RFinancieross, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/incentivo-colectivo', name: 'icolectivo', component: IColectivo, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/premios-y-reconocimientos', name: 'premios', component: Premios, meta: { autenticado: true } },
  { path: '/portada-bgi/:idServicio/info', name: 'oInfo', component: oInfo, meta: { autenticado: true } },

  { path: '/min-hacienda/persona-en-el-estado/fondef', name: 'fondef-index', component: IndexFondef },
  { path: '/min-hacienda/persona-en-el-estado/fondef/composicion', name: 'fondef-composicion', component: ComposicionFondef },
  { path: '/min-hacienda/persona-en-el-estado/fondef/dotacion', name: 'fondef-dotacion', component: DotacionFondef },
  { path: '/min-hacienda/persona-en-el-estado/fondef/remuneraciones', name: 'fondef-remuneraciones', component: RemuneracionesFondef },
  { path: '/min-hacienda/persona-en-el-estado/fondef/longevidad', name: 'fondef-longevidad', component: LongevidadFondef },
  { path: '/min-hacienda/persona-en-el-estado/fondef/personas', name: 'fondef-personas', component: PersonasFondef },
  { path: '/min-hacienda/persona-en-el-estado/fondef/minsal', name: 'fondef-minsal', component: MinsalFondef },
  { path: '/min-hacienda/persona-en-el-estado/fondef/ministerios', name: 'fondef-ministerios', component: MinisteriosFondef },
  { path: '/min-hacienda/persona-en-el-estado/fondef/datos', name: 'fondef-datos', component: DatosFondef },

  // RUTAS DE 404
  {
    path: '/404',
    name: 'notfound',
    component: NotFound
  },
  {
    path: '/*',
    component: NotFound
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  //
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

// function router
router.beforeEach((to, from, next) => {
  // console.log(to);
  if (to.matched.some((record) => record.meta.autenticado)) {
    // user logger
    if (store.state.sesion.user && store.state.sesion.usuario) {
      next()
    } else {
      // user not logger
      next({ name: 'login' })
    }
  } else {
    next()
  }
})

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('userToken')
    if (config.url.search('http') !== 0) {
      config.url = process.env.VUE_APP_RUTA_API + config.url
    }
    if (token) {
      config.headers.Authorization = `JWT ${token}`
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use((response) => {
  //   console.log(response)
  return response
}, function (error) {
  if (error.response && error.response.status === 401 && error.response.data && error.response.data.detail === 'Signature has expired.') {
    // si el url es el refresh, signifia que ya es el segundo request una vez que el primero no fue existos o por tanto debe devolver el error
    localStorage.removeItem('userToken')
    return Promise.reject(error)
  }
  if (error.response && error.response.status === 401 && error.response.data && error.response.data.code === 'token_not_valid') {
    // si el url es el refresh, signifia que ya es el segundo request una vez que el primero no fue existos o por tanto debe devolver el error
    localStorage.removeItem('userToken')
    return Promise.reject(error)
  }

  return Promise.reject(error)
})

export default router
