<template>
  <div class="login">
    <b-row>
      <!-- <b-col>
        <Img/>
      </b-col> -->
      <b-col>
        <div class="content"><Content /></div
      ></b-col>
    </b-row>
  </div>
</template>

<script>
import Content from './components/Content.vue'
// import Img from "./components/Img.vue"
export default {
  components: {
    Content
    // Img
  }
}
</script>

<style scoped>
.row{
  margin: 0;
}
</style>
