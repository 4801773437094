<template>
  <div class="cards cffm" style="display:none" >
    <div class="card-left">
      <CardLeft />
    </div>

    <div class="card-center">
      <CardCenter />
    </div>

    <div class="card-right">
      <CardRight />
    </div>
  </div>
</template>

<script>
import CardLeft from '../components/card/CardLeft.vue'
import CardCenter from '../components/card/CardCenter.vue'
import CardRight from '../components/card/CardRight.vue'
export default {
  components: {
    CardLeft,
    CardCenter,
    CardRight
  }
}
</script>

<style scoped>
.cards {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
/* @media screen and (min-width: 1601px) {
  .card-center {
    position: absolute;
    left: 600px;
    top: -40px;
    width: 84px;
  }
  .card-left {
    position: absolute;
    left: 328px;
    width: 84px;
  }
  .card-right {
    position: absolute;
    left: 853px;
    top: 5px;
    width: 84px;
  }
}
@media screen and (max-width: 1600px) {
  .card-center {
    position: absolute;
    left: 600px;
    top: -40px;
    width: 84px;
  }
  .card-left {
    position: absolute;
    left: 328px;
    width: 84px;
  }
  .card-right {
    position: absolute;
    left: 855px;
    top: 5px;
    width: 84px;
  }
}

@media screen and (max-width: 1499px) {
  .card-center {
    position: absolute;
    left: 560px;
    top: -40px;
    width: 84px;
  }
  .card-left {
    position: absolute;
    left: 309px;
  }
  .card-right {
    position: absolute;
    left: 815px;
    top: 5px;
    width: 84px;
  }
}

@media screen and (max-width: 1398px) {
  .card-left {
    position: absolute;
    left: 255px;
    width: 84px;
  }

  .card-center {
    position: absolute;
    left: 525px;
    top: -40px;
    width: 84px;
  }

  .card-right {
    position: absolute;
    left: 781px;
    top: 5px;
    width: 84px;
  }
}

@media screen and (max-width: 1150px) {
  .card-left {
    position: absolute;
    left: 70px;
    width: 74px;
  }
  .card-center {
    position: absolute;
    left: 343px;
    width: 74px;
  }
  .card-right {
    position: absolute;
    left: 598px;
    width: 74px;
  }
}

@media screen and (max-width: 895px) {
  .card-left {
    position: absolute;
    left: 50px;
    width: 74px;
  }
  .card-center {
    position: absolute;
    left: 319px;
    width: 74px;
  }
  .card-right {
    position: absolute;
    left: 575px;
    width: 74px;
  }
}

@media screen and (max-width: 860px) {
  .card-left {
    position: absolute;
    left: 260px;
    width: 74px;
    top: -80px;
  }
  .card-center {
    position: absolute;
    left: 260px;
    width: 74px;
    top: 290px;
  }
  .card-right {
    position: absolute;
    left: 260px;
    width: 74px;
    top: 660px;
  }
}

@media screen and (max-width: 754px) {
  .card-left {
    position: absolute;
    left: 160px;
    width: 74px;
    top: -80px;
  }
  .card-center {
    position: absolute;
    left: 160px;
    width: 74px;
    top: 290px;
  }
  .card-right {
    position: absolute;
    left: 160px;
    width: 74px;
    top: 660px;
  }
}

@media screen and (max-width: 600px) {
  .card-left {
    position: absolute;
    left: 70px;
    width: 74px;
    top: -80px;
  }
  .card-center {
    position: absolute;
    left: 70px;
    width: 74px;
    top: 290px;
  }
  .card-right {
    position: absolute;
    left: 70px;
    width: 74px;
    top: 660px;
  }
}

@media screen and (max-width: 400px) {
  .card-left {
    position: absolute;
    left: 70px;
    width: 74px;
    top: -80px;
  }
  .card-center {
    position: absolute;
    left: 70px;
    width: 74px;
    top: 290px;
  }
  .card-right {
    position: absolute;
    left: 70px;
    width: 74px;
    top: 660px;
  }
} */
</style>
