<template>
  <div>
    <div class="home">
      <Navbar />
      <Header />
    </div>

    <Contents />

    <div class="contents" v-show="showBlank"></div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from './components/Header.vue'
import Contents from '../institutions/components/Contents.vue'
import Footer from '../../components/layout/Footer.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    Navbar,
    Header,
    Contents,
    Footer
  },
  data () {
    return {
      showBlank: true
    }
  },
  computed: {
    ...mapGetters(['servicesList'])
  },
  watch: {
    servicesList () {
      this.showBlank = false
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap");
.help {
  height: 62px;
  width: 200px;
}

.contents {
  height: 80vh;
}
</style>
