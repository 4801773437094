<template>
  <div>
    <div class="home">
      <Navbar />
    </div>

    <div>
      <div>
        <Header
          pretitle="COMPOSICIÓN"
          title="Análisis composicional del Estado"
        />
      </div>
      <b-container>
        <br />
        <br />
        <b-row>
          <b-col sm="12" md="6">
            <b-img src="/img_fondef/composicion/composicion_2015.png" fluid />
            <p class="lead center">2015</p>
            <p>
              Las figuras muestra diagramas ternarios de la composición de 112
              servicios del Ministerio de Salud de acuerdo al régimen de
              contratación (Planta, Contrata, Honorarios) de su dotación. Se
              comparan dos años para destacar la tendencia. Claramente, la
              preferencia se desplaza hacia Contrata:
            </p>
          </b-col>
          <b-col sm="12" md="6">
            <b-img src="/img_fondef/composicion/composicion_2020.png" fluid />
            <p class="lead center">2020</p>
            <p>
              Diagrama ternario de la composición para el año 2020. Se comparan
              los dos años para destacar la tendencia. Claramente, la
              preferencia se desplaza hacia Contrata.
            </p>
          </b-col>
        </b-row>
        <p class="lead">
          Como ejemplo, en la siguiente figura se muestran las "trayectorias" de
          tres servicios:
        </p>
        <b-row>
          <b-col sm="12" md="6">
            <div>
              <b-img src="/img_fondef/composicion/movimiento.png" fluid></b-img>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <p>
              Se observa que el Hospital Sótero del Rio al 2020 cuenta con mayor
              dotación en régimen honorarios respecto a los otros servicios. Se
              muestra además que la tendencia durante 10 años es al incremento
              de este tipo de contratación en dicho hospital. Tendencia que solo
              es revertida durante el año 2020.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from './components/header/Header.vue'

export default {
  components: {
    Navbar,
    Header
  }
}
</script>

<style scoped>
p {
  font-family: Georgia, serif;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin: 2rem 0;
}

.lead {
  font-size: 1.7rem;
  margin: 3.4rem 0 1.7rem 0;
  font-family: sans-serif;
}

.center {
  text-align: center;
}
</style>
