<template>
  <div>
    <Header />
    <Contents />

  </div>
</template>

<script>
import Header from '../../components/private/Header'
import Contents from './components/Contents.vue'
export default {
  components: {
    Header,
    Contents
  }
}
</script>
