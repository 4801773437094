<template>
  <div class="content">
    <div class="container grid">
      <div class="col-sm-12 col-md-6 text"><ContentOne /></div>
      <div class="col-md-6 img"><ImgContentOne /></div>

    </div>
  </div>
</template>

<script>
import ContentOne from '@/components/home/content/ContentOne.vue'
import ImgContentOne from '@/components/image/home/ImgContentOne.vue'
export default {
  components: {
    ContentOne,
    ImgContentOne
  }
}
</script>

<style scoped>
.content {
  background: #080d46;
  height: 427px;
}
.grid {
  display: flex;
  max-width: 1200px;
}

.img {
  position: relative;
  top: 81px;
}

@media screen and (max-width: 1150px) {
  .content {
    height: 370px;
  }
}

@media screen and (max-width: 960px) {
  .content {
    height: 307px;
  }
}

@media screen and (max-width: 767px) {
  .img{
    display: none;
  }

}
</style>
