<template>
<div>
  <Header />
  <div class="container">
    <Title />
    <Carrusel />
    <Breadcrumb :links="breadCrumbData" />
    <Button />
        <h1 class="privado-titulo">Leyes</h1>
        <b-row>
            <b-col>
                <!-- <Instrucciones/> -->
            </b-col>
            <b-col>
                <Visualizar seccion="leyes_promulgadas" />
            </b-col>
            <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
                 <b-form-checkbox v-model="anexo.datos.no_aplica">
                    No Aplica
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-button v-if="can_edit_anexos_de_servicios()" @click="guardar" pill variant="primary" class="btn-one">Guardar</b-button>
        <div v-if="itemList && !anexo.datos.no_aplica">
            <h3 class="title-leyes">Anexo 10b: Leyes Promulgadas durante 2018-2021</h3>
            <div  v-for="(item, index) in itemList" :key="index" >
                <hr />
                <b-row>
                    <b-col cols="12">
                        <label class="label-leyes">
                            <span class="label-leyes-span">Nº Ley</span>
                            <i class="bx bxs-trash" @click="removeItem(item)" style="color:#24c6ab; cursor: pointer;"></i>
                        </label>
                        <b-form-input v-model="item.numero_ley" class="input-leyes" type="text" :disabled="!can_edit_anexos_de_servicios()" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <label class="label-leyes"><span class="label-leyes-span">Fecha de Promulgación</span></label>
                        <b-form-datepicker class="input-leyes" :id="`datepicker-prom-${index}`" :hide-header="true" locale="es" :disabled="!can_edit_anexos_de_servicios()" v-model="item.fecha_promulgacion"></b-form-datepicker>
                    </b-col>
                    <b-col cols="6">
                        <label class="label-leyes"><span class="label-leyes-span">Fecha de Entrada en Vigencia</span></label>
                        <b-form-datepicker class="input-leyes" :id="`datepicker-vigencia-${index}`"  :hide-header="true" locale="es" :disabled="!can_edit_anexos_de_servicios()" v-model="item.fechaIngreso_vigencia" ></b-form-datepicker>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <label class="label-leyes"><span class="label-leyes-span">Materia</span></label>
                        <b-form-textarea v-model="item.materia" class="input-leyes" type="text" :disabled="!can_edit_anexos_de_servicios()" />
                    </b-col>
                </b-row>
            </div>
            <div class="add-btn" @click="addItemList" v-b-tooltip.hover title="Agregar otro Boletín">
                <hr class="separator-hr" />
                <b-button class="button-leyes"><i class="bx bx-plus"></i></b-button>
                <hr class="separator-hr" />
            </div>
        </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../../components/private/Header'
import Footer from '../../components/layout/Footer.vue'
import { utils } from '@/components/tools/ViewUtils.js'
import axios from 'axios'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'

import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '@/components/private/Carrusel.vue'
import Button from './components/Button.vue'
import { mapState } from 'vuex'

export default {
  mixins: [utils],
  components: {
    Title,
    Breadcrumb,
    Carrusel,
    Header,
    Visualizar,
    Footer,
    Button
  },
  data () {
    return {
      breadCrumbData: null,
      itemList: null,
      anexo: null
    }
  },
  computed: {
    ...mapState('sesion', ['usuario'])

  },
  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Leyes', routeName: 'leyes', routeParams: { idServicio: this.$route.params.idServicio } }
      ]
    },

    addItemList: function () {
      const toAddObj = {}
      this.itemList.push(toAddObj)
    },
    removeItem: function (index) {
      if (this.readOnly) return
      const pos = this.itemList.indexOf(index)

      this.itemList.splice(pos, 1)
    },
    guardar: function () {
      axios.put(`/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    },
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'leyes_promulgadas'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        this.anexo = res.data[0]
        this.anexo.datos = this.anexo.datos || {}
        this.anexo.datos.listado = this.anexo.datos.listado || []
        this.itemList = this.anexo.datos.listado
        console.log(this.anexo)
      })
    }

  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRevision()
  }
}
</script>

<style scoped>

/* INICIO SEPARATOR */
.add-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}
.separator-hr {
  width: 45%;
}
.button-leyes {
  font-size: 20px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  border: 2px solid #ccc;
  background: white;
  padding: 0;
  color: #ccc;
}
.bx-plus {
  position: relative;
  left: -2px;
  top: -5px;
}
.btn-check:focus + .btn,
.btn-secondary:hover,
.btn:focus {
  box-shadow: none;
  color: #ccc;
  border: 2px solid #ccc;
  background: white;
}
/* FIN SEPARATOR */
.containers {
margin-bottom: 30px;
margin-left: 30px;
margin-right: 30px;
}
</style>
