import { render, staticRenderFns } from "./PServicio.vue?vue&type=template&id=3e36798c&scoped=true&"
var script = {}
import style0 from "./PServicio.vue?vue&type=style&index=0&id=3e36798c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e36798c",
  null
  
)

export default component.exports