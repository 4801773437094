<template>
    <div class="privado">
        <Title  />

        <div class="container">
            <Breadcrumb :links="breadCrumbData" />
            <Carrusel />
            <h1 class="privado-titulo"> Información Estratégica, Financiera e Instrumentos de Gestión de la Institución</h1>

            <b-row>
                <b-col>
                    <table class="table table-striped">
                        <thead>
                            <tr style="text-align: center;">
                                <th class="apply-width" scope="col">Sección</th>
                                <th class="apply-width-four" scope="col">Detalle</th>
                                <th class="apply-width-one" scope="col">Enlace</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="align-left">
                                    Identificación de la Institución (Formulario A1)
                                </td>
                                <td class="align-left">
                                    Corresponde a la información estratégica del Servicio. Para acceder, dirigirse a www.dipres.cl, sección Evaluación y Transparencia Fiscal, apartado Planificación Estratégica, Definiciones Estratégicas, instrumentos por año, 2023 y escoger el Ministerio y Servicio.
                                </td>
                                <td class="align-left">
                                    <a href="https://www.dipres.gob.cl/597/w3-multipropertyvalues-15400-35324.html" target="_blank">https://www.dipres.gob.cl/597/w3-multipropertyvalues-15400-35324.html</a>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2" class="align-left">
                                    Recursos Financieros
                                </td>
                                <td rowspan="2" class="align-left">
                                    Corresponde a información presupuestaria del Servicio disponible en los informes de ejecución presupuestaria mensuales, a diciembre de cada año o trimestrales. Dipres presenta información a través de dos vías: i) Sitio web de Dipres, sección Presupuestos, año 2023, y escoger la partida presupuestaria requerida; y ii) Sitio web de Dipres, banner Datos Abiertos, donde se presentan archivos con información del Gobierno Central.
                                </td>
                                <td class="align-left">
                                    <a href="https://www.dipres.gob.cl/597/w3-multipropertyvalues-15149-35324.html" target="_blank">https://www.dipres.gob.cl/597/w3-multipropertyvalues-15149-35324.html</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="align-left">
                                    <a href="https://datos.gob.cl/organization/direccion_de_presupuestos" target="_blank">https://datos.gob.cl/organization/direccion_de_presupuestos</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="align-left">
                                    Indicadores de Desempeño 2023
                                </td>
                                <td class="align-left">
                                    Para acceder, dirigirse a www.dipres.cl, sección Evaluación y Transparencia Fiscal, apartado Planificación Estratégica, Indicadores de Desempeño, Fichas Anuales y 2023.
                                </td>
                                <td class="align-left">
                                    <a href="https://www.dipres.gob.cl/597/w3-multipropertyvalues-15157-35324.html" target="_blank">https://www.dipres.gob.cl/597/w3-multipropertyvalues-15157-35324.html</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="align-left">
                                    Informe Programas / Instituciones Evaluadas
                                </td>
                                <td class="align-left" rowspan="2">
                                    Se presenta el buscador de programas e instituciones evaluadas. Para acceder, dirigirse a www.dipres.cl, sección Evaluación y Transparencia Fiscal, apartado Evaluación y Revisión del Gasto e Informes de Evaluaciones. Para acceder a los informes, se debe escoger el Ministerio, línea de evaluación y año de publicación de la evaluación buscada.
                                </td>
                                <td class="align-left" rowspan="2">
                                    <a href="https://www.dipres.gob.cl/597/w3-propertyvalue-23076.html" target="_blank">https://www.dipres.gob.cl/597/w3-propertyvalue-23076.html</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="align-left">
                                    Informe Preliminar de Cumplimiento de los Compromisos de los Programas/Instituciones Evaluadas
                                </td>
                            </tr>
                            <tr>
                                <td class="align-left">
                                    Cumplimiento de Sistemas de Incentivos Institucionales 2023
                                </td>
                                <td class="align-left">
                                    Para acceder, dirigirse a www.dipres.cl, sección Mecanismos de Incentivo de Remuneraciones, y elegir entre los distintos mecanismos presentados, para luego seleccionar el instrumento para un determinado año (según corresponda) u otro tipo de documentación relacionada.
                                </td>
                                <td class="align-left">
                                    <a href="https://www.dipres.gob.cl/598/w3-propertyvalue-16140.html" target="_blank">https://www.dipres.gob.cl/598/w3-propertyvalue-16140.html</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row>
                &nbsp;
            </b-row>
        </div>
    </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
import Title from '../../../components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '../../../components/private/Carrusel.vue'
import { mapState } from 'vuex'
export default {
  mixins: [utils],
  components: {
    Title,
    Breadcrumb,
    Carrusel
  },
  data () {
    return {
      anexo: null,
      breadCrumbData: null
    }
  },
  computed: {
    // get User
    ...mapState('sesion', ['usuario'])

  },
  methods: {
    // name of breadcrumb
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Información Estratégica, Financiera e Instrumentos de Gestión', routeName: 'info', routeParams: { idServicio: this.$route.params.idServicio } }]
    }
  },
  mounted () {
    this.setBreadcrumbData()
  }
}
</script>

<style scoped>

</style>
