<template>
  <div style="display: flex; justify-content: center;">
    <div
      class="alerta-a
    "
    >
      <i class="bx bx-error icon"></i>
      <h5 class="texto">No aplica para esta institución</h5>
    </div>
  </div>
</template>
<script>
export default {
  props: ['text']
}
</script>
<style>
.alerta-a {
  color: white;
  margin: 8rem 0;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #24c6ab;
  padding: 1rem;
  border-radius: 30px;
}
.icon {
  font-size: 3rem;
}
.texto {
  text-align: center;
  font-weight: 600;
}
</style>
