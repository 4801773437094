<template>
  <div>
    <b-button
      variant="link"
      class="modal-button"
      id="show-btn"
      @click="$bvModal.show('bv-modal-example')"
    >
      <Config /> {{ button }}
    </b-button>

    <b-modal id="bv-modal-example" hide-footer>
      <template #modal-title>
        <h4 class="title-modal">{{ title }}</h4>
      </template>

      <div class="container" style="margin-top:5px">
        <div>
          <p v-if="text" class="span-text">{{ text }}</p>
          <p v-if="text_Two" class="span-text">{{ text_Two }}</p>
          <p v-if="text_One" class="span-text">{{ text_One }}</p>
          <p v-if="text_three" class="span-text">{{ text_three }}</p>
          <p v-if="text_four" class="span-text">{{ text_four }}</p>
          <p v-if="text_five" class="span-text">{{ text_five }}</p>
          <p v-if="text_six" class="span-text">{{ text_six }}</p>
          <p v-if="text_seven" class="span-text">{{ text_seven }}</p>
          <p v-if="text_eight" class="span-text">{{ text_eight }}</p>
          <p v-if="text_nine" class="span-text">{{ text_nine }}</p>
          <p v-if="text_ten" class="span-text">{{ text_ten }}</p>

          <ul>
            <li v-if="uno">{{ uno }}</li>
            <li v-if="dos">{{ dos }}</li>
            <li v-if="tres">{{ tres }}</li>
            <li v-if="cuatro">{{ cuatro }}</li>
            <li v-if="cinco">{{ cinco }}</li>
            <li v-if="seis">{{ seis }}</li>
            <li v-if="siete">{{ siete }}</li>
            <li v-if="ocho">{{ ocho }}</li>
            <li v-if="nueve">{{ nueve }}</li>
            <li v-if="diez">{{ diez }}</li>
          </ul>

          <p v-if="text_eleven" class="span-text">{{ text_eleven }}</p>
          <p v-if="text_twelve" class="span-text">{{ text_twelve }}</p>
          <p v-if="text_thirteen" class="span-text">{{ text_thirteen }}</p>
          <p v-if="text_fourteen" class="span-text">{{ text_fourteen }}</p>
          <p v-if="text_fifteen" class="span-text">{{ text_fifteen }}</p>
        </div>
      </div>

      <!-- <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')"
        >Close Me</b-button
      > -->
    </b-modal>
  </div>
</template>

<script>
import Config from '../image/icon/Config'
export default {
  props: [
    'title',
    'text',
    'uno',
    'dos',
    'tres',
    'cuatro',
    'cinco',
    'seis',
    'siete',
    'ocho',
    'nueve',
    'diez',
    'text_One',
    'text_Two',
    'text_three',
    'text_four',
    'text_five',
    'text_six',
    'text_seven',
    'text_eight',
    'text_nine',
    'text_ten',
    'text_eleven',
    'text_twelve',
    'text_thirteen',
    'text_fourteen',
    'text_fifteen'
  ],
  components: {
    Config
  },
  data () {
    return {
      button: 'Instrucciones: ¿Cómo completar esta sección?'
    }
  }
}
</script>

<style scoped>
.modal-button {
  display: flex;
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 22px;
}

.modal-button:hover {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.modal-button:focus {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.title-modal {
  color: #052333;
  font-size: 20px;
  text-align: center;
}

li {
  margin-top: 7px;
  color: #052333;
  font-size: 14px;
  font-weight: 500;
}

.span-text {
  font-size: 15px;
  color: #052333;
  margin-top: 10px !important;
  font-weight: 500;
  margin-bottom: 10px;
}

@media screen and (max-width: 1600px) {
  .modal-button {
    font-size: 18px;
  }
}

@media screen and (max-width: 1300px) {
  .modal-button {
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .modal-button {
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .modal-button {
    font-size: 12px;
  }
}

@media screen and (max-width: 670px) {
  .modal-button {
    font-size: 9px;
  }
}
</style>
