<template>
  <div class="profile">
    <div>
      <Header />
    </div>

    <div class="main">
      <Contents />
    </div>

    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from '../../components/layout/Footer.vue'
import Header from '../../components/private/Header'
import Contents from './components/Contents.vue'

export default {
  components: {
    Header,
    Contents,
    Footer
  }
}
</script>

<style scoped>
.main {
  background: #fff;
  margin-bottom: 30px;
}
</style>
