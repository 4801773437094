<template>
  <div class="container">
    <div>
      <Title/>
    </div>

    <Breadcrumb :links="breadCrumbData" />

    <Carrusel />
    <div class="container">
        <Button />

        <b-row>
            <b-col>
                <Instrucciones/>
            </b-col>
            <b-col>
                <Visualizar seccion="presentacion_servicio" />
            </b-col>
            <b-col v-if="can_edit_anexos_de_servicios()" style="text-align:right">
                 <b-form-checkbox v-model="anexo.datos.no_aplica">
                    No Aplica
                </b-form-checkbox>
            </b-col>
        </b-row>

        <div v-if="!can_edit_anexos_de_servicios() && anexo">
            <br>
            <AlertPerms/>
            <div v-html="anexo.datos.texto" class="seccion-background-color-table"/>
        </div>
        <div v-if="can_edit_anexos_de_servicios()">
            <br><br>
            <b-button @click="guardar" pill variant="primary" class="btn-one">Guardar</b-button>
            <br><br>
            <ck-editor-text :modifyContent="modifyContent" :borrador="anexo.datos.texto" v-if="anexo && !anexo.datos.no_aplica"></ck-editor-text>
        </div>
    </div>

  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'

import AlertPerms from '@/components/alert/Watch.vue'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'

import CkEditorText from '@/components/private/CkEditorText'
import Title from '@/components/private/Title'
import Carrusel from '@/components/private/Carrusel.vue'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Button from './else/Button.vue'
import { mapState } from 'vuex'
import axios from 'axios'
import Instrucciones from './Instrucciones.vue'

export default {
  mixins: [utils],
  components: {
    Title,
    Carrusel,
    AlertPerms,
    Breadcrumb,
    Button,
    CkEditorText,
    Instrucciones,
    Visualizar
  },
  computed: {
    ...mapState('sesion', ['usuario'])
  },
  data () {
    return {
      permiso: false,
      breadCrumbData: null,
      anexo: null,
      user: false,
      userPermissions: true,
      userPermission: true,
      idServicio: this.$route.params.idServicio
    }
  },
  methods: {
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Presentación del Servicio - Resumen Ejecutivo', routeName: 'servicios', routeParams: { idServicio: this.$route.params.idServicio } }]
    },
    obtenerRelacionUsuarioServicio () {
      const data = JSON.parse(localStorage.getItem('user'))
      const relacionUs = data.servicios.find(
        (s) => s.id === parseInt(this.$route.params.idServicio)
      )
      if (!relacionUs) return
      if (
        relacionUs.permisos.find((p) => p.permisoId === 2) ||
        relacionUs.rolId === 3 ||
        relacionUs.rolId === 5 ||
        relacionUs.rolId === 4
      ) {
        this.permiso = true
      }
    },
    obtenerContenido: async function () {

    },
    modifyContent: function (text) {
      this.anexo.datos.texto = text
    },
    guardar: function () {
      axios.put(`/anexos/anexo_cf/${this.anexo.id}/`, this.anexo).then(res => {
        this.successMessage('Guardado correctamente')
      })
    },
    obtenerRevision: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'presentacion_servicio'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.texto = anexo.datos.texto || ''
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerRelacionUsuarioServicio()
    this.obtenerContenido()
    this.obtenerRevision()
  }
}
</script>
