<template>
  <div>
    <div class="main-text">
      <h6>{{ text }}</h6>
    </div>

    <div class="cnt-btn">
      <b-button variant="primary" class="boton"
        ><i class="bx bx-download"></i
        ><span class="btn-text">BGI 2018</span></b-button
      >

      <b-button variant="primary" class="boton"
        ><i class="bx bx-download"></i
        ><span class="btn-text">BGI 2019</span></b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      text:
        'A continuación detallamos las Declaraciones de BGI de años anteriores para su descarga:'
    }
  }
}
</script>

<style scoped>
.main-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b8f4e9;
  height: 50px;
}
.main-text > h6 {
  font-weight: bold;
  color: #515151;
  margin: 0;
  text-align: center;
  margin: 15px;
}
.cnt-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.boton {
  border-radius: 40px;
  width: 150px;
  background: #4f52ff !important;
  border: none;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-text {
  font-size: 0.8rem;
}
.bx-download {
  margin-right: 10px;
  font-size: 20px;
  color: white;
}
</style>
