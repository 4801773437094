<template>
  <footer>
    <div style="text-align: center">
      Fondef IDeA I+D,
      <i>
        “Hacia la modernización del Estado: Modelado analítico de capacidades
        estatales basado en datos de dotación de personal.”
      </i>
    </div>
    <div class="logos">
      <div>
        <img src="/img_fondef/footer.png" width="125" />
      </div>
      <div><img src="/img_fondef/udd.png" width="200" /></div>
      <div><img src="/img_fondef/uai.png" width="200" /></div>
    </div>

    <div style="font-size: 12px; margin: 20px auto; max-width: 960px;">
      * Los datos originales de Transparencia fueron transformados y cruzados
      con otras fuentes de datos para incorporar nuevas variables en el estudio.
      La base de datos obtenida es distinta a la publicada por el Consejo
      Nacional para la Transparencia (CPLT). Para tener más información visitar:
      <a href="https://datospersonaldelestado.udd.cl/"
        >https://datospersonaldelestado.udd.cl/</a
      >
    </div>
  </footer>
</template>

<script>
export default {
  props: {}
}
</script>

<style scoped>
footer {
  background: #080d46;
  padding: 30px;
  color: #fff;
  margin-top: 30px;
}
.logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 10px auto;
}
</style>
