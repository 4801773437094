<template>
  <div class="container">
    <div class="text-center all">
      <h1>Redirigiendo a Inicio</h1>
      <div class="loading-div">
        <Loading />
      </div>
      <div v-if="showError">
        <h1>
          {{ errorMsg }}
        </h1>
        <div class="loading-div">
          <Loading />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Loading from '../../components/tools/Loading.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    Loading
  },
  data () {
    return {
      showError: false,
      errorMsg:
        'Ha ocurrido un error conectando con ClaveÚnica. Redirigiendo a Sitio Público.'
    }
  },
  methods: {
    redirectError: function () {
      this.showError = true
      setTimeout(() => {
        this.$router.push({ name: 'home' })
      }, 3000)
      this.showError = false
    },
    ...mapActions('sesion', ['inicioSesion', 'setUsuarioObj']),
    decodeJwt: function (token) {
      var base64Payload = token.split('.')[1]
      var payload = Buffer.from(base64Payload, 'base64')
      return JSON.parse(payload.toString())
    }
  },
  async mounted () {
    try {
      const data = this.$route.query
      if (!data.code || !data.state) {
        this.redirectError()
        return
      }
      const res = await axios.post(
        `${process.env.VUE_APP_RUTA_API}/usuarios/clave_unica/`,
        data
      )
      const token = res.data.token
      if (token) {
        const a = this.decodeJwt(token)
        localStorage.setItem('userToken', token)
        console.log(a)

        try {
          const user = await axios.get(
            `${process.env.VUE_APP_RUTA_API}/usuarios/usuarios/${a.user_id}/`,
            {
              headers: {
                Authorization: `JWT ${token}`
              }
            }
          )
          localStorage.setItem('user', JSON.stringify(user.data))
          this.setUsuarioObj(user.data)
          // console.log(user);
        } catch (error) {
          console.log(error.response)
        }

        this.inicioSesion(token)
        this.$router.push({ name: 'gestion' }).catch(() => {})
      }
    } catch (error) {
      alert('Usuario no registrado en sistema')
      this.redirectError()
    }
  }
}
</script>
<style scoped>
.all {
  height: 100vh;
}
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading-div {
  margin: 30px 0;
}
</style>
