<template>
  <div class="container">
    <Title v-if="!isAdmin" :showMinister="true" :showService="true" />

    <Carrusel />
    <div>
      <Breadcrumb :links="breadCrumbData" />
      <Buttons />
        <!--
          <h3 class="h3">Evaluaciones - Informe Cumplimiento</h3>
        -->
        <br>
        <br>
        Corresponde a la información de las instituciones evaluadas o que tienen programas evaluados en el marco del Programa de Evaluación de DIPRES y que contaban con compromisos vigentes a diciembre de 2021.
        <br>
        Si no se dispone de evaluaciones con compromisos vigentes, se debe seleccionar la opción “No Aplica”.
        <br>
        <br>
        <div v-if="anexo">
          <div v-for="item in anexo.datos.lista" :key="item.programa">
          <h5>
            Programa / Institución:
            <span>{{ item.programa }}</span>
          </h5>

          <h5>
            Año Evaluación:
            <span>{{ item.anio }}</span>
          </h5>

          <h5>
            Fecha del Informe:
            <span>{{ item.vencimiento }}</span>
          </h5>
          <div class="div-table">
            <h5>
              Cumplimiento de Compromisos de Programas / Instituciones Evaluadas
            </h5>
          </div>
          <table>
            <thead>
              <tr>
                <th scope="col">Compromiso</th>
                <th scope="col">Cumplimiento</th>
              </tr>
            </thead>
            <tbody>
               <tr class="tr" v-for="compromisos in item.compromisos" :key="compromisos.compromiso">
                 <td>{{ compromisos.compromiso }}</td>
                 <td>{{ compromisos.cumplimiento }}
                   <br>
                   <br>
                   Calificacion: {{ compromisos.calificacion }}
                   <br>
                 </td>
               </tr>
          </tbody>
        </table>
      </div>
      Fuente: Departamento de Evaluación de la Dirección de Presupuestos
    </div>
  </div>
  </div>
</template>

<script>
import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '@/components/private/Carrusel.vue'
import Buttons from './Buttons.vue'
import { mapState } from 'vuex'
import axios from 'axios'
// import { showAlert } from '@/components/tools/utils'
// import AlertError from '@/components/error/AlertError.vue'
// import Visualizar from '@/components/button/escritorio/Visualizar.vue'

export default {
  components: {
    Title,
    Breadcrumb,
    Carrusel,
    Buttons
    // AlertError,
    // Visualizar
  },
  data () {
    return {
      anexo: null,
      breadCrumbData: null,
      idServicio: this.$route.params.idServicio

    }
  },
  computed: {
    // get user
    ...mapState('sesion', ['usuario']),
    isAdmin: function () {
      return this.usuario.username === process.env.VUE_APP_USER_ADMIN
    }
  },
  methods: {
    // name of breadcrumb
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Evaluaciones - Informe Cumplimiento', routeName: 'informepreliminar', routeParams: { idServicio: this.$route.params.idServicio } }
      ]
    },
    obtenerContenido: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'informe-preliminar'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        anexo.datos = anexo.datos || {}
        anexo.datos.lista = anexo.datos.lista || []
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerContenido()
  }
}
</script>

<style scoped>
.margin-margin {
  margin: 0;
  width: 100%;
}

.containers {
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.col-Left {
  color: #052333;
  font-weight: bold;
}

h5 {
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #052333;
}

.h3 {
  text-align: center;
  font-weight: bold;
  font-size: 1.7rem;
}

table {
  margin-bottom: 20px !important;
  border: 1px solid #ccc;
}

th {
  font-size: 1.4rem !important;
  width: 50%;
}

td {
  font-size: 1.1rem !important;
  text-align: left !important;
  border: 1px solid #ccc;
}

@media screen and (max-width: 1800px) {
  td {
    font-size: 1.1rem !important;
  }

  th {
    font-size: 1.2rem !important;
  }

  h5 {
    font-size: 1rem;
  }

  .h3 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1600px) {
  td {
    font-size: 0.9rem !important;
  }

  th {
    font-size: 1rem !important;
  }

  h5 {
    font-size: 0.9rem;
  }

  .h3 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1400px) {
  td {
    font-size: 0.8rem !important;
  }

  th {
    font-size: 0.9rem !important;
  }

  h5 {
    font-size: 0.9rem;
  }

  .h3 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1200px) {
  td {
    font-size: 0.7rem !important;
  }

  th {
    font-size: 0.8rem !important;
  }

  h5 {
    font-size: 0.8rem;
  }

  .h3 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1000px) {
  h5 {
    font-size: 0.6rem;
  }
}
</style>
