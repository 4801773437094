<template>
  <div >
    <span
      >Productos Estratégicos vinculados a Objetivos Estratégicos.</span
    >
    <table class="table table-striped" style="border: 1px solid #000;">
      <thead>
        <tr>
          <th scope="col">Número</th>
          <th scope="col">Nombre</th>
          <th scope="col">Descripción</th>
          <th scope="col">Objetivos Estratégicos a los cuales se vincula</th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, items) in items" :key="items">
          <td>{{items+1}}</td>
          <td>{{ item.nombre }}</td>
           <td>{{ item.descripcion }}</td>
           <td>{{item.objRelacionados}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['contenido'],
  data () {
    return {
      encabezado: 'Número',
      items: null
    }
  },

  mounted () {
    setTimeout(() => {
      // console.log(this.contenido)
      const a = this.contenido.prodEstrategicos
      const unArray = Object.keys(this.contenido.prodEstrategicos)
      this.items = unArray.map((i) => a[i])
    }, 1000)
  }
}
</script>

<style scoped>
.container {
  margin-top: 20px;
}
th {
  text-align: center !important;
  font-size: 18px !important ;
}

td {
  text-align: left;
  font-size: 18px !important ;
}

@media screen and (max-width: 1800px) {
  th {
    text-align: center !important;
    font-size: 16px !important ;
  }

  td {
    text-align: left;
    font-size: 16px !important ;
  }
}

@media screen and (max-width: 1600px) {
  th {
    text-align: center !important;
    font-size: 15px !important ;
  }

  td {
    text-align: left;
    font-size: 15px !important ;
  }
}

@media screen and (max-width: 1400px) {
  th {
    text-align: center !important;
    font-size: 14px !important ;
  }

  td {
    text-align: left;
    font-size: 14px !important ;
  }
}

@media screen and (max-width: 1200px) {
  th {
    text-align: center !important;
    font-size: 12px !important ;
  }

  td {
    text-align: left;
    font-size: 12px !important ;
  }
}

@media screen and (max-width: 900px) {
  th {
    text-align: center !important;
    font-size: 10px !important ;
  }

  td {
    text-align: left;
    font-size: 10px !important ;
  }
}
</style>
