import Swal from 'sweetalert2'

export const maxData = (number) => {
  let aux
  let totalCeros = 0

  for (let i = 1; ; i *= 10) {
    aux = number / i
    totalCeros++
    if (Math.round(aux) < 10) break
  }

  totalCeros -= 1

  const firstNumber = Math.floor(number / Math.pow(10, totalCeros))
  let result = firstNumber * Math.pow(10, totalCeros)
  const test = number - result

  if (number > 10000) {
    if (test < 5000) {
      result += 10000
    } else {
      if (number < 40000) {
        result += 15000
      } else {
        result += 20000
      }
    }
  } else {
    if (number < 1000) {
      if (number < 100) {
        result += 20
      } else {
        result += 100
      }
    } else {
      result += 1000
    }
  }

  return result
}

// Formar role user
export const formatRole = (name) => {
  switch (name) {
    case 'administrador':
      return 'Administrador de la DIPRES'
    case 'encargado_bgi':
      return 'Encargado BGI'
    case 'encargado':
      return 'Encargado BGI'
    case 'funcionario':
      return 'Funcionario'
    case 'jefe_servicio':
      return 'Jefe de Servicio'
    case 'representante':
      return 'Representante Ministerial'
    case 'representante_ministerial':
      return 'Representante Ministerial'
    case 'superadministrador':
      return 'SuperAdministrador'
  }
}

// Possibility of roles according to the user

export const possibleRoles = (idRol) => {
  switch (idRol) {
    case 1: {
      // SuperAdministrador
      return [2, 3, 4, 5, 6]
    }
    case 2: {
      // Administrador
      return [3, 4, 6]
    }
    case 3: {
      // Jefe de Servicio
      return [4, 5]
    }
    case 4: {
      // Encargado BGI
      return [3, 5]
    }
    case 5: {
      // Funcionario
      return []
    }
    case 6: {
      // Representante Ministerial
      return [3, 4, 5]
    }
  }
}
// Add roles based on their role weight
export const hierarchyRole = (idRol) => {
  switch (idRol) {
    case 1:
      return [2, 3, 4, 5, 6]
    case 2:
      return [3, 4, 5, 6]
    case 3:
      return [4, 5]
    case 4:
      return [3, 5]
    case 5:
      return []
    case 6:
      return [3, 4, 5]
  }
}

// # 1: superadministrador
// # 2: administrador
// # 3: jefe_servicio
// # 4: encargado_bgi
// # 5: funcionario
// # 6: representante_ministerial

export const hierarchyRoleEdit = (idRol) => {
  switch (idRol) {
    case 1:
      return [5]
    case 2:
      return [5]
    case 3:
    case 4:
      return [5]
    default:
      return []
  }
}
// Formatting modules
export const formatPerm = (name) => {
  switch (name) {
    case 'compromisos':
      return 'Compromisos'
    case 'desafios':
      return 'Desafíos'
    case 'equidad_genero':
      return 'Equidad de Género'
    case 'evaluaciones':
      return 'Evaluaciones'
    case 'incentivo_colectivo':
      return 'Incentivo Colectivo'
    case 'incentivo_institucional':
      return 'Incentivo Institucional'
    case 'indicadores':
      return 'Indicadores'
    case 'leyes':
      return 'Leyes'
    case 'organigrama_ministerio':
      return 'Organigrama de Ministerio'
    case 'organigrama_servicio':
      return 'Organigrama de Servicio'
    case 'premios_reconocimientos':
      return 'Premios y Reconocimientos'
    case 'presentacion_ministro':
      return 'Presentación del Ministro'
    case 'presentacion_servicio':
      return 'Presentación del Servicio'
    case 'recursos_financieros':
      return 'Recursos Financieros'
    case 'recursos_humanos':
      return 'Recursos Humanos'
    case 'resultados_gestion':
      return 'Resultados de la Gestión'
  }
  return name
}
// Relationship status according to user is not used
export const formatState = (state, apro) => {
  switch (state) {
    case 'revisado_encargado_bgi':
      switch (apro) {
        case 'aprobado':
          return 'En revisión Jefe de Servicio'
        case 'rechazado':
          return 'En elaboración'
      }
      break
    case 'revisado_jefe_servicio':
      switch (apro) {
        case 'aprobado':
          return 'En revisión Representante Ministerial'
        case 'rechazado':
          return 'En elaboración'
      }
      break
    case 'revisado_representante_ministerial':
      switch (apro) {
        case 'aprobado':
          return 'En revisión Administrador'
        case 'rechazado':
          return 'En elaboración'
      }
      break
    case 'revisado_administrador':
      switch (apro) {
        case 'aprobado':
          return 'En revisión Super Administrador'
        case 'rechazado':
          return 'En elaboración'
      }
      break
    case 'finalizado':
      switch (apro) {
        case 'aprobado':
          return 'Proceso BGI Finalizado'
        case 'rechazado':
          return 'En elaboración'
      }
      break
    // case "finalizado":
    //   return "Revisión Finalizada";
    // case "rechazado":
    //   return "En revisión Encargado BGI";
    default:
      return 'En elaboración'
  }
}
// Sweet Alert (2)
export const showAlert = (data) => {
  Swal.fire(data)
}

export const labelGraphics = (label) => {
  switch (label) {
    case 'M':
      return 'Mujeres'
    case 'H':
      return 'Hombres'
    case '24 AÑOS O MENOS':
      return '24 o menos'
    case 'ENTRE 25 y 34 AÑOS':
      return '25-34'
    case 'ENTRE 35 y 44 AÑOS':
      return '35-44'
    case 'ENTRE 45 y 54 AÑOS':
      return '45-54'
    case 'ENTRE 55 y 59 AÑOS':
      return '55-59'
    case 'ENTRE 60 y 64 AÑOS':
      return '60-64'
    case '65 y MÁS AÑOS':
      return '65 o más'
    case '65 Y MÁS AÑOS':
      return '65 o más'
    case 'NNN':
      return 'No informado'
    default:
      return null
  }
}
