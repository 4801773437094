<template>
  <div>
    <div class="home">
      <Navbar />
    </div>

    <div>
      <div>
        <Header
          postTitle="Se incluyen datos de 112 servicios a través de 11 años (2010 – 2020)"
          title="Visores Ministerio de Salud"
          paragraph="Los visores permiten interactuar con los datos de dotación de personal de las distintas dependencias del Ministerio de Salud. Los datos pueden explorarse en las dimensiones de Dotación, Remuneración y Longevidad, utilizando además distintos contextos caracterizados por Profesión, Estamento, Grado, Sexo y Región. Cada visor tiene una breve explicación para facilitar su uso."
          image="/img_fondef/portada/minsal.png"
        />
      </div>

      <NavBar2 />

      <b-container>
        <br />
        <br />

        <blockquote class="blockquote ">
          <p class="lead">
            REMUNERACIÓN - DOTACIÓN
          </p>
          <footer class="blockquote-footer">
            En este visor se incluye información sobre las
            <strong>Remuneraciones</strong> en el Ministerio de Salud y su
            relación con la <strong>Dotación</strong>. Con el uso de distintos
            filtros podrá identificar un <strong>Servicio</strong> en particular
            o definir un <strong>Año</strong> de interés. Puede cliquear sobre
            los diagramas de pie también para definir una
            <strong>Región o Sexo</strong>, o cliquear sobre las barras apiladas
            para ver detalles de las remuneraciones de acuerdo al
            <strong>Régimen</strong> de contratación.
          </footer>
        </blockquote>
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiMGZlM2JhNzctM2U2Yi00MmNmLTkzZjAtMzBhN2NjYWU5ZmI0IiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSection"
        />
        <br /><br /><br />

        <blockquote class="blockquote ">
          <p class="lead">
            REMUNERACIÓN - LONGEVIDAD
          </p>
          <footer class="blockquote-footer">
            En este visor se explora la relación entre
            <strong>Remuneración</strong> y <strong>Longevidad</strong>,
            teniendo en cuenta además una perspectiva de género. La gráfica de
            líneas de tiempo permite observar la evolución de los salarios
            promedios diferenciados por <strong>Sexo</strong>. Se compara además
            la longevidad promedio en los servicios de acuerdo al género en un
            diagrama de pie. Las gráficas dadas por los diagramas de violín se
            construyen sobre muestras de los datos y permiten dar una idea de la
            distribución de remuneraciones y longevidad. El uso de filtros
            permite definir un servicio en particular y hacer seguimiento de la
            remuneración promedio a través de once años.
          </footer>
        </blockquote>
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiMTI2OTI3MmItM2MyNi00NGMxLTkyMjEtNjRkNDZkMWE5MTZiIiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSectiond502e2c05e0ad93950d9"
        />
        <br /><br /><br />

        <blockquote class="blockquote ">
          <p class="lead">
            DOTACIÓN
          </p>
          <footer class="blockquote-footer">
            El visor se enfoca mayormente en la <strong>Dotación</strong> de los
            servicios, incorporando otros parámetros como el
            <strong>Estamento, Régimen</strong> y <strong>Región</strong>.
            También se muestra la evolución temporal de la dotación diferenciada
            por género. Con ayuda de los filtros puede cambiar estos parámetros
            y definir un servicio en especifico. Se incluyen etiquetas con
            información diferenciada por género.
          </footer>
        </blockquote>
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiZmRlZWNiNzYtNGQxZi00NzM1LWEzMDUtMTljOGVhYzZlMGUzIiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSection1446ab25007c343a2431"
        />
        <br /><br /><br />

        <blockquote class="blockquote ">
          <p class="lead">
            COMPARACIONES
          </p>
          <footer class="blockquote-footer">
            Este visor consta de dos partes:<br />
            <b>Visor 1:</b> Comparación de
            <strong>Remuneraciones</strong> promedios por
            <strong>Servicio</strong>.
            <br />
            <b>Visor 2:</b> Comparación de <strong>Dotación</strong> por
            <strong>Servicio</strong><br />
            En el primer visor se comparan las Remuneraciones promedios por
            Servicio y Año. En el segundo se hace esta comparación para la
            Dotación. De manera dinámica se posicionan los primeros 15 servicios
            con más altos valores. Haciendo uso de los filtros se puede hacer
            seguimiento a uno o varios servicios de interés a través de los
            años. También se puede filtrar por Región y Régimen. Por ejemplo,
            pude haber interés en mostrar qué servicios/hospitales han
            incorporado más personal con régimen honorarios.
          </footer>
        </blockquote>
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiNTNhZmZiZWYtZWIyYi00YTlmLWJkYjMtZTEyNzk5ZTVjNjQ4IiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9&pageName=ReportSection891cd0403a420ce383ce"
        />
        <br /><br /><br />
      </b-container>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from './components/header/Header.vue'
import PowerBI from './components/powerbi/PowerBI.vue'
import NavBar2 from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navbar,
    Header,
    PowerBI,
    NavBar2,
    Footer
  }
}
</script>

<style scoped>
p {
  font-family: Georgia, serif;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin: 2rem 0;
}

.lead {
  font-size: 1.7rem;
  margin: 3.4rem 0 1.7rem 0;
  font-family: sans-serif;
  padding:20px;
}

.center {
  text-align: center;
}

.blockquote .lead {
  font-weight: bold;
  color: #bdc3c7;
}

.blockquote-footer::before {
  content: "";
}

strong {
  color: #980100;
}
</style>
