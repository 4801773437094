<template>
  <div>
    <b-button class="hamburguer" v-b-toggle.sidebar-no-header
      ><i class="bx bx-menu" style="color:#080d46"></i
    ></b-button>
    <b-sidebar
      id="sidebar-no-header"
      aria-labelledby="sidebar-no-header-title"
      no-header
      shadow
    >
      <template>
        <div class="logo">
          <Logo />
        </div>

        <div class="menu-sibedar">
          <div class="menu-sibedar-link">
            <b-button class="link-sibedar" variant="link" :to="{ name: 'Home' }"
              >Inicio</b-button
            >
          </div>
          <div class="menu-sibedar-link">
            <b-button
              class="link-sibedar"
              variant="link"
              :to="{ name: 'people' }"
              >Persona en el Estado</b-button
            >
          </div>
          <div class="menu-sibedar-link">
            <b-button
              class="link-sibedar"
              variant="link"
              :to="{ name: 'ejecucion' }"
              >Ejecucion Presupuestaria</b-button
            >
          </div>
          <div class="menu-sibedar-link">
            <b-button
              class="link-sibedar"
              variant="link"
              :to="{ name: 'instituciones' }"
              >Instituciones</b-button
            >
          </div>
          <!-- <div class="menu-sibedar-link">
            <b-button
              class="link-sibedar"
              variant="link"
              :to="{ name: 'comparate' }"
              >Comparador</b-button
            >
          </div> -->
        </div>

        <div class="cu-btn-cnt">
          <ClaveUnicaLogin />
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import Logo from '../../../image/navbar/Logo.vue'
import ClaveUnicaLogin from './ClaveUnicaLogin.vue'
export default {
  components: {
    Logo,
    ClaveUnicaLogin
  }
}
</script>

<style scoped>
.logo {
  margin-top: 10px;
  margin-left: 20px;
}

.cu-btn-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
}

.menu-sibedar-link {
  background: #f8f9fa;
  height: 57px;
}

.menu-sibedar-link:hover {
  background: #24c6ab;
}

.link-sibedar {
  margin-top: 10px;
  margin-left: 11px;
  text-decoration: none;
  color: #080d46;

  width: 306px;
  text-align: inherit;
}

.hamburguer {
  border: none;
  background: #fff;
  font-size: 24px;
}

.hamburguer:hover {
  border: none;
  background: #fff;
  font-size: 24px;
}

.hamburguer:focus {
  background: #24c6ab;
}
</style>
