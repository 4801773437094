<template>
  <div class="container">
    <Title v-if="!isAdmin" :showMinister="true" :showService="true" />

    <Breadcrumb :links="breadCrumbData" />

    <Carrusel />

    <div class="container">
        <Button />

        <Visualizar :idServicio="idServicio" seccion="quienes_somos" />

        <h1 class="privado-titulo"> Presentación del Servicio - Quiénes Somos</h1>

        <section class="section-table" v-if="contenido">
        <!-- - Leyes y Normativas que rigen el funcionamiento de la Institución -->
        <!-- - Misión Institucional -->
        <TableTwo :contenido="contenido" />
        <!-- - Aspectos Relevantes contenidos en la Ley de Presupuestos año 2020 -->
        <TableFour :contenido="contenido" />
        <!-- - Objetivos Estratégicos -->
        <TableThree :contenido="contenido" />
        <!-- - Productos Estratégicos vinculados a Objetivos Estratégicos -->
        <TableFive :contenido="contenido" />
        <!-- - Clientes / Beneficiarios / Usuarios -->
        <TableOne :contenido="contenido" />
        </section>
        <section v-else>
        <AlertError />
        </section>
    </div>
  </div>
</template>

<script>
import { utils } from '@/components/tools/ViewUtils.js'
import axios from 'axios'

import Title from '@/components/private/Title'
import Breadcrumb from '@/components/tools/Breadcrumb.vue'
import Carrusel from '@/components/private/Carrusel.vue'
import Button from './Button.vue'
import { mapState } from 'vuex'
// import axiosClient from '@/config/axiosClient'
import TableOne from './table/TableOne.vue'
import TableTwo from './table/TableTwo.vue'
import TableThree from './table/TableThree.vue'
import TableFour from './table/TableFour.vue'
import TableFive from './table/TableFive.vue'
import AlertError from '@/components/error/AlertError.vue'
import Visualizar from '@/components/button/escritorio/Visualizar.vue'

export default {
  mixins: [utils],
  components: {
    Title,
    Carrusel,
    Breadcrumb,
    Button,
    TableOne,
    TableTwo,
    TableThree,
    TableFour,
    TableFive,
    AlertError,
    Visualizar
  },
  computed: {
    // get User
    ...mapState('sesion', ['usuario', 'relacionUS'])
    // isAdmin: function () {
    //   return this.usuario.username === process.env.VUE_APP_USER_ADMIN
    // }
  },
  data () {
    return {
      permission: true,
      breadCrumbData: null,
      contenido: null,
      anexo: null,
      idServicio: this.$route.params.idServicio
    }
  },
  methods: {
    // name of breadcrumb
    setBreadcrumbData: function () {
      this.breadCrumbData = [
        { name: 'Portada BGI', routeName: 'bgi', routeParams: { idUsuario: this.usuario.id } },
        { name: 'Escritorio de Gestión Virtual', routeName: 'buttons', routeParams: { idUsuario: this.usuario.id, idServicio: this.$route.params.idServicio } },
        { name: 'Presentación del Servicio - Quiénes Somos', routeName: 'somos', routeParams: { idServicio: this.$route.params.idServicio } }
      ]
    },
    // USER SERVICE RELATIONSHIP
    obtenerRelacionUsuarioServicio () {
      const relacionUs = this.usuario.servicios.find(
        (s) => s.id === parseInt(this.$route.params.idServicio)
      )
      if (!relacionUs) return
      if (
        relacionUs.permisos.find((p) => p.permisoId === 8) ||
        relacionUs.rolId === 3 ||
        relacionUs.rolId === 4
      ) {
        this.permission = true
      }
    },

    obtenerContenido: function () {
      var params = {
        servicio: this.$route.params.idServicio,
        anio: this.anioBgi,
        anexo_str: 'quienes_somos'
      }
      axios.get('/anexos/anexo_cf/', { params: params }).then(res => {
        var anexo = res.data[0]
        this.contenido = anexo.datos
        if (anexo.datos.no_aplica === undefined) { anexo.datos.no_aplica = false }
        this.anexo = anexo
      })
    }
  },
  mounted () {
    this.setBreadcrumbData()
    this.obtenerContenido()
    this.obtenerRelacionUsuarioServicio()
  }
}
</script>

<style scoped>
.title {
  width: 100%;
}
h5 {
  text-align: center !important;
  color: #052333;
  font-size: 40px;
  font-weight: bold;
}

.section-table{
  margin-left: 30px;
  margin-right: 30px;
}

@media screen and (max-width: 1800px) {
  h5 {
    color: #052333;
    font-size: 36px;
    font-weight: bold;
  }
}

@media screen and (max-width: 1600px) {
  h5 {
    font-size: 31px;
  }
}

@media screen and (max-width: 1400px) {
  h5 {
    font-size: 24px;
  }
}

@media screen and (max-width: 1100px) {
  h5 {
    font-size: 22px;
  }
}

@media screen and (max-width: 800px) {
  h5 {
    font-size: 18px;
  }
}
</style>
