<template>
  <div class="">
    <b-container fluid="md">
      <b-row>
        <div>
          <div class="left">
            <MinHacienda />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MinHacienda from '../../../image/layout/MinHacienda.vue'
export default {
  components: {
    MinHacienda
  }
}
</script>

<style scoped>
.left {
  top: 12px;
}
</style>
