<template>
  <div>
    <b-button variant="link" class="modal-button" id="show-btn" @click="$bvModal.show('bv-modal-example')">
      <Config /> {{ button }}
    </b-button>
    <b-modal size="lg" id="bv-modal-example" hide-footer>
      <template #modal-title><h4 class="title-modal">{{ title }}</h4></template>

        <div class="container" style="margin-top:5px">
            Describir brevemente los premios o reconocimientos logrados el periodo 2018-2021 por la institución, señalando el motivo del premio o reconocimiento y el efecto en los funcionarios, si corresponde.
      </div>

    </b-modal>
  </div>
</template>

<script>
import Config from '@/components/image/icon/Config'
export default {
  props: ['html'],
  components: {
    Config
  },
  data () {
    return {
      button: 'Instrucciones: ¿Cómo completar esta sección?'
    }
  }
}
</script>

<style scoped>
.modal-button {
  display: flex;
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.modal-button:hover {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.modal-button:focus {
  color: #052333;
  background: none;
  border: none;
  font-weight: bold;
}

.title-modal {
  color: #052333;
  font-size: 20px;
  text-align: center;
}

</style>
