<template>
  <div>
    <div class="home">
      <Navbar />
    </div>

    <div>
      <div>
        <Header
          pretitle="DOTACIÓN"
          title="Análisis de la dotación del Estado"
        />
      </div>
      <b-container>
        <br />
        <br />
        <b-img
          src="/img_fondef/dotacion/Minsal_dotacion_por_regimen.png"
          fluid
        />

        <blockquote class="blockquote text-center">
          <p class="lead">
            Dotación del Ministerio de Salud
          </p>
          <footer class="blockquote-footer">
            Diferencias por régimen de contratación y a través de 11 años.
          </footer>
        </blockquote>
        <br /><br /><br />
        <b-row>
          <b-col sm="12" md="7">
            <b-img src="/img_fondef/dotacion/Minsal_dotacion1.png" fluid />
            <p class="lead center">
              Ministerio de Salud. Dotación total por año*
            </p>
            <p>
              *Estos números están dados a partir de personas correctamente
              identificadas.
            </p>
          </b-col>
          <b-col sm="12" md="5">
            <b-img src="/img_fondef/dotacion/Minsal_dotacion_sexo.png" fluid />
            <p class="lead center">
              Proporción de mujeres y hombres en la dotación del Ministerio de
              Salud
            </p>
            <p></p>
          </b-col>
        </b-row>
        <br /><br /><br />
        <PowerBI
          url="https://app.powerbi.com/view?r=eyJrIjoiNGY2ZTk1ODAtNDlhMy00Mjc5LWIzZDctYzRlMTgwN2E3ZjMwIiwidCI6ImI1ZDc4OTI3LTI1ZDAtNDRhOS04MzcwLWQ4NmU1N2M3YmE5NiIsImMiOjR9"
        />
      </b-container>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Header from './components/header/Header.vue'
import PowerBI from './components/powerbi/PowerBI.vue'

export default {
  components: {
    Navbar,
    Header,
    PowerBI
  }
}
</script>

<style scoped>
p {
  font-family: Georgia, serif;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin: 2rem 0;
}

.lead {
  font-size: 1.7rem;
  margin: 3.4rem 0 1.7rem 0;
  font-family: sans-serif;
}

.center {
  text-align: center;
}
</style>
